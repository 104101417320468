import Vue from 'vue';

import numeral from 'numeral'
// load a locale
numeral.register('locale', 'vi', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'nghìn',
    million: 'triệu',
    billion: 'tỷ',
    trillion: 'nghìn tỷ'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: 'VNĐ'
  }
})

// switch between locales
numeral.locale('vi')

Vue.filter('formatNumber', function (value) {
    return numeral(value).format('0,0.')
})

/*
Use it:
<tr v-for="(item, key, index) in tableRows">
  <td>{{item.value | formatNumber}}</td>
</tr>

*/