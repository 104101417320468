<template>
    <div>
        <!-- Bar chart -->
        <div class="msub-title" style="margin-top: 50px">II.2 Số quyết định xử phạt theo nhóm hành vi</div>

        <div style="text-align: left; font-weight: bold; margin-bottom: 20px; margin-top: 20px">Biểu đồ số quyết định xử phạt theo nhóm hành vi</div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <highcharts :options="barChart"></highcharts>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { Chart } from 'highcharts-vue'

    export default {
        name: 'report-org-bar-chart',
        components: {
            highcharts: Chart,
        },
        data() {
            return {
                barChart: {
                    accessibility: {
                        enabled: false
                    },
                    chart: {
                        type: 'bar'
                    },
                    title: {
                        text: ''   //Biểu đồ số lượng hành vi vi phạm
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: [ // Categories theo group mẫu, mặc định
                            'Nhóm hành vi',
                            'Nhóm hành vi'
                        ],
                        title: {
                            text: ''
                        }
                    },
                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: 'Số quyết định',
                            align: 'high'
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    tooltip: {
                        borderWidth: 1,
                        shadow: true,
                        useHTML: false,
                        style: {
                            padding: 0
                        },
                        valueSuffix: '',   // Đơn vị tính ha, m3, hành vi, ....
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                        // x: 0,
                        // y: 0,
                        floating: false,
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true,
                        enabled: true               // Hide / Show Legend
                    },
                    credits: {
                        enabled: false           // Hide highchart.com
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true                   // Show / hide value on column
                            }
                        }
                    },
                    series: [ ]
                },
            }
        },
        computed: {
			...mapGetters([
                'getBehaviorOrganization'
			]),
		},
        async created() {
            await this.createSeriesBarChart(this.getBehaviorOrganization.behavior)
        },
        watch: {
            '$store.state.report.behavior_organization': async function() {
                await this.createSeriesBarChart(this.getBehaviorOrganization.behavior)
            }
        },
		methods: {
            createSeriesBarChart(data_behavior){
                var series = []
                if(data_behavior.length){
                    data_behavior.forEach(obj => {
                        var obj_serie = { // Không thích hợp
                            name: obj.behavior_name,
                            data: [parseInt(obj.num_behavior)]
                        }
                        series.push(obj_serie)
                    })
                    this.barChart.series = series
                }else{
                    this.barChart.series = []
                }
            }
        }
}
</script>

<style scoped>
    .msub-title {
        color: #307484;
        font-weight: bold;
    }
</style>