const DATE_TYPE = {
    DATE_CREATED: 1,
    DATE_ISSUED: 2
};

const STATUS_DECISION = {
    STATUS_WAIT: 1,
    STATUS_DOING: 2,
    STATUS_COMPLATED: 3
};

const LOCATION_LEVEL = {
    NATION: 1,
    REGION: 2,
    PROVINCE: 3,
    DISTRICT: 4
};

const NATION_CODE = 704
  
// Export
export { 
    DATE_TYPE,
    STATUS_DECISION,
    LOCATION_LEVEL,
    NATION_CODE
};