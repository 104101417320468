<template>
    <div>
        <div class="card" style="border-radius: 0px;">
            <div class="card-header ui-sortable-handle" style="padding-top: 15px;">
                <h3 class="card-title">
                    <i class="ion ion-clipboard mr-1"></i>
                    Căn cứ
                </h3>
            </div>
            <div class="card-body">

                <!-- I. MẪU VĂN BẢN -->
                <!-- ========================================================================= -->
                <div>
                    <div class="mheader-1"> <i class="fas fa-file-word fa-lg"></i> Mẫu quyết định </div>

                    <!-- Cơ quan ban hành -->
                    <div class="row">
                        <div class="col-md-5 bold txt-center">
                            <!-- Nếu chưa nhập -->
                            <div v-if="ldecision_organization === ''">
                                <span class="num1 " :class="ldecision_organization">1</span> CƠ QUAN
                            </div>

                            <!-- Nếu nhập -->
                            <div style="text-transform: uppercase;"
                                v-if="ldecision_organization !== ''"
                                v-html="decision_organization"></div>

                            <!-- Đường gạch dưới  -->
                            <div>--------------</div>
                        </div>
                        <div class="col-md-7 bold txt-center">
                            <div class="bold">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</div>
                            <div>Độc lập - Tự do - Hạnh phúc</div>
                            <div>--------------</div>
                        </div>
                    </div>

                    <!-- Số quyết định | Ngày tháng ban hành -->
                    <div class="row">
                        <div class="col-md-5 txt-center">
                            <div>
                                <span class="num1 " :class="lviolation_decision_number">2</span> 
                                Số: <span v-html="html_violation_decision_number"></span>/QĐ-XPHC
                            </div>
                        </div>
                        <div class="col-md-7 txt-center italic">
                            <div>
                                <!-- Địa danh -->
                                <span class="num1 " :class="ldecision_site">3</span> 
                                <span v-html="html_decision_site"></span>, 
                                
                                <!-- Ngày / tháng / năm -->
                                <span class="num1 " :class="ldecision_date">4</span> ngày </span> 
                                <span v-html="html_date"></span> <span> tháng </span> 
                                <span v-html="html_month"></span><span> năm </span> 
                                <span v-html="html_year"></span></div>
                        </div>
                    </div>

                    <!-- Tiêu đề quyết định -->
                    <div class="row bold txt-center mtop-bottom-40">
                        <div>QUYẾT ĐỊNH</div>
                        <div>Xử phạt vi phạm hành chính</div>
                        <div>
                            <span class="num1 " :class="ldecision_title_agency_name">5</span> 
                            <span v-html="html_decision_title_agency_name"></span>
                        </div>
                    </div>

                    <!-- Căn cứ -->
                    <div class="row mtop-40" style="padding-left: 100px;">
                        <div v-if="decision_law_base === ''">
                            <span class="num1 txt-center" :class="ldecision_law_base">6</span>
                            <span> Căn cứ ban hành văn bản</span>
                        </div>
                        <div class="txt-left">
                            <span else v-html="decision_law_base"></span>
                        </div>
                    </div>
                </div>
                <!-- /I. MẪU VĂN BẢN -->
                <!-- <hr class="mtop-bottom-40"> -->
            </div>

            <div class="card-body mbg-input">
                <!-- II. NHẬP THÔNG TIN -->
                <!-- ========================================================================== -->
                <div>
                    <div class="mheader-2"> 
                        <i class="fas fa-marker fa-lg"></i> Nhập thông tin 
                        <i class="fas fa-backspace scroll-up fa-lg txt-right micon" @click="resetForm()"></i>
                    </div>

                    <div class="row">
                        <!-- Cơ quan -->
                        <div class="mheader-3"> 
                                
                                <span class="mybadge txt-right">1</span> </div>
                        <div class="col-md-6">
                            <i class="fas fa-university micon"></i> Cơ quan chủ quản
                            <div class="input-group mb-3">
                                <input v-model="decision_organization_head" type="text" class="form-control" placeholder="Cơ quan chủ quản" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <i class="fas fa-university micon"></i> Cơ quan ban hành
                            <div class="input-group mb-3">
                                <input v-model="decision_organization_name" type="text" class="form-control" placeholder="Cơ quan ban hành" autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Số quyết định -->
                        <div class="col-md-6">
                            <div class="mheader-3"> <i class="fas fa-list-ol micon"></i> 
                            Quyết định số 
                            <span class="mybadge txt-right">2</span></div>
                            <div class="input-group mb-3">
                                <input v-model="violation_decision_number" type="text" class="form-control" placeholder="Ghi số quyết định xử phạt" autocomplete="off">
                            </div>
                        </div>

                        <!-- Địa danh -->
                        <div class="col-md-6">
                            <div class="mheader-3"> <i class="fas fa-list-ol micon"></i> 
                            Địa danh
                            <span class="mybadge txt-right">3</span></div>
                            <div class="input-group mb-3">
                                <input v-model="decision_site" type="text" class="form-control" placeholder="Ghi địa danh theo hướng dẫn về thể thức của Chính phủ" autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Ngày tháng năm -->
                        <div class="col-md-6">
                            <div class="mheader-3"> <i class="fas fa-calendar-alt micon"></i> 
                            Ngày, tháng, năm ban hành <span class="mybadge txt-right">4</span></div>
                                
                            <!-- :min-date="new Date()" -->
                            <v-date-picker 
                                :masks="masks"
                                :attributes='attrs'
                                show-weeknumbers
                                @input="handExtractDateMMYYY()"
                                v-model="decision_date">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="input-group">
                                        <input
                                            placeholder="Ghi ngày, tháng, năm ban hành quyết định xử phạt: DD-MM-YYYY"
                                            class="form-control"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>

                        <!-- Thẩm quyền ban hành -->
                        <div class="col-md-6">
                            <div class="mheader-3"> 
                                <i class="fas fa-user-tie micon"></i> 
                                    Thẩm quyền ban hành <span class="mybadge txt-right">5</span> </div>
                            <div class="input-group mb-3">
                                <input v-model="decision_title_agency_name" type="text" class="form-control" placeholder="Ghi chức danh và tên cơ quan của người ra quyết định" autocomplete="off">
                            </div>
                        </div>

                    </div>

                    <!-- Căn cứ -->
                    <div class="row">
                        <div class="col-md-12" style="margin-top: 10px;">
                            <p> <i class="fas fa-gavel micon"></i> 
                                Căn cứ ban hành  <span class="mybadge txt-right">6</span> </p>
                                <!-- <textarea class="form-control" rows="3" placeholder="Nhập các căn cứ ban hành quyết định xử phạt" v-model="decision_law_base"></textarea> -->
                            <ckeditor :editor="editor" :config="editorConfig" v-model="decision_law_base"></ckeditor>
                        </div>
                    </div>
                    <div id="article11" style="margin-bottom: 20px;"></div>
                </div>
                <!-- /II. NHẬP THÔNG TIN -->

            </div>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import moment from 'moment';
    import { fixMonthAndDay } from '@/helper/index'

    export default {
        name: 'vd-article0',
        data() {
            return {

                // Violation decision
                decision_organization_head: '',             // * Cơ quan chủ quản
                decision_organization_name: '',             // * Tên cơ quan tổ chức ra quyết định (1)
                violation_decision_number: '',              // * Số quyết định (2)
                decision_site: '',                          // * Địa danh (3)
                decision_date: '',                          // * Ngày tháng năm ra quyết định xử phạt (v-calendar) (4)
                date: '',                                   // date: trích từ decision_date
                month: '',                                  // month
                year: '',                                   // year
                decision_title_agency_name: '',             // * Thẩm quyền lãnh đạo cơ quan ban hành (5)
                decision_law_base: `<span style="font-style: italic"> Căn cứ (Theo yêu cầu của cơ quan ban hành)........................................ ; </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Điều 57, Điều 68, Điều 70, Điều 78, Điều 85 Luật Xử lý vi phạm hành chính (sửa đổi, bổ sung năm 2020); </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Nghị định số 35/2019/NĐ-CP ngày 25 tháng 4 năm 2019 của Chính phủ quy định xử phạt vi phạm hành chính trong lĩnh vực Lâm nghiệp; Nghị định số 07/2022/NĐ-CP ngày 10 tháng 01 năm 2022 của Chính phủ sửa đổi, bổ sung một số điều của các Nghị định về xử phạt vi phạm hành chính trong lĩnh vực lâm nghiệp; bảo vệ và kiểm dịch thực vật; thú y; chăn nuôi; </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Biên bản vi phạm hành chính số:.../BB-VPHC lập ngày …/…/……; </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Biên bản phiên giải trình trực tiếp số: ..../BB-GTTT lập ngày …/…/…… (nếu có); </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Biên bản số: .../BB-XM lập ngày …/…/…… xác minh tình tiết của vụ việc vi phạm hành chính (nếu có); </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Quyết định số: ..../QĐ-GQXP ngày …/…/…… về việc giao quyền xử phạt vi phạm hành chính (nếu có).`, // * Căn cứ

                // CKEditor
				editor: ClassicEditor,
				editorConfig: {
					toolbar: {
						items: [
							'heading', '|',
							// 'fontfamily', 'fontsize', '|',
							// 'alignment', '|',
							// 'fontColor', 'fontBackgroundColor', '|',
							'bold', 'italic', '|',                      //'strikethrough', 'underline',
							'outdent', 'indent', '|',
							'bulletedList', 'numberedList', '|', 		// 'todoList', 
							// 'insertTable', '|',
							'undo', 'redo'
						],
						shouldNotGroupWhenFull: true
					}
				},

                // VCalendar
				selectedDate: null,
				attrs: [
					{
						key: 'today',
						highlight: true,       // dot
						dates: new Date()
					}
				],
				masks: {
					input: 'DD-MM-YYYY',
				}
                
            }
        },
        computed: {
			...mapGetters([
              
			]),

            // Hiển thị cơ quan
            decision_organization: function (){
                if(this.decision_organization_head === '' & this.decision_organization_name === '') return ''
                if(this.decision_organization_head !== '' & this.decision_organization_name === '') return this.decision_organization_head
                if(this.decision_organization_head === '' & this.decision_organization_name !== '') return this.decision_organization_name
                return this.decision_organization_head + '<br>' + this.decision_organization_name
            },

            // Quyết định xử phạt
            // 1. Cơ quan
            ldecision_organization: function (){
                if(this.decision_organization_head === '' & this.decision_organization_name === '') return ''
                return 'hide'
            },

            // 2. Số quyết định
            lviolation_decision_number: function (){
                if(this.violation_decision_number === '') return ''
                return 'hide'
            },

            html_violation_decision_number: function (){
                if(this.violation_decision_number === '') return '.....'
                return '<span class="bold">' + this.violation_decision_number +'</span>'
            },

            // 3. Địa danh
            ldecision_site: function (){
                if(this.decision_site === '') return ''
                return 'hide'
            },

            html_decision_site: function (){
                if(this.decision_site === '') return '.......'
                return '<span class="bold">' + this.decision_site +'</span>'
            },

            // 4. Ngày tháng năm ban hành
            ldecision_date: function (){
                if(!this.decision_date) return ''
                return 'hide'
            }, 
            
            html_date: function (){
                if(!this.date || this.date === "Invalid date") return '.......'
                return '<span class="bold">' + this.date +'</span>'
            },

            html_month: function (){
                if(!this.month || this.month === "Invalid date") return '.......'
                return '<span class="bold">' + this.month +'</span>'
            },

            html_year: function (){
                if(!this.year || this.year === "Invalid date") return '.......'
                return '<span class="bold">' + this.year +'</span>'
            },

            // 5. Thẩm quyền ban hành
            ldecision_title_agency_name: function (){
                if(this.decision_title_agency_name === '') return ''
                return 'hide'
            },

            html_decision_title_agency_name: function (){
                if(this.decision_title_agency_name === '') return ' THẨM QUYỀN BAN HÀNH'
                return '<span class="bold">' + this.decision_title_agency_name.toUpperCase() +'</span>'
            },

            ldecision_law_base: function (){
                if(this.decision_law_base === '') return ''
                return 'hide'
            },
		},
		methods: {
            // ADD
            // =========================================================================
            // Ngày tháng năm
            handExtractDateMMYYY(){
                this.date = fixMonthAndDay(moment(this.decision_date).format('DD'))
                this.month = fixMonthAndDay(moment(this.decision_date).format('MM'))
                this.year = moment(this.decision_date).format('YYYY')
            },

            resetForm(){ // reset all fields
                this.decision_organization_head = ''
                this.decision_organization_name = ''             
                this.violation_decision_number = ''                      
                this.decision_date = ''  
                this.decision_site = ''              
                this.date = ''              
                this.month = ''            
                this.year = ''                
                this.decision_title_agency_name = '' 
                this.decision_law_base = `<span style="font-style: italic"> Căn cứ (Theo yêu cầu của cơ quan ban hành)........................................ ; </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Điều 57, Điều 68, Điều 70, Điều 78, Điều 85 Luật Xử lý vi phạm hành chính (sửa đổi, bổ sung năm 2020); </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Nghị định số 35/2019/NĐ-CP ngày 25 tháng 4 năm 2019 của Chính phủ quy định xử phạt vi phạm hành chính trong lĩnh vực Lâm nghiệp được sửa đổi, bổ sung bởi Nghị định số 07/2022/NĐ-CP ngày 10 tháng 01 năm 2022 của Chính phủ sửa đổi, bổ sung một số điều của các Nghị định về xử phạt vi phạm hành chính trong lĩnh vực lâm nghiệp; bảo vệ và kiểm dịch thực vật; thú y; chăn nuôi; </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Biên bản vi phạm hành chính số:.../BB-VPHC lập ngày …/…/……; </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Biên bản phiên giải trình trực tiếp số: ..../BB-GTTT lập ngày …/…/…… (nếu có); </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Biên bản số: .../BB-XM lập ngày …/…/…… xác minh tình tiết của vụ việc vi phạm hành chính (nếu có); </span> <br/>
                                    <span style="font-style: italic"> Căn cứ Quyết định số: ..../QĐ-GQXP ngày …/…/…… về việc giao quyền xử phạt vi phạm hành chính (nếu có).`
            },

            // EDIT
            // ================================================================================================
            setEdit(data){
                // console.log("data setEdit ===", data)

                this.decision_organization_head = data.decision_organization_head
                this.decision_organization_name = data.decision_organization_name
                this.violation_decision_number  = data.violation_decision_number
                this.decision_title_agency_name = data.decision_title_agency_name
                this.decision_law_base          = data.decision_law_base
                this.decision_site              = data.decision_site
                
                var check_date = parseInt(moment(data.decision_date).format('YYYY'))
                if(check_date !== 1900 && check_date !== 1899){
                    this.decision_date          = data.decision_date
                    this.date = fixMonthAndDay(moment(this.decision_date).format('DD'))
                    this.month = fixMonthAndDay(moment(this.decision_date).format('MM'))
                    this.year = moment(this.decision_date).format('YYYY')
                }
            }
		}
    }
</script>

<style scoped>
.mheader-3{
    color: #5C677D;
    margin-bottom: 5px;
    margin-top: 5px;
}

</style>