import axiosInstance from '@/plugin/axios.js';

export default {

    // ==========================================================================================
    //                                       ADD DECISION                                      //
    // ==========================================================================================
    async actSavePenaltyDecision({ commit }, data) { // Login và set current user, access token
        try {

            // console.log("Edit status === ", data.edit_status)
            var edit_status = data.edit_status

            // Convert to JSON
            var decision = JSON.stringify(data)

            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('decision', decision)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            if(edit_status === false){ // Add
                var res = await axiosInstance.post('/penalty_decision/add', bodyFormData, config)
            }

            if(edit_status === true){ // Edit
                var res = await axiosInstance.post('/penalty_decision/edit', bodyFormData, config)
            }

            // console.log('res actSavePenaltyDecision= ', res)

            if (res.status === 200) {
                return {
                    ok: true
                }
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actSavePenaltyDecision: ', error)
        }
    },


    // ==========================================================================================
    //                                    DELETE DECISION                                      //
    // ==========================================================================================
    // DELETE VIOLATION DECISION
    async actDeleteDecision({ commit }, data) { // Sử dụng lấy thông tin khi tạo và xóa user

        try {

            var data_json = JSON.stringify(data)
            var res = await axiosInstance.delete('/penalty_decision/violation_decision/' + data_json)
            var result = res.data
            // console.log('result actDeleteDecision = ', res.data)

            if (res.status === 200) {
                if(data.entity_type_id === 1) commit('GET_LIST_INDIVIDUAL', result)
                if(data.entity_type_id === 2) commit('GET_LIST_ORGANIZATION', result)

                return {
                    ok: true,
                    data: res.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actDeleteDecision: ', error)
        }
    },

    // ==========================================================================================
    //                                    GET DECISION                                         //
    // ==========================================================================================
    // GET VIOLATION DECISION BY VIOLATION_DECISION_ID
    async actGetViolationDecisionById({ }, data_json) { // Sử dụng lấy thông tin khi tạo và xóa user
        try {

            var res = await axiosInstance.get('/penalty_decision/violation_decision_info/' + data_json)
            // console.log('result actGetViolationDecisionById = ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    data: res.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetViolationDecisionById: ', error)
        }
    },

    // GET INDIVIDUAL VIOLATION DECISION
    async actGetIndividualViolationByUser({ commit }, data) { // Sử dụng lấy thông tin khi tạo và xóa user

        try {

            var data_json = JSON.stringify(data)
            var res = await axiosInstance.get('/penalty_decision/list_individual/' + data_json)
            const result = res.data;

            if (res.status === 200) {
                commit('GET_LIST_INDIVIDUAL', result)

                return {
                    ok: true,
                    data: result.list_individual
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetIndividualViolationByUser: ', error)
        }
    },


    // GET ORGANIZATION VIOLATION DECISION
    async actGetOrganizationViolationByUser({ commit }, data) { // Sử dụng lấy thông tin khi tạo và xóa user
        try {

            var data_json = JSON.stringify(data)
            var res = await axiosInstance.get('/penalty_decision/list_organization/' + data_json)
            const result = res.data;
            console.log('result actGetOrganizationViolationByUser = ', result)

            if (res.status === 200) {
                commit('GET_LIST_ORGANIZATION', result)

                return {
                    ok: true,
                    data: result.list_organization
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetOrganizationViolationByUser: ', error)
        }
    },


    // ==========================================================================================
    //                                 UPLOAD DECISION                                         //
    // ==========================================================================================
    // Upload quyết định xử phạt
    async uploadViolationDecision({commit}, data) {

        try {
            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_id', data.user_id)
            bodyFormData.append('role_id', data.role_id)
            bodyFormData.append('location_level_code', data.location_level_code)
            bodyFormData.append('violation_decision_id', data.violation_decision_id)
            bodyFormData.append('decision_status_id', data.decision_status_id)
            bodyFormData.append('decision_status_note', data.decision_status_note)
            bodyFormData.append('entity_type_id', data.entity_type_id)
            bodyFormData.append('date_type', data.date_type)
            bodyFormData.append('date_start', data.date_start)
            bodyFormData.append('date_end', data.date_end)

            if(data.entity_type_id === 1){ // Riêng đối với cá nhân, phải sử dụng cột violation_person_id để set decision_status_id cho bảng vd_violation_decision trường hợp có nhiều cá nhân vi phạm
                bodyFormData.append('violation_person_id', data.violation_person_id)
            }
         
            // Files
            if (data.files.length){
                for (let index = 0; index < data.files.length; index++) {
                    // Lưu ý: phải sử dụng vòng lặp for để append và phải sử dụng dấu '[' + index + ']' để nối các file lại thành files.
                    bodyFormData.append('files[' + index + ']', data.files[index]) 
                }
            }

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/penalty_decision/upload_decision', bodyFormData, config)
            var result = res.data

            if (res.status === 200) {
                if(data.entity_type_id === 1) commit('GET_LIST_INDIVIDUAL', result)
                if(data.entity_type_id === 2) commit('GET_LIST_ORGANIZATION', result)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action uploadViolationDecision: ', error)
        }
    },

    // ==========================================================================================
    //                                DOWNLOADDECISION                                         //
    // ==========================================================================================
    // Download violation document (word)
    async actDownloadDecisionOrganization({}, violation_decision_id) { // Tất cả các file thuộc về một task_id nên gửi task_id để lấy tất cả các file trên CSDL
        try {

            var config = {
                responseType: 'blob'
            }

            var res = await axiosInstance.get('/penalty_decision/download_decision_organization/' + violation_decision_id, config)
            // console.log("actDownloadDecisionOrganization ===", res)

            if (res.status === 200) {

                // Tiến hành download
                const blob = new Blob([res.data], { type: res.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'quyet_dinh_xu_phat_tochuc';
                link.click()
                URL.revokeObjectURL(link.href)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error actDownloadDecision', error)
        }
    },

    
    // Download violation document (word)
    async actDownloadDecisionIndividual({}, violation_decision_id) { // Tất cả các file thuộc về một task_id nên gửi task_id để lấy tất cả các file trên CSDL
        try {

            var config = {
                responseType: 'blob'
            }

            var res = await axiosInstance.get('/penalty_decision/download_decision_individual/' + violation_decision_id, config)

            if (res.status === 200) {

                // Tiến hành download
                const blob = new Blob([res.data], { type: res.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'quyet_dinh_xu_phat_canhan';
                link.click()
                URL.revokeObjectURL(link.href)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error actDownloadDecisionIndividual', error)
        }
    },

    // Tải quyết định xử phạt ở trạng thái "Đang thi hành"
    async actDownloadDecision({}, data) {
        try {

            var violation_decision_id = data.violation_decision_id

            var config = {
                responseType: 'blob'        
            } 

            // Lấy dữ liệu qua API
            var res = await axiosInstance.get('/penalty_decision/download_decision/' + violation_decision_id, config)
            // console.log('actDownloadDecision = ', res.status)

            if (res.status === 200) {
                // Tiến hành download
                const blob = new Blob([res.data], { type: res.headers['content-type'] })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'Quyet dinh xu phat';
                link.click()
                URL.revokeObjectURL(link.href)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actDownloadDecision: ', error)
        }
    },


    // ==========================================================================================
    //                                CHANGE STATUS DECISION                                   //
    // ==========================================================================================
    // Chuyển quyết định từ trạng thái "Đang thi hành" sang trạng thái "Đã thi hành"
    async actConfirmCompleteDecision({commit}, data) {
        try {

            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_id', data.user_id)
            bodyFormData.append('role_id', data.role_id)
            bodyFormData.append('location_level_code', data.location_level_code)
            bodyFormData.append('violation_decision_id', data.violation_decision_id)
            bodyFormData.append('decision_status_id', data.decision_status_id)
            bodyFormData.append('decision_status_note', data.decision_status_note)
            bodyFormData.append('entity_type_id', data.entity_type_id)
            bodyFormData.append('date_type', data.date_type)
            bodyFormData.append('date_start', data.date_start)
            bodyFormData.append('date_end', data.date_end)
            bodyFormData.append('execution_date', data.execution_date)

            if(data.entity_type_id === 1){ // Riêng đối với cá nhân, phải sử dụng cột violation_person_id để set decision_status_id cho bảng vd_violation_decision trường hợp có nhiều cá nhân vi phạm
                bodyFormData.append('violation_person_id', data.violation_person_id)
            }

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/penalty_decision/complete_decision', bodyFormData, config)
            var result = res.data
            // console.log('actConfirmCompleteDecision = ', result)

            if (res.status === 200) {
                if(data.entity_type_id === 1) commit('GET_LIST_INDIVIDUAL', result)
                if(data.entity_type_id === 2) commit('GET_LIST_ORGANIZATION', result)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actConfirmCompleteDecision: ', error)
        }
    },

    // ==========================================================================================
    //                                           SEARCH                                        //
    // ==========================================================================================
    // Tra cứu thông tin từ "DANH SÁCH KẾT QUẢ XỬ PHẠT" đối tượng cá nhân
    async actSearchViolationDecisionIndividual({ commit }, data_json) { // Tìm kiếm đối tượng vi phạm ở trang "Danh sách kết quả xử phạt"
        try {
            var res = await axiosInstance.get('/penalty_decision/search_individual/' + data_json)
            const result = res.data;
            if (res.status === 200) {
                commit('GET_LIST_INDIVIDUAL', result)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actSearchViolationDecisionIndividual: ', error)
        }
    },


    // Tra cứu thông tin từ "DANH SÁCH KẾT QUẢ XỬ PHẠT" đối tượng tổ chức
    async actSearchViolationDecisionOrganization({ commit }, data_json) { // Tìm kiếm đối tượng vi phạm ở trang "Danh sách kết quả xử phạt"
        try {
            var res = await axiosInstance.get('/penalty_decision/search_organization/' + data_json)
            const result = res.data;

            console.log('result actSearchViolationDecisionOrganization = ', result)

            if (res.status === 200) {
                commit('GET_LIST_ORGANIZATION', result)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actSearchViolationDecisionOrganization: ', error)
        }
    },
    // END SEARCH ///////////////////////////////////////////////////////////////////////////////


    // ==========================================================================================
    //                                           EDIT                                          //
    // ==========================================================================================
    async actGetInfoToEdit({}, data_json) { // Tìm kiếm đối tượng vi phạm ở trang "Danh sách kết quả xử phạt"
        try {
            var res = await axiosInstance.get('/penalty_decision/edit_info/' + data_json)
            // console.log('result actGetInfoToEdit = ', res.data)

            if (res.status === 200) {

                return {
                    ok: true,
                    data: res.data.edit_info
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetInfoToEdit: ', error.response.data)
        }
    },

    async actIsEdit({commit}, data) { // Xác nhận chỉnh sửa
        try {

        commit('IS_EDIT', data)

        } catch (error) {
            console.log('Error action actIsEdit', error)
        }
    },


    // ==========================================================================================
    //                                         SAVE NOTE                                       //
    // ==========================================================================================
    async actSaveNote({commit}, data) { // Lưu thông tin ghi chú
        try {
            // console.log('actSaveNote = ', data)
            var data_json = JSON.stringify(data)     

             // Data
             let bodyFormData = new FormData();
             bodyFormData.append('data_json', data_json)
 
             let config = {
                 headers: {
                     'Content-Type': 'multipart/form-data'
                 }
             }
 
            var res = await axiosInstance.post('/penalty_decision/save_note', bodyFormData, config)
            // console.log('result actSaveNote = ', res.data)

            if (res.status === 200) {
                if(data.entity_type_id === 1) commit('GET_LIST_INDIVIDUAL', res.data)
                if(data.entity_type_id === 2) commit('GET_LIST_ORGANIZATION', res.data)

                return {
                    ok: true,
                    data: res.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actSaveNote: ', error.response.data)
        }
    },

}