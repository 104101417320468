<template>
    <div class="card-header ui-sortable-handle">      
        <div class="txt-left">
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive0 ? 'mbtn-active' : ''" @click="handleIndividual(1)">Cá nhân</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive1 ? 'mbtn-active' : ''" @click="handleOrgnization(2)">Tổ chức</button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'navbar-report',
    data() {
        return {
            // Set individual or organization
            entity_type_id: 1,  // Mặc định là cá nhân

            // isActive navbar
            isActive0: true,
            isActive1: false
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentUser'
        ])
    },
    methods: {
        ...mapActions([
            'actGetIndividualViolationByUser',
            'actGetOrganizationViolationByUser'
        ]),

        handleIndividual(entity_type_id) {
            // Set active cá nhân
            this.entity_type_id = entity_type_id

            // Active navbar
            this.isActive0 = true
            this.isActive1 = false

            // Get user info, the user is logging.
            var data = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_level_code: this.getCurrentUser.location_level_code,
                date_type: 1,                                                       // Mặc định là  theo thời gian lập quyết định xử phạt           
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),
                type_get_data: 'by_user'
            }
            // console.log("data ===", data)
            this.actGetIndividualViolationByUser(data).then((data) => {
                // console.log("data individual ===", data)

                if(data.ok){
                    this.$emit('emitEntityType', 1)   // 1: mã cá nhân
                }
            })

        },

        handleOrgnization(entity_type_id) {
            // Set active tổ chức
            this.entity_type_id = entity_type_id

            // Active navbar
            this.isActive0 = false
            this.isActive1 = true

            // Get user info, the user is logging.
            var data = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_level_code: this.getCurrentUser.location_level_code,
                date_type: 1,                                                       // Mặc định là theo thời gian lập quyết định xử phạt
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD")
            }

            this.actGetOrganizationViolationByUser(data).then((data) => {
                if(data.ok){
                    this.$emit('emitEntityType', 2)   // 2: mã tổ chức
                }
            })
        }
    }
}
</script>

<style scoped>

/* Active navbar */
.mbtn-navbar{
    margin-right: 40px;
    width: 150px;
    height: 37px;
}

.mbtn-active{
    /* background-color: #E9ECEF; */
    border-bottom: 2px solid #7585ad;
}
</style>