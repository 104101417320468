

export default {
    GET_REPORT_INDIVIDUAL (state, data) {
        state.number_person_individual   = data.individual.number_person_decision
        state.number_decision_individual = data.individual.number_decision
        state.behavior_individual        = data.individual.number_behavior
        state.data_download_individual   = data.individual.data_download

        state.number_person_behavior_individual  = data.individual.number_person_behavior
        state.number_money_behavior_individual   = data.individual.number_money_behavior
        state.total_money_behavior_individual    = data.individual.total_money_behavior
        state.number_area_behavior_individual    = data.individual.number_area_behavior
        state.total_area_behavior_individual     = data.individual.total_area_behavior
    },

    GET_REPORT_ORGANIZATION (state, data) {
        state.number_decision_organization  = data.organization.number_decision
        state.behavior_organization         = data.organization.number_behavior
        state.data_download_organization    = data.organization.data_download

        state.number_org_behavior_organization     = data.organization.number_org_behavior
        state.number_money_behavior_organization   = data.organization.number_money_behavior
        state.total_money_behavior_organization    = data.organization.total_money_behavior
        state.number_area_behavior_organization    = data.organization.number_area_behavior
        state.total_area_behavior_organization     = data.organization.total_area_behavior
    },

}