<template>
    <div class="card-header ui-sortable-handle">      
        <div class="txt-left">
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive0 ? 'mbtn-active' : ''" @click="handleIndividual(1)">Cá nhân</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive1 ? 'mbtn-active' : ''" @click="handleOrgnization(2)">Tổ chức</button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'navbar-report',
    data() {
        return {
            // Set individual or organization
            entity_type_id: 1,  // Mặc định là cá nhân

            // isActive navbar
            isActive0: true,
            isActive1: false
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentUser'
        ])
    },
    methods: {
        ...mapActions([
            'actSearchIndividualViolation',
            'actSetListIndividualEmpty',
            'actGetOrganizationViolationByUser',
            'actSetListOrganizationSearchEmpty'
        ]),

        handleIndividual(entity_type_id) {
            // Set active cá nhân
            this.entity_type_id = entity_type_id
            this.actSetListIndividualEmpty()  // Tắt kết quả tra cứu trước đó đang hiển thị

            // Active navbar
            this.isActive0 = true
            this.isActive1 = false

            this.$emit('emitEntityType', 1)   // 1: mã cá nhân
        },

        handleOrgnization(entity_type_id) {
            // Set active tổ chức
            this.entity_type_id = entity_type_id
            this.actSetListOrganizationSearchEmpty()

            // Active navbar
            this.isActive0 = false
            this.isActive1 = true

            this.$emit('emitEntityType', 2)   // 2: mã tổ chức
        }
    }
}
</script>

<style scoped>

/* Active navbar */
.mbtn-navbar{
    margin-right: 40px;
    width: 150px;
    height: 37px;
}

.mbtn-active{
    /* background-color: #E9ECEF; */
    border-bottom: 2px solid #7585ad;
}
</style>