import Vue from 'vue'

/* GLOBAL VARIABLES 
    1. Asign value: Vue.prototype.$name_variable = 'value'
    2. User: this.$name_variable
*/

/* Phân biệt DEV và PRODUCTION */
// =======================================================

/* ROOT_PATH: sử dụng để link đến css, js, files
    - DEV       : ROOT_PATH = ''
    - PRODUCT   : ROOT_PATH = '/quanlycongviec'            (Do reverse proxy từ {{domain}}/quanlycongvie ---> localhost:52025  (khi DEV))
*/

// 1. ROOT PATH
// -------------------------------------------------------
// console.log('path in path config ===', process.env.VUE_APP_PUBLIC_PATH)

Vue.prototype.$ROOT_PATH = process.env.VUE_APP_PUBLIC_PATH  //'/quanlycongviec': nếu sử dụng sub-path cho production

// 2. OTHER PATH: CHỈ SỬ DỤNG ĐƯỢC VỚI HTML TRONG FILE VUE, KHÔNG SỬ DỤNG ĐƯỢC ĐỐI VỚI FILE JS
Vue.prototype.$ASSETS_PATH      = Vue.prototype.$ROOT_PATH + 'assets'









