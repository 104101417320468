<template>
    <div>
        <div class="card-body">
            <div class="row">
                <div style="padding-right: 18px;">
                    <i class="fas fa-backspace scroll-up fa-lg micon txt-right" @click="resetFormSearch()"></i>
                </div>
            </div>
            <!-- Row 1 -->
            <div class="row">
                <div class="col-md-6">
                    <div><i class="fas fa-user myicon"></i> Họ và tên</div>
                    <div class="input-group input-group mb-3">
                        <input type="text" class="form-control" v-model="full_name" placeholder="Nhập họ và tên" autocomplete="off">
                    </div>
                </div>
                <div class="col-md-6">
                    <div><i class="far fa-address-card myicon"></i> Số CMT hoặc CCCD</div>
                    <div class="input-group input-group mb-3">
                        <input type="number" class="form-control" v-model="person_id_number" placeholder="Nhập số CMT / CCCD" autocomplete="off">
                    </div>
                </div>
            </div>
        </div>

        <!-- Submit -->
        <div class="card-footer clearfix" style="min-height: 48px;">
            <button @click="searchViolationDecision()"
                    type="button" 
                    class="btn btn-outline-success btn-sm float-right"
                    style="width: 150px">
                    Tra cứu</button>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'form-search-individual',
    data() {
        return {
            // Person info
            full_name: '',
            person_id_number: ''           // * CMT / CCCD
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentUser'
        ])
    },
    methods: {
        ...mapActions([
            'actSearchIndividualViolation',
            'actSetListIndividualEmpty',
        ]),

        // Tra cứu quyết định xử phạt
        searchViolationDecision(){
            var data = {
                full_name: this.full_name,
                person_id_number: this.person_id_number
            }
            var data_json = JSON.stringify(data)

            if( // Nếu form trống (người dùng chưa nhập)
                this.full_name           === '' & 
                this.person_id_number    === ''
            ){
                // Thông báo chưa nhập thông tin
                toastr.error('Chưa nhập thông tin tra cứu!', '', {closeButton: true})

            }else{ // Nếu người dùng đã nhập --> tiến hành tìm kiếm

                this.actSearchIndividualViolation(data_json).then((res) => {

                    if(res.ok === true & res.data.length > 0){ // Có kết quả tìm kiếm
                        // Hiển thị kết quả: ghi vào getter --> hiển thị kết quả
                        // Tắt thông báo search not found trước đó (nếu có)
                        this.$emit('searchIndividualNotFound', false)

                    }else{ // Tìm không có kết quả
                        // Hiển thị thông báo không tìm thấy
                        this.$emit('searchIndividualNotFound', true)

                        // Tắt kết quả tra cứu trước đó đang hiển thị
                        this.actSetListIndividualEmpty()
                    }
                })
            }
        },

        // resetForm search
        resetFormSearch(){
            this.full_name = ''
            this.person_id_number = ''

            // Tắt hiển thị thông báo không tìm thấy
            this.$emit('searchIndividualNotFound', false)

            // Tắt kết quả tra cứu trước đó đang hiển thị
            this.actSetListIndividualEmpty()
        }
    }
}
</script>

<style scoped>
</style>