import axiosInstance from '@/plugin/axios.js';

export default {

    // Lấy danh sách Mục
    async actGetListSection({ commit }) {
        try {
            var res = await axiosInstance.get('/decree/list_section')
            // console.log('result actGetListSection === ', res.data)

            if (res.status === 200) {
                commit('GET_LIST_SECTION', res.data)

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListSection: ', error)
        }
    },

    // Chọn Mục: Lấy danh sách Điều
    async actGetListArctileBySection({ }, section_id) {
        try {
            var res = await axiosInstance.get('/decree/list_article/' + section_id)
            // console.log('result actGetListArctile === ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    list_article: res.data.list_article
                }
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListArctile: ', error)
        }
    },

    
    // Chọn Điều: Lấy danh sách Khoản
    async actGetListClauseByArticle({ }, article_id) {
        try {
            var res = await axiosInstance.get('/decree/list_clause/' + article_id)
            // console.log('result actGetListClauseByArticle === ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    list_clause: res.data.list_clause
                }
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListClauseByArticle: ', error)
        }
    },

    // Chọn Điều, Khoản: Lấy danh sách Điểm
    async actGetListPointByClause({ }, params) {

        try {
            // Data
            // console.log("params", params) 
            let bodyFormData = new FormData();
            bodyFormData.append('article_id', params.article_id)
            bodyFormData.append('clause_id_dev', params.clause_id_dev)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/decree/list_point', bodyFormData, config)
            // console.log('result actGetListPointByClause === ', res.data)

            if (res.status === 200) {
                if(res.data.result.end === false){  // Còn Điểm dưới Khoản
                    return {
                        end: false,
                        list_point: res.data.result.list_point
                    }
                }

                if(res.data.result.end === true){  // Không còn Điểm dưới Khoản
                    return {
                        end: true,
                        statistic: res.data.result.statistic
                    }
                }
                
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListPointByClause: ', error)
        }
    },


    // Chọn Điều, Khoản, Điểm: Lấy danh sách Tiết
    async actGetListUnderPointByPoint({ }, params) {

        try {
            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('article_id', params.article_id)
            bodyFormData.append('clause_id_dev', params.clause_id_dev)
            bodyFormData.append('point_id_dev', params.point_id_dev)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/decree/list_under_point', bodyFormData, config)
            // console.log('result actGetListUnderPointByPoint === ', res.data)

            if (res.status === 200) {
                if(res.data.result.end === false){  // Còn Tiết dưới Điểm
                    return {
                        end: false,
                        list_under_point: res.data.result.list_under_point
                    }
                }

                if(res.data.result.end === true){  // Không còn Tiết dưới Điểm
                    return {
                        end: true,
                        statistic: res.data.result.statistic
                    }
                }
                
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListUnderPointByPoint: ', error)
        }
    },


    // Chọn Tiết (kết thúc)
    async actStatisticUnderPoint({ }, params) {

        try {
            // Data

            let bodyFormData = new FormData();
            bodyFormData.append('article_id', params.article_id)
            bodyFormData.append('clause_id_dev', params.clause_id_dev)
            bodyFormData.append('point_id_dev', params.point_id_dev)
            bodyFormData.append('under_point_id_dev', params.under_point_id_dev)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/decree/statistic_under_point', bodyFormData, config)
            // console.log('result actStatisticUnderPoint === ', res.data)

            if (res.status === 200) {
                return {
                    end: true,
                    statistic: res.data.result.statistic
                }
                
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actStatisticUnderPoint: ', error)
        }
    },

    

    
}