<template>
	<div>
		
		<!-- FORM EDIT USER -->
			<div class="modal fade" id="edit-public-user">
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content" style="border-color: cadetblue; margin: 10px;">
						
						<!-- Header -->
						<div class="modal-header myheader-info">
							<i class="fas fa-edit myicon-info"></i>
							<p class="modal-title" style="font-size: 16px; ">Chỉnh sửa thông tin tài khoản</p>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true" style="color:white">&times;</span>
							</button>
						</div>

						<!-- Body: content edit user -->
						<div style="margin: 15px">
							<p class="mytitle"><i class="fas fa-user" style="width: 20px; color: #143F6B;"></i> Tài khoản <code>*</code><code class="txt-right" v-if="isUserNameError">{{ msgUserName }}</code></p>
							<div class="input-group mb-3">
								<input v-model="user_name" type="text" class="form-control" placeholder="Tài khoản" autocomplete="off">
							</div>

							<p class="mytitle"><i class="fas fa-user-edit" style="width: 20px; color: #143F6B;"></i> Tên đơn vị <code>*</code><code class="txt-right" v-if="isFullNameError">{{ msgFullName }}</code></p>
							<div class="input-group mb-3">
								<input v-model="full_name" type="text" class="form-control" placeholder="Tên đơn vị. VD: Chi cục Kiểm Lâm Nghệ An / Hạt kiểm lâm Diễn Châu" autocomplete="off">
							</div>

							<p class="mytitle"><i class="fas fa-map-marker-alt" style="width: 20px; color: #143F6B;"></i> Địa chỉ đơn vị <code>*</code><code class="txt-right" v-if="isAddressError">{{ msgAddress }}</code></p>
							<div class="input-group mb-3">
								<input v-model="address" type="text" class="form-control" placeholder="Nhập thông tin địa chỉ đơn vị" autocomplete="off">
							</div>

							<p class="mytitle"><i class="fas fa-envelope" style="width: 20px; color: #143F6B;"></i> Email <code>*</code><code class="txt-right" v-if="isEmailError">{{ msgEmail }}</code></p>
							<div class="input-group mb-3">
								<input v-model="email" type="email" class="form-control" placeholder="Địa chỉ email" autocomplete="off">
							</div>

							<div class="input-group mb-3">
								<input v-model="get_notify_status" type="checkbox" autocomplete="off"> <span style="margin-left: 10px;">Tiếp nhận tin báo qua email</span>  
							</div>

							<p class="mytitle"><i class="fas fa-mobile" style="width: 20px; color: #143F6B;"></i> Điện thoại <code>*</code><code class="txt-right" v-if="isMobileError">{{ msgMobile }}</code></p>
							<div class="input-group mb-3">
								<input v-model="mobile" type="number" class="form-control" placeholder="Điện thoại" autocomplete="off">
							</div>

							<!-- Tài khoản Quản trị hệ thống -->
							<div v-if="isSuperAdmin || isAdminSystem">

								<!-- Phạm vi xử phạt -->
								<div>
									<p class="mytitle"> <i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Phạm vi xử phạt <code>*</code><code class="txt-right" v-if="isLocationLevelError">{{ msgLocationLevel }}</code></p>
									<multiselect
										id="location_level"
										track-by="location_level_code" label="location_level_name" 
										@input="handleGetLocationLevel()"
										v-model="location_level_selected" 
										open-direction="bottom"
										:options="getListLocationLevel" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="false"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<!-- Nếu chọn Phạm vi xử phạt thì hiển thị đơn vị xử phạt -->
								<!-- 1. Cấp toàn quốc -->
								<div style="margin-top: 10px" v-if="this.location_level_code === 1">
									<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Chọn đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isNationalLevelError">{{ msgNationalLevel }}</code></p>
									<multiselect
										id="zone"
										track-by="organization_code" label="organization_name" 
										@input="handleGetOrgNation()"
										v-model="organization_selected" 
										open-direction="bottom"
										:options="getListOrgByLocationLevel" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<!-- 2. Cấp vùng -->
								<div style="margin-top: 10px" v-if="this.location_level_code === 2">
									<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn vùng <code>*</code><code class="txt-right" v-if="isZoneLevelError">{{ msgZoneLevel }}</code></p>
									<multiselect
										id="zone"
										track-by="zone_code" label="zone_name" 
										@input="handleSelectZone()"
										v-model="zone_selected" 
										open-direction="bottom"
										:options="getListOrgByLocationLevel" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<!-- 3. Cấp tỉnh  -->
								<!-- 3.1. Chọn đơn vị xử phạt thuộc tỉnh: Chi cục Kiểm lâm | Đội kiểm lâm cơ động và PCCC-->
								<div v-if="this.location_level_code === 3" style="margin-top: 10px">
									<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Chọn đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isOrgProvinceError">{{ msgOrgProvince }}</code></p>
									<multiselect
										id="zone"
										track-by="organization_code" label="organization_name" 
										@input="handleGetOrgProvince()"
										v-model="organization_selected" 
										open-direction="bottom"
										:options="getListOrgByLocationLevel" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<!-- 3.2. Chọn tỉnh -->
								<div v-if="this.location_level_code === 3" style="margin-top: 10px">
									<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> 
									<span> Chọn tỉnh </span>		
									<code>*</code><code class="txt-right" v-if="isProvinceLevelError">{{ msgProvinceLevel }}</code></p>
									<multiselect
										id="province"
										track-by="province_code" label="province_name" 
										@input="handleSelectProvince()"
										v-model="province_selected" 
										open-direction="bottom"
										:options="getListProvince" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<div class="input-group mb-3" 
									style="margin-top: 15px"
									v-if="this.location_level_code === 3 && this.organization_code === 4">
									<input v-model="is_admin_province" type="checkbox" autocomplete="off"> <span style="margin-left: 10px; "> Tài khoản admin cấp tỉnh </span>  
								</div>


								<!-- 4. Cấp huyện: Hiển thị tỉnh / sau đó chọn huyện:  -->
								<!-- 4.1. Chọn tổ chức cấp huyện -->
								<div v-if="this.location_level_code === 4" style="margin-top: 10px">
									<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isOrgDistrictError">{{ msgOrgDistrict }}</code></p>
									<multiselect
										id="zone"
										track-by="organization_code" label="organization_name" 
										@input="handleGetOrgDistrict()"
										v-model="organization_selected" 
										open-direction="bottom"
										:options="getListOrgByLocationLevel" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<!-- 4.2. Chọn location cấp huyện -->
								<!-- 4.2.1. Chọn tỉnh -->
								<div v-if="this.location_level_code === 4" style="margin-top: 10px">
									<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> 
									<span> Chọn tỉnh, sau đó chọn huyện </span> 
									<code>*</code><code class="txt-right" v-if="isProvinceLevelError">{{ msgProvinceLevel }}</code></p>
									<multiselect
										id="province"
										track-by="province_code" label="province_name" 
										@input="handleSelectProvince()"
										v-model="province_selected" 
										open-direction="bottom"
										:options="getListProvince" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<!-- 4.2.2. Chọn huyện -->
								<div style="margin-top: 10px" v-if="this.location_level_code === 4">
									<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn huyện <code>*</code><code class="txt-right" v-if="isDistrictLevelError">{{ msgDistrictLevel }}</code></p>
									<multiselect
										id="district"
										track-by="district_code" label="district_name"
										@input="handleGetDistrict()" 
										v-model="district_selected" 
										open-direction="bottom"
										:options="getListDistrictByProvinceCode" 
										:multiple="true"
										:searchable="true"
										:close-on-select="false"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>
							</div>

							<!-- Tài khoản Quản trị cấp tỉnh -->
							<div v-if="isAdminProvince">
								<!-- Phạm vi xử phạt: Tỉnh / Huyện -->
								<div>
									<p class="mytitle"> <i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Phạm vi xử phạt <code>*</code><code class="txt-right" v-if="isLocationLevelError">{{ msgLocationLevel }}</code></p>
									<multiselect
										id="location_level"
										track-by="location_level_code" label="location_level_name" 
										@input="handleGetLocationLevel()"
										v-model="location_level_selected" 
										open-direction="bottom"
										:options="getListLocationLevelAdminProvince" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="false"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

							<!-- Đơn vị xử phạt: chỉ hiển thị khi chọn cấp tỉnh ở bước trên -->
								<div v-if="this.location_level_code === 3" style="margin-top: 10px">
									<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Chọn đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isOrgProvinceError">{{ msgOrgProvince }}</code></p>
									<multiselect
										id="zone"
										track-by="organization_code" label="organization_name" 
										@input="handleGetOrgProvince()"
										v-model="organization_selected" 
										open-direction="bottom"
										:options="getListOrgByLocationLevel" 
										:multiple="false"
										:searchable="true"
										:close-on-select="true"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>

								<!-- Chọn huyện -->
								<div style="margin-top: 10px" v-if="this.location_level_code === 4">
									<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn huyện <code>*</code><code class="txt-right" v-if="isDistrictLevelError">{{ msgDistrictLevel }}</code></p>
									<multiselect
										id="district"
										track-by="district_code" label="district_name"
										@input="handleGetDistrict()" 
										v-model="district_selected"
										open-direction="bottom"
										:options="getListDistrictByProvinceCode" 
										:multiple="true"
										:searchable="true"
										:close-on-select="false"
										:clear-on-select="true"
										:show-pointer="true"
										:hide-selected="true">
									</multiselect>
								</div>
							</div>

						</div>
						
						<div class="modal-footer justify-content-between" style="padding: 15px; background-color: #F7F7F7">
							<button type="button" class="btn btn-info" style=" width: 150px; padding: 2px; font-size: 15px; color: white; background-color: #138496;" @click="handUserEdit()">Chỉnh sửa</button>
							<button type="button" class="btn btn-secondary mybtn-cancel" data-dismiss="modal" ref="closeForm" @click="handCandelEdit()">Hủy chỉnh sửa</button>
						</div>
					</div>
					<!-- /.modal-content -->
				</div>
				<!-- /.modal-dialog -->
			</div>
			<button type="button" class="btn btn-info" data-toggle="modal" data-target="#edit-public-user" style="display:none" ref="formUserEdit">
                User Edit
            </button>
			<!-- /.modal -->
		
	</div>

</template>

<script>

	import { mapActions, mapGetters } from 'vuex'
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'form-edit-user',
		components: {
			Multiselect
		},
		data() {
			return {
				// Button tạo tài khoản
				isShowAddBtn: true,			// Mặc định là true
				isShowWarning: false,       // Hiển thị thông báo lỗi khi nhập form đăng ký tài khoản
				isSubmit: false,			// Sử dụng để manage cảnh báo khi chưa chọn huyện

				// Show / Hide form
				isShowForm: false,     		// Form nhập thông tin nhóm
				isShowNote: false,     		// Note lưu ý khi tạo tài khoản 

				// Show msg warning error
				isUserNameError: false,
				isPasswordError: false,
				isFullNameError: false,
				isAddressError: false,
				isEmailError: false,
				isMobileError: false,			// Mobile
				isLocationLevelError: false,	// Location level
				isNationalLevelError: false,			
				isZoneLevelError: false,
				isOrgProvinceError: false,		// Organization
				isOrgDistrictError: false,		// Organization
				isProvinceLevelError: false,	
				isDistrictLevelError: false,	
				
				// Message warning Error
				msgUserName: '',
				msgPassword: '',
				msgFullName: '',
				msgAddress: '',
				msgEmail: '',
				msgMobile: '',
				msgLocationLevel: '',
				msgNationalLevel: '',
				msgZoneLevel: '',
				msgOrgProvince: '',
				msgOrgDistrict: '',
				msgProvinceLevel: '',
				msgDistrictLevel: '',
			
				// Location level
				location_level_code: '',		// Sử dụng để lưu location_level_code để ẩn, hiện các trường dữ liệu select. Mặc định để giá trị không có ý nghĩa, mục đích sau call API thì điền mới vào
				location_level_selected: [],    // Phải khai báo ở v-model để khi click @input="handleGetLocationLevel" lưu location_level được chọn vào mảng này

				// Đơn vị lập quyết định cấp quốc gia
				organization_code: '',			
				organization_selected: [],      	

				// Location: Mã quốc gia, vùng, tỉnh, huyện
				location_code: 0,				// Biến lưu thông tin mã quốc gia, vùng, tỉnh, huyện.

				// Location: Zone
				zone_selected: [],      		

				// Location: Province
				province_selected: [],      	

				// Location: District
				district_selected: [],      	// [obj1, obj2, ...]
				district_code_selected: '',		// String vì trường hợp select nhiều huyện

				// Form
				user_id: '',				// User ID				
				user_name: '',				// Tài khoản đăng nhập
				user_type: 1,				// Mặc định user_type = 1 (chọn một đơn vị). Trừ trường hợp chọn nhiều huyện thì bằng 2
				email: '',        			// Tên nhóm
				full_name: '',				// Họ và tên
				address: '',				// Địa chỉ
				mobile: '', 				// Điện thoại
				get_notify_status: false,	// Nhận tin báo vi phạm
				is_admin_province: false	// Admin cấp tỉnh
				
			}
		},
		async created(){
			if(this.isAdminProvince){
				// List district by province
				var province_code = this.getCurrentUser.location_code
				await this.actGetDistrictByProvinceCode(province_code)				
			}
		},
		watch: { // Sử dụng lắng nghe khi người dùng thay đổi giá trị trong Form
			// User Name: Nếu đang có lỗi (this.isUserNameError === true)
			user_name: function (new_value) {
				if(this.isUserNameError === true & new_value !== '') this.isUserNameError = false
			},

			full_name: function (new_value) {
				if(this.isFullNameError === true & new_value !== '') this.isFullNameError = false
			},

			address: function (val) {
				if(this.isAddressError === true & val !== '') this.isFullNameError = false
			},

			email: function (new_value) {
				if(this.isEmailError === true & new_value !== '') this.isEmailError = false
			},

			location_level_selected: function (new_value) {
				if(this.isLocationLevelError === true & new_value !== '') this.isLocationLevelError = false
			},

			organization_selected: function (new_value) {
				// Cấp quốc gia
				if(this.location_level_selected.location_level_code === 1){
					if(this.isNationalLevelError === true & new_value.length > 0) this.isNationalLevelError = false
				}

				// Cấp vùng
				if(this.location_level_selected.location_level_code === 2){
					if(this.isZoneLevelError === true & new_value.length > 0) this.isZoneLevelError = false
				}

				// Cấp tỉnh
				if(this.location_level_selected.location_level_code === 3){
					if(this.isOrgProvinceError === true & new_value.length > 0) this.isOrgProvinceError = false
				}	

				// Cấp huyện
				if(this.location_level_selected.location_level_code === 4){
					if(this.isOrgDistrictError === true & new_value.length > 0) this.isOrgDistrictError = false
				}	
			},

			zone_selected: function (new_value) {
				if(this.isZoneLevelError === true & new_value.length > 0) this.isZoneLevelError = false
			},

			province_selected: function (new_value) { 
				if(this.isProvinceLevelError === true & new_value.length > 0) this.isProvinceLevelError = false
			},

			district_selected: function (new_value) { // Sử dụng hàm watch để listen to change data of district_selected
				if(this.isSubmit === false) this.isDistrictLevelError = false
				if(this.isDistrictLevelError === true & new_value.length > 0) this.isDistrictLevelError = false
			}
		},

		computed: {
			...mapGetters([
				'isSuperAdmin',
				'isAdminSystem',
				'isAdminProvince',
				'getCurrentUser',
				'getEditUser',
				'getListLocationLevel',
				'getListOrgByLocationLevel',
				'getListLocationLevelAdminProvince',
				'getListDistrictByProvinceCode',
				'getListProvince'
			])
		},

		methods: {
			...mapActions([
				'actEditUser',
				'actGetAllUser',
				'actGetUserInfoById',
				'actLocationLevel',
				'actGetListOrgByLocationLevel',
				'actGetDistrictByProvinceCode',
				'actGetListProvince',
				'actGetProvinceByDistrictCode',
				'actGetUserByRole'
			]),
		
			// LẤY THÔNG TIN USER
			// ------------------------------------------------------
			getUserEditInfo(user_id){
				// Reset Form trước khi fill data. Phải reset do việc Edit user trước đó đang dùng lệnh push vào mảng location_level_selected, organization_selected
				this.resetForm()

				// Lấy dữ liệu user
				this.actGetUserInfoById(user_id).then(userInfo => {
					// console.log("userInfo using: ", userInfo)

					var user 	  = userInfo.data
					var user_type = user.user_type
					var location  = user.location

					this.location_level_code = user.location_level_code
					this.location_code       = location[0].location_code
					this.organization_code   = user.organization_code
					this.user_name = user.user_name
					this.full_name = user.full_name
					this.address   = user.address
					this.mobile	   = user.mobile
					this.email 	   = user.email
					this.user_id   = user.user_id
					this.get_notify_status = user.get_notify_status

					if(user.location_level_code === 3 && user.organization_code === 4 && user.role_id === 2) {
						this.is_admin_province = true
					}
					
					// Tạo array location_level_selected kiểu Array[obj1, obj2, ...] theo template của vue-multiselect
					this.location_level_selected = {
						'location_level_code': user.location_level_code,
						'location_level_name': user.location_level_name
					}

					this.organization_selected = {
						'organization_code': user.organization_code,
						'organization_name': user.organization_name
					}
					// console.log("this.organization_selected.push: ", this.organization_selected)

					// Cấp vùng
					if(this.location_level_code === 2){
						this.zone_selected = {
							'zone_code': location[0].location_code,
							'zone_name': location[0].location_name
						}
					}

					// Cấp tỉnh
					if(this.location_level_code === 3){
						this.province_selected = {
							'province_code': location[0].location_code,
							'province_name': location[0].location_name
						}

						// List province
						this.actGetListProvince()
					}

					// Cấp huyện
					if(this.location_level_code === 4){

						// Lấy Tỉnh theo mã huyện
						var district_code = location[0].location_code
						this.actGetProvinceByDistrictCode(district_code).then((res) => {
							this.province_selected = {
								'province_code': res.province[0].province_code,
								'province_name': res.province[0].province_name
							}
						})

						// List province
						this.actGetListProvince()

						if(location.length === 1){ // Quản lý 1 huyện
								this.district_selected = {
									'district_code': location[0].location_code,
									'district_name': location[0].location_name
								}
							}

						if(location.length > 1){ // Quản lý nhiều huyện
							this.location_code = ''

							location.forEach(obj => {
								this.location_code += obj.location_code + ','
								this.district_selected = [ ... this.district_selected, {
									'district_code': obj.location_code,
									'district_name': obj.location_name
								}]
							})

							this.location_code = this.location_code.slice(0, -1)
							// console.log('this.location_code', this.location_code)
						}
					}
					
					if(userInfo.ok){
						this.$refs.formUserEdit.click()
					}
				})
			},

			showWarningInputEnterError(){
				this.isShowWarning = true
				setTimeout(() => this.isShowWarning = false, 6000);
			},

			validEmail: function (email) {
				var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			},

			handleGetLocationLevel(){
				// Reset form nếu chọn mới Phạm vi xử phạt location_level
				this.location_code = 0
				this.organization_code = 0 
				this.organization_selected = []    		// National  		
				this.zone_selected = []      			// Zone		  	
				this.province_selected = []    			// Province  	
				this.district_selected = []  			// District 

				if(Object.keys(this.location_level_selected).length >= 1){// Nếu chọn location_level
					this.location_level_code = this.location_level_selected.location_level_code
					this.actGetListOrgByLocationLevel(this.location_level_code)
					this.resetToOrganizationLevel()
				}
			},

			handleGetOrgNation(){ // Mã quốc gia Việt Nam
				if(Object.keys(this.organization_selected).length >= 1){
					this.location_code = 704   
					this.organization_code = this.organization_selected.organization_code   
				}
			},

			handleSelectZone(){ // Vùng
				if(Object.keys(this.zone_selected).length >= 1){
					this.location_code = this.zone_selected.zone_code 
					this.organization_code = 3			// Chỉ có một cấp là kiểm lâm vùng nên gán mặc định, ở giao diện không có phần chọn
				}
			},

			handleGetOrgProvince(){
				if(Object.keys(this.organization_selected).length >= 1){ 
					this.organization_code = this.organization_selected.organization_code   
				}			
			},

			handleSelectProvince(){
				// Nếu đang chọn nhiều huyện thuộc tỉnh khác, chọn sang tỉnh mới thì reset lại mã các tỉnh đã chọn
				this.location_code = ''
				this.district_selected = []  			// District 

				if(Object.keys(this.province_selected).length >= 1){
					this.location_code = this.province_selected.province_code

					if(this.location_level_code === 4){ // Nếu Phạm vi xử phạt là huyện
						this.actGetDistrictByProvinceCode(this.location_code)
						this.resetDistrictLevel()
					}
				}
			},

			handleGetOrgDistrict(){
				if(Object.keys(this.organization_selected).length >= 1){ 
					this.organization_code = this.organization_selected.organization_code   
				}			
			},

			handleGetDistrict(){   
				if(Object.keys(this.district_selected).length >= 1){// Nếu chọn huyện

					this.location_code = ''
					this.district_selected.forEach(obj => {
						this.location_code += obj.district_code + ','
					})

					this.location_code = this.location_code.slice(0, -1)

				}else{ // Bỏ chọn 
					this.location_code = ''
				}
			},

			validateFormShare(){
				// Validate Form khi submit 
				if(this.user_name === ''){
					this.isUserNameError = true
					this.msgUserName = 'Chưa nhập tài khoản!'
					this.showWarningInputEnterError()
				}

				if(this.full_name === ''){
					this.isFullNameError = true
					this.msgFullName = 'Chưa nhập Họ và tên!'
					this.showWarningInputEnterError()
				}

				if(this.address === ''){
					this.isAddressError = true
					this.msgAddress = 'Chưa nhập địa chỉ!'
					this.showWarningInputEnterError()
				}

				if (this.validEmail(this.email) === false) { //this.email === '' || 
					this.isEmailError = true
					this.msgEmail = 'Email không hợp lệ!'
					this.showWarningInputEnterError()
				}
				
				if(this.mobile === ''){
					this.isMobileError = true
					this.msgMobile = 'Chưa nhập số điện thoại!'
					this.showWarningInputEnterError()
				}

				if( this.isUserNameError === false &&
					this.isFullNameError === false &&
					this.isEmailError === false &&
					this.isMobileError === false
				) return true
				
				return false
			},

			validateFormAdminSystem(){
				if(this.location_level_selected.length === 0){
					this.isLocationLevelError = true
					this.msgLocationLevel = 'Chưa chọn Phạm vi xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_code === 1){
					this.isNationalLevelError = true
					this.msgNationalLevel = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.zone_selected.length === 0 && this.location_level_code === 2){
					this.isZoneLevelError = true
					this.msgZoneLevel = 'Chưa chọn vùng!'
					this.showWarningInputEnterError()
				}

				if(this.province_selected.length === 0 && this.location_level_code === 3){
					this.isProvinceLevelError = true
					this.msgProvinceLevel = 'Chưa chọn tỉnh!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_code === 3){
					this.isOrgProvinceError = true
					this.msgOrgProvince = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_code === 4){
					this.isOrgDistrictError = true
					this.msgOrgDistrict = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.province_selected.length === 0 && this.location_level_code === 4){
					this.isProvinceLevelError = true
					this.msgProvinceLevel = 'Chưa chọn tỉnh!'
					this.showWarningInputEnterError()
				}

				if(this.district_selected.length === 0 && this.location_level_code === 4){
					this.isDistrictLevelError = true
					this.msgDistrictLevel = 'Chưa chọn huyện!'
					this.showWarningInputEnterError()
				}

				if( this.isLocationLevelError === false &&	
					this.isNationalLevelError === false &&			
					this.isZoneLevelError === false &&
					this.isOrgProvinceError === false &&		
					this.isOrgDistrictError === false &&		
					this.isProvinceLevelError === false &&	
					this.isDistrictLevelError === false) return true
				
				return false
			},

			validateFormProvince(){
				if(this.location_level_selected.length === 0){
					this.isLocationLevelError = true
					this.msgLocationLevel = 'Chưa chọn Phạm vi xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_selected.location_level_code === 3){
					this.isNationalLevelError = true
					this.msgNationalLevel = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.district_selected.length === 0 && this.location_level_code === 4){
					this.isDistrictLevelError = true
					this.msgDistrictLevel = 'Chưa chọn huyện!'
					this.showWarningInputEnterError()
					return false
				} 
				return true
			},


			// EXECUTE EDIT
			// --------------------------------------------------------------------------
			handUserEdit(){

				let flag_share = this.validateFormShare()
				
				let flag = false
				// Quyền tài khoản 
				var role_id = 4   // Mặc định tất cả tài khoản thì rode_id là 4 (Cán bộ xử phạt); 

				// Tài khoản quản trị hệ thống
				if(this.isAdminSystem || this.isSuperAdmin){

					let flag_admin = this.validateFormAdminSystem()

					if(flag_share && flag_admin){						
						flag = true

						if(this.location_level_code === 1 && this.organization_code === 1){ // Cấp quốc gia và là Cục Kiểm lâm
							role_id = 3			
						}

						if(this.location_level_code === 3 && this.organization_code === 4 && this.is_admin_province === true){ // Cấp tỉnh và là tài khoản admin
							role_id = 2
						}

						var data = {
							user_id: this.user_id,
							user_name: this.user_name,
							full_name: this.full_name,
							address: this.address,
							mobile: this.mobile,
							email: this.email,
							location_level_code: this.location_level_code,
							location_code: this.location_code,
							organization_code: this.organization_code,
							role_id: role_id, 			
							user_type: this.user_type,
							get_notify_status: this.get_notify_status
						}
					}
				}

				// Tài khoản quản trị cấp huyện
				if(this.isAdminProvince){
					let flag_province = this.validateFormProvince()

					if(flag_share && flag_province){
						flag = true

						var organization_code = ''
						var location_code = ''

						// Nếu chọn tỉnh
						if(this.location_level_code === 3){
							organization_code = this.organization_selected.organization_code   // Mặc định lấy org theo cấp tỉnh
							location_code = this.getCurrentUser.location_code
						}

						// Nếu chọn huyện
						if(this.location_level_code === 4){ // Cấp huyện
							location_code = this.location_code
							
							if(this.district_selected.length === 1){ // Chọn một huyện
								this.user_type = 1

							}else{// Chọn nhiều huyện
								this.user_type = 2
							}

							if(this.district_selected.length > 0){ // Nếu chọn huyện thì organization_code = 6 (Hạt kiểm lâm)
								organization_code = 6
							}
						}

						var data = {
							user_id: this.user_id,
							user_name: this.user_name,
							full_name: this.full_name,
							address: this.address,
							mobile: this.mobile,
							email: this.email,
							location_level_code: this.location_level_code,
							location_code: location_code,
							organization_code: organization_code,
							role_id: role_id, 			
							user_type: this.user_type,
							get_notify_status: this.get_notify_status
						}
					}
				}
				console.log("data ========", data)

				if(flag){ 

					this.actEditUser(data).then((res)=>{
						console.log('data', res)

						if(res.ok === true){

							// console.log('data', res.ok)
							// Ẩn Form
							this.$refs.closeForm.click()

							// Update lại bảng kết quả
							var user = {
								user_id: this.getCurrentUser.user_id,
								role_id: this.getCurrentUser.role_id,
								location_code: this.getCurrentUser.location_code,
								location_level_code: this.getCurrentUser.location_level_code
							}

							var user_json = JSON.stringify(user)
							this.actGetUserByRole(user_json)
							

							// Hiển thị câu thông báo thành công
							toastr.success('Chỉnh sửa thành công!', 'Chỉnh sửa thông tin tài khoản')

						}else{ // Cảnh báo trùng với tài khoản đã tồn tại
							toastr.error(`${res.message}`, 'Chỉnh sửa tài khoản')

						}	
					})
				}
			},

			handCandelEdit(){
				this.resetForm()
			},

			// RESET FORM
			// --------------------------------------------------------------------------
			resetForm(){
				this.isNationalLevelError = false		
				this.resetBaseInfo()
				this.resetToLocationLevel()
			},

			resetBaseInfo(){
				this.user_name = '' 
				this.email = '' 
				this.password = '' 
				this.mobile = '' 
				this.full_name = ''
				this.address = ''
				this.user_type = 1 
				this.get_notify_status = false 
				this.is_admin_province = false 

				this.isUserNameError = false 
				this.isEmailError = false 
				this.isPasswordError = false 
				this.isFullNameError = false 
				this.isAddressError = false 
				this.isMobileError = false 		
			},

			resetToLocationLevel(){
				this.isLocationLevelError = false,	
				this.location_level_selected = []
				this.resetToOrganizationLevel()    
			},

			resetToOrganizationLevel(){
				this.organization_selected = []
				this.resetToZoneLevel()    		

			},

			resetToZoneLevel(){
				this.isZoneLevelError = false
				this.zone_selected = [], 
				this.resetToProvinceLevel()
			},

			resetToProvinceLevel(){
				this.isProvinceLevelError = false
				this.province_selected = []
				this.resetDistrictLevel()
			},

			resetDistrictLevel(){
				this.isDistrictLevelError = false
				this.district_selected = [],
				this.district_code_selected = ''
			}
		}
	}
</script>

<style scoped>

	/* LABEL INPUT */
    .mytitle {
        margin-top: 15px;
		margin-bottom: 5px;
    }

	.mya {
        text-decoration: none;
        color: #138496;
    }

    .mya:hover {
        color: #138496;
        text-decoration: none;
    }

	/* CARD */
    .mcard-header {
        padding: 0;
    }

    .mcard-subheader {
        padding: 5px 0px 5px 20px;
        margin-bottom: 0px;
    }

	/* BUTTON */
    .mbtn {
        width: 200px !important;
        margin-bottom: 20px !important;
        margin-left: 15px !important;
    }

	/* LABEL INPUT */
    .mlb-input {
        margin-top: 15px;
		margin-bottom: 5px;
    } 


	/* FORM EDIT */
	.mybtn-cancel {
		width: 150px; 
		padding: 2px !important; 
		font-size: 14px !important; 
		background-color: #FFFFFF !important;   
		color: black !important;  
		border: 1px solid #dee2e6 !important;  
	}

	.mybtn-cancel:hover {
		width: 150px; 
		padding: 2px !important; 
		font-size: 14px !important; 
		background-color: #E5E5E5 !important;  
		color: black !important; ; 
		border: 1px solid #dee2e6 !important;  
	}

	.myicon-info {
		margin-top: 6px;
		padding-right: 10px;
		font-size: 18px;
	}

	.myheader-info {
		padding: 22px;
		background-color: #17A2B8;
		color: white;
	}


	/* SUCCESS */
	.myicon-success {
		margin-top: 6px;
		padding-right: 10px;
		font-size: 18px;
	}

	.myheader-success {
		padding: 12px;
		background-color: #28A745;
		color: white;
	}

	.mybtn-action-success {
		width: 150px; 
		padding: 2px; 
		font-size: 14px; 
		background-color: #A0A6AB; 
		border-color: #A0A6AB
	}

</style>