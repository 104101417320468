import axiosInstance from '@/plugin/axios';

export default {

    // I. TẠO TÀI KHOẢN
    // ======================================================================================
    async actAddUser({ commit }, objDataForm) { // Tạo Group

        try {
            var mykey_secret = process.env.VUE_APP_KEY_SECRET

            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_type', objDataForm.user_type)
            bodyFormData.append('user_name', objDataForm.user_name)
            bodyFormData.append('full_name', objDataForm.full_name)
            bodyFormData.append('address', objDataForm.address)
            bodyFormData.append('mobile', objDataForm.mobile)
            bodyFormData.append('email', objDataForm.email)
            bodyFormData.append('password', objDataForm.password)
            bodyFormData.append('location_level_code', objDataForm.location_level_code)
            bodyFormData.append('location_code', objDataForm.location_code)
            bodyFormData.append('organization_code', objDataForm.organization_code)
            bodyFormData.append('role_id', objDataForm.role_id)
            bodyFormData.append('get_notify_status', objDataForm.get_notify_status)
            bodyFormData.append('is_admin_province', objDataForm.is_admin_province)
            bodyFormData.append('user_created', objDataForm.user_created)
            bodyFormData.append('mykey_secret', mykey_secret)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/manage_user/admin/add_user', bodyFormData, config)
            var result = res.data
            console.log("actAddUser", result)

            if (res.status === 200) {
                commit('ADD_USER', result)
                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actAddUser: ', error)
        }
    },

    async actGetAllUser({ commit }) { // Danh sách user trên hệ thống
        try {

            var res = await axiosInstance.get('/manage_user/admin/all_user')
            var result = res.data;
            // console.log('result actGetAllUser ==== ', result)

            if (res.status === 200) {
                commit('GET_ALL_USER', result)
                
                return {
                    ok: true,
                    data: result
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetAllUser: ', error)
        }
    },

    
    async actGetUserByRole({ commit }, user_json) { // Danh sách user trên hệ thống theo phân quyền tài khoản quản trị
        try {

            var res = await axiosInstance.get('/manage_user/admin/user_by_role/' + user_json)
            console.log('result actGetUserByRole ==== ', res)

            if (res.status === 200) {
                commit('GET_ALL_USER', res.data)
                
                return {
                    ok: true,
                    data: res.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetUserByRole: ', error)
        }
    },
    
    async actGetUserPhoneBook({ commit }) { // Danh sách user trên hệ thống
        try {

            var res = await axiosInstance.get('/manage_user/admin/user_phone_book')
            var result = res.data;
            // console.log('result actGetAllUser ==== ', result)

            if (res.status === 200) {
                commit('GET_USER_PHONE_BOOK', result)
                
                return {
                    ok: true,
                    data: result
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetAllUser: ', error)
        }
    },

    
    async actGetUserByLocationLevelFromManageUser({ commit }, location_level_code) { // Danh sách user
        try {

            var res = await axiosInstance.get('/manage_user/admin/user_by_location_level_from_manage_user/' + location_level_code)
            var result = res.data;
            console.log('result actGetUserByLocationLevelFromManageUser ==== ', result)

            if (res.status === 200) {
                commit('GET_USER_BY_LOCATION_LEVEL_FROM_MANAGE_USER', result)

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetAllUser: ', error)
        }
    },


    async actGetUserByLocationLevel({ commit }, location_level_code) { // Danh sách user
        try {

            var res = await axiosInstance.get('/manage_user/admin/user_by_location_level/' + location_level_code)
            var result = res.data;
            console.log('result actGetUserByLocationLevel ==== ', result)

            if (res.status === 200) {
                commit('GET_USER_BY_LOCATION_LEVEL', result)

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetAllUser: ', error)
        }
    },


    // END TẠO TÀI KHOẢN ===================================================================


    // II. EDIT USER =======================================================================
    async actGetUserInfoById({ commit }, user_id) { // Sử dụng lấy thông tin khi tạo và xóa user
        try {
            var res = await axiosInstance.get('/manage_user/admin/user_info/' + user_id)
            const result = res.data;
            // console.log('result actGetUserInfoById = ', result)

            if (res.status === 200) {
                commit('GET_EDIT_USER', result)
                return {
                    ok: true,
                    data: result.user_edit
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action editRole: ', error)
        }
    },


    async actEditUser({ commit }, objDataForm) { // Chỉnh sửa 

        try {
            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_id', objDataForm.user_id)
            bodyFormData.append('user_type', objDataForm.user_type)
            bodyFormData.append('user_name', objDataForm.user_name)
            bodyFormData.append('full_name', objDataForm.full_name)
            bodyFormData.append('address', objDataForm.address)
            bodyFormData.append('mobile', objDataForm.mobile)
            bodyFormData.append('email', objDataForm.email)
            bodyFormData.append('location_level_code', objDataForm.location_level_code)
            bodyFormData.append('location_code', objDataForm.location_code)
            bodyFormData.append('organization_code', objDataForm.organization_code)
            bodyFormData.append('role_id', objDataForm.role_id)
            bodyFormData.append('get_notify_status', objDataForm.get_notify_status)
            bodyFormData.append('mykey_secret', process.env.VUE_APP_KEY_SECRET)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/manage_user/admin/edit', bodyFormData, config)
            const result = res.data;
            // console.log('result actEditUser: ', res.data)

            if (res.status === 200) {
                // Cập nhật lại thông tin nhóm
                var data = {
                    user_id_edited: result.user_id_edited,
                    user_all: result.user_all
                }

                commit('GET_ALL_USER_EDIT', data)
                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }
        } catch (error) {
            console.log('Error action Edit USER: ', error)
        }
    },
    

    // III. DELETE USER =======================================================================
    async actDeleteUser({ commit }, user_id) { // Xóa một nhóm

        try {
            var res = await axiosInstance.get('/manage_user/admin/delete/' + user_id)
            const result = res.data;

            if (res.status === 200) {
                commit('GET_ALL_USER', result)
                
                return {
                    ok: true,
                    data: result.user_all
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actDeleteUser: ', error)
        }
    },


    // IV. CHANGE PASSWORD =======================================================================
    async actChangePassword({}, objDataForm) { // Chỉnh sửa
        try {
            // console.log('objDataForm = ', objDataForm)
            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_id', objDataForm.user_id)
            bodyFormData.append('old_password', objDataForm.old_password)
            bodyFormData.append('new_password', objDataForm.new_password)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/manage_user/admin/change_password', bodyFormData, config)
            
            if (res.data.result === 'success'){
                return {
                    ok: true
                }
            }else{
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action Edit USER: ', error)
        }
    },

    
    //RESET PASSWORD IN PAGE ADMIN ======================================================================
    async actResetPassword({}, objDataForm) { // Chỉnh sửa
        try {
            // console.log('objDataForm = ', objDataForm)
            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_id', objDataForm.user_id)
            bodyFormData.append('new_password', objDataForm.new_password)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/manage_user/admin/reset_password', bodyFormData, config)
            
            if (res.data.result === 'success'){
                return {
                    ok: true
                }
            }else{
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actResetPassword: ', error)
        }
    },

    // RESET PASSWORD VIA EMAIL
    // ====================================================================================================
    async actResetPasswordViaEmailSendEmail ({}, email) { 

        try {

            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('email', email)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/manage_user/admin/reset_password_sendemail', bodyFormData, config)
            // console.log('res actResetPasswordViaEmail = ', res)

            if (res.status === 200) {
                return {
                    ok: true,
                    message: res.data.message
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actResetPasswordViaEmail: ', error)
        }
    },


    async actResetPasswordViaEmailUpdatePassword({}, objDataForm) { // Chỉnh sửa
        try {
            // console.log('objDataForm = ', objDataForm)
            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_id', objDataForm.user_id)
            bodyFormData.append('new_password', objDataForm.new_password)
            bodyFormData.append('full_name', objDataForm.full_name)
            bodyFormData.append('user_name', objDataForm.user_name)
            bodyFormData.append('email', objDataForm.email)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/manage_user/admin/reset_password_via_email', bodyFormData, config)
            // console.log('result actResetPasswordViaEmailUpdatePassword: ', res.data.result)
            
            if (res.data.result === 'success'){
                return {
                    ok: true,
                    message: res.data.message
                }
            }else{
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actResetPasswordViaEmailUpdatePassword: ', error)
        }
    },

    // V. Thống kê số lượng tài khoản
    async actGetNumberUserByRole({ commit }, user_json) { // Xóa một nhóm

        try {
            var res = await axiosInstance.get('/manage_user/admin/number_user_by_role/' + user_json)
            const result = res.data;

            if (res.status === 200) {
                commit('GET_NUMBER_USER_BY_ROLE', result)
                return {
                    ok: true,
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetNumberUserByRole: ', error)
        }
    },

    // V. Thống kê số lượng tài khoản
    async actGetNumberUserByLevelLocation({ commit }) { // Xóa một nhóm

        try {
            var res = await axiosInstance.get('/manage_user/admin/number_user_by_location_level')
            const result = res.data;

            if (res.status === 200) {
                commit('GET_NUMBER_USER_BY_LOCATION_LEVEL', result)
                return {
                    ok: true,
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetNumberUserByRole: ', error)
        }
    },

    
    async actGetProvinceByDistrictCode({ commit }, district_code) { // Xóa một nhóm

        try {
            var res = await axiosInstance.get('/manage_user/admin/province_by_district_code/' + district_code)

            if (res.status === 200) {
               
                return {
                    ok: true,
                    province: res.data.province
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetProvinceByDistrictCode: ', error)
        }
    },
}