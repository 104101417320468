import axiosInstance from '@/plugin/axios.js';

export default {

    // 1. Lấy danh sách hành vi
    async actGetListBehavior({ commit }) {

        try {
            var res = await axiosInstance.get('/get_notify/list_behavior')
            // console.log('result actGetListBehavior === ', res)

            if (res.status === 200) {
                commit('GET_LIST_BEHAVIOR', res.data.list_behavior)
                return {
                    ok: true,
                    data: res.data.list_behavior
                }

            } else {
                return {
                    ok: false,
                    error: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetListBehavior: ', error)
        }
    },

    // 2. Cấp nhận tin cảnh báo
    // 2.1. Cấp nhận tin: toàn quốc, vùng, tỉnh, huyện
    async actGetLocationLevel({ commit }) {

        try {
            var res = await axiosInstance.get('/get_notify/list_location_level')
            // console.log('result actGetListBehavior === ', res)

            if (res.status === 200) {
                commit('GET_LIST_LOCATION_LEVEL', res.data.list_location_level)
                return {
                    ok: true,
                    data: res.data.list_location_level
                }

            } else {
                return {
                    ok: false,
                    error: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetListBehavior: ', error)
        }
    },


    // 2.2. Từ cấp nhận tin --> lấy thông tin danh sách vùng, tỉnh, huyện
    async actGetOrgByLocationLevel({ commit}, location_level_code) {

        try {
            var res = await axiosInstance.get('/get_notify/list_org_by_location_level/' + location_level_code)
            // console.log('result actGetOrgByLocationLevel === ', res)

            if (res.status === 200) {
                commit('GET_LIST_ORG_BY_LOCATION_LEVEL', res.data.list_org_by_location_level)
                return {
                    ok: true,
                    data: res.data.list_org_by_location_level
                }

            } else {
                return {
                    ok: false,
                    error: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetOrgByLocationLevel: ', error)
        }
    },

    // Cấp cục
    // =========================================================
    async actGetEmailFPD({ commit}, organization_code) {

        try {
            var res = await axiosInstance.get('/get_notify/email_fpd/' + organization_code)
            // console.log('result actGetEmailFPD === ', res.data.email)

            if (res.status === 200) {
                return {
                    ok: true,
                    email: res.data.email.email
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetEmailFPD: ', error)
        }
    },

    // Cấp Vùng
    // =========================================================
    async actGetZoneGetNotify({}, organization_code) {

        try {
            var res = await axiosInstance.get('/get_notify/zone_get_notify/' + organization_code)
            // console.log('result actGetZoneByOrg === ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    zone: res.data.zone
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetZoneByOrg: ', error)
        }
    },

    // Cấp tỉnh
    // =========================================================
    // Email province: các tỉnh có email đã được active
    async actGetProvinceGetNotify({}, organization_code) {

        try {
            var res = await axiosInstance.get('/get_notify/province_get_notify/' + organization_code)
            console.log('result actGetProvinceGetNotify === ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    province: res.data.province
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetProvinceGetNotify: ', error)
        }
    },

    // Cấp huyện
    // =========================================================
    // Email province: các tỉnh có email huyện đã được active
    async actGetProvinceForGetDistrictNotify({}) {
        try {
            var res = await axiosInstance.get('/get_notify/province_for_district_get_notify')
            // console.log('result actGetProvinceGetNotify === ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    province: res.data.province
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetProvinceGetNotify: ', error)
        }
    },

    // Từ Province --> lấy thông tin District
    async actGetDistrictGetNotifyByProvince({}, province_code) {

        try {
            var res = await axiosInstance.get('/get_notify/district_get_notify/' + province_code)
            // console.log('result actGetDistrictGetNotifyByProvince === ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    district: res.data.district
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actGetDistrictGetNotifyByProvince: ', error)
        }
    },
    

    // Gửi tin cảnh báo
    async actSendNofity({}, data_json) {

        try {
            var res = await axiosInstance.post('/get_notify/add/' + data_json)
            console.log('result actSentNofity === ', res.data)

            if (res.status === 200) {
                return {
                    ok: true,
                    message: res.data.message
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actSentNofity: ', error)
        }
    },
    

    // Lấy tin cảnh báo
    async actGetNotifyResult({commit}, data_json) {

        try {
            var res = await axiosInstance.get('/get_notify/result/' + data_json)

            if (res.status === 200) {
                commit("GET_LIST_NOTIFY_RESULT", res.data.data)

                return {
                    ok: true,
                    data: res.data.data
                }

            } else {
                return {
                    ok: false,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actSentNofity: ', error)
        }
    },
    
    async actDeleteNotify({ commit }, data_json) { // Xóa

        try {
            var res = await axiosInstance.get('/get_notify/delete/' + data_json)
            const result = res.data;

            if (res.status === 200) {
                commit("GET_LIST_NOTIFY_RESULT", res.data.data)
                
                return {
                    ok: true,
                    data: result.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error,
                    message: res.data.message
                }
            }

        } catch (error) {
            console.log('Error action actDeleteNotify: ', error)
        }
    },



    
}