<template>
	<div style="margin-left: 10px">

		<!-- BUTTON CREATE GROUP -->
		<button type="button" class="btn btn-block btn-info btn-sm" style="width: 200px; color: white; background-color: #138496;"
		v-on:click="showForm" v-if="isShowAddBtn"><i class="fas fa-plus"></i> Tạo tài khoản</button>
		<button type="button" class="btn btn-block btn-info btn-sm" style="width: 200px; color: white; background-color: #138496;" v-on:click="hideForm" v-if="!isShowAddBtn"><i class="fas fa-minus"></i> Hủy tạo tài khoản</button>
		
		<!-- NHẬP THÔNG TIN TÀI KHOẢN -->
		<b-row style="margin-top: 20px;">
			<!-- Note lưu ý tạo tài khoản -->
			<b-col cols="4">
				<FormAddNote 
				    v-if="isShowNote"
					v-on:fartherHideNote="hideChildNote"
				/>
			</b-col>

			<!-- Form tạo tài khoản -->
			<b-col cols="5" v-if="isShowForm">
				<div class="card card-info">
					<div class="card-header">
						<div class="txt-left"><b-img :src="this.$ASSETS_PATH + '/img/icon/user-login.png'" width="40px"></b-img></div>
						<h3 class="txt-center" style="color: #94E4EF; font-size: 14px; margin-top: 10px;">TẠO TÀI KHOẢN</h3>
					</div>

					<div class="card-body my-card-body">
						<p class="mytitle"><i class="fas fa-user" style="width: 20px; color: #143F6B;"></i> Tài khoản <code>*</code><code class="txt-right" v-if="isUserNameError">{{ msgUserName }}</code></p>
						<div class="input-group mb-3">
							<input v-model="user_name" type="text" class="form-control" placeholder="Tài khoản" autocomplete="off">
						</div>

						<p class="mytitle"> <i class="fas fa-lock" style="width: 20px; color: #143F6B;"></i> Mật khẩu <code>*</code><code class="txt-right" v-if="isPasswordError">{{ msgPassword }}</code></p>
						<div class="input-group mb-3">
							<input v-model="password" type="password" class="form-control" placeholder="Mật khẩu" autocomplete="off">
						</div>

						<p class="mytitle"><i class="fas fa-user-edit" style="width: 20px; color: #143F6B;"></i> Tên đơn vị <code>*</code><code class="txt-right" v-if="isFullNameError">{{ msgFullName }}</code></p>
						<div class="input-group mb-3">
							<input v-model="full_name" type="text" class="form-control" placeholder="Tên đơn vị. VD: Chi cục Kiểm Lâm Nghệ An / Hạt kiểm lâm Diễn Châu" autocomplete="off">
						</div>

						<p class="mytitle"><i class="fas fa-map-marker-alt" style="width: 20px; color: #143F6B;"></i> Địa chỉ đơn vị <code>*</code><code class="txt-right" v-if="isAddressError">{{ msgAddress }}</code></p>
						<div class="input-group mb-3">
							<input v-model="address" type="text" class="form-control" placeholder="Nhập thông tin địa chỉ đơn vị" autocomplete="off">
						</div>

						<p class="mytitle"><i class="fas fa-envelope" style="width: 20px; color: #143F6B;"></i> Email <code>*</code><code class="txt-right" v-if="isEmailError">{{ msgEmail }}</code></p>
						<div class="input-group mb-3">
							<input v-model="email" type="email" class="form-control" placeholder="Địa chỉ email" autocomplete="off">
						</div>
						
						<div class="input-group mb-3">
							<input v-model="get_notify_status" type="checkbox" autocomplete="off"> <span style="margin-left: 10px;">Tiếp nhận tin báo qua email</span>  
						</div>

						<p class="mytitle"><i class="fas fa-mobile" style="width: 20px; color: #143F6B;"></i> Điện thoại <code>*</code><code class="txt-right" v-if="isMobileError">{{ msgMobile }}</code></p>
						<div class="input-group mb-3">
							<input v-model="mobile" type="number" class="form-control" placeholder="Điện thoại" autocomplete="off">
						</div>

						<!-- Tài khoản Quản trị hệ thống -->
						<div v-if="isSuperAdmin || isAdminSystem">
							<!-- Phạm vi xử phạt -->
							<div>
								<p class="mytitle"> <i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Phạm vi xử phạt <code>*</code><code class="txt-right" v-if="isLocationLevelError">{{ msgLocationLevel }}</code></p>
								<multiselect
									id="location_level"
									track-by="location_level_code" label="location_level_name" 
									@input="handleGetLocationLevel()"
									v-model="location_level_selected" 
									open-direction="bottom"
									:options="getListLocationLevel" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="false"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- Nếu chọn Phạm vi xử phạt thì hiển thị đơn vị -->
							<!-- 1. Cấp toàn quốc: @input="handleGetNational()" -->
							<div v-if="this.location_level_selected.location_level_code === 1" style="margin-top: 10px" >
								<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Chọn đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isNationalLevelError">{{ msgNationalLevel }}</code></p>
								<multiselect
									id="organization"
									track-by="organization_code" label="organization_name" 
									v-model="organization_selected" 
									open-direction="bottom"
									:options="getListOrgByLocationLevel" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- 2. Cấp vùng -->
							<div v-if="this.location_level_selected.location_level_code === 2" style="margin-top: 10px">
								<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn vùng <code>*</code><code class="txt-right" v-if="isZoneLevelError">{{ msgZoneLevel }}</code></p>
								<multiselect
									id="zone"
									track-by="zone_code" label="zone_name" 
									v-model="zone_selected" 
									open-direction="bottom"
									:options="getListOrgByLocationLevel" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- 3. Cấp tỉnh: @input="handleGetOrgDistrict()" -->
							<!-- 3.1. Chọn đơn vị xử phạt thuộc tỉnh: Chi cục Kiểm lâm | Đội kiểm lâm cơ động và PCCC-->
							<div v-if="this.location_level_selected.location_level_code === 3" style="margin-top: 10px">
								<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Chọn đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isOrgProvinceError">{{ msgOrgProvince }}</code></p>
								<multiselect
									id="zone"
									track-by="organization_code" label="organization_name" 
									v-model="organization_selected" 
									open-direction="bottom"
									:options="getListOrgByLocationLevel" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- 3.2. Chọn tỉnh -->
							<div v-if="this.location_level_selected.location_level_code === 3" style="margin-top: 10px">
								<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> 
								<span> Chọn tỉnh </span>		
								<code>*</code><code class="txt-right" v-if="isProvinceLevelError">{{ msgProvinceLevel }}</code></p>
								<multiselect
									id="province"
									track-by="province_code" label="province_name" 
									@input="handleGetProvince()"
									v-model="province_selected" 
									open-direction="bottom"
									:options="getListProvince" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<div class="input-group mb-3" 
								style="margin-top: 15px"
								v-if="this.location_level_selected.location_level_code === 3 && this.organization_selected.organization_code === 4">
								<input v-model="is_admin_province" type="checkbox" autocomplete="off"> <span style="margin-left: 10px; "> Tài khoản admin cấp tỉnh </span>  
							</div>

							<!-- 4. Cấp huyện: Hiển thị tỉnh / sau đó chọn huyện:  -->
							<!-- 4.1. Chọn tổ chức cấp huyện -->
							<div v-if="this.location_level_selected.location_level_code === 4" style="margin-top: 10px">
								<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isOrgDistrictError">{{ msgOrgDistrict }}</code></p>
								<multiselect
									id="zone"
									track-by="organization_code" label="organization_name" 
									v-model="organization_selected" 
									open-direction="bottom"
									:options="getListOrgByLocationLevel" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- 4.2. Chọn location cấp huyện -->
							<!-- 4.2.1. Chọn tỉnh -->
							<div v-if="this.location_level_selected.location_level_code === 4" style="margin-top: 10px">
								<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> 
								<span> Chọn tỉnh, sau đó chọn huyện </span> 
								<code>*</code><code class="txt-right" v-if="isProvinceLevelError">{{ msgProvinceLevel }}</code></p>
								<multiselect
									id="province"
									track-by="province_code" label="province_name" 
									@input="handleGetProvince()"
									v-model="province_selected" 
									open-direction="bottom"
									:options="getListProvince" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- 4.2.2. Chọn huyện -->
							<div style="margin-top: 10px" v-if="this.location_level_selected.location_level_code === 4 && this.province_selected.province_code > 0">
								<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn huyện <code>*</code><code class="txt-right" v-if="isDistrictLevelError">{{ msgDistrictLevel }}</code></p>
								<multiselect
									id="district"
									track-by="district_code" label="district_name"
									@input="handleGetDistrict()" 
									v-model="district_selected" 
									open-direction="bottom"
									:options="getListDistrictByProvinceCode" 
									:multiple="true"
									:searchable="true"
									:close-on-select="false"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>
						</div>


						<!-- Tài khoản Quản trị cấp huyện -->
						<div v-if="isAdminProvince">
							<!-- Phạm vi xử phạt: Tỉnh / Huyện -->
							<div>
								<p class="mytitle"> <i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Phạm vi xử phạt <code>*</code><code class="txt-right" v-if="isLocationLevelError">{{ msgLocationLevel }}</code></p>
								<multiselect
									id="location_level"
									track-by="location_level_code" label="location_level_name" 
									@input="handleGetLocationLevel()"
									v-model="location_level_selected" 
									open-direction="bottom"
									:options="getListLocationLevelAdminProvince" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="false"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- Đơn vị xử phạt: chỉ hiển thị khi chọn cấp tỉnh ở bước trên -->
							<div style="margin-top: 10px" v-if="this.location_level_code === 3">
								<p class="mytitle"><i class="fas fa-file-signature" style="width: 20px; color: #143F6B;"></i> Chọn đơn vị xử phạt <code>*</code><code class="txt-right" v-if="isNationalLevelError">{{ msgNationalLevel }}</code></p>
								<multiselect
									id="organization"
									track-by="organization_code" label="organization_name" 
									v-model="organization_selected" 
									open-direction="bottom"
									:options="getListOrgByLocationLevel" 
									:multiple="false"
									:searchable="true"
									:close-on-select="true"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>

							<!-- Chọn huyện -->
							<div style="margin-top: 10px" v-if="this.location_level_code === 4">
								<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn huyện <code>*</code><code class="txt-right" v-if="isDistrictLevelError">{{ msgDistrictLevel }}</code></p>
								<multiselect
									id="district"
									track-by="district_code" label="district_name"
									@input="handleGetDistrict()" 
									v-model="district_selected"
									open-direction="bottom"
									:options="getListDistrictByProvinceCode" 
									:multiple="true"
									:searchable="true"
									:close-on-select="false"
									:clear-on-select="true"
									:show-pointer="true"
									:hide-selected="true">
								</multiselect>
							</div>
						</div>

						<b-button v-on:click="handleAddUser()" size="sm" 
							style="margin-top: 20px !important; background-color: #15AABF !important; border: 1px solid #15AABF !important;	width: 150px;"
						> Tạo tài khoản</b-button>
						<button type="submit" class="btn btn-light btn-sm" v-on:click="hideForm()"
							style="margin-top: 20px !important; background-color: #E9ECEF !important; float: right; width: 150px;"
						>Hủy tạo tài khoản</button>
					</div>
              		<!-- /.card-body -->

            	</div>
			</b-col>
			<!-- /Form tài khoản -->
		</b-row>
		<!-- /Nhập thông tin -->

	</div>

</template>

<script>
	import FormAddNote from './FormAddNote.vue'
	import { mapActions, mapGetters } from 'vuex'
	import { getValueObjInArrayByKey } from '@/helper/index.js'
	import Multiselect from 'vue-multiselect'

	export default {
		name: 'form-add-user',
		components: {
			FormAddNote,
			Multiselect
		},
		data() {
			return {
				// Button tạo tài khoản
				isShowAddBtn: true,			// Mặc định là true
				isShowWarning: false,       // Hiển thị thông báo lỗi khi nhập form đăng ký tài khoản
				isSubmit: false,			// Sử dụng để manage cảnh báo khi chưa chọn huyện

				// Show / Hide form
				isShowForm: false,     		// Form nhập thông tin nhóm
				isShowNote: false,     		// Note lưu ý khi tạo tài khoản 

				// Show msg warning error
				isUserNameError: false,
				isPasswordError: false,
				isFullNameError: false,
				isAddressError: false,
				isEmailError: false,
				isMobileError: false,			// Mobile
				isLocationLevelError: false,	// Location level
				isNationalLevelError: false,			
				isZoneLevelError: false,
				isOrgProvinceError: false,		// Organization
				isOrgDistrictError: false,		// Organization
				isProvinceLevelError: false,	
				isDistrictLevelError: false,	
				
				// Message warning Error
				msgUserName: '',
				msgPassword: '',
				msgFullName: '',
				msgAddress: '',
				msgEmail: '',
				msgMobile: '',
				msgLocationLevel: '',
				msgNationalLevel: '',
				msgZoneLevel: '',
				msgOrgProvince: '',
				msgOrgDistrict: '',
				msgProvinceLevel: '',
				msgDistrictLevel: '',
			
				// Location level
				location_level_code: '',
				location_level_selected: [],    // Phải khai báo ở v-model để khi click @input="handleGetLocationLevel" lưu location_level được chọn vào mảng này

				// Đơn vị lập quyết định cấp quốc gia
				organization_selected: [],      	

				// Location: Zone
				zone_selected: [],      		

				// Location: Province
				province_selected: [],      	

				// Location: District
				district_selected: [],      	
				district_code_selected: '',		// String vì trường hợp select nhiều huyện

				// Form
				user_name: '',				// Tài khoản đăng nhập
				user_type: 1,				// Mặc định user_type = 1 (chọn một đơn vị). Trừ trường hợp chọn nhiều huyện thì bằng 2
				email: '',        			// Tên nhóm
				full_name: '',				// Họ và tên
				address: '',				// Địa chỉ
				mobile: '',					// Điện thoại
				password: '', 	  			// Mật khẩu,
				is_admin_province: false,	// Tài khoản quản trị cấp tỉnh
				get_notify_status: false	// Nhận tin báo vi phạm
			}
		},
		created(){
            this.actLocationLevel()

			if(this.isAdminProvince){
				// List district
				var province_code = this.getCurrentUser.location_code
				this.actGetDistrictByProvinceCode(province_code)
			}
		},

		watch: { // Sử dụng lắng nghe khi người dùng thay đổi giá trị trong Form
			// User Name: Nếu đang có lỗi (this.isUserNameError === true)
			user_name: function (val) {
				if(this.isUserNameError === true & val !== '') this.isUserNameError = false
			},

			password: function (val) {
				if(this.isPasswordError === true & val !== '') this.isPasswordError = false
			},

			full_name: function (val) {
				if(this.isFullNameError === true & val !== '') this.isFullNameError = false
			},

			address: function (val) {
				if(this.isAddressError === true & val !== '') this.isFullNameError = false
			},

			email: function (val) {
				if(this.isEmailError === true & val !== '') this.isAddressError = false
			},

			mobile: function (val) {
				if(this.isMobileError === true & val !== '') this.isMobileError = false
			},

			location_level_selected: function (new_value) {
				if(this.isLocationLevelError === true & new_value !== '') this.isLocationLevelError = false
			},

			organization_selected: function (new_value) {
				// Cấp quốc gia
				if(this.location_level_selected.location_level_code === 1){
					if(this.isNationalLevelError === true & new_value.length > 0) this.isNationalLevelError = false
				}

				// Cấp vùng
				if(this.location_level_selected.location_level_code === 2){
					if(this.isZoneLevelError === true & new_value.length > 0) this.isZoneLevelError = false
				}

				// Cấp tỉnh
				if(this.location_level_selected.location_level_code === 3){
					if(this.isOrgProvinceError === true & new_value.length > 0) this.isOrgProvinceError = false
				}	

				// Cấp huyện
				if(this.location_level_selected.location_level_code === 4){
					if(this.isOrgDistrictError === true & new_value.length > 0) this.isOrgDistrictError = false
				}	
			},

			zone_selected: function (new_value) {
				if(this.isZoneLevelError === true & new_value.length > 0) this.isZoneLevelError = false
			},

			province_selected: function (new_value) { 
				if(this.isProvinceLevelError === true & new_value.length > 0) this.isProvinceLevelError = false
			},

			district_selected: function (new_value) { // Sử dụng hàm watch để listen to change data of district_selected
				if(this.isSubmit === false) this.isDistrictLevelError = false
				if(this.isDistrictLevelError === true & new_value.length > 0) this.isDistrictLevelError = false
			},
		},

		computed: {
			...mapGetters([
				'isSuperAdmin',
				'isAdminSystem',
				'isAdminProvince',
				'getCurrentUser',
				'getListLocationLevel',
				'getListLocationLevelAdminProvince',
				'getListOrgByLocationLevel',
				'getListDistrictByProvinceCode',
				'getListProvince'
			])
		},

		methods: {
			...mapActions([
				'actLocationLevel',
				'actGetListOrgByLocationLevel',
				'actGetDistrictByProvinceCode',
				'actGetListProvince',
				'actAddUser',
				'actGetUserByRole'
			]),

			handleGetLocationLevel(){    
				if(Object.keys(this.location_level_selected).length >= 1){// Nếu chọn location_level
					this.location_level_code = this.location_level_selected.location_level_code
					
					this.actGetListOrgByLocationLevel(this.location_level_code)
					this.resetToOrganizationLevel()
				}
			},

			handleGetProvince(){
				// Nếu đang chọn nhiều huyện thuộc tỉnh khác, chọn sang tỉnh mới thì reset lại mã các tỉnh đã chọn
				this.district_code_selected = ''
				
				if(Object.keys(this.province_selected).length >= 1){
					this.location_level_code = this.location_level_selected.location_level_code

					if(this.location_level_code === 4){ // Nếu Phạm vi xử phạt là huyện
						var province_code = this.province_selected.province_code
						this.actGetDistrictByProvinceCode(province_code)
						this.resetDistrictLevel()
					}
				}
			},

			handleGetDistrict(){     
				if(Object.keys(this.district_selected).length >= 1){// Nếu chọn huyện
					this.district_code_selected = getValueObjInArrayByKey(this.district_selected, 'district_code')

				}else{ // Bỏ chọn 
					this.district_code_selected = ''
				}
			},

			showWarningInputEnterError(){
				this.isShowWarning = true
				setTimeout(() => this.isShowWarning = false, 6000);
			},

			validEmail: function (email) {
				var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			},

			validateFormShare(){
				// Validate Form khi submit 
				if(this.user_name === ''){
					this.isUserNameError = true
					this.msgUserName = 'Chưa nhập tài khoản!'
					this.showWarningInputEnterError()
				}

				if(this.password === ''){
					this.isPasswordError = true
					this.msgPassword = 'Chưa nhập mật khẩu!'
					this.showWarningInputEnterError()
				}

				if(this.password !== '' && this.password.length < 6){
					this.isPasswordError = true
					this.msgPassword = 'Mật khẩu tối thiểu phải 6 ký tự!'
					this.showWarningInputEnterError()
				}

				if(this.full_name === ''){
					this.isFullNameError = true
					this.msgFullName = 'Chưa nhập tên đơn vị!'
					this.showWarningInputEnterError()
				}

				if(this.address === ''){
					this.isAddressError = true
					this.msgAddress = 'Chưa nhập địa chỉ!'
					this.showWarningInputEnterError()
				}

				if (this.validEmail(this.email) === false) { //this.email === '' || 
					this.isEmailError = true
					this.msgEmail = 'Email không hợp lệ!'
					this.showWarningInputEnterError()
				}
				
				if(this.mobile === ''){
					this.isMobileError = true
					this.msgMobile = 'Chưa nhập số điện thoại!'
					this.showWarningInputEnterError()
				}

				if( this.isUserNameError === false &&
					this.isPasswordError === false &&
					this.isAddressError === false &&
					this.isFullNameError === false &&
					this.isEmailError === false &&
					this.isMobileError === false
				) return true
				
				return false
			},

			validateFormAdminSystem(){
				if(this.location_level_selected.length === 0){
					this.isLocationLevelError = true
					this.msgLocationLevel = 'Chưa chọn Phạm vi xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_selected.location_level_code === 1){
					this.isNationalLevelError = true
					this.msgNationalLevel = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.zone_selected.length === 0 && this.location_level_selected.location_level_code === 2){
					this.isZoneLevelError = true
					this.msgZoneLevel = 'Chưa chọn vùng!'
					this.showWarningInputEnterError()
				}

				if(this.province_selected.length === 0 && this.location_level_selected.location_level_code === 3){
					this.isProvinceLevelError = true
					this.msgProvinceLevel = 'Chưa chọn tỉnh!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_selected.location_level_code === 3){
					this.isOrgProvinceError = true
					this.msgOrgProvince = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_selected.location_level_code === 4){
					this.isOrgDistrictError = true
					this.msgOrgDistrict = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.province_selected.length === 0 && this.location_level_selected.location_level_code === 4){
					this.isProvinceLevelError = true
					this.msgProvinceLevel = 'Chưa chọn tỉnh!'
					this.showWarningInputEnterError()
				}

				if(this.district_selected.length === 0 && this.location_level_selected.location_level_code === 4){
					this.isDistrictLevelError = true
					this.msgDistrictLevel = 'Chưa chọn huyện!'
					this.showWarningInputEnterError()
				}

				if( this.isLocationLevelError === false &&	
					this.isNationalLevelError === false &&			
					this.isZoneLevelError === false &&
					this.isOrgProvinceError === false &&		
					this.isOrgDistrictError === false &&		
					this.isProvinceLevelError === false &&	
					this.isDistrictLevelError === false) return true
				
				return false
			},

			validateFormProvince(){
				if(this.location_level_selected.length === 0){
					this.isLocationLevelError = true
					this.msgLocationLevel = 'Chưa chọn Phạm vi xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.organization_selected.length === 0 && this.location_level_selected.location_level_code === 3){
					this.isNationalLevelError = true
					this.msgNationalLevel = 'Chưa chọn đơn vị xử phạt!'
					this.showWarningInputEnterError()
				}

				if(this.district_selected.length === 0 && this.location_level_code === 4){
					this.isDistrictLevelError = true
					this.msgDistrictLevel = 'Chưa chọn huyện!'
					this.showWarningInputEnterError()
					return false
				} 
				return true
			},

			// tài khoản
			handleAddUser(){
				
				let flag_share = this.validateFormShare()
				let flag = false

				// Tài khoản quản trị hệ thống
				if(this.isAdminSystem || this.isSuperAdmin){
					let flag_admin = this.validateFormAdminSystem()

					if(flag_share && flag_admin){
						flag = true

						// 1. location_code: xác định dựa vào người dùng chọn 
						var location_level_code = this.location_level_selected.location_level_code   // Quốc gia, vùng, tỉnh, huyện
						var location_code = ''  		// Mã quốc gia, vùng, tỉnh, huyện
						var organization_code = ''   	// Tổ chức lập biên bản xử phạt
						var role_id = 4    				// Mặc định tất cả tài khoản thì rode_id là 4 (Cán bộ xử phạt); 

						if(location_level_code === 1){ 	// Cấp quốc gia
							location_code = 704			// Mã quốc gia VN	
							organization_code = this.organization_selected.organization_code	// Cục kiểm lâm | Đội đặc nhiệm

							if(organization_code === 1){ // Cục kiểm lâm; Nếu là Đội đặc nhiệm thì role_id vẫn là 4
								role_id = 3
							}				
						}

						if(location_level_code === 2){ 	// Cấp vùng
							location_code = this.zone_selected.zone_code	
							organization_code = 3		// Kiểm lâm vùng
						}

						if(location_level_code === 3){ // Cấp tỉnh
							location_code = this.province_selected.province_code
							organization_code = this.organization_selected.organization_code

							if(organization_code === 4 && this.is_admin_province === true){ // Tài khoản admin cấp tỉnh
								role_id = 2
							}	
						}

						if(location_level_code === 4){ // Cấp huyện
							if(this.district_selected.length === 1){ // Chọn một huyện
								location_code = this.district_selected[0].district_code
								organization_code = this.organization_selected.organization_code
								this.user_type = 1

							}else{// Chọn nhiều huyện
								location_code = this.district_code_selected
								organization_code = this.organization_selected.organization_code
								this.user_type = 2
							}
						}

						var data = {
							user_name: this.user_name,
							full_name: this.full_name,
							address: this.address,
							mobile: this.mobile,
							email: this.email,
							password: this.password,
							location_level_code: location_level_code,
							location_code: location_code,
							organization_code: organization_code,
							role_id: role_id, 				
							user_type: this.user_type,
							get_notify_status: this.get_notify_status,
							user_created: this.getCurrentUser.user_id
						}
						console.log(data)
					}
				}

				// Tài khoản quản trị cấp huyện
				if(this.isAdminProvince){
					let flag_province = this.validateFormProvince()

					if(flag_share && flag_province) {
						flag = true
						var organization_code = ''
						var location_code = ''

						// Nếu chọn tỉnh
						if(this.location_level_code === 3){
							organization_code = this.organization_selected.organization_code   // Mặc định lấy org theo cấp tỉnh
							location_code = this.getCurrentUser.location_code
						}

						// Nếu chọn huyện
						if(this.location_level_code === 4){ // Cấp huyện
							if(this.district_selected.length === 1){ // Chọn một huyện
								location_code = this.district_selected[0].district_code
								this.user_type = 1

							}else{// Chọn nhiều huyện
								location_code = this.district_code_selected
								this.user_type = 2
							}

							if(this.district_selected.length > 0){ // Nếu chọn huyện thì organization_code = 6 (Hạt kiểm lâm)
								organization_code = 6
							}
						}

						var data = {
							user_name: this.user_name,
							full_name: this.full_name,
							address: this.address,
							mobile: this.mobile,
							email: this.email,
							password: this.password,
							location_level_code: this.location_level_code,				
							location_code: location_code,
							organization_code: organization_code,						
							role_id: 4, 												// Cán bộ xử phạt
							user_type: this.user_type,
							get_notify_status: this.get_notify_status,
							user_created: this.getCurrentUser.user_id
						}
					}
				}
				
				if(flag){ 
					this.actAddUser(data).then((res)=>{
						if(res.ok === true){
							// Ẩn Form
							this.hideForm()						

							// Hiển thị câu thông báo thành công
							toastr.success(`Tạo tài khoản ${data.user_name} thành công!`,"Tạo tài khoản")

							// Update lại phần thống kê số lượng User phân theo Administrative
							var user = {
								user_id: this.getCurrentUser.user_id,
								role_id: this.getCurrentUser.role_id,
								location_code: this.getCurrentUser.location_code,
								location_level_code: this.getCurrentUser.location_level_code
							}

							var user_json = JSON.stringify(user)
							this.actGetUserByRole(user_json)

						}else{

							toastr.error(`${res.message}`,"Tạo tài khoản")
						}
					})
				}
			},


			// HIDE AND RESET FORM
			// ===============================================================================
			// Show / Hide Note
			hideChildNote(){ // Ẩn Note form create group
				this.isShowNote = false
			},

			// Show / Hide form
			showForm(){// Ẩn hiện Form create group
				this.isShowAddBtn = false;  // Ẩn nút tạo tài khoản, hiện nút Ẩn tạo tài khoản

				if(this.isShowForm === false) { // Nếu đang đóng, thì mở form
					this.isShowForm = true;
					this.isShowNote = true;
					this.resetForm()

				}else{							// Nếu đang mở form, thì đóng lại
					this.hideForm()
				}
			},

			hideForm(){
				this.isShowAddBtn = true;  // Ẩn nút Ẩn tạo tài khoản, hiện nút tạo tài khoản
				this.isShowForm = false;
				this.isShowNote = false;
				this.resetForm()
			},

			resetForm(){
				this.isNationalLevelError = false		
				this.resetBaseInfo()
				this.resetToLocationLevel()
			},

			resetBaseInfo(){
				this.user_name = '' 
				this.email = '' 
				this.password = '' 
				this.mobile = '' 
				this.full_name = '' 
				this.address = '' 
				this.user_type = 1 
				this.get_notify_status = false 
				this.is_admin_province = false 

				this.isUserNameError = false 
				this.isAddressError = false 
				this.isEmailError = false 
				this.isPasswordError = false 
				this.isFullNameError = false 
				this.isMobileError = false 		
			},

			resetToLocationLevel(){
				this.isLocationLevelError = false,
				this.location_level_code = ''	
				this.location_level_selected = []
				this.resetToOrganizationLevel()    
			},

			resetToOrganizationLevel(){
				this.organization_selected = []
				this.resetToZoneLevel()    		

			},

			resetToZoneLevel(){
				this.isZoneLevelError = false
				this.zone_selected = [], 
				this.resetToProvinceLevel()
			},

			resetToProvinceLevel(){
				this.isProvinceLevelError = false
				this.province_selected = []
				this.resetDistrictLevel()
			},

			resetDistrictLevel(){
				this.isDistrictLevelError = false
				this.district_selected = []
				this.district_code_selected = ''
			}
		}
	}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
	/* BUTTON */
    .registry {
        width: 200px !important;
        margin-bottom: 20px !important;
        margin-left: 15px !important;
		background-color: #138496;
		color: white;
		border-color: #138496;
    }

	.registry:hover {
		width: 200px !important;
        margin-bottom: 20px !important;
        margin-left: 15px !important;
		background-color: #15AABF;
		color: white;
	}

	/* LABEL INPUT */
    .mytitle {
        margin-top: 0px;
		margin-bottom: 5px;
    } 

	/* Nut register */
	.btn-register {
		margin-top: 20px !important;
		background-color: #15AABF !important;
		border: 1px solid #15AABF !important;
		width: 150px;
	}

	.btn-register:hover {
		background-color: #15AABF !important;
		border: 1px solid #15AABF !important;
	}

</style>