import Vue from 'vue'
import Router from 'vue-router'


// 1. LOGIN
import RouterLogin from '@/router/Login/Index.js'

// 2. MANAGE USER
import RouterManageUserAdmin from './ManageUser/Admin.js'

// 3. PENALTY DECISION
import PenaltyDecision from './PenaltyDecision/Index.js'

// 4. PENALTY RESULT
import PenaltyResult from './PenaltyResult/Index.js'

// 5. SEARCH RESULT
import SearchResult from './SearchResult/Index.js'

// 6. NOT FOUND
import RouterNotFound from './NotFound/Index.js'

// 7. Report
import Report from './Report/Index.js'

// 8. PHONE BOOK
import PhoneBook from './PhoneBook/Index.js'

// 9. GET NOTIFY
import SendNotify from './GetNofity/IndexSend.js'
import NotifyResult from './GetNofity/IndexResult.js'

Vue.use(Router)

// Lưu ý thứ tự sắp xếp Router: RouterNotFound bao giờ cũng phải để cuối cùng. Check cuối cùng sau khi đã check hết các router xếp trên.

const router = new Router({
	mode: 'history',
    routes: [								
		...RouterLogin,    					// Điều hướng trang Login
		...RouterManageUserAdmin,			// Điều hướng: trang quản lý user
		...PenaltyDecision,    				// Điều hướng: trang lập quyết định xử phạt
		...PenaltyResult,    				// Điều hướng: trang kết quả ra quyết định xử phạt
		...SearchResult,         			// Điều hướng: trang kết quả tra cứu quyết định xử phạt
		...Report,							// Báo cáo
		...PhoneBook,						// Danh bạ
		...SendNotify,						// Gửi tin báo vi phạm
		...NotifyResult,					// Danh sách kết quả tin báo vi phạm
		...RouterNotFound,					// Điều hướng đến trang NotFound (404) khi nhập đường dẫn URL sai
	]
  })

export default router