<template>
	<div>
		  <!-- Main Sidebar Container -->
		<aside class="main-sidebar sidebar-light-primary elevation-2">
			<!-- Sidebar -->
			<div class="sidebar">
				<!-- Sidebar user panel (optional) -->
				<div class="user-panel mt-2 mb-2 d-flex" style="padding-bottom: 14px;">
					<div class="image">
					<img :src="this.$ASSETS_PATH + '/img/icon/user-login-yellow.png'" class="img-circle elevation-2" alt="User Image">
					</div>
					<div class="info">
						<div class="d-block"> {{ getCurrentUser.full_name }}</div>
					</div>
				</div>

				<!-- Sidebar Menu -->
				<nav class="mt-2">
					<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
						<!-- Tài khoản cá nhân -->
						<li class="nav-item">
							<router-link :to="{name: 'user-manage'}" active-class="active" class="nav-link">
								<i class="nav-icon fas fa-user cl-icon-admin"></i>
								<p>
									Quản trị tài khoản
								</p>
							</router-link>
						</li>
						<!-- /Tài khoản cá nhân -->

						<!-- Hướng dẫn quản trị -->
						<li class="nav-item">
							<router-link :to="{name: 'user-guide'}" active-class="active" class="nav-link">
								<i class="nav-icon fas fa-book cl-icon-admin"></i>
								<p>
									Hướng dẫn quản trị
								</p>
							</router-link>
						</li>
						<!-- Hướng dẫn quản trị -->
					</ul>
				</nav>
				<!-- /.sidebar-menu -->
			</div>
			<!-- /.sidebar -->
		</aside>
	</div>
	
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'manage-user-sidebar-index',
		data() {
			return {
				isOpenAdmin: true,	   // Cấu hình trang Supper Admin	
				isOpenGroup: true,     // Cấu hình Sibar Quản Trị nhóm mặc định mở hay đóng
				isOpenUser: true,      // Cấu hình Sibar Quản Trị user mặc định mở hay đóng
				isPublicRoleGroup: '',
				isPublicListRole: ''
			}
		},
		computed: {
			...mapGetters([
				'getCurrentUser'
			]),

			isActive(){
				return this.$route.name
			}
		},

		methods: {
			// Do AdminLTE sidebar ở mức 2 level không tự đóng mở nên phải xây dựng hàm hàm
			handleOpenGroup(){
				this.isOpenGroup = !this.isOpenGroup
			},

			handleOpenUser(){
				this.isOpenUser = !this.isOpenUser
			},

			handleOpenAdmin(){
				this.isOpenAdmin = !this.isOpenAdmin
			},
		}
	}
</script>

<style scoped>
	.cl-icon-admin {
		color: #5c677d !important;
	}

	.active {
		background-color: #e5e5e5 !important;
		border-radius: 5px !important;
	}

	li.nav-item > a.nav-link.router-link-active.active:hover,
	li.nav-item > a.nav-link.router-link-exact-active.active {
		background-color: #e5e5e5 !important;
		color: black !important;
		border-radius: 5px !important;
		cursor: pointer !important;
	}

</style>