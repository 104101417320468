export default {
    // Individual
    number_person_individual: [],           
    number_decision_individual: [],
    behavior_individual: [],
    data_download_individual: [],
    number_person_behavior_individual: [],

    number_money_behavior_individual: [],
    total_money_behavior_individual: [],
    number_area_behavior_individual: [],
    total_area_behavior_individual: [],

    // Organization
    number_decision_organization: [],
    behavior_organization: [],   
    data_download_organization: [],  
    number_org_behavior_organization: [],
    
    number_money_behavior_organization: [],
    total_money_behavior_organization: [],
    number_area_behavior_organization: [],
    total_area_behavior_organization: [],
}