<template>
    <div>
        <div class="card" style="border-radius: 0px; margin-top: 25px;">
            <div class="card-header ui-sortable-handle" style="padding-top: 15px;">
                <h3 class="card-title">
                    <i class="ion ion-clipboard mr-1"></i>
                    Điều 1 / Phần 2: Hành vi, hình thức xử phạt và biện pháp khắc phục hậu quả
                </h3>
            </div>
            <div class="card-body">

                <!-- I. MẪU VĂN BẢN -->
                <!-- ========================================================================= -->
                <div>
                    <div class="mheader-1"> <i class="fas fa-file-word fa-lg"></i> Mẫu quyết định </div>
                    <!-- MỤC 2 - 5  -->
                    <!-- Đã thực hiện hành vi vi phạm hành chính -->
                    <div class="mline-first">
                        2. Đã thực hiện hành vi vi phạm hành chính: 
                        <span class="num1 txt-center " :class="lviolation_behavior_enter">1</span> 
                        <span v-html="html_violation_behavior_enter"></span>

                        <!-- Scroll to nhập hành vi -->
                        <i class="fas fa-arrow-circle-down micon txt-right pointer" v-scroll-to="'#behavior'"></i>
                    </div>
                    <!-- Phải viết trực tiếp ở đây vì viết ở computed khi Edit không auto update lại giao diện -->
                    <div class="bold" 
                        v-if="behavior_regulation_penalty.length === 1"
                        v-html="behavior_regulation_penalty[0].violation_behavior_enter"
                    ></div> 

                    <div v-if="behavior_regulation_penalty.length > 1"
                        v-for="(item, index) in behavior_regulation_penalty">
                        <span class="bold"> Hành vi {{index + 1}}:</span> <span v-html="item.violation_behavior_enter"></span>
                    </div>
                        
                
                    <!-- Quy định tại -->
                    <div class="mline-first">3. Quy định tại: 
                        <span class="num1 txt-center " :class="lregulation_at_enter">2</span>
                        <span v-html="html_regulation_at_enter"></span>
                    </div>

                    <div class="bold" 
                        v-if="behavior_regulation_penalty.length === 1"
                        v-html="behavior_regulation_penalty[0].regulation_at_enter"
                    ></div> 

                    <div v-if="behavior_regulation_penalty.length > 1"
                        v-for="(item, index) in behavior_regulation_penalty">
                        <span class="bold"> Hành vi {{index + 1}}:</span> <span v-html="item.regulation_at_enter"></span>
                    </div>
                    
                    <!-- Tình tiết tăng nặng -->
                    <div class="mline-first">4. Các tình tiết tăng nặng (nếu có): 
                        <span class="num2 txt-center " :class="lincrease_issue">*</span>
                        <span v-html="html_increase_issue"></span>
                    </div>

                    <!-- Tình tiết giảm nhẹ -->
                    <div class="mline-first">5. Các tình tiết giảm nhẹ (nếu có): 
                        <span class="num2 txt-center " :class="ldecrease_issue">**</span>
                        <span v-html="html_decrease_issue"></span>
                    </div>

                    <!-- MỤC 6 -->
                    <div class="mline-first">6. Bị áp dụng hình thức xử phạt, biện pháp khắc phục hậu quả như sau: </div>
                    <!-- Số (5). Hình thức xử phạt chính -->
                    <div class="mline">a) Hình thức xử phạt chính: Phạt tiền 
                        <span class="num1 txt-center " :class="lmain_penalty_enter">3</span> 
                        <span v-html="html_main_penalty_enter"></span>
                    </div>

                    <div class="bold" 
                        v-if="behavior_regulation_penalty.length === 1"
                        v-html="behavior_regulation_penalty[0].main_penalty_enter"
                    ></div> 

                    <div v-if="behavior_regulation_penalty.length > 1"
                        v-for="(item, index) in behavior_regulation_penalty">
                        <span class="bold"> Hành vi {{index + 1}}:</span> <span v-html="item.main_penalty_enter"></span>
                    </div>

                    <div>
                        Tổng số tiền phạt: 
                        <span class="num1 txt-center " :class="lmoney_penalty_total_int">4</span>
                        <span v-html="html_money_penalty_total_int"></span> 
                        (Bằng chữ: 
                        <span class="num1 txt-center " :class="lmoney_penalty_total_text">5</span>
                        <span v-html="html_money_penalty_total_text"></span>)
                    </div>

                    <!-- Hình thức xử phạt bổ sung -->
                    <div class="mline">b) Hình thức xử phạt bổ sung (nếu có):</div>
                    
                    <!-- Số (6) Tịch thu tang vật -->
                    <div> + Tịch thu tang vật VPHC gồm (nếu có): <span class="num2 txt-center " :class="lextend_penalty_exhibit">6</span> </div>
                    <div v-html="html_extend_penalty_exhibit"> </div>

                    <!-- Số (7) Tịch thu phương tiện -->
                    <div> + Tịch thu phương tiện được sử dụng để thực hiện hành vi VPHC (nếu có): <span class="num2 txt-center " :class="lextend_penalty_vihicle">7</span></div>
                    <div v-html="html_extend_penalty_vihicle"> </div>
                    
                    <!-- Số (8, 9) Tổng số phương tiện tịch thu -->
                    <div class="mline">
                        Tổng số phương tiện tịch thu (bao gồm cả dụng cụ, công cụ) (nếu có): 
                        <span class="num2 txt-center " :class="lextend_penalty_vihicle_num">8</span> 
                        <span v-html="html_extend_penalty_vihicle_num"></span>,

                        đơn vị: 
                        <span class="num2 txt-center " :class="lextend_penalty_vihicle_unit_num">9</span> 
                        <span v-html="html_extend_penalty_vihicle_unit_num"></span>
                    </div>

                    <!-- Số (10) Nội dung khác có liên quan -->
                    <div class="mline"> + Nội dung khác có liên quan: <span class="num2 txt-center " :class="lextend_penalty_other" >10</span> </div>
                    <div  v-html="html_extend_penalty_other"></div>
                
                    <!-- Số (11) Thời hạn thực hiện hình thức xử phạt bổ sung -->
                    <div class="mline">
                        Thời hạn thực hiện hình thức xử phạt bổ sung là 
                        <span class="num2 txt-center " :class="lextend_penalty_duration">11</span> 
                        <span v-html="html_extend_penalty_duration"></span> ngày, kể từ ngày nhận được Quyết định này. </div>

                    <!-- Số (12). Biện pháp khắc phục hậu quả  -->
                    <div class="mline">c) Biện pháp khắc phục hậu quả (nếu có): </div>
                    <div class="mline">Cụ thể: <span class="num2 txt-center " :class="lremedial_measure">12</span> </div>
                    <div v-html="html_remedial_measure"></div>
                    
                    <!-- Số (13). Thời hạn thực hiện biện pháp khắc phục hậu quả -->
                    <div class="mline">
                        Thời hạn thực hiện biện pháp khắc phục hậu quả 
                        <span class="num2 txt-center " :class="lremedial_measure_duration">13</span> 
                        <span v-html="html_remedial_measure_duration"></span> ngày, kể từ ngày nhận được Quyết định này </div>
                    
                    <!-- Số (14). Nội dung trực tiếp liên quan đến việc thực hiện biện pháp khắc phục hậu quả (nếu có) -->
                    <div class="mline">
                        Những nội dung trực tiếp liên quan đến việc thực hiện biện pháp khắc phục hậu quả (nếu có): 
                        <span class="num2 txt-center " :class="lremedial_measure_content">14</span> 
                        <span v-html="html_remedial_measure_content"></span>
                    </div>
                    <div class="mline">Mọi chi phí tổ chức thi hành biện pháp khắc phục hậu quả do ông (bà) bị xử phạt có tên tại Điều này chi trả. </div>
                    
                    <br/>
                    <!-- Số (15, 16). Ông (bà)/Tổ chức(**) có tên tại Điều này phải hoàn trả số kinh phí là -->
                    <div class="mline">
                        Ông (bà) có tên tại Điều này phải hoàn trả số kinh phí là: 
                        <span class="num2 txt-center " :class="lremedial_measure_money_int">15</span> 
                        <span v-html="html_remedial_measure_money_int"></span>
                    </div>

                    <div class="mline">
                        (Bằng chữ: <span class="num2 txt-center " :class="lremedial_measure_money_text">16</span> 
                        <span v-html="html_remedial_measure_money_text"></span>)
                    </div>
                    
                    <!-- Số (17). Tên cơ quan thực hiện biện pháp khắc phục hậu quả -->
                    <div class="mline">
                        Cho: <span class="num2 txt-center " :class="lorganization_get_money">17</span> 
                        <span v-html="html_organization_get_money"></span>
                        là cơ quan đã thực hiện biện pháp khắc phục hậu quả theo quy định tại khoản 5 Điều 85 Luật Xử lý vi phạm hành chính.</div>
                </div>
                 <!-- /I. MẪU VĂN BẢN -->
                
                <!-- hr line -->

                <!-- <hr class="mtop-bottom-40"> -->
                <div class="mtop-bottom-40"></div>
            </div>

                <!-- II. NHẬP THÔNG TIN -->
                <!-- ========================================================================== -->
                <div class="card-body mbg-input">
                    <div class="mheader-2" id="input_behavior"> 
                        <i class="fas fa-marker fa-lg"></i> Nhập thông tin 
                        <i class="fas fa-backspace scroll-up fa-lg txt-right micon" @click="resetForm()"></i>
                    </div>

                    <div>
                        <!-- Số 1. Đã thực hiện hành vi vi phạm hành chính -->
                        <div class="card" id="behavior">
                            <!-- Header -->
                            <div class="card-header">
                                <p class="bold mheader-3" style="color: #05668D;"> 
                                    a) Hình thức xử phạt chính 

                                    <!-- Scroll to Mẫu văn bản -->
                                    <i class="fas fa-arrow-circle-up micon txt-right pointer"  v-scroll-to="'#article12'"></i>
                                </p>
                            </div>

                            <!-- Danh sách hành vi vi phạm -->
                            <div v-if="behavior_regulation_penalty.length > 0" class="txt-right">
                                <ul class="nav nav-pills ml-auto p-2 txt-right">
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="true">
                                            Danh sách hành vi vi phạm <span class="caret"></span>
                                        </a>
                                        <div class="dropdown-menu" x-placement="bottom-start" 
                                            style="position: absolute; transform: translate3d(-5px, 40px, 0px); top: 0px; left: 0px; will-change: transform;">
                                                <!-- Danh sách Hành vi -->
                                                <div class="dropdown-item" tabindex="-1" 
                                                    v-for="(item, index) in behavior_regulation_penalty">
                                                        Hành vi {{index + 1}} 
                                                        <span class="txt-right"><i class="fas fa-trash micon-delete" @click="confirmDeleteBehaviorRegulationPenalty(item.id)"></i></span>
                                                        <span class="txt-right"><i class="fas fa-edit micon-edit" style="margin-right: 10px;" @click="editBehaviorRegulationPenaltyFilltoForm(item.id)"></i></span>
                                                </div>                    
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div style="clear: both;"></div>

                            <!-- Mục / Điều -->
                            <div class="card-body row">
                                <div style="margin-top: 10px;">
                                    <i class="fas fa-list-ol micon"></i> Số hành vi vi phạm <sup><code>(*)</code></sup>
                                </div>
                                <div class="input-group mb-3">
                                    <input v-model="num_violation_behavior" type="number" class="form-control" placeholder="Nhập số lượng hành vi vi phạm" autocomplete="off">
                                </div>
                            
                                <div class="row">
                                    <!-- Chọn Mục -->
                                    <multiselect
                                        id="section_id"
                                        track-by="section_id" label="section_content" 
                                        @input="handleSelectSection()"
                                        v-model="section_selected" 
                                        open-direction="bottom"
                                        :disabled="isDisabledMultiselect"
                                        placeholder="Chọn Mục"
                                        :options="getListSection" 
                                        :multiple="false"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-pointer="true"
                                        :hide-selected="true">
                                    </multiselect>
                                </div>

                                <!-- Chọn Điều -->
                                <div class="row" style="margin-top: 20px" v-if="isShowArticle">
                                    <multiselect
                                        id="article_id"
                                        track-by="article_id" label="article_content" 
                                        @input="handleSelectArticle()"
                                        v-model="article_selected" 
                                        open-direction="bottom"
                                        placeholder="Chọn Điều"
                                        :options="list_article" 
                                        :multiple="false"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-pointer="true"
                                        :hide-selected="true">
                                    </multiselect>
                                </div>

                                <!-- Chọn Khoản -->
                                <div class="row" style="margin-top: 20px" v-if="isShowClause">
                                    <multiselect
                                        id="clause_id_dev"
                                        track-by="clause_id_dev" label="clause_content" 
                                        @input="handleSelectClause()"
                                        v-model="clause_selected" 
                                        open-direction="bottom"
                                        placeholder="Chọn Khoản"
                                        :options="list_clause" 
                                        :multiple="false"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-pointer="true"
                                        :hide-selected="true">
                                    </multiselect>
                                </div>

                                <!-- Điểm -->
                                <div class="row" style="margin-top: 20px" v-if="isShowPoint">
                                    <multiselect
                                        id="point_id"
                                        track-by="point_id_dev" label="point_content" 
                                        @input="handleSelectPoint()"
                                        v-model="point_selected" 
                                        open-direction="bottom"
                                        placeholder="Chọn Điểm"
                                        :options="list_point" 
                                        :multiple="false"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-pointer="true"
                                        :hide-selected="true">
                                    </multiselect>
                                </div>

                                <!-- Tiết -->
                                <div class="row" style="margin-top: 20px" v-if="isShowUnderPoint">
                                    <multiselect
                                        id="under_point_id"
                                        track-by="under_point_id_dev" label="under_point_content" 
                                        @input="handleSelectUnderPoint()"
                                        v-model="under_point_selected" 
                                        open-direction="bottom"
                                        placeholder="Chọn Tiết"
                                        :options="list_under_point" 
                                        :multiple="false"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :show-pointer="true"
                                        :hide-selected="true">
                                    </multiselect>
                                </div>

                                <!-- Số (1). Người dùng chỉnh sửa hành vi vi phạm hành chính -->
                                <div style="margin-top: 20px;">
                                    <p> <i class="fas fa-ban micon"></i> 
                                        Hành vi vi phạm hành chính 
                                        <sup><code>(*)</code></sup>
                                        <span class="mybadge txt-right">1</span> 
                                    </p>
                                    
                                    <!-- Nhập chỉ tiêu thống kê -->
                                    <div class="row" 
                                        style="margin-bottom: 5px;"
                                        v-if="is_statistic"
                                    >
                                        <div class="col-md-6">
                                            Chỉ số thống kê:
                                            <div>{{statistic_index_name}}</div> 
                                        </div>
                                        <div class="col-md-6">
                                            {{ statistic_input_name }} <span v-html="statistic_show"></span> {{ ` (${ statistic_input_unit.toLowerCase()})` }}
                                            <div class="input-group" style="width: 100%;">
                                                <imask-input
                                                    style="width: 100% !important; border: 1px solid #c4c4c4 !important; border-radius: 5px !important; height: 35px;"
                                                    v-model="statistic_value"
                                                    :mask="Number"
                                                    :signed="false"
                                                    :scale="5"
                                                    thousandsSeparator="."
                                                    radix=","
                                                    :unmask="true"
                                                    placeholder='Nhập số liệu thống kê'
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <ckeditor :editor="editor" :config="editorConfig" v-model="violation_behavior_enter"></ckeditor>
                                </div>

                                <!-- Số (2). Quy định tại -->
                                <div style="margin-top: 20px;">
                                    <p> <i class="fas fa-gavel micon"></i> 
                                        Quy định tại 
                                        <sup><code>(*)</code></sup>
                                        <span class="mybadge txt-right">2</span> 
                                    </p>
                                    <ckeditor :editor="editor" :config="editorConfig" v-model="regulation_at_enter"></ckeditor>
                                </div>

                                <!-- Số (5). Hình thức xử phạt chính -->
                                <div class="col-md-12" style="margin-top: 20px;">
                                    <p> <i class="fas fa-money-check micon"></i> 
                                        Hình thức xử phạt chính 
                                        <sup><code>(*)</code></sup> 
                                        <span class="mybadge txt-right">3</span>
                                    </p>
                                    <!-- Nhập số tiền phạt -->
                                    <div class="row" style="margin-bottom: 5px;">
                                        <div class="col-md-6" v-if="main_penalty_dc !== ''">
                                            <span>
                                                Khung hình phạt:
                                                <div>{{main_penalty_dc}}</div> 
                                            </span>
                                        </div>
                                        <div class="col-md-6">
                                            <div>
                                                Số tiền phạt 
                                                <span class="badge " :class="money_operator_code_waring" v-if="money_penalty_by_behavior_enter"><strong> {{money_penalty_by_behavior_enter | formatNumber}} (đồng) </strong></span>
                                                <span v-if="!money_penalty_by_behavior_enter"> (đồng)</span>
                                            </div>
                                            <div>
                                                <div class="input-group" style="width: 100%;">
                                                    <!-- <input v-model="money_penalty_by_behavior" type="number" class="form-control" placeholder="Nhập số tiền phạt (đồng)" autocomplete="off"></input> -->
                                                    <imask-input
                                                        class="form-control"
                                                        style="border: 1px solid #c4c4c4 !important; border-radius: 5px !important; float: right;"
                                                        v-model="money_penalty_by_behavior"
                                                        :mask="Number"
                                                        :signed="false"
                                                        :scale="5"
                                                        thousandsSeparator="."
                                                        radix=","
                                                        :unmask="true"
                                                        placeholder='Nhập số tiền phạt (đồng)'
                                                    />
                                                    <span class="input-group-append float-right">
                                                        <button type="button" class="btn btn-default btn-flat"
                                                            style="background-color: #05668D; color: white;"
                                                            @click="suggestMainPenaltyEnterText()"
                                                        >
                                                            Hình thức xử phạt
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <ckeditor :editor="editor" :config="editorConfig" v-model="main_penalty_enter"></ckeditor>
                                </div>
                            </div>


                            
                            <!-- II. THÊM MỚI và CHỈNH SỬA HÀNH VI, QUY ĐỊNH, HÌNH THỨC XỬ PHẠT CHÍNH -->
                            <!-- Add: Nếu 1 hành vi -->
                            <div class="card-footer clearfix" v-if="isEdit === false">
                                <div class="row" >
                                    <!-- Nut ấn chỉnh sửa -->
                                    <div class="col-md-4">
                                        <button type="button" class="btn btn-default btn-sm float-left " :class="isDisabled"
                                            style="background-color: #05668D; color: white;"
                                            v-html="html_label_btn_add"
                                            @click="addBehaviorRegulationPenalty()">
                                        </button>
                                    </div>

                                    <div class="col-md-4">
                                        <span class="info-input-status">Số hành vi đã nhập: {{behavior_regulation_penalty.length}} </span> 
                                    </div>
                                    <div class="col-md-4">
                                        <span class="info-input-status">Số hành vi chưa nhập: {{num_violation_behavior - behavior_regulation_penalty.length}}</span> 
                                    </div>
                                </div>
                            </div>

                            <!-- Edit -->
                            <div class="card-footer clearfix" v-if="isEdit">
                                <div class="row">
                                    <!-- Nut ấn chỉnh sửa -->
                                    <div class="col-md-6">
                                        <button type="button" class="btn btn-default btn-sm float-left" 
                                            style="background-color: #05668D; color: white; border-color: #c4c4c4;"
                                            @click="saveEditBehaviorRegulationPenalty()">
                                        <i class="fas fa-save"></i> Lưu thông tin chỉnh sửa </button>
                                    </div>

                                    <div class="col-md-6">
                                        <button type="button" class="btn btn-default btn-sm txt-right" 
                                            style="background-color: #E5E5E5; color: black; border-color: #c4c4c4;"
                                            @click="cancelEditBehaviorRegulationPenalty()">
                                            Hủy chỉnh sửa </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- / Kết thúc Hành vi vi phạm hành chính, quy định và hình thức xử phạt chính -->


                        <!-- Số (4, 5). Ông (bà)/Tổ chức(**) có tên tại Điều này phải hoàn trả số kinh phí là -->
                        <div class="row" style="margin-top: 20px;">
                            <!-- Bằng số -->
                            <div class="col-md-6">
                                <div>
                                    <i class="fas fa-list-ol micon"></i> Hình thức xử phạt chính / Tổng số tiền phạt (bằng số)
                                    <span class="mybadge txt-right">4</span>
                                </div>
                                <div class="input-group mb-3">
                                    <input v-model="money_penalty_total_int" type="number" class="form-control" placeholder="Nhập tổng số tiền phạt bằng số" autocomplete="off">
                                </div>
                            </div>

                            <!-- Bằng chữ -->
                            <div class="col-md-6">
                                <div>
                                    <i class="fas fa-list-ol micon"></i> Hình thức xử phạt chính / Tổng số tiền phạt (bằng chữ)
                                    <span class="mybadge txt-right">5</span>
                                </div>
                                <div class="input-group mb-3">
                                    <input v-model="money_penalty_total_text" type="text" class="form-control" placeholder="Nhập tổng số tiền phạt bằng chữ" autocomplete="off">
                                </div>
                            </div>
                        </div>

                        <!-- Số (3). Các tình tiết tăng nặng (nếu có) -->
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 10px;">
                                <p> <i class="fas fa-hashtag micon"></i> 
                                    Các tình tiết tăng nặng (nếu có)  
                                    <span class="mybadge txt-right">*</span> 
                                    <span class="txt-right pointer" @click="showGuideIncreaseIssue()">
                                        <i class="far fa-question-circle micon" v-if="!isShowGuideIncreaseIssue" style="margin-top: 8px; margin-right: 5px;"></i>
                                        <i class="far fa-window-close micon" v-if="isShowGuideIncreaseIssue" style="margin-top: 8px; margin-right: 5px;"></i>
                                    </span>
                                </p>
                                    <IncreaseIssue v-if="isShowGuideIncreaseIssue" />
                                <ckeditor :editor="editor" :config="editorConfig" v-model="increase_issue"></ckeditor>
                            </div>
                        </div>

                        <!-- Số (4). Các tình tiết giảm nhẹ (nếu có) -->
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 10px;">
                                <p> <i class="fas fa-hashtag micon"></i> 
                                    Các tình tiết giảm nhẹ (nếu có)  <span class="mybadge txt-right">**</span> 
                                    <span class="txt-right pointer" @click="showGuideDecreaseIssue()">
                                        <i class="far fa-question-circle micon" v-if="!isShowGuideDecreaseIssue" style="margin-top: 8px; margin-right: 5px;"></i>
                                        <i class="far fa-window-close micon" v-if="isShowGuideDecreaseIssue" style="margin-top: 8px; margin-right: 5px;"></i>
                                    </span>
                                </p>
                                    
                                    <DecreaseIssue v-if="isShowGuideDecreaseIssue" />
                                <ckeditor :editor="editor" :config="editorConfig" v-model="decrease_issue"></ckeditor>
                            </div>
                        </div>

                        <div class="bold" style="margin-top: 50px; color: #05668D;">b) Hình thức xử phạt bổ sung</div>

                        <!-- Số (6). Tịch thu tang vật VPHC gồm (nếu có) -->
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 10px;">
                                <p> <i class="fas fa-list micon"></i> 
                                    Tịch thu tang vật VPHC gồm (nếu có) <span class="mybadge txt-right">6</span> </p>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="extend_penalty_exhibit"></ckeditor>
                            </div>
                        </div>

                        <!-- Số (7). Tịch thu phương tiện (nếu có) -->
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 10px;">
                                <p> <i class="fas fa-motorcycle micon"></i> 
                                    Tịch thu phương tiện được sử dụng để thực hiện hành vi VPHC (nếu có) <span class="mybadge txt-right">7</span> </p>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="extend_penalty_vihicle"></ckeditor>
                            </div>
                        </div>

                        <!-- Số (8, 9) Tổng số phương tiện tịch thu, đơn vị -->
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-md-6">
                                <i class="fas fa-motorcycle micon"></i> Tổng số phương tiện tịch thu (bao gồm cả dụng cụ, công cụ) 
                                <span class="mybadge txt-right">8</span>
                                <div class="input-group mb-3">
                                    <input v-model="extend_penalty_vihicle_num" type="number" class="form-control" placeholder="Nhập tổng số phương tiện tịch thu" autocomplete="off">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <span class="mybadge txt-right">9</span>
                                <div class="input-group mb-3">
                                    <input v-model="extend_penalty_vihicle_unit_num" type="text" class="form-control" placeholder="Đơn vị (cái, chiếc)" autocomplete="off">
                                </div>
                            </div>
                        </div>

                        <!-- Số (10). Nội dung khác có liên quan -->
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 10px;">
                                <p> <i class="fas fa-sticky-note micon"></i> 
                                    Nội dung khác có liên quan <span class="mybadge txt-right">10</span> </p>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="extend_penalty_other"></ckeditor>
                            </div>
                        </div>

                        <!-- 11. Thời hạn thực hiện hình thức xử phạt bổ sung -->
                        <div class="row">
                            <div style="margin-top: 10px;">
                                <i class="fas fa-list-ol micon"></i> Thời hạn thực hiện hình thức xử phạt bổ sung (ngày)
                                <span class="mybadge txt-right">11</span>
                            </div>
                            <div class="input-group mb-3">
                                <input v-model="extend_penalty_duration" type="number" class="form-control" placeholder="Nhập thời hạn thực hiện hình thức xử phạt bổ sung (ngày)" autocomplete="off">
                            </div>
                        </div>

                        <div class="bold" style="margin-top: 40px; margin-bottom: 20px; color: #05668D;">c) Biện pháp khắc phục hậu quả</div> 

                        <!-- Số (12). Biện pháp khắc phục hậu quả -->
                        <div class="row">
                            <div class="col-md-12">
                                <p> <i class="fas fa-sticky-note micon"></i> 
                                    Nội dung biện pháp khắc phục hậu quả (nếu có) <span class="mybadge txt-right">12</span> </p>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="remedial_measure"></ckeditor>
                            </div>
                        </div>

                        <!-- 13. Thời hạn thực hiện biện pháp khắc phục hậu quả -->
                        <div class="row">
                            <div style="margin-top: 10px;">
                                <i class="fas fa-list-ol micon"></i> Thời hạn thực hiện biện pháp khắc phục hậu quả (ngày)
                                <span class="mybadge txt-right">13</span>
                            </div>
                            <div class="input-group mb-3">
                                <input v-model="remedial_measure_duration" type="number" class="form-control" placeholder="Nhập thời hạn thực hiện biện pháp khắc phục hậu quả (ngày)" autocomplete="off">
                            </div>
                        </div>

                        <!-- 14. Nội dung trực tiếp liên quan đến việc thực hiện biện pháp khắc phục hậu quả (nếu có) -->
                        <div class="row">
                            <div class="col-md-12">
                                <p> <i class="fas fa-sticky-note micon"></i> 
                                    Nội dung liên quan đến việc thực hiện biện pháp khắc phục hậu quả (nếu có) <span class="mybadge txt-right">14</span> </p>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="remedial_measure_content"></ckeditor>
                            </div>
                        </div>

                        <!-- Số (15, 16). Ông (bà)/Tổ chức(**) có tên tại Điều này phải hoàn trả số kinh phí là -->
                        <div class="row" style="margin-top: 10px;">
                            <!-- Bằng số -->
                            <div class="col-md-6">
                                <div>
                                    <i class="fas fa-list-ol micon"></i> Số kinh phí phải hoàn trả bằng số
                                    <span class="badge badge-info" v-if="remedial_measure_money_int"><strong> {{remedial_measure_money_int | formatNumber}} (đồng) </strong></span>
                                    <span v-if="!remedial_measure_money_int"> (đồng)</span>
                                    <span class="mybadge txt-right">15</span>
                                </div>
                                <div class="input-group mb-3">
                                    <input v-model="remedial_measure_money_int" type="number" class="form-control" placeholder="Nhập số kinh phí phải hoàn trả bằng số" autocomplete="off">
                                </div>
                            </div>

                            <!-- Bằng chữ -->
                            <div class="col-md-6">
                                <div>
                                    <i class="fas fa-list-ol micon"></i> Số kinh phí phải hoàn trả bằng chữ
                                    <span class="mybadge txt-right">16</span>
                                </div>
                                <div class="input-group mb-3">
                                    <input v-model="remedial_measure_money_text" type="text" class="form-control" placeholder="Nhập số kinh phí phải hoàn trả bằng chữ" autocomplete="off">
                                </div>
                            </div>
                        </div>

                        <!-- Số 17. Cho .... là cơ quan đã thực hiện biện pháp khắc phục hậu quả -->
                        <div class="row">
                                <div>
                                    <i class="fas fa-university micon"></i> Tên cơ quan thực hiện biện pháp khắc phục hậu quả
                                    <span class="mybadge txt-right">17</span>
                                </div>
                                <div class="input-group mb-3">
                                    <input v-model="organization_get_money" type="text" class="form-control" placeholder="Nhập tên cơ quan thực hiện biện pháp khắc phục hậu quả" autocomplete="off">
                                </div>
                            </div>
                    </div>

                    <div id="article2" style="margin-bottom: 10px;"></div>

                </div>
                <!-- /II. NHẬP THÔNG TIN -->
                
                <!-- III. FORM XÁC NHẬN XÓA THÔNG TIN HÀNH VI ====================================================================== -->
                <div>
                    <div class="modal fade" id="form-delete">
                        <div class="modal-dialog modal-default modal-lg">
                            <div class="modal-content">
                                <div class="modal-header bg-warning" style="color: white; background-color: #138496;">
                                    <h6 class="modal-title"><i class="icon fas fa-ban"></i> Xác nhận xóa thông tin hành vi, quy định, hình thức xử phạt chính </h6>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeConfirmDelete" style="display: none">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <!-- Body: content edit user -->
                                    <div style="margin: 15px">
                                        <div class="row">
                                            <p class="mheader-3"> 
                                                <i class="fas fa-ban micon"></i> 
                                                <span class="bold">Hành vi vi phạm</span>
                                            </p>
                                            <div v-html="violation_behavior_enter_delete"></div>
                                        </div>
                                        <div class="row">
                                            <p class="mheader-3"> 
                                                <i class="fas fa-gavel micon"></i> 
                                                <span class="bold">Quy định tại </span>
                                            </p>
                                            <div v-html="regulation_at_enter_delete"></div>
                                        </div>
                                        <div class="row">
                                            <p class="mheader-3"> 
                                                <i class="fas fa-money-check micon"></i>
                                                <span class="bold"> Hình thức xử phạt chính </span> 
                                            </p>
                                            <div v-html="main_penalty_enter_delete"></div>
                                        </div>

                                    </div>
                                </div>
                                <div class="modal-footer justify-content-between">
                                    <button type="button" class="btn btn-sm" v-on:click.prevent="handDeleteBehaviorRegulationPenalty()" style="width: 150px; color: white; background-color: #DC5C51;">Xóa</button>
                                    <button type="button" class="btn btn-sm mybtn-cancel" data-dismiss="modal" style="width: 100px; color: #5C677D; background-color: #E5E5E5;"> Hủy xóa </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="button" class="btn btn-default" data-toggle="modal" data-target="#form-delete" style="display: none" ref="openConfirmDelete">
                        Confirm Delete
                    </button>
                </div>

        </div>
    </div>
</template>

<script>
    
    import Vue from 'vue';
    import { mapActions, mapGetters } from 'vuex';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import { IMaskComponent } from 'vue-imask';                // 
    import IncreaseIssue from './Increase_Issue';
    import DecreaseIssue from './Decrease_Issue';
    import { htmlToText, capitalizeFirstLetter, formatNumberVn, checkToShowSubOption } from '@/helper/index'

    export default {
    name: "vd-article12",
    props: [
            'entity_type_id'
        ],
    components: {
        DecreaseIssue,
        IncreaseIssue,
        'imask-input': IMaskComponent
    },
    data() {
        return {
            // Dấu (*): là các trường lưu vào CDSL, để temp hoặc không đánh dấu là biến sử dụng temp và cho mục đích xử lý trung gian, không lưu vào CSDL
            // Mục số 1, 2, 3: Hành vi, hình thức xử phạt và biện pháp khắc phục hậu quả
            // Section: Mục
            section_id: "",
            section_content: "",
            section_selected: [],
            list_section: [],
            
            // Acticle: Điều
            article_id: "",
            article_content: "",
            article_selected: [],
            list_article: [],

            // Clause: Khoản
            clause_id_dev: "",
            clause_content: "",
            clause_selected: [],
            list_clause: [],

            // Point: Điểm
            point_id_dev: "",
            point_content: "",
            point_selected: [],
            list_point: [],

            // Under point: Tiết
            under_point_id_dev: "",             // * ID tiết
            under_point_content: "",            // Temp: Nội dung tiết
            under_point_selected: [],           // Temp: Tiết người dùng đã chọn
            list_under_point: [],               // Temp: Danh sách Tiết (lấy dựa vào point_id_dev)

            // Số (1) Hành vi vi phạm hành chính
            violation_behavior_enter: "",       // Temp: hành vi vi phạm hành chính do người dùng nhập vào (cho từng hành vi)

            // Chỉ số thống kê
            num_violation_behavior: 1,          // * số hành vi vi phạm
            is_statistic: false,                // Temp: thống kê | không thống kê
            statistic_index_id: "",             // Mã số chỉ tiêu thống kê từ bảng dc_content
            statistic_index_name: "",           // Tên chỉ tiêu thống kê lấy từ bảng dc_content
            statistic_input_name: "",           // label đơn vị chỉ tiêu thống kê từ bảng dc_content
            statistic_input_unit: "",           // Đơn vị chỉ tiêu thống kê từ bảng dc_content
            statistic_value: null,              // Giá trị chỉ tiêu thống kê do người dùng nhập vào

            // Số (2). Quy định tại
            regulation_at_enter: "",            // Temp: quy định tại (theo từng hành vi) do người dùng nhập vào
            regulation_at_main: "",             // Temp: quy định tại (theo từng hành vi) theo nghị định (nếu 1 hành vi thì lấy theo main)
            regulation_at_sub: "",              // Temp: quy định tại (theo từng hành vi) theo nghị định (nếu từ 2 hành vi trở lên thì hành vi từ thứ 2 lấy theo sub)

            // Số (3). Hình thức xử phạt chính
            main_penalty_dc: "",                // Temp: hình thức xử phạt chính (theo từng hành vi) theo nghị định
            main_penalty_enter: "",             // Temp: hình thức xử phạt chính (theo từng hành vi) do người dùng nhập vào
            money_penalty_by_behavior: 0,       // * Số tiền phạt theo hành vi
            money_penalty_total_int: 0,         // *: tổng số tiền bằng số
            money_penalty_total_text: "",       // *: tổng số tiền bằng chữ
            money_operator_code: 0,             // Temp
            money_min: 0,                       // Temp: số tiền tối thiểu theo khung hình phạt quy định tại nghị định 35
            money_max: 0,                       // Temp: số tiền tối đa theo khung hình phạt quy định tại nghị định 35

            // Mảng lưu thông tin Hành vi, hình thức xử phạt và biện pháp khắc phục hậu quả khi người dùng ấn nút "Đưa vào quyết định"
            behavior_regulation_penalty: [],


            // Số (*). Các tình tiết tăng nặng (nếu có)
            increase_issue: "",
            // Số (**). Các tình tiết giảm nhẹ (nếu có)
            decrease_issue: "",
            // Số (4). Hình thức xử phạt bổ sung / Tịch thu tang vật
            extend_penalty_exhibit: "",
            // Số (5). Hình thức xử phạt bổ sung / Tịch thu phương tiện
            extend_penalty_vihicle: "",
            extend_penalty_vihicle_num: "",
            extend_penalty_vihicle_unit_num: "",
            // Số (8). Hình thức xử phạt bổ sung / Nội dung khác có liên quan
            extend_penalty_other: "",
            // Số (9). Thời gian thực hiện hình thức xử phạt bổ sung
            extend_penalty_duration: "",
            // Số (10). Biện pháp khắc phục hậu quả (nếu có):
            remedial_measure: "",
            // Số (11). Thời hạn thực hiện biện pháp khắc phục hậu quả
            remedial_measure_duration: "",
            // Số (12). Nội dung trực tiếp liên quan đến việc thực hiện biện pháp khắc phục hậu quả (nếu có)
            remedial_measure_content: "",
            // Số (13, 14). Ông (bà)/Tổ chức(**) có tên tại Điều này phải hoàn trả số kinh phí là
            remedial_measure_money_int: "",
            remedial_measure_money_text: "",
            // Số (15). Cho: 16 .................................... là cơ quan đã thực hiện biện pháp khắc phục hậu quả theo quy định tại khoản 5 Điều 85 Luật Xử lý vi phạm hành chính.
            organization_get_money: "",
           
            // Edit
            id_obj_edit: "",
            isEdit: false,
            
            // Delete
            id_obj_delete: "",
            violation_behavior_enter_delete: "",
            regulation_at_enter_delete: "",
            main_penalty_enter_delete: "",

            // isShow
            isShowArticle: false,
            isShowClause: false,
            isShowPoint: false,
            isShowUnderPoint: false,
            isShowGuideDecreaseIssue: false,
            isShowGuideIncreaseIssue: false,

            // CKEditor
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        // 'fontfamily', 'fontsize', '|',
                        // 'alignment', '|',
                        // 'fontColor', 'fontBackgroundColor', '|',
                        "bold",
                        "italic",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "|",
                        // 'insertTable', '|',
                        "undo",
                        "redo"
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
        };
    },
    created() {
        this.actGetListSection(); // Lấy danh sách các mục ở chương II     
    },
    watch: {
        remedial_measure_money_int(newVal){
            if(newVal !== "") {
                this.remedial_measure_money_text = this.convertNumberToString(newVal)
            } else {
                this.remedial_measure_money_text = ''
            }
        }
    },
    computed: {
        ...mapGetters([
            "getListSection",
            "isEditFromServer"
        ]),
        // 1. Hành vi vi phạm hành chính
        lviolation_behavior_enter: function () {
            if (this.behavior_regulation_penalty.length < this.num_violation_behavior)
                return "";
            return "hide";
        },
        html_violation_behavior_enter: function () {
            if (this.behavior_regulation_penalty.length === 0)
                return "........................................................................................................................................................................................................";
        },
        // 2. Quy định tại: 
        lregulation_at_enter: function () {
            if (this.behavior_regulation_penalty.length < this.num_violation_behavior)
                return "";
            return "hide";
        },
        html_regulation_at_enter: function () {
            if (this.behavior_regulation_penalty.length === 0)
                return "..................................................................................................................................................................................................................................................................";
        },
        // 5. Hình thức xử phạt chính
        lmain_penalty_enter: function () {
            if (this.behavior_regulation_penalty.length < this.num_violation_behavior)
                return "";
            return "hide";
        },
        html_main_penalty_enter: function () {
            if (this.behavior_regulation_penalty.length === 0)
                return ".......................................................................................................................................................................................................................";
        },
        // 5.1. Tổng số tiền phạt bằng số
        lmoney_penalty_total_int: function () {
            if (this.money_penalty_total_int === 0)
                return "";
            return "hide";
        },
        html_money_penalty_total_int: function () {
            if (this.money_penalty_total_int === 0)
                return "......................................";
            return `<span class="bold">` + formatNumberVn(this.money_penalty_total_int) + "</span>";
        },
        // 5.2. Tổng số tiền phạt bằng chữ
        lmoney_penalty_total_text: function () {
            if (this.money_penalty_total_text === "")
                return "";
            return "hide";
        },
        html_money_penalty_total_text: function () {
            if (this.money_penalty_total_text === "")
                return "......................................................................................................................................................................................";
            return `<span class="bold">` + this.money_penalty_total_text + `</span>`;
        },
        // 3. Tình tiết tăng nặng
        lincrease_issue: function () {
            if (this.increase_issue === "")
                return "";
            return "hide";
        },
        html_increase_issue: function () {
            if (this.increase_issue === "")
                return "...........................................................................................................................................................................................................................";
            return `<span class="bold">` + this.increase_issue + "</span>";
        },
        // 4. Tình tiết giảm nhẹ
        ldecrease_issue: function () {
            if (this.decrease_issue === "")
                return "";
            return "hide";
        },
        html_decrease_issue: function () {
            if (this.decrease_issue === "")
                return "............................................................................................................................................................................................................................";
            return `<span class="bold">` + this.decrease_issue + "</span>";
        },
        // 6. Tịch thu tang vật VPHC gồm (nếu có)
        lextend_penalty_exhibit: function () {
            if (this.extend_penalty_exhibit === "")
                return "";
            return "hide";
        },
        html_extend_penalty_exhibit: function () {
            if (this.extend_penalty_exhibit === "")
                return ".........................................................................................................................................................................................................................................................................................................";
            return `<span class="bold">` + this.extend_penalty_exhibit + "</span>";
        },
        // 7. Tịch thu phương tiện (nếu có)
        lextend_penalty_vihicle: function () {
            if (this.extend_penalty_vihicle === "")
                return "";
            return "hide";
        },
        html_extend_penalty_vihicle: function () {
            if (this.extend_penalty_vihicle === "")
                return ".........................................................................................................................................................................................................................................................................................................";
            return `<span class="bold">` + this.extend_penalty_vihicle + "</span>";
        },
        // 8 và 9. Tổng số phương tiện tịch thu
        // 8. Số lượng
        lextend_penalty_vihicle_num: function () {
            if (this.extend_penalty_vihicle_num === "")
                return "";
            return "hide";
        },
        html_extend_penalty_vihicle_num: function () {
            if (this.extend_penalty_vihicle_num === "")
                return "........................";
            return `<span class="bold">` + this.extend_penalty_vihicle_num + "</span>";
        },
        // 9. Đơn vị
        lextend_penalty_vihicle_unit_num: function () {
            if (this.extend_penalty_vihicle_unit_num === "")
                return "";
            return "hide";
        },
        html_extend_penalty_vihicle_unit_num: function () {
            if (this.extend_penalty_vihicle_unit_num === "")
                return "...................................................................................................";
            return `<span class="bold">` + this.extend_penalty_vihicle_unit_num + "</span>";
        },
        // 10. Nội dung khác có liên quan
        lextend_penalty_other: function () {
            if (this.extend_penalty_other === "")
                return "";
            return "hide";
        },
        html_extend_penalty_other: function () {
            if (this.extend_penalty_other === "")
                return ".........................................................................................................................................................................................................................................................................................................";
            return `<span class="bold">` + this.extend_penalty_other + "</span>";
        },
        // 11. Thời hạn thực hiện hình thức xử phạt bổ sung
        lextend_penalty_duration: function () {
            if (this.extend_penalty_duration === "")
                return "";
            return "hide";
        },
        html_extend_penalty_duration: function () {
            if (this.extend_penalty_duration === "")
                return "....";
            return `<span class="bold">` + this.extend_penalty_duration + "</span>";
        },
        // Số (12). Biện pháp khắc phục hậu quả
        lremedial_measure: function () {
            if (this.remedial_measure === "")
                return "";
            return "hide";
        },
        html_remedial_measure: function () {
            if (this.remedial_measure === "")
                return ".........................................................................................................................................................................................................................................................................................................";
            return `<span class="bold">` + this.remedial_measure + "</span>";
        },
        // 13. Thời hạn thực hiện biện pháp khắc phục hậu quả
        lremedial_measure_duration: function () {
            if (this.remedial_measure_duration === "")
                return "";
            return "hide";
        },
        html_remedial_measure_duration: function () {
            if (this.remedial_measure_duration === "")
                return "....";
            return `<span class="bold">` + this.remedial_measure_duration + "</span>";
        },
        // 14. Nội dung trực tiếp liên quan đến việc thực hiện biện pháp khắc phục hậu quả (nếu có)
        lremedial_measure_content: function () {
            if (this.remedial_measure_content === "")
                return "";
            return "hide";
        },
        html_remedial_measure_content: function () {
            if (this.remedial_measure_content === "")
                return "..................................................................................................";
            return `<span class="bold">` + this.remedial_measure_content + "</span>";
        },

        // Số (15, 16). Ông (bà)/Tổ chức(**) có tên tại Điều này phải hoàn trả số kinh phí là
        // Số 15. Bằng số
        lremedial_measure_money_int: function () {
            if (this.remedial_measure_money_int === "")
                return "";
            return "hide";
        },
        html_remedial_measure_money_int: function () {
            if (this.remedial_measure_money_int === "")
                return ".............................................................................................................................................................................";
            return `<span class="bold">` + formatNumberVn(this.remedial_measure_money_int) + " (đồng) </span>";
        },

        // Số 16. Bằng chữ
        lremedial_measure_money_text: function () {
            if (this.remedial_measure_money_text === "")
                return "";
            return "hide";
        },
        html_remedial_measure_money_text: function () {
            if (this.remedial_measure_money_text === "")
                return "..........................................................................................................................................................................................................................................................................";
            return `<span class="bold">` + this.remedial_measure_money_text + "</span>";
        },

        // Số 17. Cho .... là cơ quan đã thực hiện biện pháp khắc phục hậu quả
        lorganization_get_money: function () {
            if (this.organization_get_money === "")
                return "";
            return "hide";
        },
        html_organization_get_money: function () {
            if (this.organization_get_money === "")
                return "....................................";
            return `<span class="bold">` + this.organization_get_money + "</span>";
        },

        // NHÃN NÚT ADD
        html_label_btn_add: function () {
            // Nếu 1 hành vi
            var xhtml = `<i class="fas fa-save"></i> Lưu`;
            // Từ 2 hành vi trở lên
            if (this.num_violation_behavior > 1) {
                if(this.num_violation_behavior - this.behavior_regulation_penalty.length > 1){
                    xhtml = `<i class="fas fa-plus"></i> Lưu và thêm hành vi`;
                }

                if(this.num_violation_behavior - this.behavior_regulation_penalty.length === 1){
                    xhtml = `<i class="fas fa-save"></i> Lưu`;
                }
            }
            return xhtml;
        },

        // Disable button cập nhật nếu số người nhập bằng số người đã khai báo
        isDisabled: function () {
            if (parseInt(this.num_violation_behavior) === this.behavior_regulation_penalty.length ||
                this.num_violation_behavior === "" ||
                this.num_violation_behavior === 0)
                return "disabled";
            return "";
        },
        isDisabledMultiselect: function () {
            if(this.isEdit === true) return false
            if (parseInt(this.num_violation_behavior) === this.behavior_regulation_penalty.length ||
                this.num_violation_behavior === "" ||
                this.num_violation_behavior === 0)
                return true;
            return false;
        },

        // Validate chỉ số thống kê
        statistic_show: function(){
            if(parseFloat(this.statistic_value) > 0) return `<span class="badge-info" style='border-radius: 5px; padding: 4px; margin-right: 5px'> ${new Intl.NumberFormat('vi-IN').format(this.statistic_value)} </span> `
            if(this.statistic_value == 0) return ''
            return `<span class="badge-danger" style='border-radius: 5px; padding: 4px; margin-right: 5px; font-size: 12px'> [số nhập vào không hợp lệ: kiểm tra dấu phân tách hàng nghìn, hàng thập phân] </span>`            
        },  

        // Hình thức phạt chính: format số tiền
        money_penalty_by_behavior_enter: function () {
            if (this.money_penalty_by_behavior > 0)
                return this.money_penalty_by_behavior;
            return "";
        },
        
        money_operator_code_waring: function () {
            // Nếu không có khoảng min và max
            if(this.money_min === 0 & this.money_max === 0) return 'badge-secondary'

            // Nhập đúng từ A đến B
            if(this.entity_type_id === 1){ // Cá nhân
                if ((this.money_operator_code === 2 &
                    this.money_penalty_by_behavior > 0 &
                    this.money_penalty_by_behavior >= this.money_min &
                    this.money_penalty_by_behavior <= this.money_max) || this.isEditFromServer === true)
                    return "badge-info";

                // Nhập đúng từ A đến dưới B
                if (this.money_operator_code === 4 &
                    this.money_penalty_by_behavior > 0 &
                    this.money_penalty_by_behavior >= this.money_min &
                    this.money_penalty_by_behavior < this.money_max)
                    return "badge-info";
                return "badge-danger";
            }

            // Nhập đúng từ A đến B
            if(this.entity_type_id === 2){ // Tổ chức
                if (this.money_operator_code === 2 &
                    this.money_penalty_by_behavior > 0 &
                    this.money_penalty_by_behavior >= this.money_min &
                    this.money_penalty_by_behavior <= this.money_max *2)
                    return "badge-info";

                // Nhập đúng từ A đến dưới B
                if (this.money_operator_code === 4 &
                    this.money_penalty_by_behavior > 0 &
                    this.money_penalty_by_behavior >= this.money_min &
                    this.money_penalty_by_behavior < this.money_max * 2)
                    return "badge-info";
                return "badge-danger";
            }
            
        }
    },
    methods: {
        ...mapActions([
            "actGetListSection",
            "actGetListArctileBySection",
            "actGetListClauseByArticle",
            "actGetListPointByClause",
            "actGetListUnderPointByPoint",
            "actStatisticUnderPoint"
        ]),

        convertNumberToString(number){
            if(number){
                return capitalizeFirstLetter(Vue.prototype.$numberToString(number)) + " đồng chẵn."
            }
            return ''
        },

        // USER SELECT
        // ======================================================================
        // Số 2. Đã thực hiện hành vi vi phạm hành chính
        handleSelectSection() {
            this.section_id = this.section_selected.section_id;
            this.section_content = this.section_selected.section_content;

            if (this.section_id !== "") {
                // Lấy danh sách Điều
                this.actGetListArctileBySection(this.section_id).then((data) => {
                    this.list_article = data.list_article;
                    this.isShowArticle = true;
                });

                // Reset form under section
                this.resetUnderSection();
            }
        },

        handleSelectArticle() {
            this.article_id = this.article_selected.article_id;
            this.article_content = this.article_selected.article_content;

            if (this.article_id !== "") {
                // Lấy danh sách Khoản
                this.actGetListClauseByArticle(this.article_id).then((data) => {
                    this.list_clause = data.list_clause;
                    this.isShowClause = true;
                });
                
                // Reset lại form dưới Article
                this.resetUnderArticle();
            }
        },

        handleSelectClause() {
            this.clause_id_dev = this.clause_selected.clause_id_dev;
            this.clause_content = this.clause_selected.clause_content;

            if (this.clause_id_dev !== "") {
                var params = {
                    article_id: this.article_id,
                    clause_id_dev: this.clause_id_dev
                };

                // Lấy danh sách Khoản
                this.actGetListPointByClause(params).then((data) => {
                    // console.log("com actGetListPointByClause data ==== ", data);

                    if (data.end === true) { // Dưới Khoản không còn Điểm
                        // Xóa thông tin lưu trước đó
                        this.resetBehaviorRegulatioPenalty();

                        // workflow (1), workflow (2): Phần hiển thị chung khi có hoặc không thống kê thì hiển Hành vi, Quy định, Hình thức xử phạt
                        // 1. Hành vi vi phạm
                        this.violation_behavior_enter = data.statistic.violation_behavior_dc;

                        // 2. Quy định tại
                        this.regulation_at_enter = data.statistic.regulation_at_main;
                        if (this.behavior_regulation_penalty.length >= 1) {
                            this.regulation_at_enter = data.statistic.regulation_at_main;
                        }

                        // 3. Hình thức xử phạt chính
                        if (data.statistic.main_penalty_dc !== null) { // Nếu có hình thức xử phạt chính
                            this.main_penalty_dc = data.statistic.main_penalty_dc;
                            this.money_min = data.statistic.money_min;
                            this.money_max = data.statistic.money_max;
                            this.money_operator_code = data.statistic.money_operator_code;
                        }

                        // Check có thống kê hay không: nếu có thì tiến hành hiển thị form thống kê; nếu không thì hiển thị form nhập thông tin vi phạm
                        if (data.statistic.statistic_index_id) { // workflow (1): Nếu có thống kê
                            // Chỉ tiêu thống kê
                            this.is_statistic = true;
                            this.statistic_index_id = data.statistic.statistic_index_id;
                            this.statistic_index_name = data.statistic.statistic_index_name;
                            this.statistic_input_name = data.statistic.statistic_input_name;
                            this.statistic_input_unit = data.statistic.statistic_input_unit;
                        }
                    }
                    if (data.end === false) { // Dưới Khoản còn Điểm --> liệt kê danh sách Điểm
                        this.list_point = data.list_point;
                        this.isShowPoint = true;
                    }
                });
            }
            // Reset lại form dưới Article
            this.resetUnderClause();
        },

        handleSelectPoint() {
            this.point_id_dev = this.point_selected.point_id_dev;
            this.point_content = this.point_selected.point_content;

            if (this.point_id_dev !== "") {
                var params = {
                    article_id: this.article_id,
                    clause_id_dev: this.clause_id_dev,
                    point_id_dev: this.point_id_dev
                };

                // Lấy danh sách Điểm
                this.actGetListUnderPointByPoint(params).then((data) => {
                    console.log("com actGetListUnderPointByPoint data ==== ", data);
                    if (data.end === true) { // Dưới Khoản không còn Điểm

                        // Xóa thông tin lưu trước đó
                        this.resetBehaviorRegulatioPenalty();

                        // workflow (1), workflow (2): Phần hiển thị chung khi có hoặc không thống kê thì hiển Hành vi, Quy định, Hình thức xử phạt
                        // 1. Hành vi vi phạm
                        this.violation_behavior_enter = data.statistic.violation_behavior_dc;

                        // 2. Quy định tại
                        this.regulation_at_enter = data.statistic.regulation_at_main;
                        if (this.behavior_regulation_penalty.length >= 1) {
                            this.regulation_at_enter = data.statistic.regulation_at_main;   //regulation_at_sub
                        }

                        // 3. Hình thức xử phạt chính
                        if (data.statistic.main_penalty_dc !== null) { // Nếu có hình thức xử phạt chính
                            this.main_penalty_dc = data.statistic.main_penalty_dc;
                            this.money_min = data.statistic.money_min;
                            this.money_max = data.statistic.money_max;
                            this.money_operator_code = data.statistic.money_operator_code;
                        }

                        // Check có thống kê hay không: nếu có thì tiến hành hiển thị form thống kê; nếu không thì hiển thị form nhập thông tin vi phạm
                        if (data.statistic.statistic_index_id) { // workflow (1): Nếu có thống kê
                            // Chỉ tiêu thống kê
                            this.is_statistic = true;
                            this.statistic_index_id = data.statistic.statistic_index_id;
                            this.statistic_index_name = data.statistic.statistic_index_name;
                            this.statistic_input_name = data.statistic.statistic_input_name;
                            this.statistic_input_unit = data.statistic.statistic_input_unit;
                        }
                    }

                    if (data.end === false) { // Dưới Khoản còn Điểm --> liệt kê danh sách Điểm
                        this.list_under_point = data.list_under_point;
                        this.isShowUnderPoint = true;
                    }
                });
            }
            // Reset lại form dưới Article
            this.resetUnderPoint();
        },

        handleSelectUnderPoint() {
            this.under_point_id_dev = this.under_point_selected.under_point_id_dev;
            this.under_point_content = this.under_point_selected.under_point_content;

            if (this.point_id_dev !== "") {
                var params = {
                    article_id: this.article_id,
                    clause_id_dev: this.clause_id_dev,
                    point_id_dev: this.point_id_dev,
                    under_point_id_dev: this.under_point_id_dev
                };

                // Lấy thông tin Hành vi, Quy định, Hình phạt chính và chỉ tiêu thống kê
                this.actStatisticUnderPoint(params).then((data) => {
                    // console.log("com actStatisticUnderPoint data ==== ", data);

                    if (data.end === true) { 
                        // Xóa thông tin lưu trước đó
                        this.resetBehaviorRegulatioPenalty();

                        // 1. Hành vi vi phạm
                        this.violation_behavior_enter = data.statistic.violation_behavior_dc;

                        // 2. Quy định tại
                        this.regulation_at_enter = data.statistic.regulation_at_main;
                        if (this.behavior_regulation_penalty.length >= 1) {
                            this.regulation_at_enter = data.statistic.regulation_at_main;   //regulation_at_sub
                        }

                        // 3. Hình thức xử phạt chính
                        if (data.statistic.main_penalty_dc !== null) {
                            this.main_penalty_dc = data.statistic.main_penalty_dc;
                            this.money_min = data.statistic.money_min;
                            this.money_max = data.statistic.money_max;
                            this.money_operator_code = data.statistic.money_operator_code;
                        }

                        // Check có thống kê hay không: nếu có thì tiến hành hiển thị form thống kê; nếu không thì hiển thị form nhập thông tin vi phạm
                        if (data.statistic.statistic_index_id) { 
                            // Chỉ tiêu thống kê
                            this.is_statistic = true;
                            this.statistic_index_id = data.statistic.statistic_index_id;
                            this.statistic_index_name = data.statistic.statistic_index_name;
                            this.statistic_input_name = data.statistic.statistic_input_name;
                            this.statistic_input_unit = data.statistic.statistic_input_unit;
                        }
                    }
                });
            }
        },

        // VALIDATE: hành vi, quy định, hình thức xử phạt chính
        // ======================================================================
        // validate before save
        validateBehaviorRegulationPenalty(obj){           

            if(obj.violation_behavior_enter === ''){
                toastr.error("Chưa nhập hành vi vi phạm", "Hành vi vi phạm", { "closeButton": true });
                return false
            }

            if(obj.statistic_value === '' && this.is_statistic === true){
                toastr.error("Chưa nhập chỉ số thống kê.", "Hành vi vi phạm", { "closeButton": true });
                return false
            }

            if(obj.regulation_at_enter === ''){
                toastr.error("Chưa nhập quy định tại.", "", { "closeButton": true });
                return false
            }

            if(obj.money_penalty_by_behavior === ''){
                toastr.error("Chưa nhập số tiền phạt.", "Hình thức xử phạt chính", { "closeButton": true });
                return false
            }

            if(obj.main_penalty_enter === ''){
                toastr.error("Chưa nhập hình thức xử phạt.", "Hình thức xử phạt chính", { "closeButton": true });
                return false
            }

            return true
        },

        // ADD: hành vi, quy định, hình thức xử phạt chính
        // ======================================================================
        addBehaviorRegulationPenalty() {
            // obj
            let id = this.$uuidKey();
            var obj = this.getInfoBehaviorRegulationPenalty(id);
            console.log('pfes obj:=====', obj);
            
            let flag_validate = this.validateBehaviorRegulationPenalty(obj)

            // Lưu thông tin người vi phạm vào mảng: behavior_regulation_penalty
            if(flag_validate){
                this.behavior_regulation_penalty.push(obj);

                // Hiển thị thông báo lưu thành công
                if(this.num_violation_behavior === 1){
                    toastr.success("Đưa vào quyết định thành công!", "", { "closeButton": true });
                }else{
                    toastr.success("Thêm hành vi thành công!", "", { "closeButton": true });
                }

                // reset form input
                this.resetBehaviorRegulatioPenalty();
                this.resetFromSectionToUnderPoint();

                // Tính tổng số tiền phạt do vi phạm hành vi chính
                this.sumTotalMainPenalty(this.behavior_regulation_penalty);
            }              
        },

        // Function gợi ý đưa ra câu hình thức phạt chính
        suggestMainPenaltyEnterText() {
            this.main_penalty_enter = htmlToText("Phạt tiền " + formatNumberVn(this.money_penalty_by_behavior) + " đồng về hành vi " + this.violation_behavior_enter.toLowerCase());
        },

        // Hàm sử dụng để lấy thống tin "Hành vi, Quy định tại, Hình thức phạt chính" để lưu vào mảng cho Add và Edit
        getInfoBehaviorRegulationPenalty(id) {
            // Cần truyền vào ID vì trường hợp Add là tạo ID mới, Edit là lấy ID đã có ở data
            var obj = {

                // I. THÔNG TIN GHI VÀO CSDL
                id: "",
                // 1. Hành vi vi phạm
                article_id: this.article_id,
                clause_id_dev: this.clause_id_dev,
                point_id_dev: this.point_id_dev,
                under_point_id_dev: this.under_point_id_dev,
                violation_behavior_enter: this.violation_behavior_enter,
                statistic_index_id: this.statistic_index_id,
                statistic_value: this.statistic_value,
                statistic_index_name: this.statistic_index_name,
                statistic_input_name: this.statistic_input_name,
                statistic_input_unit: this.statistic_input_unit,

                // 2. Quy định tại
                regulation_at_enter: this.regulation_at_enter,

                // 3. Hình thức xử phạt chính
                main_penalty_enter: this.main_penalty_enter,
                money_penalty_by_behavior: this.money_penalty_by_behavior,

                // II. THÔNG TIN TEMP CHO CHỨC NĂNG EDIT
                is_statistic: this.is_statistic,
                main_penalty_dc: this.main_penalty_dc,
                section_selected: this.section_selected,
                article_selected: this.article_selected,
                list_article: this.list_article,
                clause_selected: this.clause_selected,
                list_clause: this.list_clause,
                point_selected: this.point_selected,
                list_point: this.list_point,
                under_point_selected: this.under_point_selected,
                list_under_point: this.list_under_point
            };
            obj.id = id;
            return obj;
        },

        sumTotalMainPenalty(behavior_regulation_penalty) {
            // 1. Tính tổng                        
            var sum_money_penalty = 0;
            behavior_regulation_penalty.forEach((obj) => {
                sum_money_penalty += parseFloat(obj.money_penalty_by_behavior);
            });
            this.money_penalty_total_int = sum_money_penalty;

            // 2. Chuyển số thành chữ: capitalizeFirstLetter
            this.money_penalty_total_text = this.convertNumberToString(sum_money_penalty)
        },

        // EDIT: hành vi, quy định, hình thức xử phạt chính
        // ======================================================================
        editBehaviorRegulationPenaltyFilltoForm(id) {
            // console.log('editBehaviorRegulationPenaltyFilltoForm id = ', id)
            
            this.isEdit = true;
            this.id_obj_edit = id;

            // Lấy thông tin item
            var obj = this.behavior_regulation_penalty.filter(e => e.id === id);
            // console.log('editBehaviorRegulationPenaltyFilltoForm obj = ', obj)

            // 1. Hành vi vi phạm
            this.article_id                 = obj[0].article_id
            this.clause_id_dev              = obj[0].clause_id_dev
            this.point_id_dev               = obj[0].point_id_dev
            this.under_point_id_dev         = obj[0].under_point_id_dev
            this.violation_behavior_enter   = obj[0].violation_behavior_enter
            this.is_statistic               = obj[0].is_statistic
            this.statistic_index_id         = obj[0].statistic_index_id
            this.statistic_index_name       = obj[0].statistic_index_name
            this.statistic_input_name       = obj[0].statistic_input_name
            this.statistic_input_unit       = obj[0].statistic_input_unit
            this.statistic_value            = obj[0].statistic_value
            

            // Quy định tại
            this.regulation_at_enter = obj[0].regulation_at_enter

            // Hình thức xử phạt chính
            this.main_penalty_enter = obj[0].main_penalty_enter
            this.main_penalty_dc = obj[0].main_penalty_dc
            this.money_penalty_by_behavior = obj[0].money_penalty_by_behavior

            // Thông tin cho Edit: Mục, Điều, Khoản, Điểm, Tiết
            this.section_selected = obj[0].section_selected
            this.article_selected = obj[0].article_selected
            this.list_article = obj[0].list_article
            this.isShowArticle = checkToShowSubOption(this.article_selected)

            this.clause_selected = obj[0].clause_selected
            this.list_clause = obj[0].list_clause
            this.isShowClause = checkToShowSubOption(this.clause_selected)

            this.point_selected = obj[0].point_selected
            this.list_point = obj[0].list_point
            this.isShowPoint = checkToShowSubOption(this.point_selected)

            this.under_point_selected = obj[0].under_point_selected
            this.list_under_point = obj[0].list_under_point
            this.isShowUnderPoint = checkToShowSubOption(this.under_point_selected)
        },

        saveEditBehaviorRegulationPenalty() {
            // obj
            let id = this.id_obj_edit;
            var obj = this.getInfoBehaviorRegulationPenalty(id);

            let flag_validate = this.validateBehaviorRegulationPenalty(obj)

            // Lưu thông tin người vi phạm vào mảng: behavior_regulation_penalty
            if(flag_validate){
                // 1. Tìm index mảng cần thay thế dựa vào view_person_id
                var index_obj_edit = this.behavior_regulation_penalty.findIndex(obj => obj.id === this.id_obj_edit);

                // 2. Tiến hành thay thế
                this.behavior_regulation_penalty[index_obj_edit] = obj;

                // 3. Hiển thị thông báo lưu thành công
                toastr.success("Đã chỉnh sửa thông tin thành công!", "", { "closeButton": true });

                // 4. Ẩn nút Edit, xóa id_obj_edit và reset lại form
                this.resetVariableTempUseForEdit();
                this.resetBehaviorRegulatioPenalty();
                this.resetFromSectionToUnderPoint();

                // 5. Tính tổng số tiền phạt do vi phạm hành vi chính
                this.sumTotalMainPenalty(this.behavior_regulation_penalty);
            }
        },

        resetVariableTempUseForEdit() {
            this.id_obj_edit = "";
            this.isEdit = false;
        },

        cancelEditBehaviorRegulationPenalty() {
            this.resetBehaviorRegulatioPenalty();
            this.resetFromSectionToUnderPoint();
            this.resetVariableTempUseForEdit();
        },

        // DELETE: hành vi, quy định, hình thức xử phạt chính
        // ======================================================================
        confirmDeleteBehaviorRegulationPenalty(id) {
            // 1. Set id_obj_delete cho bước handDeleteBehaviorRegulationPenalty
            this.id_obj_delete = id;
            // console.log('deleteBehavior id = ', id)

            // 2. Lấy thông tin người vi phạm trong mảng individual --> điền vào các mảng selected để hiển thị ra form để chỉnh sửa
            var obj_delete = this.behavior_regulation_penalty.find(obj => obj.id === id);
            this.violation_behavior_enter_delete = obj_delete.violation_behavior_enter;
            this.regulation_at_enter_delete = obj_delete.regulation_at_enter;
            this.main_penalty_enter_delete = obj_delete.main_penalty_enter;

            // 3. Show form view
            this.$refs.openConfirmDelete.click();
        },

        handDeleteBehaviorRegulationPenalty() {
            this.behavior_regulation_penalty = this.behavior_regulation_penalty.filter(item => item.id !== this.id_obj_delete);

            toastr.success("Xóa thành công!", "", { "closeButton": true });

            // Reset lại form
            this.resetBehaviorRegulatioPenalty();
            this.resetVariableTempUseForDelete();

            // Đóng form
            this.$refs.closeConfirmDelete.click();

            // Tính tổng số tiền phạt do vi phạm hành vi chính
            this.sumTotalMainPenalty(this.behavior_regulation_penalty);
        },

        resetVariableTempUseForDelete() {
            this.id_obj_delete = "";
            this.violation_behavior_enter_delete = "";
            this.regulation_at_enter_delete = "";
            this.main_penalty_enter_delete = "";
        },

        // II. IS SHOW GUIDE
        // =========================================================================================
        showGuideIncreaseIssue(){
            this.isShowGuideIncreaseIssue = !this.isShowGuideIncreaseIssue
        },

        showGuideDecreaseIssue(){
            this.isShowGuideDecreaseIssue = !this.isShowGuideDecreaseIssue
        },

        // III. RESET FORM
        // =========================================================================================
        resetForm() {
            this.behavior_regulation_penalty = []
            this.money_penalty_total_int = 0
            this.money_penalty_total_text = ''
            this.resetBehaviorRegulatioPenalty()
            this.resetFromSectionToUnderPoint()
            this.resetStatistic()
            this.resetOther()
            this.resetIsShow()
        },

        // 2.1. Form under section
        resetFromSectionToUnderPoint() {
            this.resetSection()
            this.resetArticle()
            this.resetClause()
            this.resetPoint()
            this.resetUnderPoint()
        },

        resetUnderSection() {
            this.resetArticle()
            this.resetClause()
            this.resetPoint()
            this.resetUnderPoint()
        },

        // 2.2. Form under article
        resetUnderArticle() {
            this.resetClause()
            this.resetPoint()
            this.resetUnderPoint()
        },

        // 2.3. Reset under Clause
        resetUnderClause() {
            this.resetPoint()
            this.resetUnderPoint()
        },

        // 2.4. Reset Hành vi, Quy định tại, Hình thức xử phạt
        resetBehaviorRegulatioPenalty() {
            this.resetBehavior()
            this.resetRegulationAt()
            this.resetMainPenalty()
        },

        resetSection() {
            this.section_id = ""
            this.section_content = ""
            this.section_selected = []
        },

        resetArticle() {
            this.article_id = ""
            this.article_content = ""
            this.list_article = []
            this.article_selected = []
            this.isShowArticle = false
        },

        resetClause() {
            this.clause_id_dev = ""
            this.clause_content = ""
            this.list_clause = []
            this.clause_selected = []
            this.isShowClause = false
        },

        resetPoint() {
            this.point_id_dev = ""
            this.point_content = ""
            this.list_point = []
            this.point_selected = []
            this.isShowPoint = false
        },

        resetUnderPoint() {
            this.under_point_id_dev = ""
            this.under_point_content = ""
            this.list_under_point = []
            this.under_point_selected = []
            this.isShowUnderPoint = false
        },

        // Hành vi vi phạm, quy định tại và biện pháp khắc phục hậu quả
        resetBehavior() {
            this.violation_behavior_enter = ""      // Hành vi vi phạm do người dùng nhập vào (đưa vào quyết định)
            this.is_statistic = false
            this.statistic_value = ""
        },

        resetRegulationAt() {
            this.regulation_at_enter = ""           // Quy định tại => do người dùng nhập vào (đưa vào quyết định)
            this.regulation_at_main = ""            // Quy định tại => nếu có 1 hành vi thì chỉ hiển thị regulation_at_main
            this.regulation_at_sub = ""             // Quy định tại => nếu có nhiều hành vi thì hiển thị cả __main và __sub
        },

        resetMainPenalty() {
            this.main_penalty_dc = ""
            this.main_penalty_enter = ""            // Do người dùng nhập vào (đưa vào quyết định)
            this.money_penalty_by_behavior = ""     // Số tiền phạt theo từng hành vi
            this.money_penalty_by_behavior = ""     // Số tiền phạt theo từng hành vi
            this.money_min = 0
            this.money_max = 0
        },

        resetStatistic(){
            this.num_violation_behavior = 1          // * số hành vi vi phạm
            this.is_statistic = false                // Temp = thống kê | không thống kê
            this.statistic_index_id = ""             // Temp = mã số chỉ tiêu thống kê từ bảng dc_content
            this.statistic_index_name = ""           // Temp = tên chỉ tiêu thống kê lấy từ bảng dc_content
            this.statistic_input_name = ""           // Temp = label đơn vị chỉ tiêu thống kê từ bảng dc_content
            this.statistic_input_unit = ""           // Temp = đơn vị chỉ tiêu thống kê từ bảng dc_content
            this.statistic_value = ''
        },

        // Các trường còn lại
        resetOther(){
            this.isEdit = false
            this.increase_issue = ""
            this.decrease_issue = ""
            this.extend_penalty_exhibit = ""
            this.extend_penalty_vihicle = ""
            this.extend_penalty_vihicle_num = ""
            this.extend_penalty_vihicle_unit_num = ""
            this.extend_penalty_other = ""
            this.extend_penalty_duration = ""
            this.remedial_measure = ""
            this.remedial_measure_duration = ""
            this.remedial_measure_content = ""
            this.remedial_measure_money_int = ""
            this.remedial_measure_money_text = ""
            this.organization_get_money = ""
        },

        // IsShow
        resetIsShow(){
            this.isShowArticle = false
            this.isShowClause = false
            this.isShowPoint = false
            this.isShowUnderPoint = false
            this.isShowGuideDecreaseIssue = false
            this.isShowGuideIncreaseIssue = false
        },



        // EDIT VIOLATION DECISION (GET DATA FROM DATABASE --> FILL TO FORM)
        // ================================================================================================
        // SET DATA
        // ------------------------------------------------------------------------------------------------
        setEdit(data){
            console.log("data behavior ===", data.behavior)
            console.log("data violation ===", data.violation)

            var behavior = data.behavior
            var violation = data.violation

            // 1. HÀNH VI VI PHẠM
            this.num_violation_behavior = parseInt(violation.num_violation_behavior)
            this.behavior_regulation_penalty = behavior

            // Tổng số tiền
            this.money_penalty_total_int            = violation.money_penalty_total_int
            this.money_penalty_total_text           = violation.money_penalty_total_text

            // 2. TÌNH TIẾT TĂNG NẶNG, GIẢM NHẸ,....
            this.increase_issue                     = violation.increase_issue
            this.decrease_issue                     = violation.decrease_issue
            this.extend_penalty_exhibit             = violation.extend_penalty_exhibit
            this.extend_penalty_vihicle             = violation.extend_penalty_vihicle
            if(violation.extend_penalty_vihicle_num > 0){
                this.extend_penalty_vihicle_num         = violation.extend_penalty_vihicle_num
                this.extend_penalty_vihicle_unit_num    = violation.extend_penalty_vihicle_unit_num
            }            
            this.extend_penalty_other               = violation.extend_penalty_other
            if(violation.extend_penalty_duration > 0){
                this.extend_penalty_duration            = violation.extend_penalty_duration
            }
            this.remedial_measure                   = violation.remedial_measure
            if(violation.remedial_measure_duration > 0){
                this.remedial_measure_duration          = violation.remedial_measure_duration
            }
            this.remedial_measure_content           = violation.remedial_measure_content
            if(violation.remedial_measure_money_int > 0){
                this.remedial_measure_money_int         = violation.remedial_measure_money_int
                this.remedial_measure_money_text        = violation.remedial_measure_money_text
            }
            this.organization_get_money             = violation.organization_get_money
        }
},
}
</script>

<style scoped>

input:focus {
    outline: none !important;
    border:1px solid #719ECE;
    box-shadow: 0 0 10px #719ECE;
}

.mybtn-cancel {
    width: 150px; 
    padding: 2px !important; 
    font-size: 14px !important; 
    background-color: #FFFFFF !important;   
    color: black !important;  
    border: 1px solid #dee2e6 !important;  
}

.mybtn-delete{
    width: 150px; 
    padding: 2px !important; 
    font-size: 14px !important; 
    border: 1px solid #dee2e6 !important; 
}

.mybtn-cancel:hover {
    width: 150px; 
    padding: 2px !important; 
    font-size: 14px !important; 
    background-color: #E5E5E5 !important;  
    color: black !important; ; 
    border: 1px solid #dee2e6 !important;  
}

</style>