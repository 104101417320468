import PenaltyDecision from '@/pages/PenaltyDecision/Index.vue'

// 1. COMPONENT (VIEW IN VIEW ROUTER)======================================
import CompPenaltyDecisionIndex from '@/components/PenaltyDecision/ViewRouter/Index.vue'        // Quản lý tài khoản cá nhân


export default [
    {
        path: '/penalty-decision',
        name: 'penalty-decision',
        component: PenaltyDecision,
        children: [ // View Router
            {
                path: 'form-violation-decision',
                name: 'form-violation-decision',
                component: CompPenaltyDecisionIndex,
                meta:{ keepAlive:true }          
            }
        ]
    }
]