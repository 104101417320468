<template>
    <div style="margin: 5px 0px 10px 0px">
        <div class="bold">Điều 10. Tình tiết tăng nặng</div>
        <div>1. Những tình tiết sau đây là tình tiết tăng nặng:</div>
        <div>a) Vi phạm hành chính có tổ chức;</div>
        <div>b) Vi phạm hành chính nhiều lần; tái phạm;</div>
        <div>c) Xúi giục, lôi kéo, sử dụng người chưa thành niên vi phạm; ép buộc người bị lệ thuộc vào mình về vật chất, tinh thần thực hiện hành vi vi phạm hành chính;</div>
        <div>d) Sử dụng người biết rõ là đang bị tâm thần hoặc bệnh khác làm mất khả năng nhận thức hoặc khả năng điều khiển hành vi để vi phạm hành chính;</div>
        <div>đ) Lăng mạ, phỉ báng người đang thi hành công vụ; vi phạm hành chính có tính chất côn đồ;</div>
        <div>e) Lợi dụng chức vụ, quyền hạn để vi phạm hành chính;</div>
        <div>g) Lợi dụng hoàn cảnh chiến tranh, thiên tai, thảm họa, dịch bệnh hoặc những khó khăn đặc biệt khác của xã hội để vi phạm hành chính;</div>
        <div>h) Vi phạm trong thời gian đang chấp hành hình phạt của bản án hình sự hoặc đang chấp hành quyết định áp dụng biện pháp xử lý vi phạm hành chính;</div>
        <div>i) Tiếp tục thực hiện hành vi vi phạm hành chính mặc dù người có thẩm quyền đã yêu cầu chấm dứt hành vi đó;</div>
        <div>k) Sau khi vi phạm đã có hành vi trốn tránh, che giấu vi phạm hành chính;</div>
        <div>l) Vi phạm hành chính có quy mô lớn, số lượng hoặc trị giá hàng hóa lớn;</div>
        <div>m) Vi phạm hành chính đối với nhiều người, trẻ em, người già, người khuyết tật, phụ nữ mang thai.</div>
        <div>2. Tình tiết quy định tại khoản 1 Điều này đã được quy định là hành vi vi phạm hành chính thì không được coi là tình tiết tăng nặng.</div>
    </div>
</template>

<script>
    export default {
        name: 'increase-issue',
        data() {
            return {
            }
        },

    }
</script>

<style scoped>

</style>

<style scoped>

</style>