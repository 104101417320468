

export default {
    GET_LIST_INDIVIDUAL (state, data) {
        state.list_individual = data.list_individual
        state.number_person_individual = data.number_person
    },

    GET_LIST_ORGANIZATION (state, data) {
        state.list_organization = data.list_organization
    },

    IS_EDIT(state, data) {
        state.is_edit = data
    },

}