<template>
    <div>
        <div class="card-body" style="padding-bottom: 32px;">
            <div class="row">
                <div style="padding-right: 18px;">
                    <i class="fas fa-backspace scroll-up fa-lg micon txt-right" @click="resetFormSearch()"></i>
                </div>
            </div>
            <!-- Row 1 -->
            <div class="row">
                <div class="col-md-6">
                    <div><i class="fas fa-university myicon"></i> Tên tổ chức / người đại diện</div>
                    <div class="input-group input-group">
                        <input type="text" class="form-control"  placeholder="Nhập tên tổ chức / người đại diện" v-model="organization_name" autocomplete="off">
                    </div>
                </div>
                <div class="col-md-6">
                    <div><i class="far fa-address-card myicon"></i> Mã số doanh nghiệp</div>
                    <div class="input-group input-group">
                        <input type="text" class="form-control" placeholder="Nhập mã số doanh nghiệp" v-model="organization_code_number" autocomplete="off">
                    </div>
                </div>
            </div>
        </div>           
        <div class="card-footer clearfix" style="min-height: 48px;">
            <button type="button" class="btn btn-outline-success btn-sm float-right" @click="searchViolationDecision()" style="width: 150px">Tra
                cứu</button>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'form-search-organization',
    data() {
        return {
            
            // Person info
            organization_name: '',
            organization_code_number: '',           // * CMT / CCCD
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentUser'
        ])
    },
    methods: {
        ...mapActions([
            'actSearchOrganizationViolation',
            'actSetListOrganizationSearchEmpty'
        ]),

        // Tra cứu quyết định xử phạt
        searchViolationDecision(){
            var data = {
                organization_name: this.organization_name,
                organization_code_number: this.organization_code_number
            }

            var data_json = JSON.stringify(data)

            if( // Nếu form trống hoặc khi người dùng bỏ chọn tất cả
                this.organization_name           === '' & 
                this.organization_code_number    === ''
            ){
                // Thông báo chưa nhập thông tin
                toastr.error('Chưa nhập thông tin tra cứu!', '', {closeButton: true})

            }else{ // Nếu người dùng chọn

                this.actSearchOrganizationViolation(data_json).then((res) => {
                    if(res.ok === true & res.data.length > 0){ // Có kết quả tìm kiếm
                        // Hiển thị kết quả: ghi vào getter --> hiển thị kết quả
                        // Tắt thông báo search not found trước đó (nếu có)
                        this.$emit('searchOrganizationNotFound', false)

                    }else{ // Tìm không có kết quả
                        // Hiển thị thông báo không tìm thấy
                        this.$emit('searchOrganizationNotFound', true)

                        // Tắt kết quả tra cứu trước đó đang hiển thị
                        this.actSetListOrganizationSearchEmpty()
                    }
                })
            }
        },

        // resetForm search
        resetFormSearch(){
            // Xóa form
            this.organization_name = ''
            this.organization_code_number = ''

            // Tắt hiển thị thông báo không tìm thấy
            this.$emit('searchOrganizationNotFound', false)

            // Tắt kết quả tra cứu trước đó đang hiển thị
            this.actSetListOrganizationSearchEmpty()
        }
    }
}
</script>

<style>
table.gridjs-table {
    width: 100% !important;
}
</style>