<template>
	<!-- Content Wrapper. Contains page content -->
	<div>
		<!-- HEADER -->
            <div class="content-header">
                <div style="margin-left: 8px">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <p>HƯỚNG DẪN QUẢN TRỊ HỆ THỐNG</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- / HEADER -->

            <!-- MAIN content -->
            <section class="content">
                <div style="margin-left: 8px">
                    <Guide />
                </div>
            </section>
        <!-- / MAIN content -->
	</div>
</template>

<script>
    import Guide from './Guide.vue'
	
    export default {
		name: 'manage-user-guide',
        components: {
            Guide
        }
	}
</script>

<style scoped>

</style>