<template>
	<div>
		<div class="row">
			<!-- DOWNLOAD -->
			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="txt-right" style="margin-right: -30px;">
						<vue-excel-xlsx
							:data="getNotifyResult"
							:columns="columns"
							:file-name="'Danh sach tin bao'"
							:file-type="'xlsx'"
							:sheet-name="'Danh sach tin bao'"
							>
							<span 
								id="hide-btn-vue-excel-xlsx"
								class="btn btn-sm" 
								style="border: 1px solid #5A6268;"
							>
							Tải về 
							<i class="fas fa-download micon"></i>
							</span>
						</vue-excel-xlsx>
					</div>
				</div>
			</div>
			
			<!-- LIST PHONE BOOKS -->
			<grid
				:cols="header_table" 
				:rows="getNotifyResult"
				:language="language"
    			:pagination="pagination"
				:search="search"
			>
			</grid>
		</div> 
		
		
		<!-- DELETE NOTIFYCATION -->
		<div class="modal fade" id="delete-decision">
			<div class="modal-dialog modal-default modal-lg">
				<div class="modal-content">
					<div class="modal-header bg-warning" style="color: white; background-color: #138496;">
						<h6 class="modal-title"><i class="icon fas fa-ban"></i> Xóa thông tin báo vi phạm </h6>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeConfirmDelete" style="display: none">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div style="padding: 20px"> 
							Xác nhận xóa tin báo vi phạm:
							<ul style="list-style-type:circle; margin-top: 10px;">
								<li>Đơn vị tiếp nhận: {{ organization_get_notify }}</li>
								<li>Hành vi vi phạm: {{ behavior_name }}</li>
								<li>Thông tin chi tiết: {{ detail_violation }}</li>
								<li>Người báo tin: {{ full_name }}</li>
							</ul>
						</div>
					</div>
					<div class="modal-footer justify-content-between">
						<button type="button" class="btn btn-sm" v-on:click.prevent="deleteNofify()" style="width: 150px; color: white; background-color: #DC5C51;">Xác nhận xóa</button>
						<button type="button" class="btn btn-sm mybtn-cancel" data-dismiss="modal" v-on:click.prevent="resetDeleceDecision()" style="width: 100px; color: #5C677D; background-color: #E5E5E5;"> Hủy xóa </button>
					</div>
				</div>
			</div>
		</div>

		<button type="button" class="btn btn-default" data-toggle="modal" data-target="#delete-decision" style="display: none" ref="openConfirmDelete">
			Confirm Delete
		</button>
		<!-- /DELETE NOTIFYCATION -->
	</div>
</template>

<script>

	import { Grid } from 'gridjs-vue'
	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'notify-result-index',
		components: {
			Grid
		},
		data() {
			return {
				// Download
				// vue-excel-xlsx
				// ----------------------------
				columns: [
					{
						field: 'location_name',
						label: 'Cấp nhận tin'
					},
					{
						field: 'organization_get_notify',
						label: 'Đơn vị tiếp nhận tin'
					},
					{
						field: 'date_created',
						label: 'Ngày tiếp nhận'
					},
					{
						field: 'behavior_name',
						label: 'Hành vi vi phạm'
					},
					{
						field: 'location_violation',
						label: 'Địa điểm xảy ra vi phạm'
					},
					{
						field: 'detail_violation',
						label: 'Thông tin chi tiết'
					},
					{
						field: 'full_name',
						label: 'Người báo tin'
					},
					{
						field: 'mobile',
						label: 'Điện thoại người báo tin'
					},
					{
						field: 'email',
						label: 'Email người báo tin'
					},
					{
						field: 'address',
						label: 'Địa chỉ người báo tin'
					},
					{
						field: 'other_info',
						label: 'Thông tin khác của người báo tin'
					}
				],

				// GridJS: table phone-book
				header_table: [
					{ 
						id: 'organization_get_notify',
						name: 'Đơn vị tiếp nhận tin'
					},
					{
						id: 'date_created',
						name: 'Ngày nhận tin'
					},
					{
						id: 'behavior_name',
						name: 'Hành vi vi phạm'
					},
					{
						id: 'location_violation',
						name: 'Địa điểm xảy ra vi phạm'
					},
					{
						id: 'detail_violation',
						name: 'Thông tin chi tiết'
					},
					{
						id: 'full_name',
						name: 'Người báo'
					},
					{
						id: 'mobile',
						name: 'Điện thoại'
					},
					{ 
						id: 'violation_decision_id',
						name: 'Công cụ',
						sort: false,
						width: '170px',
						formatter: (cell, row) => {  
							if(this.isSuperAdmin === true){ // Người tạo ra quyết định thì mới có quyền chỉnh sửa, xem bản nháp
								return [ 
									//  Edit
									this.$gridjs.h('button', 
										{
											className: 'mclass',
											onClick: () => this.confirmDelete(row)
										}, 
									this.$gridjs.html(`<div class="btn btn-sm">
														<i class="fas fas fa-trash mclass-delete"></i></div>`))                                
									
								]
							}
						}
					},
					{
						id: 'id',
						name: 'id_record',
						hidden: true
					},
				],

				pagination: true,
				// sort: true,
				search: true,
				language: {
					search: {
						placeholder: '🔍 Tìm kiếm ...',
					},
					sort: {
						sortAsc: 'Sắp xếp tăng dần',
						sortDesc: 'Sắp xếp giảm dần',
					},
					pagination: {
						previous: 'Về trước',
						next: 'Kế tiếp',
						navigate: (page, pages) => `Trang ${page} trên tổng ${pages}`,
						page: (page) => `Trang ${page}`,
						showing: 'Hiển thị',
						of: 'trên tổng',
						to: 'từ',
						results: 'kết quả',
					},
					loading: 'Đang load trang ...',
					noRecordsFound: 'Không có kết quả nào được tìm thấy!',
					error: 'Có lỗi xảy ra trong quá trình lấy dữ liệu!',
				},

				// Confirm delete
				id_record: null,
				organization_get_notify: null,
				behavior_name: '',
				detail_violation: '',
				full_name: ''
			}
		},
		async created(){
			var data = {
				user_id: this.getCurrentUser.user_id,
				location_code: this.getCurrentUser.location_code,
				location_level_code: this.getCurrentUser.location_level_code
			}

			var data_json = JSON.stringify(data)
			await this.actGetNotifyResult(data_json)
		},
		computed: {
			...mapGetters([
				'isSuperAdmin',
				'getCurrentUser',
				'getNotifyResult'
			])
		},
		methods: {
			...mapActions([
				'actGetNotifyResult',
				'actDeleteNotify'
			]),

			// DELETE
			// =========================================================
			confirmDelete(row) {

				this.id_record = row.cells[8].data
				this.organization_get_notify = row.cells[0].data
				this.behavior_name = row.cells[2].data
				this.detail_violation = row.cells[4].data
				this.full_name = row.cells[5].data
				
				// Hiển thị box confirm delete
				this.$refs.openConfirmDelete.click()
			},

			deleteNofify(){
				var data = {
					id_record: this.id_record,
					user_id: this.getCurrentUser.user_id,
					location_code: this.getCurrentUser.location_code,
					location_level_code: this.getCurrentUser.location_level_code
				}

				let data_json = JSON.stringify(data)

				this.actDeleteNotify(data_json).then((res)=>{

					if(res.ok === true){

						this.$refs.closeConfirmDelete.click()					

						toastr.success(`Xóa tin báo vi phạm thành công!`,'', {closeButton: true})

						this.resetDeleceDecision()

					}else{
						toastr.error(`Xóa tin báo vi phạm thất bại!`,'', {closeButton: true})
					}
				})
			},

			resetDeleceDecision(){
				this.organization_get_notify = ''
				this.behavior_name = ''
				this.detail_violation = ''
				this.full_name = ''
				this.$refs.closeConfirmDelete.click()
			},
		}
	}
</script>



<style>

	td[data-column-id = 'date_created'],
    td[data-column-id = 'mobile']
    {
        text-align: right;
    }

	td[data-column-id = 'detail_violation'],
    td[data-column-id = 'behavior_name']
    {
		white-space: normal !important
    }

	.mclass {
		border-color: white;
		background-color: white;
		border: 0px;
	}

	.mclass-delete {
		color: #dc3545;
	}

	.mclass-edit {
		color: #05668D;
	}

	.mclass-password {
		color: #157347;
	}

	button {
		background-color: white;
		border: white;
	}
</style>