<template>
	<div>
		<!-- Navbar top -->
		<b-row class="navbar-top">
				<b-col cols="3">
					<div class="txt-left"><i class="fas fa-phone cl-icon-nav"></i> <span class="font-email-mobile">Đường dây nóng: +0235 381 3578</span></div>
				</b-col>
				<b-col cols="2">
					<div class="txt-left"><i class="far fa-envelope cl-icon-nav"></i> <span class="font-email-mobile">Email: fpd@kiemlam.org.vn</span></div>
				</b-col>
				<b-col cols="7">
					<!-- <div class="txt-right" v-if="!isLogin">TỔNG CỤC LÂM NGHIỆP</div> -->
					<div class="txt-right" >HỆ THỐNG CƠ SỞ DỮ LIỆU XỬ PHẠT VI PHẠM TRONG LĨNH VỰC LÂM NGHIỆP</div>
				</b-col>
		</b-row>

		<!-- NAVBAR show before login -->
		<nav class="navbar-not-login" v-if="!isLogin">
			<div class="row">
				<div class="col-md-1 img-not-login">
					<b-img :src="this.$ASSETS_PATH + '/img/logo.png'" width="60px"></b-img>
				</div>
				<!-- <div class="col-md-10 header-not-login">HỆ THỐNG CƠ SỞ DỮ LIỆU XỬ PHẠT VI PHẠM HÀNH CHÍNH TRONG LĨNH VỰC LÂM NGHIỆP</div> -->
				<div class="col-md-11">
					<div class="mnavbar-btn">
						<button class="btn btn-login btn-sm mbtn-navbar " 
							:class="isActive ? 'mbtn-active' : ''" @click="handActive('login')">ĐĂNG NHẬP</button>

						<button class="btn btn-login btn-sm mbtn-navbar" 
							style="width: 200px;"
							:class="!isActive ? 'mbtn-active' : ''" @click="handActive('send-notify')">TIẾP NHẬN TIN BÁO VI PHẠM</button>
					</div>	
				</div>
			</div>
		</nav>

		<!-- NAVBAR show after login -->
		<nav class="navbar navbar-expand navbar-light" style="background-color: #198754; padding-bottom: 12px;" v-if="isLogin">

			<!-- Left navbar links -->
			<ul class="navbar-nav">
				<li class="nav-item d-sm-inline-block">
					<b-img :src="this.$ASSETS_PATH + '/img/logo.png'" width="60px"></b-img>
				</li>
				<li class="nav-item d-sm-inline-block fix-mgin-top" style="margin-left: 25px;" v-if="isDecisionMaker === true || isSuperAdmin === true">
					<b-nav-item class="font-nav-upper"><router-link :to="{name: 'form-violation-decision'}" active-class="active"> Lập quyết định xử phạt </router-link></b-nav-item>
				</li>
				<li class="nav-item d-sm-inline-block fix-mgin-top" v-if="isDecisionMaker === true || isSuperAdmin === true">
					<b-nav-item class="font-nav-upper"><router-link :to="{name: 'penalty-result'}" active-class="active"> Danh sách kết quả xử phạt </router-link></b-nav-item>
				</li>
				<li class="nav-item d-sm-inline-block fix-mgin-top" v-if="isDecisionMaker === true || isSuperAdmin === true">
					<b-nav-item class="font-nav-upper"><router-link :to="{name: 'search-result'}" active-class="active"> Tra cứu thông tin đối tượng vi phạm </router-link></b-nav-item>
				</li>
				<li class="nav-item d-sm-inline-block fix-mgin-top" v-if="isDecisionMaker === true || isSuperAdmin === true">
					<b-nav-item class="font-nav-upper"><router-link :to="{name: 'notify-result'}" active-class="active"> Tin báo vi phạm</router-link></b-nav-item>
				</li>
				<li class="nav-item d-sm-inline-block fix-mgin-top" v-if="isDecisionMaker === true || isSuperAdmin === true">
					<b-nav-item class="font-nav-upper"><router-link :to="{name: 'report'}" active-class="active"> Báo cáo </router-link></b-nav-item>
				</li>
				<li class="nav-item d-sm-inline-block fix-mgin-top">
					<b-nav-item class="font-nav-upper"><router-link :to="{name: 'phone-book'}" active-class="active"> Danh bạ </router-link></b-nav-item>
				</li>
				<li class="nav-item d-sm-inline-block fix-mgin-top" v-if="isSuperAdmin === true || isAdminSystem === true || isAdminProvince === true">
					<b-nav-item class="font-nav-upper"><router-link :to="{name: 'user-manage'}" active-class="active"> Quản trị tài khoản </router-link></b-nav-item>
				</li>
			</ul>

			<!-- Right navbar links -->
			<ul class="navbar-nav ml-auto" style="z-index: 50;" id="general-info">
				<li class="nav-item dropdown">
					<a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
						<i class="fa fa-user mr-icon" aria-hidden="true" style="color:white"></i>
					</a>
					<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<a class="dropdown-item">
							<i class="fas fa-user icon-user-info" ></i> Đơn vị: {{getCurrentUser.full_name}}
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fa fa-user-circle icon-user-info"></i> Tài khoản: {{getCurrentUser.user_name}}
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fas fa-envelope icon-user-info"></i> Email: {{getCurrentUser.email}}
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fas fa-mobile icon-user-info"></i> Điện thoại: {{getCurrentUser.mobile}}
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fas fa-university icon-user-info"></i> Cấp: {{getCurrentUser.organization_name}}
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fas fa-globe-africa icon-user-info"></i> Phạm vi xử phạt: {{getCurrentUser.location}}
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fas fa-gavel icon-user-info"></i> Quyền: {{getCurrentUser.role_name}}
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fas fa-dollar-sign icon-user-info"></i>
							Phạt tiền tối đa: {{getCurrentUser.issue_money | formatNumber}} VNĐ
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item">
							<i class="fas fa-motorcycle icon-user-info"></i>
							<span v-if="getCurrentUser.location_level_code === 1"> Giá trị TV, PT tối đa: Không giới hạn.</span>
							<span v-else> Giá trị TVPT tối đa: {{getCurrentUser.exhibit_value | formatNumber}} VNĐ</span>
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" @click="handleChangePassword(getCurrentUser.user_id)">
							<i class="fas fa-lock mclass-edit"></i>
							Thay đổi mật khẩu
						</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item fix-a" @click="handleLogOut()">
							<i class="fas fa-sign-out-alt mclass-edit"></i> <router-link :to="{name: 'login'}" style="text-decoration: none; color: #5c677d;"> Đăng xuất </router-link>
						</a>
					</div>
				</li>

				<li class="nav-item">
					<a class="nav-link" data-widget="fullscreen" href="#" role="button" style="color:white">
						<i class="fas fa-expand-arrows-alt"></i>
					</a>
				</li>
			</ul>
		</nav>

		<!-- CHANGE PASSWORD -->
			<ChangePassword 
				ref="formChangePassword"
			/>
		<!-- /CHANGE PASSWORD -->
	</div>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import ChangePassword from './FormChangePassword.vue';
	
	export default {
		name: 'navbar-header',
		components: {
            ChangePassword
        },
		data() {
			return {
				isActive: true
			}
		},
		computed: {
			...mapGetters([
				'getCurrentUser',
				'isDecisionMaker',
				'isLogin',
				'isSuperAdmin',
				'isAdminSystem',
				'isAdminProvince'
			])
		},
		methods: {
			...mapActions([ 'actLogOut' ]),

			handleLogOut(){
				this.actLogOut().then((res) => {
					// Thực hiện xóa ACCESS_TOKEN và current_user = []
					if(res){ // res === true: đã xóa ACCESS_TOKEN và current_user = []
						this.$router.push({ name: 'login', params: { redirect: 'login' } })
					}
				})
			},

			handleChangePassword(user_id){
				this.$refs.formChangePassword.openFormChangePassword(user_id)
			},

			handActive(key){
				// Active tab
				this.isActive = !this.isActive

				// Change router
				if(key === 'login'){ // login
					this.$router.push({ name: 'login', params: { redirect: 'login' } })
				}

				if(key === 'send-notify'){ // Notify
					this.$router.push({ name: 'send-notify', params: { redirect: 'send-notify' } })
				}
			}
		}

	}
</script>

<style scoped>
	.mbtn-navbar {
		color: white;
		width: 150px;
		margin-right: 15px;
		font-size: 13px;
	}

	.mbtn-navbar:hover {
		color: white;
	}

	.mbtn-active{
		border-bottom: 1.5px solid #3eaf7c;
	}

	.mnavbar-btn {
		float: right;
		margin-top: 17px;
		margin-right: 20px;
	}

	.navbar-not-login{
		background-color: rgb(25, 135, 84);
		text-align: center;
		min-height: 60px;
	}

	.img-not-login{
		margin-top: 8px;
	}

	.header-not-login{
		text-align: center;
		color: white;
		margin-top: 18px;
		font-size: 18px;
	}

	.icon-user-info {
		color: #5c677d !important;
	}

	/* Header */
	.font-email-mobile {
        font-size: 12px;
    }

    .navbar-top {
         text-align: center;
         color: white;
         font-size: 14px;
         background-color: #198754;
         border-bottom: 1px solid #3CB371;
         padding: 8px 50px;
		 margin: 0px 0px;
     }

    .cl-icon-nav {
        color: #deb887 !important;
    }

	.navbar-top {
         text-align: center;
         color: white;
         font-size: 14px;
         background-color: #198754;
         border-bottom: 1px solid #3CB371;
         padding: 8px 20px;
     }

	.fix-mgin-top {
		padding-top: 10px;
	}

	.navbar-top {
         text-align: center;
         color: white;
         font-size: 14px;
         background-color: #198754;
         border-bottom: 1px solid #3CB371;
         padding: 8px 20px;
     }

	.font-nav-upper {
		font-size: 13px;
		text-transform: uppercase;
	}

	.mr-icon {
		margin-left: 5px;
	}
	
	a.nav-link > a:hover {
		color: white;
		text-decoration: none;
	}

	/* Dãn cách các Tab */
	a.nav-link > a, a.nav-link > span > a { 
		padding: 4px;
		color: #C5DBCC !important;
		text-decoration: none;
		background-color: #198754 !important;
	}

	a.nav-link > .router-link-active.active:hover,
	a.nav-link > .router-link-exact-active.active {
		color: white !important;
		border-radius: 3px;
		border: 1px solid #3CB371 !important;
		cursor: pointer;
		background-color: #198754 !important;
	}

</style>