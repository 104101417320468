<template >
	<div class="navbar-footer">
		<!--{{moment().format('YYYY')}} -->
		<p class="txt-center fm-copyright">Copyright &copy;<span>2022</span> Cục Kiểm lâm</p>
		<div>
			<span class="txt-center"><i class="fas fa-phone cl-icon-footer"></i> <span class="font-email-mobile">Điện thoại: +024 3733 5680</span></span>
			<span class="txt-center mg-icon"><i class="far fa-envelope cl-icon-footer"></i> <span class="font-email-mobile">Email: fpd@kiemlam.org.vn</span></span>
			<span class="txt-center mg-icon"><i class="fa fa-map-marker cl-icon-footer" aria-hidden="true"></i> <span class="font-email-mobile">Địa chỉ: Số 2 Phường Ngọc Hà, Quận Ba Đình, Hà Nội</span></span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'navbar-footer'
	}
</script>

<style scoped>
	.navbar-footer {
         text-align: center;
         color: white;
         background-color: #198754;
		 padding: 15px;
     }

	.mg-icon {
		padding-left: 20px;
	}

	.fm-copyright{
		font-size: 12px;
		margin-bottom: 5px;
	}

	.font-email-mobile {
		font-size: 11px;
	}

	.cl-icon-footer {
        color: #deb887 !important;
    }

</style>