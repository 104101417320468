import Vue from 'vue';
import { BootstrapVue, IconsPlugin, FormPlugin, BootstrapVueIcons, VBTooltipPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FormPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VBTooltipPlugin)