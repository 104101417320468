import {STATUS_COMPLATED } from '@/config/enum'

export default {

    // CÁ NHÂN ======================================
    getReportIndividual: state => {
        return state.report_individual
    },

    getPersonIndividual: state => {
        
        var num_wait        = 0
        var num_doing       = 0
        var num_completed   = 0
        var total = 0

        state.number_person_individual.forEach((obj) => {
            if(obj.decision_status_id === 1){
                num_wait = parseInt(obj.count)
            }

            if(obj.decision_status_id === 2){
                num_doing = parseInt(obj.count)
            }

            if(obj.decision_status_id === 3){
                num_completed = parseInt(obj.count)
            }

            total += parseInt(obj.count)
        })
    
        return {total, num_wait, num_doing, num_completed}
    },

    getDecisionIndividual: state => {
        
        var num_wait        = 0
        var num_doing       = 0
        var num_completed   = 0
        var total = 0

        state.number_decision_individual.forEach((obj) => {
            if(obj.decision_status_id === 1){
                num_wait = parseInt(obj.count)
            }

            if(obj.decision_status_id === 2){
                num_doing = parseInt(obj.count)
            }

            if(obj.decision_status_id === 3){
                num_completed = parseInt(obj.count)
            }

            total += parseInt(obj.count)
        })
    
        return {total, num_wait, num_doing, num_completed}
    },

    getBehaviorIndividual: state => {
        var num_type_behavior   =  state.behavior_individual.length
        var total_num_behavior  = 0

        state.behavior_individual.forEach((obj) => {
            total_num_behavior += parseInt(obj.num_behavior)
        })
    
        return {num_type_behavior, total_num_behavior, behavior: state.behavior_individual}
    },

    getPersonBehaviorIndividual: state => {
        return state.number_person_behavior_individual
    },

    getDataDownloadIndividual: state => {
        return state.data_download_individual
    },

    getMoneyBehaviorIndividual: state => {
        return state.number_money_behavior_individual
    },

    getTotalMoneyBehaviorIndividual: state => {
        return state.total_money_behavior_individual
    },

    getAreaBehaviorIndividual: state => {
        return state.number_area_behavior_individual
    },

    getTotalAreaBehaviorIndividual: state => {
        return state.total_area_behavior_individual
    },
   
    
    // TỔ CHỨC ============================================
    getReportOrganization: state => {
        return state.report_organization
    },

    getDecisionOrganization: state => {
        
        var num_wait        = 0
        var num_doing       = 0
        var num_completed   = 0
        var total = 0

        state.number_decision_organization.forEach((obj) => {
            if(obj.decision_status_id === 1){
                num_wait = parseInt(obj.count)
            }

            if(obj.decision_status_id === 2){
                num_doing = parseInt(obj.count)
            }

            if(obj.decision_status_id === 3){
                num_completed = parseInt(obj.count)
            }

            total += parseInt(obj.count)
        })
    
        return {total, num_wait, num_doing, num_completed}
    },

    getBehaviorOrganization: state => {
        var num_type_behavior   =  state.behavior_organization.length
        var total_num_behavior  = 0

        state.behavior_organization.forEach((obj) => {
            total_num_behavior += parseInt(obj.num_behavior)
        })
    
        return {num_type_behavior, total_num_behavior, behavior: state.behavior_organization}
    },

    getOrgBehaviorOrganization: state => {
        return state.number_person_behavior_organization
    },

    getDataDownloadOrganization: state => {
        return state.data_download_organization
    },

    getMoneyBehaviorOrganization: state => {
        return state.number_money_behavior_organization
    },

    getTotalMoneyBehaviorOrganization: state => {
        return state.total_money_behavior_organization
    },

    getAreaBehaviorOrganization: state => {
        return state.number_area_behavior_organization
    },

    getTotalAreaBehaviorOrganization: state => {
        return state.total_area_behavior_organization
    },
}