import { render, staticRenderFns } from "./FormSearchIndividual.vue?vue&type=template&id=b17d3980&scoped=true&"
import script from "./FormSearchIndividual.vue?vue&type=script&lang=js&"
export * from "./FormSearchIndividual.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b17d3980",
  null
  
)

export default component.exports