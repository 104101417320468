export default {
    getAllUser: state => { // lấy toàn bộ danh sách user
        return state.user_all
    },

    getUserPhoneBook: state => { // lấy toàn bộ danh sách user
        return state.user_phone_book
    },

    getUserIDInserted: state => { // Lấy ID user vừa Add vào CSDL
        return state.user_id_inserted
    },

    getNumberUserAll: state => { // lấy toàn bộ danh sách user
        return state.user_all.length
    },

    getEditUser: state => { // Thông tin user để Edit
        return state.user_edit
    },

    getUserIdEdited: state => { // Lấy ID user vừa Edit
        return state.user_id_edited
    },

    getTotalUser: state => { 
        return state.total_user        
    },

    getFpdUser: state => {
        return state.fpd_user
    },

    getZoneUser: state => {
        return state.zone_user
    },

    getProvinceUser: state => {
        return state.province_user
    },

    getDistrictUser: state => {
        return state.district_user
    },
    
}