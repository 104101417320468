<template>
	<div>
		<SendNotify />
	</div>
</template>

<script>
	import SendNotify from '@/components/GetNotify/Send/Index.vue'
	export default {
		name: 'page-send-notify',
		components: {
			SendNotify
		}
	}
</script>

<style scoped>
	

</style>