export default {

    // Danh sách cá nhân
    getListIndividual: state => {
        state.list_individual.forEach((obj, index) => {
            if(moment(obj.birthday).format('DD-MM-YYYY') === '01-01-1900' || obj.birthday === 'Invalid date'){
                state.list_individual[index].birthday = ''
            }

            if(moment(obj.decision_date).format('DD-MM-YYYY') === '01-01-1900' || moment(obj.decision_date).format('DD-MM-YYYY') === '31-12-1899' || obj.decision_date === 'Invalid date'){
                state.list_individual[index].decision_date = ''
            }

            if(moment(obj.execution_date).format('DD-MM-YYYY') === '01-01-1900' || obj.execution_date === 'Invalid date'){
                state.list_individual[index].execution_date = ''
            }
        });
        return state.list_individual
    },

    // Thống kê số lượng theo trạng thái
    getNumDecisionIndividual: state => {
        var num_wait        = 0
        var num_doing       = 0
        var num_completed   = 0
        var total = 0
        
        if(state.number_person_individual){
            state.number_person_individual.forEach((obj, index) => {
                if(obj.decision_status_id === 1){
                    num_wait = parseInt(obj.count)
                }

                if(obj.decision_status_id === 2){
                    num_doing = parseInt(obj.count)
                }

                if(obj.decision_status_id === 3){
                    num_completed = parseInt(obj.count)
                }
            })
        }

        total = num_wait + num_doing + num_completed
        return {total, num_wait, num_doing, num_completed}
    },

    // Danh sách tổ chức
    getListOrganization: state => {
        state.list_organization.forEach((obj, index) => {
            if(moment(obj.decision_date).format('DD-MM-YYYY') === '01-01-1900' || moment(obj.decision_date).format('DD-MM-YYYY') === '31-12-1899' || obj.decision_date === 'Invalid date'){
                state.list_organization[index].decision_date = ''
            }

            if(moment(obj.execution_date).format('DD-MM-YYYY') === '01-01-1900' || obj.execution_date === 'Invalid date'){
                state.list_organization[index].execution_date = ''
            }
        });
        return state.list_organization
    },

    getNumDecisionOrganization: state => {
        var num_wait        = 0
        var num_doing       = 0
        var num_completed   = 0
        var total = state.list_organization.length

        state.list_organization.forEach((obj, index) => {
            if(obj.decision_status_id === 1){
                num_wait = num_wait + 1
            }

            if(obj.decision_status_id === 2){
                num_doing = num_doing + 1
            }

            if(obj.decision_status_id === 3){
                num_completed = num_completed + 1
            }
        })

        return {total, num_wait, num_doing, num_completed}
    },

    isEditFromServer: state => {
        return state.is_edit
    },
}