<template>
    <div>
        <!-- <CompHeader />
        <CompSidebar /> -->

        <!-- ROUTER VIEW: SỬ DỤNG KỸ THUẬT NESTED ROUTER VIEW ĐỂ VIEW CONTENT IN ADMIN-PAGES -->
        <router-view></router-view>
       
    </div>    
</template>

<script>
    import CompHeader from '@/components/ManageUser/Layout/Header/Index.vue';
    import CompSidebar from '@/components/ManageUser/Layout/Sidebar/Index.vue';

    export default {
        name: 'manage-user-router-view',
        components: {
            CompHeader,
            CompSidebar
        }
    }

</script>

<style scoped>
    .content-wrapper {
        background-color: white;
    }
</style>