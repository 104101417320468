<template>
	<div>
		<!-- Note lưu ý khi tạo nhóm -->
		<div class="callout callout-warning" style="">
			<p class="mnote-header">Hướng dẫn</p>
			<ul>
				<li>Nhập thông tin theo mẫu, sau đó kích nút <code style="font-size: 95%">Tạo tài khoản</code> </li>
				<li>Trường có dấu <code>*</code> bắt buộc phải nhập</li>
				<li>Mật khẩu tối thiểu phải là <code style="font-size: 95%">6 ký tự</code></li>
			</ul>
			<div class="form-check">
				<input class="form-check-input" type="checkbox" v-on:click="handleHideNote">
				<label class="form-check-label">Ẩn hướng dẫn</label>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'group-classify-form-add-note',
		data() {
			return {

			}
		},
		methods: {
			handleHideNote(){
				this.$emit('fartherHideNote')
			}
		}
	}
</script>

<style scoped>
	.mnote-header {
        color: #138496; 
		font-weight: 500;
    }

	.mya-form-add {
        text-decoration: none !important;
        color: #138496 !important;
    }

    .mya-form-add:hover {
        color: #138496 !important;
        text-decoration: none !important;
    }
</style>