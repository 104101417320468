<template>
    <div>
        <Report />
    </div>    
</template>

<script>
    import Report from '@/components/Report/Index.vue';

    export default {
        name: 'report',
        components: {
            Report
        }
    }

</script>

<style scoped>
    .content-wrapper {
        background-color: white;
    }
</style>