<template>
	<div>
		<div class="card-header">
			<!-- v-if="getCurrentUser.type_user === 'admin' || getCurrentUser.type_user === 'supper_admin' || getCurrentUser.group_code === 2 || getCurrentUser.group_code === 5" -->
			<h3 class="card-title" >Danh sách tài khoản</h3>

			<!-- DOWNLOAD -->
			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="txt-right" style="margin-right: -20px;">
						<vue-excel-xlsx
							:data="getAllUser"
							:columns="columns"
							:file-name="'Danh sach tai khoan FVDMS'"
							:file-type="'xlsx'"
							:sheet-name="'Danh sach tai khoan FVDMS'"
							>
							<span 
								id="hide-btn-vue-excel-xlsx"
								class="btn btn-sm" 
								style="border: 1px solid #D9D9D9;"
							>
							Tải về 
							<i class="fas fa-download micon"></i>
							</span>
						</vue-excel-xlsx>
					</div>
				</div>
				<!-- <div class="col-md-1">
					<ul class="navbar-nav ml-auto" style="z-index: 50; float: right;">
						<li class="nav-item dropdown">
							<a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
								<i class="fa fa-user icon-info" aria-hidden="true" style="color:white"></i>
							</a>
							<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
								<a class="dropdown-item">
									<input v-model="isHiddeColumnOrganization" type="checkbox" autocomplete="off" @click="changeShowHideColumnTable()"> Cấp xử phạt
								</a>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item">
									<input v-model="isHiddeColumnLocation" type="checkbox" autocomplete="off" @click="changeShowHideColumnTable()"> Phạm vi xử phạt 
								</a>			
							</div>
						</li>
					</ul>
				</div> -->
			</div>
		</div>  
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'manage-user-table-title',
		data(){
			return {
				// Download
				// vue-excel-xlsx
				// ----------------------------
				columns: [
					{
						field: 'user_name',
						label: 'Tài khoản'
					},
					{
						field: 'full_name',
						label: 'Đơn vị'
					},
					{
						field: 'mobile',
						label: 'Điện thoại'
					},
					{
						field: 'email',
						label: 'Email'
					},
					{
						field: 'organization_name',
						label: 'Cấp'
					},
					{
						field: 'location',
						label: 'Phạm vi xử phạt'
					}
				],

				// Show / hide column header table
				isHiddeColumnLocation: true,
				isHiddeColumnOrganization: true
			}
		},
		computed: {
			...mapGetters([
				'getCurrentUser',
				'getAllUser'
			])
		},
		methods: {
			changeShowHideColumnTable(){
				var data = {
					isHiddeColumnLocation: this.isHiddeColumnLocation,
					isHiddeColumnOrganization: this.isHiddeColumnOrganization
				}

				this.$emit('emitChangeShowHideColumnTable', data)
			}
		}
	}
</script>

<style scoped>
	button {
		background-color: #F7F7F7;
		border: white;
	}

	.icon-info {
		color: #5c677d !important;
	}
</style>