<template>
	<div class="container-fluid">
		<HeaderNav></HeaderNav>
			<div class="mycontent">

				<KeepAlive :max="10">
					<router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
				</KeepAlive>
				
				<router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
			</div>
		<Footer></Footer>
	</div>
</template>

<script>
	
	import HeaderNav from '@/components/share/HeaderNav.vue';
	import Footer from '@/components/share/Footer.vue';
	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'App',
		components: {
			HeaderNav,
			Footer
		},
	 	created(){
			if(this.$route.name === 'login' && this.isLogin === true){
				this.actLogOut()
			}
		},
		computed: {
			...mapGetters([
				'isLogin'
			])
		},
		methods: {
			...mapActions([
				'actLogOut'
			])
		}
	}

</script>

<style>
	@import 'assets/css/index.css';

	/* Change config toast show message */
	#toast-container {
		margin-top: 200px;
	}

	/* Fix footer at to bottom */
	.container-fluid {
		display: flex;
		flex-direction: column;
		flex-wrap: no-wrap;
		min-height: 100vh;	
	}

	.mycontent { 
		flex-grow: 1;
	}

	.toast {
        opacity: 1 !important;
    }

    #toast-container > div {
        opacity: 1 !important; 
    }
	
</style>
