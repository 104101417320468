<template>
	<div class="content-header" style="margin: 30px -8px;">
        <div>
            <p class="mymain-header">DANH BẠ CÁN BỘ XỬ PHẠT</p>
        </div>
        
        <!-- Thống kê -->
        <div class="row">

            <!-- CỤC KIỂM LÂM -->
            <div class="col-lg-3 col-6" style="cursor: pointer;" @click="handleGetUserByLocationLevel(1)">
                <div class="small-box bg-success">
                <div class="inner">
                    <h3>{{getFpdUser}} <span style="font-size: 16px;">cán bộ</span> </h3>
                    <p>Cục kiểm lâm</p>
                </div>
                <div class="icon">
                    <i class="fas fa-user-cog"> </i>
                </div>
                <a class="small-box-footer">Chi tiết <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <!-- KIỂM LÂM VÙNG -->
            <div class="col-lg-3 col-6" style="cursor: pointer;" @click="handleGetUserByLocationLevel(2)">
                <div class="small-box" style="background-color: #148A9D; color: white">
                    <div class="inner">
                        <h3>{{getZoneUser}} <span style="font-size: 16px;">cán bộ</span></h3>

                        <p>Kiểm lâm vùng</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-user"></i>
                    </div>
                    <a class="small-box-footer">Chi tiết <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <!-- KIỂM LÂM TỈNH: bg-warning -->
            <div class="col-lg-3 col-6" style="cursor: pointer;" @click="handleGetUserByLocationLevel(3)">
                <div class="small-box" style="background-color: #4F98C3; color: white">
                <div class="inner">
                    <h3>{{getProvinceUser}} <span style="font-size: 16px;">cán bộ</span></h3>

                    <p>Kiểm lâm tỉnh</p>
                </div>
                <div class="icon">
                    <i class="fas fa-user"></i>
                </div>
                <a class="small-box-footer">Chi tiết <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>


            <!-- KIỂM LÂM HUYỆN-->
            <div class="col-lg-3 col-6" style="cursor: pointer;" @click="handleGetUserByLocationLevel(4)">
                <!-- small box -->
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>{{getDistrictUser}} <span style="font-size: 16px;">cán bộ</span></h3>

                        <p>Kiểm lâm huyện</p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-user"></i>
                    </div>
                    <a class="small-box-footer">Chi tiết <i class="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>

            <!-- Tổng số -->
            <div style="cursor: pointer;" @click="handleGetUserPhoneBook()">
                <span class="badge badge-info"> <i class="fas fa-users" style="color: white;"></i> 
                Tổng {{getTotalUser}} cán bộ</span>
            </div>
        </div>
 
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
	
    export default {
		name: 'phone-book-header',
        data() {
            return {
                isShow: false
            }
        },
        computed: {
			...mapGetters([
				'getTotalUser',
                'getFpdUser',
                'getZoneUser',
                'getProvinceUser',
                'getDistrictUser'
			])
		},
		methods: {
			...mapActions([
                'actGetUserPhoneBook',
                'actGetUserByLocationLevel'
			]),

            handleGetUserPhoneBook(){
				this.actGetUserPhoneBook().then(() => {})
			},

            handleGetUserByLocationLevel(location_level_code){   
                this.actGetUserByLocationLevel(location_level_code).then(()=>{})
            },
        }
	}
</script>

<style scoped>
	.mya {
        text-decoration: none;
        color: #138496;
    }

    .mya:hover {
        color: #138496;
        text-decoration: none;
    }

</style>