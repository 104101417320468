import axiosInstance from '@/plugin/axios.js';

export default {

    async actLogin({ commit }, objDataForm) { // Login và set current user, access token
        try {
            // Data
            let bodyFormData = new FormData();
            bodyFormData.append('user_name', objDataForm.user_name)
            bodyFormData.append('password', objDataForm.password)

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // Lấy dữ liệu qua API
            var res = await axiosInstance.post('/manage_user/login', bodyFormData, config)
            const result = res.data;

            if (res.status === 200) {
                commit('SET_LOGIN', result)

                return {
                    ok: true,
                    data: res.data.current_user
                }
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action Login: ', error)
        }
    },

    async checkLogin({ commit }){ // sử dụng để keep login: các bước như sau:
        try {
            // Lấy tokenLocal
            let tokenLocal = localStorage.getItem('ACCESS_TOKEN')
            // console.log('tokenLocal', tokenLocal)

            // Parse ra lấy thông tin user ID
            if (tokenLocal){ // Nếu tồn tại
                var mykey_secret = process.env.VUE_APP_KEY_SECRET

                // Gửi json-webtoken lên server để giải mã và một mysecret-key (để tránh khi đánh cắp token có thể truy cập)
                let bodyFormData = new FormData();
                bodyFormData.append('token', tokenLocal)
                bodyFormData.append('mykey_secret', mykey_secret)

                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                    // Lấy dữ liệu qua API
                    var res = await axiosInstance.post('/manage_user/login/check_login', bodyFormData, config)
                    const result = res.data;
                    // console.log("result act checkLogin ===", result)
                    
                    if (res.status === 200) {
                        commit('SET_CHECK_LOGIN', result)
                        
                        return {
                            ok: true,
                            data: res.data.current_user
                        }
                    }else{
                        return {
                            ok: false
                        }
                    }

            } else { // Nếu không tồn tại Jwt
                return {
                    ok: false
                }
            }   
        } catch (error) {
            
            console.log('Error action checkLogin: ', error)
            return {
                ok: false
            }
        } 
    },

    async actLogOut({ commit }){
        await commit('SET_LOGOUT')
        return true
    },

}