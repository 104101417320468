<template>
	<div style="margin-bottom: 50px; margin-left: 10px;">
		<div class="callout callout-info position-relative">
			<div class="ribbon-wrapper ribbon-lg">  <!-- ribbon-xl -->
				<div class="ribbon bg-warning">  <!-- text-lg-->
					HƯỚNG DẪN
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<p class="mnote-header">Mô tả</p>					
					<ul>
						<li>Cục Kiểm lâm là đơn vị quản trị tài khoản trên toàn hệ thống.</li>
						<li>Mỗi cán bộ xử phạt được cấp một tài khoản.</li>
						<li>Tài liệu hướng dẫn sử dụng tải về theo đường link <code style="font-size: 95%"> Tài liệu hướng dẫn quản trị hệ thống FVDMS</code>.</li>
					</ul>
				</div>
				<!-- <div class="col-md-12">
					<p class="mnote-header"><i class="fas fa-user"></i> Thống kê số lượng người dùng theo đơn vị</p>
					<ul>
						<li 
							v-for="(item, index) in getListNumberUserByAdministrative"
							v-bind:key="index"
						>						
							 Nhóm không phải Admin
							<div> {{item.administrative_name}}: <span class="badge badge-warning right">{{item.number_user}} </span> người </div>

						</li>
					</ul>  
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'public-user-personal',
		computed: {
			...mapGetters([
				// 'getListNumberUserByAdministrative'
			])
		}
	}
</script>

<style scoped>
	.mnote-header {
        color: #138496;
		font-weight: 500;
    }

	.mya {
        text-decoration: none;
        color: #138496;
    }

    .mya:hover {
        color: #138496;
        text-decoration: none;
    }

	.mylink {
		font-weight: 500;
        text-decoration: none !important;
        color: #0069d9 !important;
    }

    .mylink:hover {
        color: #138496 !important;
        text-decoration: none !important;
    }
</style>