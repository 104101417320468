

export default {
    GET_LIST_INDIVIDUAL_SEARCH (state, data) {
        state.list_individual_search = data.list_individual_search
    },

    GET_LIST_ORGANIZATION_SEARCH (state, data) {
        state.list_organization_search = data.list_organization_search
    },
}