<template>
    <div>
        
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Kết quả tra cứu quyết định xử phạt tổ chức</h3>

                <!-- DOWNLOAD -->
                <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                        <div class="txt-right" style="margin-right: -20px;">
                            <vue-excel-xlsx
                                :data="getListOrganizationSearch"
                                :columns="columns"
                                :file-name="'Danh sach QD xu phat to chuc'"
                                :file-type="'xlsx'"
                                :sheet-name="'Danh sach QD xu phat to chuc'"
                                >
                                <span 
                                    id="hide-btn-vue-excel-xlsx"
                                    class="btn btn-sm" 
                                    style="border: 1px solid #D9D9D9;"
                                >
                                Tải về 
                                <i class="fas fa-download micon"></i>
                                </span>
                            </vue-excel-xlsx>
                        </div>
                    </div>
                </div>
            </div>

            <!-- LIST ORGANIZATION -->
            <ProgressBar mode="indeterminate" style="height: .2em; border-radius: 0px;" v-if="is_loading_download" />

            <div>
                <grid 
                    :cols="header_table_admin" 
                    :rows="getListOrganizationSearch" 
                    :language="language" 
                    :pagination="pagination"
                    :sort="true"
                    >
                </grid>
            </div>
        </div>

    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { Grid } from 'gridjs-vue'
import ProgressBar from 'primevue/progressbar';

export default {
    name: 'table-search-result',
    components: {
        Grid,
        ProgressBar
    },
    data() {
        return {
            // Download: vue-excel-xlsx
            // =====================================================================================
            columns: [
                {
                    field: 'violation_decision_number',
                    label: 'Số QĐ'
                },
                { // 4
                    field: 'decision_date',
                    label: 'Ngày ban hành'
                },
                { 
                    field: 'organization_group_name',
                    label: 'Đơn vị ra quyết định'
                },
                { 
                    field: 'decision_organization_name',
                    label: 'Cơ quan ban hành'
                },
                {
                    field: 'organization_name',
                    label: 'Tên tổ chức'
                },
                {
                    field: 'address',
                    label: 'Địa chỉ'
                },
                {
                    field: 'organization_code_number',
                    label: 'Mã số doanh nghiệp'
                },
                {
                    field: 'business_certificate_number',
                    label: 'Số GCN/GP'
                },
                {
                    field: 'representative_legal',
                    label: 'Người đại diện '
                },
                {
                    field: 'sex_name',
                    label: 'Giới tính'
                },
                {
                    field: 'position',
                    label: 'Chức danh'
                },
                {
                    field: 'decision_status_name',
                    label: 'Trạng thái'
                }
            ],

            // GridJS: table phone-book: formatter: (cell) => `${moment(cell).format('DD-MM-YYYY')}`
            // =====================================================================================
            header_table_admin: [
                { // 0
                    id: 'file_upload',
                    name: 'file_upload',
                    hidden: true
                },
                { // 1
                    id: 'user_id_created',
                    name: 'user_id_created',
                    hidden: true
                },    		
                { // 2
                    id: 'violation_decision_number',
                    name: 'Số QĐ',
                    formatter: (cell, row) => {
                        if(row.cells[1].data === this.getCurrentUser.user_id){
                            return this.$gridjs.html(this.violationDecisionCode(cell, 1))

                        }else{
                            return this.$gridjs.html(this.violationDecisionCode(cell, 2))
                        }
                    },
                    // width: '130px'
                },
                { // 3
                    id: 'decision_date',
                    name: 'Ngày ban hành',
                    sort: {
                        compare: (a, b) => {            
                            a = a.split('-').reverse().join('');
                            b = b.split('-').reverse().join('');
                            
                            if (a > b) {
                                return 1;
                            } else if (b > a) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                },
                { // 3 - 4
                    id: 'organization_name',
                    name: 'Tên tổ chức',
                },
                { // 5
                    id: 'address',
                    name: 'Địa chỉ',
                    sort: false
                },
                { // 6
                    id: 'organization_code_number',
                    name: 'Mã số DN',
                    sort: false
                },
                { // 7
                    id: 'business_certificate_number',
                    name: 'Số GCN/GP',
                    sort: false
                },
                { // 8
                    id: 'representative_legal',
                    name: 'Người đại diện'
                },
                { // 9
                    id: 'decision_status_id',
                    name: 'Trạng thái',
                    sort: false,
                    formatter: (cell) => this.$gridjs.html(this.formartStatus(cell))
                },
                { // 10
                    id: 'violation_decision_id',
                    name: 'Quyết định',
                    sort: false,
                    width: '240px',
                    formatter: (cell, row) => {  // row: array value of row; cell: value of this cell (user_id)
                        // 1. Trạng thái chờ phê duyệt: chỉ người tạo ra quyết định thì mới có quyền xem bản nháp
                        if(row.cells[9].data === 1 && row.cells[1].data === this.getCurrentUser.user_id){
                            return [ 
                              
                                //  View violation
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleDownloadDecisionDraft(cell)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="far fa-file-word micon2"></i></div>`))                              
                            ]
                        }

                        // 2. Người khác chỉ tải được khi quyết định đang và đã thi hành thì được tải
                        if(row.cells[9].data === 2 || row.cells[9].data === 3 ){ 
                            return [ 
                                //  Download
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleDownloadDecisionAccepted(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm"><i class="fas fa-file-pdf micon2"></i> </div>`))
                            ]
                        }
                    }
                },
                { // 11
                    id: 'province_name',
                    name: 'Tỉnh'
                },
            ],

            pagination: true,
            language: {
                search: {
                    placeholder: '🔍 Tìm kiếm ...',
                },
                sort: {
                    sortAsc: 'Sắp xếp tăng dần',
                    sortDesc: 'Sắp xếp giảm dần',
                },
                pagination: {
                    previous: 'Về trước',
                    next: 'Kế tiếp',
                    navigate: (page, pages) => `Trang ${page} trên tổng ${pages}`,
                    page: (page) => `Trang ${page}`,
                    showing: 'Hiển thị',
                    of: 'trên tổng',
                    to: 'từ',
                    results: 'kết quả',
                },
                loading: 'Đang load trang ...',
                noRecordsFound: 'Không có kết quả nào được tìm thấy!',
                error: 'Có lỗi xảy ra trong quá trình lấy dữ liệu!',
            },

            // Download
            is_loading_download: false,
        }
    },
    created(){
        // Current user info
        var data = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_level_code: this.getCurrentUser.location_level_code
            }

        // List individual violation person
        this.actGetOrganizationViolationByUser(data)
    },
    computed: {
        ...mapGetters([
            'getCurrentUser',
            'getListOrganizationSearch'
        ])
    },
    methods: {
        ...mapActions([
            'actGetOrganizationViolationByUser',
            'actDownloadDecisionOrganization',
            'actGetViolationDecisionById',
            'actDownloadDecision'
        ]),

        // TABLE: FORMAT CELL
        // =========================================================
        violationDecisionCode(cell, type){
            if(cell === ''){ // Nếu chưa điền số quyết định
                if(type === 1){
                    return `.../...`
                }

                if(type === 2){
                    return `<span style="color: red"> .../... </span>`
                }

            }else{ // Nếu đã có số quyết định
                if(type === 1){
                    return cell + `/QĐ-XPHC`
                }

                if(type === 2){
                    if(cell) return `<span style="color: red">` + cell + "/QĐ-XPHC" + `</span>`
                    return `.../QĐ-XPHC`
                }
            }
        },

        formartStatus(status_decision_id){
            if(status_decision_id === 1) return '<span> <i class="fas fa-ban status-wait"></i> Chờ phê duyệt </span>'
            if(status_decision_id === 2) return '<span class="status-process"> <i class="fas fa-circle-notch"></i> Đang thi hành </span>'
            if(status_decision_id === 3) return `<span class="status-success"> <i class="fas fa-check-circle"></i> Đã thi hành </span>`
        },
        
        resetForm(){
            this.violation_decision_id = ''
            this.files = []
            this.num_files = 0
            this.decision_status_note = ''
            this.organization_name = ''
        },


        // DOWNLOAD WORD
        // =========================================================
        handleDownloadDecisionDraft(violation_decision_id) {
            this.is_loading_download = true

            this.actDownloadDecisionOrganization(violation_decision_id).then((data) => {
                this.is_loading_download = false

                if(data.ok){
                    toastr.success('Tải quyết định xử phạt thành công!', '', {closeButton: true})
                }
            })
        },


        // DOWNLOAD DECISION
        // =========================================================
        handleDownloadDecisionAccepted(violation_decision_id, row){

            // Lấy thông tin file theo quyết định (Gridjs): row là chứa toàn bộ thông tin của dòng click, cột file_upload là cột thứ 9 (ẩn đi)
            var files = row.cells[10].data

            var data = {
                violation_decision_id: violation_decision_id,
                files: files
            }

            this.is_loading_download = true

            this.actDownloadDecision(data).then((res) => {
                this.is_loading_download = false

                if(!res) {
                    toastr.error(`File quyết định không tồn tại, tải thất bại!`,'', {closeButton: true})
                }

                if(res.ok === true){
                    toastr.success(`Tải quyết định xử phạt thành công!`,'', {closeButton: true})
                }
            })
        },


    }
}
</script>

<style scoped>
    .status-success {
        color: #28a745;
    }

    button {
		background-color: #F7F7F7;
		border: white;
	}

    .mheader-dialog {
        padding: 20px;
        background-color: #17A2B8;
        color: white;
    }

    .mybtn-cancel {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #FFFFFF !important;   
        color: black !important;  
        border: 1px solid #dee2e6 !important;  
    }

    .mybtn-upload{
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #17A2B8 !important;
        border: 1px solid #dee2e6 !important; 
    }

    .mybtn-cancel:hover {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #E5E5E5 !important;  
        color: black !important; ; 
        border: 1px solid #dee2e6 !important;  
    }

    .mclose-btn{
        color: white;
        background-color: #17A2B8;
        font-size: 20px;
    }
</style>