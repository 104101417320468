<template>
    <div>
        <div class="card" style="border-radius: 0px">
            <div class="card-header ui-sortable-handle">
                <h3 class="card-title">
                    <i class="ion ion-clipboard mr-1"></i>
                    Điều 1 / Phần 1: Thông tin đối tượng xử phạt là tổ chức
                </h3>
            </div>
            <div class="card-body">

                <!-- I. MẪU VĂN BẢN -->
                <!-- ========================================================================= -->
                <div class="mheader-1"> <i class="fas fa-file-word fa-lg"></i> Mẫu quyết định </div>

                <!-- MỤC 1 -->
                <div>1. Xử phạt vi phạm hành chính đối với <span class="bold">tổ chức </span>có tên sau đây:</div>

                <!-- Trường hợp tổ chức -->
                <div class="mline">
                    Tên tổ chức: 
                    <span class="num1 txt-center " :class="lorganization_name">1</span>
                    <span v-html="html_organization_name"></span>
                    
                </div>
                <div class="mline">
                    Địa chỉ trụ sở chính: 
                    <span class="num1 txt-center " :class="laddress">2</span>
                    <span v-html="html_address"></span>
                </div>
                <div class="mline">
                    Mã số doanh nghiệp: 
                    <span class="num1 txt-center " :class="lorganization_code_number">3</span>
                    <span v-html="html_organization_code_number"></span>
                </div>
                <div class="mline">
                    Số GCN đăng ký đầu tư/doanh nghiệp hoặc GP thành lập/đăng ký hoạt động: 
                    <span class="num1 txt-center " :class="lbusiness_certificate_number">4</span>
                    <span v-html="html_business_certificate_number"></span>, 
                    ngày cấp: 
                    <span class="num1 txt-center " :class="ldate_issued">5</span>
                    <span v-html="html_date_issued"></span>, 
                    nơi cấp: 
                    <span class="num1 txt-center " :class="lissued_by">6</span>
                    <span v-html="html_issued_by"></span>
                </div>
                <div class="mline">
                    Người đại diện theo pháp luật: 
                    <span class="num1 txt-center " :class="lrepresentative_legal">7</span>
                    <span v-html="html_representative_legal"></span>.
                    
                    Giới tính: 
                    <span class="num1 txt-center " :class="lsex_name">8</span>
                    <span v-html="html_sex_name"></span>.
                </div>
                <div class="mline">
                    Chức danh: 
                    <span class="num1 txt-center " :class="lposition">9</span>
                    <span v-html="html_position"></span>
                </div>


                <!-- hr line -->
                <!-- <hr class="mtop-bottom-40"> -->
                <div class="mtop-bottom-40"></div>
            </div>

            <!-- I.2. NHẬP THÔNG TIN ĐỐI TƯỢNG XỬ PHẠT -->
            <!-- ========================================================================== -->
            <div class="card-body mbg-input">

                <div class="mheader-2" id="input-organization"> 
                    <i class="fas fa-marker fa-lg"></i> Nhập thông tin 
                    <i class="fas fa-backspace scroll-up fa-lg txt-right" @click="resetForm()"></i>
                </div>
                
                
                <!-- II.2 Tổ chức -->
                <!-- ------------------------------------------------------------------------- -->
                <div>
                    <div class="row">
                        <!-- Tên tổ chức -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-university micon"></i> 
                                Tên của tổ chức <sup><code>(*)</code></sup> <span class="mybadge txt-right">1</span></p>
                            <div class="input-group mb-3">
                                <input v-model="organization_name" type="text" class="form-control" placeholder="Nhập tên của tổ chức" autocomplete="off">
                            </div>
                        </div>

                        <!-- Địa chỉ trụ sở -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-map-marker-alt micon"></i> 
                                Địa chỉ trụ sở chính <span class="mybadge txt-right">2</span></p>
                            <div class="input-group mb-3">
                                <input v-model="address" type="text" min="0" class="form-control" placeholder="Nhập địa chỉ trụ sở chính" autocomplete="off">
                            </div>
                        </div>
                    </div>

                    
                    <div class="row">
                        <!-- Mã số doanh nghiệp -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-address-card micon"></i> 
                                Mã số doanh nghiệp <sup><code>(*)</code></sup> <span class="mybadge txt-right">3</span></p>
                            <div class="input-group mb-3">
                                <input v-model="organization_code_number" type="text" class="form-control" placeholder="Nhập mã số doanh nghiệp" autocomplete="off">
                            </div>
                        </div>

                        <!-- Số GCN đăng ký đầu tư -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-address-card micon"></i> 
                                Số GCN đầu tư/doanh nghiệp hoặc GP thành lập/đăng ký hoạt động <span class="mybadge txt-right">4</span></p>
                            <div class="input-group mb-3">
                                <input v-model="business_certificate_number" type="text" class="form-control" placeholder="Nhập số GCN / Giấy phép" autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Ngày cấp -->
                        <div class="col-md-6">
                            <div style="margin-bottom: 6px;"> <i class="fas fa-calendar-alt micon"></i> 
                            Ngày cấp GCN / Giấy phép <span class="mybadge txt-right">5</span></div>
                                
                            <!-- :min-date="new Date()" -->
                            <v-date-picker 
                                :masks="masks"
                                :attributes='attrs'
                                show-weeknumbers
                                @input="handExtractDateBusinessNumber()"
                                v-model="date_issued_selected">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="input-group">
                                        <input
                                            placeholder="Chọn ngày cấp GCN / Giấy phép: DD-MM-YYYY"
                                            class="form-control"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>

                        <!-- Nơi cấp -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-map-marker"></i> 
                                Nơi cấp <span class="mybadge txt-right">6</span></p>
                            <div class="input-group mb-3">
                                <input v-model="issued_by" type="text" class="form-control" placeholder="Nhập nơi cấp GCN / Giấy phép" autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Người đại diện theo pháp luật -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-user-circle"></i> 
                                Người đại diện theo pháp luật <span class="mybadge txt-right">7</span></p>
                            <div class="input-group mb-3">
                                <input v-model="representative_legal" type="text" class="form-control" placeholder="Nhập tên người đại diện theo pháp luật" autocomplete="off">
                            </div>
                        </div>

                        <!-- Giới tính -->
                        <div class="col-md-6">     
                            <p class="mheader-3"><i class="fas fa-ankh micon"></i>
                            <span> Giới tính <span class="mybadge txt-right">8</span></span>	</p>
                            <multiselect
                                id="sex_id"
                                track-by="sex_id" label="sex_name" 
                                @input="handleGetSex()"
                                v-model="sex_selected" 
                                open-direction="bottom"
                                placeholder="Chọn giới tính"
                                :options="getSexType" 
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :clear-on-select="true"
                                :show-pointer="true"
                                :hide-selected="true">
                            </multiselect>
                        </div> 
                    </div>

                     <div class="row">
                        <!-- Chức danh người đại diện theo pháp luật -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-user-tag"></i> 
                                Chức danh <span class="mybadge txt-right">9</span></p>
                            <div class="input-group mb-3">
                                <input v-model="position" type="text" class="form-control" placeholder="Nhập chức danh người đại diện theo pháp luật" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div id="article12"></div>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { fixMonthAndDay } from '@/helper/index'

    export default {
        name: 'vd-article11-organization',
        data() {
            return {
                // Tổ chức
                organization_name: '',  // * Tên tổ chức
                address: '',            // * Địa chỉ trụ sở chính
                organization_code_number: '',            // * Mã số doanh nghiệp
                business_certificate_number: '',         // * Số GCN đăng ký đầu tư/doanh nghiệp
                date_issued: '',                         // * Ngày cấp số GCN đăng ký đầu tư/doanh nghiệp (lưu vào CSDL)
                date_issued_selected: '',                // Ngày zend ra hiển thị trên web
                issued_by: '',                           // * Nơi cấp số GCN đăng ký đầu tư/doanh nghiệp
                representative_legal: '',   // * Người đại diện theo pháp luật
                position: '',               // * Chức danh
                sex_id: '',                 // * Giới tính (3)
                sex_name: '',
                sex_selected: [],
                
                // Variable temp
                date: '',               // variable temp
                month: '',              // variable temp
                year: '',               // variable temp

                // VCalendar
				selectedDate: null,
				attrs: [
					{
						key: 'today',
						highlight: true,       // dot
						dates: new Date()
					}
				],
				masks: {
					input: 'DD-MM-YYYY',
				}
            }
        },
        watch: {
            representative_legal (newVal) { // Lắng nghe khi nhập tên người dùng thì emit lên Index để truyền vào Article3
                this.$emit('emitRepresentativeLegal', newVal) 
            },
            organization_name (newVal) { // Lắng nghe khi nhập tên tổ chức thì emit lên Index để truyền vào Article3
                this.$emit('emitPenaltyOrganizationName', newVal) 
            }
        },
        computed: {
			...mapGetters([
                'getSexType',
			]),

            // 1. Tên tổ chức
            lorganization_name: function (){
                if(!this.organization_name) return ''
                return 'hide'
            },

            html_organization_name: function (){
                if(!this.organization_name) return '........................................................................................................................................................................................................................................................................................'
                return '<span class="bold">' + this.organization_name +'</span>'
            },

            // 2. Địa chỉ trụ sở chính
            laddress: function (){
                if(!this.address) return ''
                return 'hide'
            },

            html_address: function (){
                if(!this.address) return '........................................................................................................................................................................................................................................................................'
                return '<span class="bold">' + this.address +'</span>'
            },

            // 3. Mã số doanh nghiệp
            lorganization_code_number: function (){
                if(!this.organization_code_number) return ''
                return 'hide'
            },

            html_organization_code_number: function (){
                if(!this.organization_code_number) return '.....................................................................................................................................................................................................................................................................'
                return '<span class="bold">' + this.organization_code_number +'</span>'
            },

            // 4. Số GCN đăng ký đầu tư/doanh nghiệp hoặc GP thành lập/đăng ký hoạt động
            lbusiness_certificate_number: function (){
                if(!this.business_certificate_number) return ''
                return 'hide'
            },

            html_business_certificate_number: function (){
                if(!this.business_certificate_number) return '..............................'
                return '<span class="bold">' + this.business_certificate_number +'</span>'
            },

            // 5. Ngày cấp
            ldate_issued: function (){
                if(!this.date_issued) return ''
                return 'hide'
            },

            html_date_issued: function (){
                if(!this.date_issued) return '........../.........../...........'
                return '<span class="bold">' + this.date_issued +'</span>'
            },

            // 6. Nơi cấp
            lissued_by: function (){
                if(!this.issued_by) return ''
                return 'hide'
            },

            html_issued_by: function (){
                if(!this.issued_by) return '........................'
                return '<span class="bold">' + this.issued_by +'</span>'
            },

            // 7. Người đại diện theo pháp luật
            lrepresentative_legal: function (){
                if(!this.representative_legal) return ''
                return 'hide'
            },

            html_representative_legal: function (){
                if(!this.representative_legal) return '.........................................................................................'
                return '<span class="bold">' + this.representative_legal +'</span>'
            },

            // 8. Giới tính
            lsex_name: function (){
                if(!this.sex_name) return ''
                return 'hide'
            },

            html_sex_name: function (){
                if(!this.sex_name) return '...........................................................................................................................'
                return '<span class="bold">' + this.sex_name +'</span>'
            },

            // 9. Chức danh
            lposition: function (){
                if(!this.position) return ''
                return 'hide'
            },

            html_position: function (){
                if(!this.position) return '..........................................................................................................................................................................................................................................................................................'
                return '<span class="bold">' + this.position +'</span>'
            },   

		},
		methods: {

            // ADD 
            // =====================================================================================
            resetForm(){
                this.organization_name = ''  // Tên tổ chức
                this.address = ''            // Địa chỉ trụ sở chính
                this.organization_code_number = ''            // Mã số doanh nghiệp
                this.business_certificate_number = ''         // Số GCN đăng ký đầu tư/doanh nghiệp
                this.date_issued = ''                         // Ngày cấp số GCN đăng ký đầu tư/doanh nghiệp (lưu vào CSDL)
                this.date_issued_selected = ''                // Ngày do người dùng selected
                this.issued_by = ''                           // Nơi cấp số GCN đăng ký đầu tư/doanh nghiệp
                this.representative_legal = ''   // Người đại diện theo pháp luật
                this.position = ''               // Chức danh
                this.sex_id = ''                 // Giới tính người đại diện theo pháp luật 
                this.sex_name = ''
                this.sex_selected = [],
                
                // Variable temp
                this.date = ''               // variable temp
                this.month = ''              // variable temp
                this.year = ''   
                this.$emit('emitPenaltyOrganizationName', '')
                this.$emit('emitRepresentativeLegal', '')          
            },

            handleGetSex(){
                this.sex_id     = this.sex_selected.sex_id
                this.sex_name   = this.sex_selected.sex_name
            },

			handExtractDateBusinessNumber(){
                this.date = fixMonthAndDay(moment(this.date_issued_selected).format('DD'))
                this.month = fixMonthAndDay(moment(this.date_issued_selected).format('MM'))
                this.year = moment(this.date_issued_selected).format('YYYY')
                if(this.date !== '' & this.date !== 'Invalid date'){
                    this.date_issued = this.date + '/' + this.month + '/' + this.year
                }
            },

            // EDIT
            // =====================================================================================
            setEdit(data){
                console.log("data setEdit ORGANIZATION ===", data)

                // Ngày cấp
                var check_date_issued = parseInt(moment(data.date_issued).format('YYYY'))
                var date_issued = ''

                if(check_date_issued !== 1900 && check_date_issued !== 1899){
                    this.date   = fixMonthAndDay(moment(data.date_issued).format('DD'))
                    this.month  = fixMonthAndDay(moment(data.date_issued).format('MM'))
                    this.year   = moment(data.date_issued).format('YYYY')
                    date_issued = this.date + '/' + this.month + '/' + this.year

                    this.date_issued                = date_issued                       // Ngày cấp số GCN đăng ký đầu tư/doanh nghiệp (lưu vào CSDL)
                    this.date_issued_selected       = data.date_issued                  // Ngày do người dùng selected
                }

                this.organization_name              = data.organization_name            // Tên tổ chức
                this.address                        = data.address                      // Địa chỉ trụ sở chính
                this.organization_code_number       = data.organization_code_number     // Mã số doanh nghiệp
                this.business_certificate_number    = data.business_certificate_number  // Số GCN đăng ký đầu tư/doanh nghiệp
                this.issued_by                      = data.issued_by                    // Nơi cấp số GCN đăng ký đầu tư/doanh nghiệp
                this.representative_legal           = data.representative_legal         // Người đại diện theo pháp luật
                this.position                       = data.position                     // Chức danh
                this.sex_id                         = data.sex_id                       // Giới tính người đại diện theo pháp luật 
                this.sex_name                       = data.sex_name
                this.sex_selected.push({
                    sex_id: data.sex_id,
                    sex_name: data.sex_name
                })
            }
		}
    }
</script>

<style scoped>

</style>