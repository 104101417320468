<template>
    <div style="margin-top: 100px">
        <div class="alert alert-warning alert-dismissible">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
            <h5><i class="icon fas fa-exclamation-triangle"></i> Không có kết quả thỏa mãn điều kiện tìm kiếm! </h5>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'report-organization-not-found',
    }
</script>

<style scoped>

</style>