<template>
    <div>
        <div class="card-body">
            <div class="mtitle" v-if="(date_type == 1)" v-html="html_title_report_type1"></div>
            <div class="mtitle" v-if="(date_type == 2)" v-html="html_title_report_type2"></div>

            <div class="row">
                <div style="padding-right: 18px;">
                    <i class="fas fa-backspace scroll-up fa-lg micon txt-right" @click="resetFormSearch()"></i>
                </div>
            </div>
            <!-- Row 1 -->
            <div class="row">
                <div class="col-md-6">
                    <div><i class="fas fa-university myicon"></i> Tên tổ chức / người đại diện</div>
                    <div class="input-group input-group">
                        <input type="text" class="form-control"  placeholder="Nhập tên tổ chức / người đại diện" v-model="organization_name" autocomplete="off">
                    </div>
                </div>
                <div class="col-md-6">
                    <div><i class="far fa-address-card myicon"></i> Mã số doanh nghiệp</div>
                    <div class="input-group input-group">
                        <input type="text" class="form-control" placeholder="Nhập mã số doanh nghiệp" v-model="organization_code_number" autocomplete="off">
                    </div>
                </div>
            </div>

            <!-- Row 2 -->
            <!-- Hành vi và trạng thái quyết định -->
            <div class="row" style="margin-top: 15px;">
                <div class="col-md-6">
                    <div><i class="fas fa-user-tag myicon"></i> Hành vi vi phạm </div>
                    <multiselect
                        id="article_id"
                        track-by="article_id" label="behavior_name" 
                        @input="handleSelectArticle()"
                        v-model="article_selected" 
                        open-direction="bottom"
                        placeholder="Chọn hành vi"
                        :options="getListArticle" 
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="true"
                        :show-pointer="true"
                        :hide-selected="true">
                    </multiselect>
                </div>
                <div class="col-md-6">
                    <div><i class="fas fa-shield-alt myicon"></i> Trạng thái quyết định</div>
                    <multiselect
                        id="decision_status_id"
                        track-by="decision_status_id" label="decision_status_name" 
                        @input="handleSelectDecisionStatus()"
                        v-model="decision_status_selected" 
                        open-direction="bottom"
                        placeholder="Chọn trạng thái"
                        :options="getListDecisionStatus" 
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="true"
                        :show-pointer="true"
                        :hide-selected="true">
                    </multiselect>
                </div>
            </div>

            <!-- Thời gian bắt đầu, kết thúc -->
            <div class="row" style="margin-top: 15px;">
                <div class="col-md-6" v-if="getCurrentUser.location_level_code === 1 || getCurrentUser.location_level_code === 2" style="margin-top: 30px;">
                    <div><i class="fas fa-globe-africa myicon"></i> Tỉnh (nơi xảy ra vi phạm) </div>
                    <multiselect
                        style="height: 20px !important;"
                        id="province_code"
                        track-by="province_code" label="province_name" 
                        @input="handleSelectProvince()"
                        v-model="province_selected" 
                        open-direction="bottom"
                        placeholder="Chọn tỉnh"
                        :options="getListProvince" 
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="true"
                        :show-pointer="true"
                        :hide-selected="true">
                    </multiselect>
                </div>
                <div class="" :class="classRowColMd">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="1" v-model="date_type" @click=selectTime(1) checked>
                        <label class="form-check-label">Thời gian lưu quyết định</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="2" v-model="date_type" @click=selectTime(2)>
                        <label class="form-check-label">Thời gian ban hành quyết định</label>
                    </div>                                
                    <v-date-picker 
                        :masks="masks"
                        :attributes='attrs'
                        show-weeknumbers
                        @input="handStartDate()"
                        v-model="date_start">
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="input-group">
                                <input
                                    placeholder="Thời gian bắt đầu: DD-MM-YYYY"
                                    class="form-control"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div class="" :class="classRowColMd" style="margin-top: 52px;">
                    <v-date-picker 
                        :masks="masks"
                        :attributes='attrs'
                        show-weeknumbers
                        @input="handEndDate()"
                        v-model="date_end">
                        <template v-slot="{ inputValue, inputEvents }">
                            <div class="input-group">
                                <input
                                    placeholder="Thời gian kết thúc: DD-MM-YYYY"
                                    class="form-control"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
            </div>
        </div>
        <div class="card-footer clearfix">
            <span class="mgr-50"><i class="fas fa-gavel myicon"></i> Tổng số tổ chức vi phạm: {{getNumDecisionOrganization.total}}</span>
            <span class="mgr-50"><i class="fas fa-ban status-wait"></i> Số tổ chức chờ thi hành: {{getNumDecisionOrganization.num_wait}}</span>
            <span class="mgr-50"><i class="fas fa-circle-notch myicon"></i> Số tổ chức đang thi hành: {{getNumDecisionOrganization.num_doing}}</span>
            <span class="mgr-50"><i class="fas fa-check-circle status-success"></i> Số tổ chức đã thi hành: {{getNumDecisionOrganization.num_completed}}</span>
            <button type="button" class="btn btn-outline-success btn-sm float-right" @click="searchViolationDecision()" style="width: 150px">Tra cứu</button>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getValueObjInArrayByKey } from '@/helper/index'
import moment from 'moment';

export default {
    name: 'form-penalty-organization',
    data() {
        return {
            
            // Person info
            organization_name: '',
            organization_code_number: '',           // * CMT / CCCD

            // Acticle: Điều (Hành vi)
            article_id: '',                 // List mã danh sách hành vi người dùng chọn: 7, 8, 9,...
            article_selected: [],

            // Decision status
            decision_status_id: '',         // List mã danh sách trạng thái quyết định người dùng chọn: 7, 8, 9,...
            decision_status_selected: [],

            // Tỉnh (select tỉnh --> gắn vào mã code nơi để xảy ra vi phạm)
            province_code: null,                 // * Mã tỉnh: nơi để xẩy ra vi phạm người dùng chọn
            province_name: '',                 // Tên tỉnh: nơi để xảy ra vi phạm
            province_selected: [],             // Tỉnh lựa chọn (3)

            // Thời gian
            date_type: 1,                       // 1 - Thời gian lập quyết định; 2 - Thời gian ban hành quyết định
            date_start: moment().startOf('year').format("YYYY-MM-DD"),       
            date_end: moment().format("YYYY-MM-DD"),   
            
            // VCalendar
            attrs: [
                {
                    key: 'today',
                    highlight: true,       // dot
                    dates: new Date()
                }
            ],
            masks: {
                input: 'DD-MM-YYYY'
            },
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentUser',
            'getListDecisionStatus',
            'getListProvince',
            'getListArticle',
            'getNumDecisionOrganization'
        ]),

        classRowColMd(){
            if(
                this.getCurrentUser.location_level_code === 1 || 
                this.getCurrentUser.location_level_code === 2) 
            return 'col-md-3'
            return 'col-md-6'
        },

        html_title_report_type1: function (){ // Thời gian lập quyết định
            // Mặc định năm hiện tại
            if(this.date_start === '' && this.date_end === '' || this.date_start === 'Invalid date' && this.date_end === 'Invalid date') return `<span>Kết quả xử phạt tổ chức lưu từ ${moment().startOf('year').format("DD-MM-YYYY")} đến ${moment().format('DD-MM-YYYY')}</span>`
            
            // Nếu chỉ chọn ngày bắt đầu
            if(this.date_start !== '' && this.date_end === '' || this.date_start !== '' && this.date_end === 'Invalid date') {
                return `<span>Kết quả xử phạt tổ chức lưu từ ${moment(this.date_start).format("DD-MM-YYYY")}</span>`
            }

            if(this.date_start === '' && this.date_end !== '' || this.date_start === 'Invalid date' && this.date_end !== '') {
                return `<span>Kết quả xử phạt tổ chức lưu đến ngày ${moment(this.date_end).format("DD-MM-YYYY")}</span>`
            }

            // Nếu chọn ngày bắt đầu và ngày kết thúc
            if(this.date_start !== '' && this.date_end !== '') {
                let date_start = moment(this.date_start).format("DD-MM-YYYY")
                let date_end = moment(this.date_end).format("DD-MM-YYYY")
                return `<span>Kết quả xử phạt tổ chức lưu từ ${date_start} đến ${date_end}</span>`
            }
        },

        html_title_report_type2: function (){ // Thời gian ban hành quyết định
            // Mặc định năm hiện tại
            if(this.date_start === '' && this.date_end === '' || this.date_start === 'Invalid date' && this.date_end === 'Invalid date') return `<span>Kết quả xử phạt tổ chức đã ban hành từ ${moment().startOf('year').format("DD-MM-YYYY")} đến ${moment().format('DD-MM-YYYY')}</span>`
            
            // Nếu chỉ chọn ngày bắt đầu
            if(this.date_start !== '' && this.date_end === '' || this.date_start !== '' && this.date_end === 'Invalid date') {
                return `<span>Kết quả xử phạt tổ chức đã ban hành từ ${moment(this.date_start).format("DD-MM-YYYY")}</span>`
            }

            if(this.date_start === '' && this.date_end !== '' || this.date_start === 'Invalid date' && this.date_end !== '') {
                return `<span>Kết quả xử phạt tổ chức đã ban hành đến ngày ${moment(this.date_end).format("DD-MM-YYYY")}</span>`
            }

            // Nếu chọn ngày bắt đầu và ngày kết thúc
            if(this.date_start !== '' && this.date_end !== '') {
                let date_start = moment(this.date_start).format("DD-MM-YYYY")
                let date_end = moment(this.date_end).format("DD-MM-YYYY")
                return `<span>Kết quả xử phạt tổ chức đã ban hành từ ${date_start} đến ${date_end}</span>`
            }
        },
    },
    methods: {
        ...mapActions([
            'actSearchViolationDecisionOrganization',
            'actGetOrganizationViolationByUser'
        ]),

        // Select Article
        handleSelectArticle(){
            this.article_id = getValueObjInArrayByKey(this.article_selected, 'article_id')            // return: 1, 2, 6, 7,...
            // console.log("Hành vi: =====", this.article_id)
        },

        handleSelectProvince(){ // Set place_happen_violation
            this.province_code = getValueObjInArrayByKey(this.province_selected, 'province_code')
            console.log("Tỉnh chọn: =====", this.province_code)
        },

        handleSelectDecisionStatus(){ // Trạng thái quyết định
            this.decision_status_id = getValueObjInArrayByKey(this.decision_status_selected, 'decision_status_id')
            // console.log("Tỉnh trạng thái: =====", this.decision_status_id)
        },

        // Start date and End date
        selectTime(value){
            this.date_type = parseInt(value)
            this.$emit('emitSetDateTypeOrganization', parseInt(value))
        },

        handStartDate(){
            this.date_start = moment(this.date_start).format('YYYY-MM-DD')
        },

        handEndDate(){
            this.date_end = moment(this.date_end).format('YYYY-MM-DD')
        },

        checkDateSelected(){
            var error = false

            if(this.date_start === ''){
                toastr.error("Chưa chọn thời gian bắt đầu!", "Lỗi chọn thời gian ban hành", { "closeButton": true });
                return error = true
            }

            if(this.date_end === ''){
                toastr.error("Chưa chọn thời gian kết thúc!", "Lỗi chọn thời gian ban hành", { "closeButton": true });
                return error = true
            }

            if(moment(this.date_end) < moment(this.date_start)) {
                toastr.error("Thời gian kết thúc nhỏ hơn bắt đầu", "Lỗi chọn thời gian ban hành", { "closeButton": true });
                return error = true
            }

            return error
        },  

        // Tra cứu quyết định xử phạt
        searchViolationDecision(){

            if( // Nếu form trống hoặc khi người dùng bỏ chọn tất cả
                this.organization_name           == '' && 
                this.organization_code_number    == '' &&
                this.article_id          == '' &&
                this.decision_status_id  == '' &&
                this.province_code       == null &&
                this.date_end   == '' &&
                this.date_start == ''
            ){
                var data = {
                    user_id: this.getCurrentUser.user_id,
                    role_id: this.getCurrentUser.role_id,
                    location_level_code: this.getCurrentUser.location_level_code
                }

                this.actGetOrganizationViolationByUser(data).then(() => {
                    toastr.success('Tra cứu thành công!', '', {closeButton: true})
                })

            }else{ // Nếu người dùng chọn

                var data = {
                    user_id: this.getCurrentUser.user_id,
                    location_level_code: this.getCurrentUser.location_level_code,
                    organization_name: this.organization_name,
                    organization_code_number: this.organization_code_number,
                    article_id: this.article_id,
                    decision_status_id: this.decision_status_id,
                    province_code: this.province_code,
                    date_type: this.date_type,
                    date_start: moment(this.date_start).format('YYYY-MM-DD'),
                    date_end: moment(this.date_end).format('YYYY-MM-DD')
                }

                var data_json = JSON.stringify(data)                
                let checkDateSelected = this.checkDateSelected()

                if(!checkDateSelected){
                    this.actSearchViolationDecisionOrganization(data_json).then(() => {
                        toastr.success('Tra cứu thành công!', '', {closeButton: true})
                    })
                }
            }
        },

        // resetForm search
        resetFormSearch(){

            // Xóa form
            this.organization_name = ''
            this.organization_code_number = ''
            this.article_id = ''
            this.decision_status_id = ''
            this.province_code = null
            this.date_type = 1
            this.date_end = moment().format("YYYY-MM-DD")
            this.date_start = moment().startOf('year').format("YYYY-MM-DD")
            this.article_selected = []
            this.decision_status_selected = []
            this.province_selected = []

            // Cập nhật lại bảng dữ liệu
            var data = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_level_code: this.getCurrentUser.location_level_code,
                date_type: this.date_type,
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD")
            }

            this.actGetOrganizationViolationByUser(data).then(() => {
                toastr.success('Hủy tra cứu thành công!', '', {closeButton: true})
            })
        }
    }
}
</script>

<style>

    table.gridjs-table {
        width: 100% !important;
    }

    .mgr-50 {
        margin-right: 50px;
    }

    .mtitle {
        color: #307484; 
        margin-bottom: 20px; 
        margin-top: 15px;
        font-size: 18px;
        font-weight: normal;
    }
</style>