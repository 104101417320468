<template>
	<!-- Content Wrapper. Contains page content -->
	<div>
		<!-- HEADER -->
            <IndexHeader />
        <!-- / HEADER -->

        <!-- MAIN content -->
        <section class="content">
            <div style="padding-bottom: 30px;">

                <!-- TẠO TÀI KHOẢN USER: SUPPER ADMIN, ADMIN, LÃNH ĐẠO PHÒNG, NGƯỜI QUẢN TRỊ:  
                    v-if="getCurrentUser.type_user === 'admin' || getCurrentUser.type_user === 'supper_admin' || getCurrentUser.group_code === 2 || getCurrentUser.group_code === 5"  -->
                    <FormAddUser />
                <!-- /ADD USER -->

                <!-- FORM EDIT -->
                    <!-- Tài khoản Supper Admin, Admin, Lãnh đạo (Tỉnh - group code = 2): có tất cả các trường tên, email, đơn vị, chức vụ:  
                        v-if="getCurrentUser.type_user === 'admin' || getCurrentUser.type_user === 'supper_admin'" -->
                    <FormEditUser
                        ref="formUserEdit"
                    />

                <!-- /FORM EDIT -->

                <!-- CHANGE PASSWORD -->
                    <ChangePassword 
                        ref="formChangePassword"
                    />
                <!-- /CHANGE PASSWORD -->

                <!-- RESET PASSWORD -->
                    <ResetPassword 
                        ref="formResetPassword"
                    />
                <!-- /RESET PASSWORD -->

                <!-- DANH SÁCH TÀI KHOẢN -->
                    <Table 
                        v-on:emitShowFormUserditFromTable="excecuteShowFromUserEdit"
                        v-on:emitShowFormChangePasswordFromTable="compShowFromChangePassword"
                        v-on:emitShowFormResetPasswordFromTable="compShowFromResetPassword" />
            </div>
        </section>
        <!-- / MAIN content -->
	</div>
</template>

<script>

    // Component
    import FormAddUser from './FormAddUser.vue'
    import IndexHeader from './IndexHeader.vue'
    import Table from './Table.vue'
    import FormEditUser from './FormEditUser.vue';
    import ChangePassword from './FormChangePassword.vue';
    import ResetPassword from './FormResetPassword.vue';

    import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'admin-group-manage',
        components: {
            FormAddUser,
            IndexHeader,
            Table,
            FormEditUser,
            ChangePassword,
            ResetPassword
        },
        async created(){
            var user = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_code: this.getCurrentUser.location_code,
                location_level_code: this.getCurrentUser.location_level_code
            }

            var user_json = JSON.stringify(user)
            await this.actGetUserByRole(user_json)
            await this.actGetListProvince()
        },
        computed: {
            ...mapGetters([
                'getCurrentUser',
                'getListProvince'
            ])
        },
        methods: {
            ...mapActions([
                'actGetUserByRole',
                'actGetListProvince'
            ]),

            excecuteShowFromUserEdit(user_id){
                this.$refs.formUserEdit.getUserEditInfo(user_id)
            },

            compShowFromChangePassword(user_id){
                this.$refs.formChangePassword.openFormChangePassword(user_id)
            },

            compShowFromResetPassword(user_id){
                this.$refs.formResetPassword.openFormResetPassword(user_id)
            }

        },
    }
</script>


<style scoped>
    .mya {
        text-decoration: none;
        color: #138496;
    }

    .mya:hover {
        color: #138496;
        text-decoration: none;
    }

    /* badge padding */
    .mbadge-pd {
        padding: 6px 15px;
    }

    /* ICON */
    .icon-mr {
        margin-right: 8px;
    }

    .txt-center {
        text-align: center;
    }

    .icon-cl-del {
        color: #f3722c;
    }

    .icon-cl-edit {
        color: #05668d;
    }

</style>