import { parseJwt } from '../../helper/index'

export default {

    getListBehavior: state => { 
        return state.list_behavior
    },

    listLocationLevel: state => { 
        return state.list_location_level
    },

    listOrgByLocationLevel: state => { 
        return state.list_org_by_location_level
    },

    getNotifyResult: state => { 
        state.list_notify_result.forEach((obj, index) => {
            state.list_notify_result[index].date_created = moment(obj.date_created).format('DD-MM-YYYY') 
        });
        return state.list_notify_result
    },

}