<template>
	<div class="wrapper">
		<!-- Navbar -->
		<nav class="main-header navbar navbar-expand navbar-white navbar-light" style="z-index: 1">
			<!-- Left navbar links -->
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
				</li>
				<!-- <li class="nav-item d-none d-sm-inline-block"> TRANG QUẢN TRỊ TÀI KHOẢN  </li> -->
			</ul>
		</nav>
		<!-- /.navbar -->
	</div>
</template>

<script>
	export default {
		name: 'manage-user-header'
	}
</script>

<style scoped>
	li.nav-item > a.nav-link.router-link-active.active:hover,
	li.nav-item > a.nav-link.router-link-exact-active.active {
		background-color: #e5e5e5;
		color: black;
		border-radius: 2px;
		cursor: pointer;
	}
</style>