

export default {
    ADD_USER(state, data) {
        state.user_all = data.user_all
        state.user_id_inserted = data.user_id_inserted
    },

    GET_ALL_USER(state, data) {
        state.user_all = data.user_all
        state.number_user = data.number_user
    },

    GET_USER_PHONE_BOOK(state, data) {
        state.user_phone_book = data.user_phone_book
    },

    GET_USER_BY_LOCATION_LEVEL_FROM_MANAGE_USER(state, data) {
        state.user_all = data.user_all
    },

    GET_USER_BY_LOCATION_LEVEL(state, data) {
        state.user_phone_book = data.user_phone_book
    },

    GET_EDIT_USER(state, data) { // Lấy thông tin user về để edit thông tin
        if (data.user_edit.location_level_code === 4){// Cấp huyện
            state.list_district_by_province = data.list_district
            state.user_edit = data.user_edit

        }else{ // Cấp toàn quốc, vùng, tỉnh
            state.user_edit = data.user_edit
        }
    },

    GET_ALL_USER_EDIT(state, data) {
        state.user_all = data.user_all
        state.user_id_edited = data.user_id_edited
    },

    GET_NUMBER_USER_BY_ROLE(state, data) {
        state.total_user = data.total_user
        state.fpd_user = data.fpd_user
        state.zone_user = data.zone_user
        state.province_user = data.province_user
        state.district_user = data.district_user
    },

    GET_NUMBER_USER_BY_LOCATION_LEVEL(state, data) {
        state.total_user = data.total_user
        state.fpd_user = data.fpd_user
        state.zone_user = data.zone_user
        state.province_user = data.province_user
        state.district_user = data.district_user
    },

}