<template>
    <div class="card-header ui-sortable-handle" style="background-color: #F7F7F7;">
        <div class="txt-right">
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive0 ? 'mbtn-active' : ''" @click="handleActiveTab(0)" v-scroll-to="'#general-info'">Thông tin chung</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive1 ? 'mbtn-active' : ''" @click="handleActiveTab(1)" v-scroll-to="'#base'">Căn cứ</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive2 ? 'mbtn-active' : ''" @click="handleActiveTab(2)" v-scroll-to="'#article11'">Điều 1 / Phần 1</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive3 ? 'mbtn-active' : ''" @click="handleActiveTab(3)" v-scroll-to="'#article12'">Điều 1 / Phần 2</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive4 ? 'mbtn-active' : ''" @click="handleActiveTab(4)" v-scroll-to="'#article2'">Điều 2</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive5 ? 'mbtn-active' : ''" @click="handleActiveTab(5)" v-scroll-to="'#article3'">Điều 3</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive6 ? 'mbtn-active' : ''" @click="handleActiveTab(6)">Lưu quyết định</button>
            <button type="button" class="btn btn-default mbtn-navbar " :class="isActive7 ? 'mbtn-active' : ''" @click="handleActiveTab(7)">Hủy lưu</button>
            <!-- v-scroll-to="'#save'" -->
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'navbar-form-add',
    data() {
        return {
            entity_type_id: 1,  // Mặc định là cá nhân
            isActive0: true,
            isActive1: false,
            isActive2: false,
            isActive3: false,
            isActive4: false,
            isActive5: false,
            isActive6: false,
            isActive7: false
		}
    },
    computed: {
        ...mapGetters([
            
        ])
    },
    methods: {
        ...mapActions([]),

        handleActiveTab(id_tab){
            if(id_tab === 0){
                this.isActive0 = true
                this.isActive1 = false
                this.isActive2 = false
                this.isActive3 = false
                this.isActive4 = false
                this.isActive5 = false
                this.isActive6 = false
                this.isActive7 = false
            }

            if(id_tab === 1){
                this.isActive0 = false
                this.isActive1 = true
                this.isActive2 = false
                this.isActive3 = false
                this.isActive4 = false
                this.isActive5 = false
                this.isActive6 = false
                this.isActive7 = false
            }

            if(id_tab === 2){
                this.isActive0 = false
                this.isActive1 = false
                this.isActive2 = true
                this.isActive3 = false
                this.isActive4 = false
                this.isActive5 = false
                this.isActive6 = false
                this.isActive7 = false
            }

            if(id_tab === 3){
                this.isActive0 = false
                this.isActive1 = false
                this.isActive2 = false
                this.isActive3 = true
                this.isActive4 = false
                this.isActive5 = false
                this.isActive6 = false
                this.isActive7 = false
            }

            if(id_tab === 4){
                this.isActive0 = false
                this.isActive1 = false
                this.isActive2 = false
                this.isActive3 = false
                this.isActive4 = true
                this.isActive5 = false
                this.isActive6 = false
                this.isActive7 = false
            }

            if(id_tab === 5){
                this.isActive0 = false
                this.isActive1 = false
                this.isActive2 = false
                this.isActive3 = false
                this.isActive4 = false
                this.isActive5 = true
                this.isActive6 = false
                this.isActive7 = false
            }

            if(id_tab === 6){
                this.isActive0 = false
                this.isActive1 = false
                this.isActive2 = false
                this.isActive3 = false
                this.isActive4 = false
                this.isActive5 = false
                this.isActive6 = true
                this.isActive7 = false

                // Thực hiện lưu quyết định xử phạt
                this.savePenaltyDecision()
            }

            if(id_tab === 7){
                this.isActive0 = false
                this.isActive1 = false
                this.isActive2 = false
                this.isActive3 = false
                this.isActive4 = false
                this.isActive5 = false
                this.isActive6 = false
                this.isActive7 = true

                // Hủy lưu quyết định xử phạt
                this.confirmCancelSavePenaltyDecision()
            }
        },

        savePenaltyDecision(){
            this.$emit('savePenaltyDecision')
        },

        confirmCancelSavePenaltyDecision(){
            this.$emit('confirmCancelSavePenaltyDecision')
        },
    }
}
</script>

<style scoped>

/* Active navbar */
/* .mbtn-navbar{
    margin-right: 80px;
} */

.mbtn-navbar{
    margin-right: 0px;
    width: 150px;
    height: 37px;
}

.mbtn-active{
    /* background-color: #E9ECEF; */
    border-bottom: 2px solid #7585ad;
}

</style>