<template>
    <!-- style="font-size: 14px;" -->
    <div style="font-size: 14.5px;">
        <div class="card" style="border-radius: 0px" id="report">
            <!-- Navbar report -->
            <Navbar
                v-on:emitEntityType="setEntityType"
            />   

            <!-- Tra cứu kết quả xử phạt -->
            <!-- 1. Cá nhân -->
            <FormPenaltyIndividual 
                v-if="entity_type_id === 1"
                v-on:emitSetDateTypeIndividual="setDateTypeIndividual"
            />

            <!-- 2. Tổ chức -->
            <FormPenaltyOrganization 
                v-if="entity_type_id === 2"
                v-on:emitSetDateTypeOrganization="setDateTypeOrganization"
            />
            
        </div>

        <!-- DANH SÁCH KẾT QUẢ XỬ PHẠT -->
        <!-- 1. Cá nhân -->
        <TablePenaltyIndividual
            v-if="entity_type_id === 1"
            ref="individual"
        />

        <!-- 2. Tổ chức -->
        <TablePenaltyOrganization
            v-if="entity_type_id === 2"
            ref="organization"
        />
        
    </div>
</template>

<script>
    import Navbar from './Navbar.vue'
    import FormPenaltyIndividual from './FormPenaltyIndividual.vue'
    import FormPenaltyOrganization from './FormPenaltyOrganization.vue'
    import TablePenaltyIndividual from './TablePenaltyIndividual.vue'
    import TablePenaltyOrganization from './TablePenaltyOrganization.vue'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'comp-penalty-result',
        components: {
            Navbar,
            FormPenaltyIndividual,
            TablePenaltyIndividual,
            FormPenaltyOrganization,
            TablePenaltyOrganization
        },
        data() {
            return {
                entity_type_id: 1,           // Mặc định hiển thị là cá nhân
            }
        },
        computed: {
			...mapGetters([
                'isSuperAdmin'
			])
		},
		methods: {
			...mapActions([ ]),

			setEntityType(entity_type_id){
				this.entity_type_id = parseInt(entity_type_id)
			},

            setDateTypeIndividual(date_type){
                this.$refs.individual.setDateType(date_type)
            },

            setDateTypeOrganization(date_type){
                this.$refs.organization.setDateType(date_type)
            }
		}
    }
</script>

<style scoped>


</style>