<template>
	<div>
		<div class="mycenter_div mgt-50">
			<div class="txt-center"><b-img :src="this.$ASSETS_PATH + '/img/icon/reset-password.jpg'" width="80px"></b-img></div>
			<div class="txt-center sub-header">KHÔI PHỤC MẬT KHẨU</div>

			<!-- Form nhập thông tin khôi phục mật khẩu -->
			<div style="padding: 10px 20px 0px 20px; margin-top:10px;" v-if="!isShowMessage">
				<p class="mytitle">Nhập mật khẩu mới cho tài khoản <span class="bold">{{user_name}}</span>.</p>
				<p v-if="isNewPasswordError"> <code class="txt-right">{{ msgValidateNewPassword }}</code></p>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
						<span class="input-group-text"><i class="fas fa-lock"></i></span>
					</div>

					<input v-if="showNewPassword" v-model="new_password" type="text" class="form-control" placeholder="Nhập mật khẩu mới" autocomplete="off">
					<input v-else v-model="new_password" type="password" class="form-control" placeholder="Nhập mật khẩu mới" autocomplete="off">
					<div class="input-group-prepend" @click="toggleShowNewPassword">
						<span class="input-group-text" style="background-color: white;">
							<i class="fas" :class="{ 'fa-eye-slash': showNewPassword, 'fa-eye': !showNewPassword }"></i>
						</span>
					</div>
				</div>
			</div>
			<!-- /Form nhập thông tin khôi phục mật khẩu -->

			<!-- Thông báo khôi phục mật khẩu thành công -->
			<div style="margin: 30px;" v-if="isShowMessage">
				<i class="fas fa-check-circle fa-2x mclass-password" style="margin-bottom: 8px;"></i>
				<br/>
				<p>{{message}} <span class="bold">{{email}}</span>.</p>
				
			</div>
			<!-- /End cảnh báo nhập sai địa chỉ Email -->


			<!-- Tiến hành khôi phục -->
			<div class="modal-footer justify-content-between" style="border: none;">
				<b-button size="sm" class="login"
					v-if="!isShowMessage"
					@click="handleResetPassword()"> Thực hiện khôi phục</b-button>
				
				<!-- Hủy khôi phục -->
				<button type="button" 
					class="btn btn-secondary mybtn-cancel" style="margin-top: 15px; height: 31px;"
					v-if="!isShowMessage"
					v-on:click.prevent="cancelResetPassword"> Hủy </button>

				<!-- Đăng nhập sau khi khôi phục thành công -->
				<b-button size="sm" class="login"
					v-if="isShowMessage"
					@click="cancelResetPassword()"> Đăng nhập</b-button>

			</div>
		</div>
		
	</div>
</template>

<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'reset-password',
		data() {
			return {
				// Is show validate warning
				isNewPasswordError: false,

				// Messade validate
				msgValidateNewPassword: '',

				// Value			
				new_password: '', 
				user_id: '',

				// Show / Hide password
				showNewPassword: '',

				// Kết quả khôi phục mật khẩu
				full_name: '',
				user_name: '',
				email: '',
				isShowMessage: false,
				message: ''
			}
		},
		async created(){
			var user_id = this.$route.query.key
			this.user_id = user_id

			await this.actGetUserInfoById(user_id).then((userInfo) => {
					// console.log(userInfo)

					// Lấy thông tin nhóm cần xóa
					this.full_name = userInfo.data.full_name
					this.user_name = userInfo.data.user_name
					this.email     = userInfo.data.email
			})
		},
		watch: { // Sử dụng lắng nghe khi người dùng thay đổi giá trị trong Form
			new_password: function (val) {
				if(this.isNewPasswordError === true & val.length > 0) this.isNewPasswordError = false
			}

		},
		methods: {
			...mapActions([
				'actGetUserInfoById',
				'actResetPasswordViaEmailUpdatePassword'
			]),

			openFormResetPassword(user_id){
				// console.log('user id === ', user_id)
				this.user_id = user_id
				this.$refs.formResetPassword.click()
			},

			toggleShowNewPassword() {
				this.showNewPassword = !this.showNewPassword;
			},

			cancelResetPassword(){
				this.new_password = ''
				this.$router.push({name:"login"})
			},

			// Tạo nhóm
			handleResetPassword(){
				if (this.new_password === '') { //this.new_password === '' || 
					this.isNewPasswordError = true
					this.msgValidateNewPassword = 'Chưa nhập mật khẩu mới!'
				}

				if (this.new_password !== '' && this.new_password.length < 6 ) { //this.new_password === '' || 
					this.isNewPasswordError = true
					this.msgValidateNewPassword = 'Mật khẩu tối thiểu phải có 6 ký tự!'
				}

				if(this.new_password.length >= 6){
					var data = {
						user_id: this.user_id,
						new_password: this.new_password,
						full_name: this.full_name,			// Gửi lên server để có thông tin để gửi thư
						user_name: this.user_name,			// Gửi lên server để có thông tin để gửi thư
						email: this.email			        // Gửi lên server để có thông tin để gửi thư
					}
					// console.log('req data = ', data)

					this.actResetPasswordViaEmailUpdatePassword(data).then((res)=>{

						if(res.ok === true){
							// Bỏ giá trị đang lưu ở form
							this.new_password = ''
							this.user_name = ''
							this.full_name = ''

							// Hiển thị câu thông báo thành công
							this.isShowMessage = true
							this.message = res.message

						}else{ // Nhập sai mật khẩu cũ hoặc mật khẩu mới và cũ trùng nhau
							toastr.error(`${res.error}`,"Khôi phục mật khẩu")
						}
					})
				}
			}
		}
		
	}
</script>

<style scoped>
	.mycenter_div{ /* Điều chỉnh hộp login vào giữa */
		margin: 0 auto;
		width: 600px /* value of your choice which suits your alignment */;
		border: 1px solid #ced4da;
		padding: 30px;
		border-radius: 5px;
		background-color: #f8f9fa; 
	}

	.main-header {
		font-weight: 500;
		color: #264653;
	}

	.sub-header {
		color: #264653;
		font-weight: 500;
	}

	.email {
		margin-top:20px;
		font-size: 14px;
		color: #264653;
	}

	.password {
		margin-top:20px;
		font-size: 14px;
		color: #264653;
	}

	.forget-password {
		font-size: 13px;
	}

	.txt-right.forget-password > a{
		text-decoration: none;
		color: #264653;
	}

	.txt-right.forget-password > a:hover{
		text-decoration: none;
		color: #6a040f;
	}

	::placeholder {
		font-size: 13px;
	}

	.mgt-15{
		margin-top: 15px;
	}

	.mgt-50 {
		margin-top: 80px;
	}

	.login {
		margin-top: 20px;
		background-color: #17A2B8;
		border: #17A2B8;
	}

	.size-icon {
		width: 43px;
	}

</style>