<template>
    <div>
        <div class="card" style="border-radius: 0px">
            <div class="card-header ui-sortable-handle">
                <h3 class="card-title">
                    <i class="ion ion-clipboard mr-1"></i>
                    Thông tin chung
                </h3>
                <i class="fas fa-backspace scroll-up fa-lg txt-right micon" @click="resetForm()"></i>
            </div>
            <div class="card-body">
                <div class="row" style="margin-top: 10px">
                    <!-- Đối tượng xử phạt -->
                    <div class="col-md-6">     
                        <div class="mheader-3"><i class="fas fa-user-friends micon"></i> 
                        <span> Đối tượng bị xử phạt <sup><code>(*)</code></sup> </span>	</div>
                        <multiselect
                            id="entity_type_id"
                            track-by="entity_type_id" label="entity_type_name" 
                            @input="handleGetEntityType()"
                            v-model="entity_type_selected" 
                            open-direction="bottom"
                            placeholder="Cá nhân, tổ chức"
                            :options="getEntityType" 
                            :multiple="false"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :show-pointer="true"
                            :hide-selected="true">
                        </multiselect>
                    </div>

                     <!-- Nơi để xảy ra vi phạm -->
                    <div class="col-md-6">     
                        <div class="mheader-3"><i class="fas fa-globe-africa micon"></i> 
                        Tỉnh (nơi xảy ra vi phạm) <sup><code>(*)</code></sup></div>
                        <multiselect
                            id="province"
                            track-by="province_code" label="province_name" 
                            @input="handleGetProvince()"
                            v-model="province_selected" 
                            open-direction="bottom"
                            placeholder="Chọn tỉnh"
                            :options="getListProvince" 
                            :multiple="false"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :show-pointer="true"
                            :hide-selected="true">
                        </multiselect>
                    </div>
                </div>

                <!-- Tổ chức ra quyết định xử phạt -->
                <div class="row" style="margin-top: 10px">
                    <div class="col-md-6">     
                        <div class="mheader-3"><i class="fas fa-university micon"></i> 
                        Đơn vị ra quyết định xử phạt <sup><code>(*)</code></sup></div>
                        <multiselect
                            id="organization_group_id"
                            track-by="organization_group_id" label="organization_group_name" 
                            @input="handleGetOrganizationGroup()"
                            v-model="organization_group_selected" 
                            open-direction="bottom"
                            placeholder="Chọn đơn vị ra quyết định xử phạt"
                            :options="getListOrganizationGroup" 
                            :multiple="false"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="true"
                            :show-pointer="true"
                            :hide-selected="true">
                        </multiselect>
                    </div>
                </div>
                <div id="base" style="margin-bottom: 20px;"></div>

            </div>
        </div>
    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    export default {
        name: 'vd-general-info',
        data() {
            return {
                // Cá nhân / Tổ chức (1)
                entity_type_id: '',                         // * Mặc định hiển thị nhập thông tin cá nhân xử phạt
                entity_type_name: '',                       
                entity_type_selected: [],

                // Tỉnh (select tỉnh --> gắn vào mã code nơi để xảy ra vi phạm)
                province_code: '',                 // * Mã tỉnh: nơi để xẩy ra vi phạm
                province_name: '',                 // Tên tỉnh: nơi để xảy ra vi phạm
                province_selected: [],             // Tỉnh lựa chọn (3)

                // Tổ chức ra quyết định xử phạt
                organization_group_id: '',          // * Mã tổ chức ra quyết định xử phạt 
                organization_group_name: '',        
                organization_group_selected: [],

            }
        },
        created() {
            // Lấy bảng định nghĩa cá nhân, tổ chức
            this.actGetEntityType()                
            // Lấy bảng danh sách tổ chức lập quyết định xử phạt
            this.actGetOrganizationGroup()          // To set organization_group_id
        },
        computed: {
			...mapGetters([
                'getEntityType',
                'getListProvince',
                'getListOrganizationGroup'
			]),
            
		},
		methods: {
			...mapActions([ 
                'actGetEntityType',
                'actGetOrganizationGroup'
            ]),

            // ADD
            // ================================================================================================
			resetForm(){ // reset all fields
                // Đối tượng bị xử phạt
                this.entity_type_id = 1,            
                this.entity_type_name = '',            
                this.entity_type_selected = [],   

                this.resetFormExceoptEntityType()
            },

            resetFormExceoptEntityType(){         
                // Tỉnh
                this.province_code = ''                 
                this.province_name = ''                 
                this.province_selected = []  
                
                // Đơn vị ra quyết định xử phạt 
                this.organization_group_id = ''
                this.organization_group_name = ''
                this.organization_group_selected = []
            },

            handleGetProvince(){ // Set place_happen_violation
				this.province_code = this.province_selected.province_code
				this.province_name = this.province_selected.province_name
			},

            handleGetEntityType(){
                this.entity_type_id = this.entity_type_selected.entity_type_id
                this.entity_type_name = this.entity_type_selected.entity_type_name
                this.$emit('emitEntityType', this.entity_type_id)
            },

            handleGetOrganizationGroup(){
                this.organization_group_id = this.organization_group_selected.organization_group_id
                this.organization_group_name = this.organization_group_selected.organization_group_name
            },

            // EDIT
            // ================================================================================================
            setEdit(data){
                // console.log("data setEdit ===", data)
                
                // Đối tượng xử phạt
                this.entity_type_id = data.entity_type_id
                this.entity_type_selected.push({
                    entity_type_id: data.entity_type_id,
                    entity_type_name: data.entity_type_name
                })
                
                // Tỉnh (nơi xảy ra vi phạm)
                this.province_code = data.province_code
                this.province_name = data.province_name
                this.province_selected.push({
                    province_code: data.province_code,
                    province_name: data.province_name
                })

                // Đơn vị ra quyết định xử phạt
                this.organization_group_id = data.organization_group_id
                this.organization_group_name = data.organization_group_name
                this.organization_group_selected.push({
                    organization_group_id: data.organization_group_id,
                    organization_group_name: data.organization_group_name
                })
            }
		}
    }
</script>

<style scoped>

.mheader-3{
    color: #5C677D;
    margin-bottom: 5px;
}

</style>