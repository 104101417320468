import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
import ProgressBar from 'primevue/progressbar';

// config path
import './config/path'

// plugin
import './plugin/index'
Vue.config.productionTip = false
Vue.use(PrimeVue);
Vue.component('ProgressBar', ProgressBar);


new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
});

