<template>
    <div>
        <div class="card" style="border-radius: 0px; margin-top: 30px;">
            <div class="card-header ui-sortable-handle" style="padding-top: 15px;">
                <h3 class="card-title">
                    <i class="ion ion-clipboard mr-1"></i>
                    Điều 3
                </h3>
            </div>
            <div class="card-body">

                <!-- I. MẪU QUYẾT ĐỊNH -->
                <div class="mheader-1"> <i class="fas fa-file-word fa-lg"></i> Mẫu quyết định </div>
                
                <!-- <span class="bold">{{penaltied_person_name}}</span> -->
                <div> 1. Giao cho ông (bà) 
                    <span v-html="html_penaltied_person_name"></span> là 
                    {{penaltied_type}} bị xử phạt có tên tại Điều 1 Quyết định này để chấp hành. </div>

                <div> {{penaltied_person_upper_no}} có tên tại Điều 1 phải nghiêm chỉnh chấp hành Quyết định xử phạt này. Nếu quá thời hạn mà {{penaltied_person_lower}} <span class="bold">{{penaltied_person_organization_name}}</span> không tự nguyện chấp hành thì sẽ bị cưỡng chế thi hành theo quy định của pháp luật. </div>

                <!-- 1_Nơi nộp tiền phạt tại (19) -->
                <div> a) {{penaltied_person_upper_has}} bị xử phạt có tên tại Điều 1 
                phải nộp tiền phạt tại 
                <span class="num1 txt-center " :class="lplace_pay_violation_money">1</span> 
                <span v-html="html_place_pay_violation_money"></span>, 
                
                <!-- 2_Tài khoản số (20) -->
                hoặc nộp tiền phạt vào tài khoản số: 
                <span class="num1 txt-center " :class="lviolation_account_number">2</span> 
                <span v-html="html_violation_account_number"></span>,
                 
                <!-- * 3_Tên ngân hàng thu tiền nộp phạt (21) -->
                của 
                <span class="num1 txt-center " :class="lstate_treasury_name">3</span> 
                <span v-html="html_state_treasury_name"></span>,
                
                <!-- * 4_Thời hạn nộp phạt -->
                trong thời hạn 
                <span class="num1 txt-center " :class="lduration_pay_fine">4</span> 
                <span v-html="html_duration_pay_fine"></span>
                ngày, kể từ ngày nhận được Quyết định này. </div>
                
                <!-- * 5_Tên tang vật, phương tiện bị giữ (22) -->
                <div> Hoặc {{penaltied_person_lower}} bị xử phạt có tên tại Điều 1 nộp tiền phạt tại chỗ cho người đã ra quyết định xử phạt theo quy định tại khoản 2 Điều 78 Luật Xử lý vi phạm hành chính.</div>
                <div> b) {{penaltied_person_upper_has}} bị xử phạt có tên tại Điều 1 bị tạm giữ: 
                <span class="num1 txt-center " :class="lkept_exhibit_name">5</span>
                <span v-html="html_kept_exhibit_name"></span>

                để bảo đảm thi hành quyết định xử phạt. </div>
                <div> c) {{penaltied_person_upper_no}} <span class="bold">{{ penaltied_person_organization_name }}</span> có quyền khiếu nại hoặc khởi kiện hành chính đối với Quyết định này theo quy định của pháp luật. </div>
                
                <!-- * 3_Tên ngân hàng thu tiền nộp phạt (21) -->
                <div> 2. Gửi cho 
                    <span class="num1 txt-center " :class="lstate_treasury_name">3</span> 
                    <span v-html="html_state_treasury_name"></span> 
                để thu tiền phạt. </div>
               
               <!-- * 6_Tên cá nhân/tổ chức thực hiện thi hành quyết định (23).....để tổ chức thực hiện -->
               <div> 3. Gửi cho 
                    <span class="num1 txt-center " :class="lorganization_implement_decision">6</span> 
                    <span v-html="html_organization_implement_decision"></span>
                để tổ chức thực hiện. </div>
                
                <!-- 7_Tên cơ quan ..... để biết và phối hợp thực hiện -->
                <div> 4. Gửi cho 
                    <span class="num1 txt-center " :class="lorganization_relation_violator">7</span> 
                    <span v-html="html_organization_relation_violator"></span>
                để biết và phối hợp thực hiện ./.</div>

                
                <div class="row" style="margin-top: 30px">
                    <!-- Nơi nhận -->
                    <div class="col-md-2">
                        <div class="bold italic">Nơi nhận:</div>
                        <div>-Như Điều 3;</div>
                        <div>-Lưu: Hồ sơ.</div>
                    </div>

                    <!-- Người ký -->
                    <div class="col-md-6"></div>
                    <div class="col-md-4 txt-center">
                        <div class="bold">
                            <span class="num1 txt-center " :class="lposition_signer">8</span> 
                            <span v-html="html_position_signer"></span>
                        </div>
                        <div>(Ký, đóng dấu (nếu có); ghi rõ họ và tên)</div>
                        <div style="margin-top: 30px">
                        <span class="num1 txt-center " :class="lsigned_person_name">9</span> 
                        <span v-html="html_signed_person_name"></span>
                        </div>
                    </div>
                </div>

                <!-- Người đại diện bị xử phạt -->
                <div class="row">
                    <div style="margin-top: 50px">Quyết định đã giao trực tiếp cho {{penaltied_type}} bị xử phạt vào 
                    hồi 
                        <span class="num1 txt-center " :class="lhour_violator_get_decision">10</span> 
                        <span v-html="html_hour_violator_get_decision"></span>
                        giờ 

                        <span class="num1 txt-center " :class="lminute_violator_get_decision">11</span> 
                        <span v-html="html_minute_violator_get_decision"></span>
                        phút, 
                        ngày 
                        <span class="num1 txt-center " :class="ldate_violator_get_decision">12</span> 
                        <span v-html="html_date_violator_get_decision_show"></span>
                    </div>
                </div>

                <!-- hr line -->
                <!-- <hr class="mtop-bottom-40"> -->
            </div>

            <!-- I.2. NHẬP THÔNG TIN -->
            <!-- ========================================================================== -->
            <div class="card-body mbg-input">

                <div class="mheader-2"> 
                    <i class="fas fa-marker fa-lg"></i> Nhập thông tin 
                    <i class="fas fa-backspace scroll-up fa-lg txt-right micon" @click="resetForm()"></i>
                </div>
                
                <div class="row">
                    <!-- (19) Nộp tiền phạt tại -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-map-marker-alt micon"></i> 
                            Tên, địa chỉ tổ chức thu tiền phạt 
                            <span class="mybadge txt-right">1</span></p>
                            
                        <div class="input-group mb-3">
                            <input v-model="place_pay_violation_money" type="text" class="form-control" placeholder="Nhập tên, địa chỉ Kho bạc nhà nước/Ngân hàng được ủy nhiệm thu tiền phạt" autocomplete="off">
                        </div>
                    </div>

                    <!-- (20) Nộp tiền phạt vào tài khoản số -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-list-ol micon"></i> 
                            Số tài khoản cá nhân/tổ chức bị xử phạt nộp tiền phạt
                            <span class="mybadge txt-right">2</span></p>

                        <div class="input-group mb-3">
                            <input v-model="violation_account_number" type="number" class="form-control" placeholder="Nhập số tài khoản mà cá nhân/tổ chức bị xử phạt nộp tiền phạt" autocomplete="off">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- (21) 3. Kho bạc nhà nước -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-university micon"></i> 
                            Tên kho bạc Nhà nước
                            <span class="mybadge txt-right">3</span></p>
                            
                        <div class="input-group mb-3">
                            <input v-model="state_treasury_name" type="text" class="form-control" placeholder="Nhập tên kho bạc nhà nước" autocomplete="off">
                        </div>
                    </div>

                    <!-- 4. Thời hạn bao nhiêu ngày -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-calendar-alt micon"></i> 
                            Thời hạn nộp sau khi nhận được quyết định xử phạt
                            <span class="badge " :class="duration_pay_fine_waring" v-if="duration_pay_fine"><strong> {{duration_pay_fine | formatNumber}} (ngày) </strong></span>
                                            <span v-if="!duration_pay_fine"> (ngày)</span>
                            <span class="mybadge txt-right">4</span></p>
                            
                        <div class="input-group mb-3">
                            <input v-model="duration_pay_fine" type="number" class="form-control" placeholder="Nhập thời hạn nộp phạt (ngày)" autocomplete="off">
                        </div>
                    </div>

                </div>

                <div class="row">
                    <!-- 5. Giấy tờ, chủng loại tang vật vi phạm -->
                    <div class="form-group">
                        <p> <i class="fas fa-newspaper micon"></i>
                            Giấy tờ, chủng loại tang vật, phương tiện vi phạm bị tạm giữ  <span class="mybadge txt-right">5</span> </p>
                        <textarea class="form-control" rows="3" 
                            v-model="kept_exhibit_name"
                            placeholder="Ghi giấy tờ, chủng loại tang vật, phương tiện vi phạm bị tạm giữ"></textarea>
                    </div>
                </div>

                <div class="row">
                    <!-- 6. Ghi tên kho bạc nhà nước -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-users micon"></i> 
                            Họ và tên của cá nhân/tên tổ chức chủ trì thi hành Quyết định
                            <span class="mybadge txt-right">6</span></p>
                            
                        <div class="input-group mb-3">
                            <input v-model="organization_implement_decision" type="text" class="form-control" placeholder="Nhập họ và tên của cá nhân/tên tổ chức chủ trì thi hành quyết định" autocomplete="off">
                        </div>
                    </div>

                    <!-- 7. Họ và tên của cá nhân/tên của cơ quan, tổ chức có liên quan -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-users micon"></i> 
                            Họ và tên của cá nhân/tên của cơ quan, tổ chức có liên quan
                            <span class="mybadge txt-right">7</span></p>
                            
                        <div class="input-group mb-3">
                            <input v-model="organization_relation_violator" type="text" class="form-control" placeholder="Nhập họ và tên của cá nhân/tên của cơ quan, tổ chức có liên quan" autocomplete="off">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- 8. Chức vụ của người ký -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-user-tie micon"></i> 
                            Chức vụ của người ký
                            <span class="mybadge txt-right">8</span></p>
                            
                        <div class="input-group mb-3">
                            <input v-model="position_signer" type="text" class="form-control" placeholder="Nhập chức vụ của người ký" autocomplete="off">
                        </div>
                    </div>

                    <!-- 9. Họ và tên người ký -->
                    <div class="col-md-6">
                        <p class="mheader-3"> <i class="fas fa-user-tie micon"></i> 
                            Họ và tên người ký quyết định
                            <span class="mybadge txt-right">9</span></p>
                            
                        <div class="input-group mb-3">
                            <input v-model="signed_person_name" type="text" class="form-control" placeholder="Nhập họ và tên người ký quyết định xử phạt" autocomplete="off">
                        </div>
                    </div>

                </div>
                <div class="row">
                    <!-- 10 / 11 / 12 Thời gian giao quyết định xử phạt -->
                    <div class="col-md-12" id="save">
                        <p class="mheader-3"> <i class="far fa-clock micon"></i>
                                Thời gian giao quyết định xử phạt 
                                <span class="mybadge txt-right">12</span>
                                <span class="mybadge txt-right" style="margin-right: 5px;">11</span>
                                <span class="mybadge txt-right" style="margin-right: 5px;">10</span></p>
                        <div class="row">
                            <!-- 10. Giờ -->
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                    <input v-model="hour_violator_get_decision" type="number" min="0" max="24" class="form-control" placeholder="Giờ: 0 - 24" autocomplete="off">
                                </div>
                            </div>
                            <!-- 11. Phút -->
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                    <input v-model="minute_violator_get_decision" type="number" min="0" max="59" class="form-control" placeholder="Phút: 0 - 59" autocomplete="off">
                                </div>
                            </div>
                            <!-- 12. Ngày -->
                            <div class="col-md-6">  
                                    <v-date-picker 
                                        :masks="masks"
                                        :attributes='attrs'
                                        show-weeknumbers
                                        @input="handExtractDate()"
                                        v-model="date_violator_get_decision"
                                        is24hr>
                                        
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="input-group">
                                                <input
                                                    placeholder="Ngày giao: DD-MM-YYYY"
                                                    class="form-control"
                                                    :value="inputValue"
                                                    v-on="inputEvents"
                                                />
                                            </div>
                                        </template>
                                    </v-date-picker>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card-footer clearfix">
                <!-- Lưu quyết định -->
                <button type="button" 
                        style="background-color: #05668D;"
                        class="btn btn-primary btn-sm"
                        @click="savePenaltyDecision()"
                        >
                        <i class="fas fa-save"></i> Lưu quyết định xử phạt </button>

                <!-- Hủy lưu quyết định -->
                <button type="button" 
                        style="border: 1px solid #B03060; background-color: #B03060; color: white;"
                        class="btn btn-default btn-sm float-right"
                        @click="confirmCancelSavePenaltyDecision()"
                        >
                        <i class="fas fa-save"></i> Hủy lưu quyết định xử phạt </button>
            </div>
        </div>
    </div>
</template>

<script>

    import { fixMonthAndDay } from '@/helper/index'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'vd-article3',
        props: [
            'info_penaltied_person'
        ],

        data() {
            return {
                place_pay_violation_money: '',           // * 1_Nơi nộp tiền phạt tại (19)
                violation_account_number: '',            // * 2_Tài khoản số (20)
                state_treasury_name: '',                 // * 3_Tên ngân hàng thu tiền nộp phạt (21)
                duration_pay_fine: '',                   // * 4_Thời hạn nộp phạt
                kept_exhibit_name: '',                   // * 5_Tên tang vật, phương tiện bị giữ (22)
                organization_implement_decision: '',     // * 6_Tên cá nhân/tổ chức thực hiện thi hành quyết định (23).....để tổ chức thực hiện
                organization_relation_violator: '',      // * 7_Tên cơ quan ..... để biết và phối hợp thực hiện
                position_signer: '',                     // * 8_Chức vụ của người ký
                signed_person_name: '',                  // * 9_Họ và tên người ký
                hour_violator_get_decision: '',          // * 10_Giờ giao quyết định xử phạt
                minute_violator_get_decision: '',        // * 11_Phút giao quyết định xử phạt
                date_violator_get_decision: '',          // * 12_Ngày người vi phạm nhận quyết định xử phạt
                date_violator_get_decision_show: '',     // Temp

                // VCalendar
				attrs: [
					{
						key: 'today',
						highlight: true,       // dot
						dates: new Date()
					}
				],
				masks: {
					input: 'DD-MM-YYYY',
				}

            }
        },

        computed: {
            // Hiển thị mẫu quyết định: cá nhân, tổ chức
            penaltied_type(){
                if(this.info_penaltied_person.entity_type_id === 1) return 'cá nhân'
                return 'người đại diện của tổ chức'
            },

            penaltied_person_lower(){
                if(this.info_penaltied_person.entity_type_id === 1) return 'ông (bà)'
                return '' //'tổ chức'
            },

            penaltied_person_upper_has(){ // Có tên tổ chức
                if(this.info_penaltied_person.entity_type_id === 1) return 'Ông (bà)'
                return 'Tổ chức'
            },

            penaltied_person_upper_no(){  // Bỏ tên tổ chức
                if(this.info_penaltied_person.entity_type_id === 1) return 'Ông (bà)'
                return '' //'Tổ chức'
            },

            //  Người vi phạm
            penaltied_person_name(){
                if(this.info_penaltied_person.penaltied_person_name === '') return ''
                return this.info_penaltied_person.penaltied_person_name
            },

            // Lưu ý: penaltied_person_name là computed ở trên
            html_penaltied_person_name: function (){
                if(this.penaltied_person_name === '') return '……………………………………………'
                return '<span class="bold">' + this.penaltied_person_name +'</span>'
            },

            //  Tên cá nhân / tổ chức
            penaltied_person_organization_name(){
                if(this.info_penaltied_person.entity_type_id === 1) return this.info_penaltied_person.penaltied_person_name
                return this.info_penaltied_person.organization_name
            },

            // 1_Nơi nộp tiền phạt tại (19)
            lplace_pay_violation_money: function (){
                if(this.place_pay_violation_money === '') return ''
                return 'hide'
            },

            html_place_pay_violation_money: function (){
                if(this.place_pay_violation_money === '') return '.......................'
                return '<span class="bold">' + this.place_pay_violation_money +'</span>'
            },

            // * 2_Tài khoản số (20)
            lviolation_account_number: function (){
                if(this.violation_account_number === '') return ''
                return 'hide'
            },

            html_violation_account_number: function (){
                if(this.violation_account_number === '') return '.......................'
                return '<span class="bold">' + this.violation_account_number +'</span>'
            },

            // * 3_Tên ngân hàng thu tiền nộp phạt (21)
            lstate_treasury_name: function (){
                if(this.state_treasury_name === '') return ''
                return 'hide'
            },

            html_state_treasury_name: function (){
                if(this.state_treasury_name === '') return '................'
                return '<span class="bold">' + this.state_treasury_name +'</span>'
            },

            // * 4_Thời hạn nộp phạt
            lduration_pay_fine: function (){
                if(this.duration_pay_fine === '') return ''
                return 'hide'
            },

            html_duration_pay_fine: function (){
                if(this.duration_pay_fine === '') return '................'
                return '<span class="bold">' + this.duration_pay_fine +'</span>'
            },

            // * 5_Tên tang vật, phương tiện bị giữ (22)
            lkept_exhibit_name: function (){
                if(this.kept_exhibit_name === '') return ''
                return 'hide'
            },

            html_kept_exhibit_name: function (){
                if(this.kept_exhibit_name === '') return "………………………………………"
                return '<span class="bold">' + this.kept_exhibit_name +'</span>'
            },

            // * 6_Tên cá nhân/tổ chức thực hiện thi hành quyết định (23).....để tổ chức thực hiện
            lorganization_implement_decision: function (){
                if(this.organization_implement_decision === '') return ''
                return 'hide'
            },

            html_organization_implement_decision: function (){
                if(this.organization_implement_decision === '') return '................'
                return '<span class="bold">' + this.organization_implement_decision +'</span>'
            },

            // * 7_Tên cơ quan ..... để biết và phối hợp thực hiện
            lorganization_relation_violator: function (){
                if(this.organization_relation_violator === '') return ''
                return 'hide'
            },

            html_organization_relation_violator: function (){
                if(this.organization_relation_violator === '') return '................'
                return '<span class="bold">' + this.organization_relation_violator +'</span>'
            },

            // 8_Chức vụ người ký
            lposition_signer: function (){
                if(this.position_signer === '') return ''
                return 'hide'
            },

            html_position_signer: function (){
                if(this.position_signer === '') return 'CHỨC VỤ CỦA NGƯỜI KÝ'
                return '<span class="bold">' + this.position_signer +'</span>'
            },

            // 9_Họ và tên người ký
            lsigned_person_name: function (){
                if(this.signed_person_name === '') return ''
                return 'hide'
            },

            html_signed_person_name: function (){
                if(this.signed_person_name === '') return 'Họ và tên người ký'
                return '<span class="bold">' + this.signed_person_name +'</span>'
            },

            // 10_Giờ
            lhour_violator_get_decision: function (){
                if(this.hour_violator_get_decision === '') return ''
                return 'hide'
            },

            html_hour_violator_get_decision: function (){
                if(this.hour_violator_get_decision === '') return '....'
                return '<span class="bold">' + this.hour_violator_get_decision +'</span>'
            },

            // 11_Phút
            lminute_violator_get_decision: function (){
                if(this.minute_violator_get_decision === '') return ''
                return 'hide'
            },

            html_minute_violator_get_decision: function (){
                if(this.minute_violator_get_decision === '') return '....'
                return '<span class="bold">' + this.minute_violator_get_decision +'</span>'
            },

            // 12_Ngày nhận quyết định xử phạt
            ldate_violator_get_decision: function (){
                if(this.date_violator_get_decision === '') return ''
                return 'hide'
            },

            html_date_violator_get_decision_show: function (){
                if(!this.date_violator_get_decision) return '……/……/………'
                return '<span class="bold">' + this.date_violator_get_decision_show +'</span>'
            },

            duration_pay_fine_waring: function () {
            // Nếu nhập đúng
            if(this.duration_pay_fine >= 1 & this.duration_pay_fine <= 10) return "badge-info";
            return "badge-danger";
        }
		},
		methods: {
			...mapActions([ ]),

            // ADD
            // ===========================================================================================
            savePenaltyDecision(){
                this.$emit('savePenaltyDecision')
            },

			handExtractDate(){ // Ngày, tháng, năm sinh
                this.date = fixMonthAndDay(moment(this.date_violator_get_decision).format('DD'))
                this.month = fixMonthAndDay(moment(this.date_violator_get_decision).format('MM'))
                this.year = moment(this.date_violator_get_decision).format('YYYY')
                if(this.date !== '' & this.date !== 'Invalid date'){
                    this.date_violator_get_decision_show = this.date + '/' + this.month + '/' + this.year
                }
            },

            resetForm(){
                this.place_pay_violation_money = ''      
                this.violation_account_number = ''       
                this.state_treasury_name = ''            
                this.duration_pay_fine = ''              
                this.kept_exhibit_name = ''              
                this.organization_implement_decision = ''
                this.organization_relation_violator = '' 
                this.position_signer = ''             
                this.signed_person_name = ''             
                this.hour_violator_get_decision = ''     
                this.minute_violator_get_decision = ''   
                this.date_violator_get_decision = ''     
                this.date_violator_get_decision_show = ''
            },

            confirmCancelSavePenaltyDecision(){
                this.$emit('confirmCancelSavePenaltyDecision')
            },

            // EDIT
            // ===========================================================================================
            setEdit(data){
                console.log("data setEdit ===", data)

                this.place_pay_violation_money          = data.place_pay_violation_money
                this.violation_account_number           = data.violation_account_number      
                this.state_treasury_name                = data.state_treasury_name            
                if(data.duration_pay_fine > 0){
                    this.duration_pay_fine              = data.duration_pay_fine
                }              
                this.kept_exhibit_name                  = data.kept_exhibit_name              
                this.organization_implement_decision    = data.organization_implement_decision
                this.organization_relation_violator     = data.organization_relation_violator 
                this.position_signer                    = data.position_signer
                this.signed_person_name                 = data.signed_person_name  
                
                if(data.hour_violator_get_decision > 0){
                    this.hour_violator_get_decision     = data.hour_violator_get_decision 
                }

                if(data.minute_violator_get_decision > 0){
                    this.minute_violator_get_decision   = data.minute_violator_get_decision
                }
                
                // Ngày giao quyết định xử phạt
                var check_date = parseInt(moment(data.date_violator_get_decision).format('YYYY'))
                var date_violator_get_decision_show = ''

                if(check_date !== 1900 && check_date !== 1899){
                    // Hiển thị ra web
                    this.date = fixMonthAndDay(moment(data.date_violator_get_decision).format('DD'))
                    this.month = fixMonthAndDay(moment(data.date_violator_get_decision).format('MM'))
                    this.year = moment(data.date_violator_get_decision).format('YYYY')
                    date_violator_get_decision_show = this.date + '/' + this.month + '/' + this.year
                    this.date_violator_get_decision_show = date_violator_get_decision_show                      

                    // Lưu vào CSDL
                    this.date_violator_get_decision  = data.date_violator_get_decision         // Lưu vào CSDL
                }
            }

		}
    }
</script>

<style scoped>
    sup {
        vertical-align: super;
        font-size: 12px;
    }

</style>