<template>
	<div>
		<div class="row">

			<!-- DELETE USER -->
			<div class="modal fade" id="public-user-delete">
				<div class="modal-dialog modal-default">
					<div class="modal-content">
						<div class="modal-header bg-warning" style="color: white; background-color: #138496;">
							<h6 class="modal-title"><i class="icon fas fa-ban"></i> Xóa tài khoản </h6>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeConfirmDelete" style="display: none">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div style="padding: 20px"> 
								Xóa tài khoản <span style="color: red; font-weight: bold;"> {{user_name}} </span> sẽ <span style="color: red;">không thể khôi phục</span> lại! Bạn có xóa tài khoản này?
							</div>
						</div>
						<div class="modal-footer justify-content-between">
							<button type="button" class="btn btn-sm" v-on:click.prevent="deleteUser" style="width: 150px; color: white; background-color: #DC5C51;">Xóa</button>
							<button type="button" class="btn btn-sm mybtn-cancel" data-dismiss="modal" style="width: 100px; color: #5C677D; background-color: #E5E5E5;"> Hủy xóa </button>
						</div>
					</div>
				</div>
			</div>

			<button type="button" class="btn btn-default" data-toggle="modal" data-target="#public-user-delete" style="display: none" ref="openConfirmDelete">
                Confirm Delete
            </button>


			<!-- TABLE -->
			<div class="col-12">
				<div class="card" style="margin: 11px;">

					<TableTitle />
					
					<div class="card-body table-responsive">
						<grid
							:cols="header_table" 
							:rows="getAllUser"
							:language="language"
							:pagination="pagination"
							:search="search"
							:sort="sort"
						>
						</grid>
					</div>

				</div>
			</div>
		</div>  
	</div>
</template>

<script>
	import TableTitle from './TableTitle.vue';
	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'user-manage-table-index',
		components: {
			TableTitle
		},
		data() {
			return {
				// User
				user_name: '',					// Tên nhóm
				user_id: '',					// ID nhóm
				isShowMsgSuccess: false,		// Hiển thị bảng thông báo sau khi xóa group thành công.
				isShowEdit: false,				// Chỉ show khi được kích hoạt

				// GridJS: table phone-book
				header_table: [
					{ // 0
						id: 'user_name',
						name: 'Tài khoản',
						width: '12%'
					},
					{ // 1
						id: 'full_name',
						name: 'Đơn vị',
						width: '15%'
					},
					{ // 3
						id: 'address',
						name: 'Địa chỉ liên hệ',
						width: '15%'
					},
					{ // 3
						id: 'mobile',
						name: 'Điện thoại',
						width: '11%'
					},
					{ // 4
						id: 'email',
						name: 'Email',
						width: '20%'
					},
					{ // 5
						id: 'location',
						name: 'Phạm vi xử phạt',
						width: '13%'
					},
					{ // 6
						id: 'get_notify_status',
						name: 'Nhận tin',
						width: '9%',
						formatter: (cell) => { 
							if(cell === false) return 'Không'
							if(cell === true) return 'Có'
						}
					},
					{ // 7
						id: 'user_id',
						name: 'Công cụ',
						width: '17%',
						sort: false,
						formatter: (cell, row) => {  // row: array value of row; cell: value of this cell (user_id)
							// Admin cấp tỉnh
							if((row.cells[8].data >= 3 && row.cells[9].data > 2 && this.isAdminProvince) || this.isSuperAdmin){ // Nếu là tài khoản admin cấp tỉnh (location_level_code === 3 hoặc role_id === 2) thì bỏ công cụ chỉnh sửa
							
								return [ 
									//  Edit
									this.$gridjs.h('button', 
										{
											className: 'mclass',
											onClick: () => this.handleUserEdit(cell)
										}, 
									this.$gridjs.html(`<div class="btn btn-sm">
													   <i class="fas fa-pencil-alt mclass-edit"></i></div>`)),
									//  Delete
									this.$gridjs.h('button', 
										{
											className: 'mclass',
											onClick: () => this.handleUserDelete(cell)
										}, 
									this.$gridjs.html(`<div class="btn btn-sm">
													   <i class="fas fas fa-trash mclass-delete"></i></div>`)),

									//  Reset password
									this.$gridjs.h('button', 
										{
											className: 'mclass',
											onClick: () => this.handResetPassword(cell)
										}, 
									this.$gridjs.html(`<div class="btn btn-sm">
													   <i class="fas fa-lock-open mclass-password"></i></div>`))
								]
							}

							if(this.isSuperAdmin || this.isAdminSystem){ // Nếu là tài khoản admin cấp tỉnh (location_level_code === 3 hoặc role_id === 2) thì bỏ công cụ chỉnh sửa
							
								return [ 
									//  Edit
									this.$gridjs.h('button', 
										{
											className: 'mclass',
											onClick: () => this.handleUserEdit(cell)
										}, 
									this.$gridjs.html(`<div class="btn btn-sm">
													<i class="fas fa-pencil-alt mclass-edit"></i></div>`)),
									//  Delete
									this.$gridjs.h('button', 
										{
											className: 'mclass',
											onClick: () => this.handleUserDelete(cell)
										}, 
									this.$gridjs.html(`<div class="btn btn-sm">
													<i class="fas fas fa-trash mclass-delete"></i></div>`)),
						
									//  Reset password
									this.$gridjs.h('button', 
										{
											className: 'mclass',
											onClick: () => this.handResetPassword(cell)
										}, 
									this.$gridjs.html(`<div class="btn btn-sm">
													<i class="fas fa-lock-open mclass-password"></i></div>`))
								]
							}
						}
					},
					{	// 8: sử dụng để xác định row nào là admin province
						id: 'location_level_code',
						hidden: true
					},
					{	// 9: sử dụng để xác định row nào là admin province
						id: 'role_id',
						hidden: true
					}				
				],

				pagination: true,
				sort: false,
				search: true,
				language: {
					search: {
						placeholder: '🔍 Tìm kiếm ...',
					},
					sort: {
						sortAsc: 'Sắp xếp tăng dần',
						sortDesc: 'Sắp xếp giảm dần',
					},
					pagination: {
						previous: 'Về trước',
						next: 'Kế tiếp',
						navigate: (page, pages) => `Trang ${page} trên tổng ${pages}`,
						page: (page) => `Trang ${page}`,
						showing: 'Hiển thị',
						of: 'trên tổng',
						to: 'từ',
						results: 'kết quả',
					},
					loading: 'Đang load trang ...',
					noRecordsFound: 'Không có kết quả nào được tìm thấy!',
					error: 'Có lỗi xảy ra trong quá trình lấy dữ liệu!',
				},
			}
		},
		computed: {
			...mapGetters([
				'isSuperAdmin',
				'isAdminSystem',
				'isAdminProvince',
				'getAllUser',
				'getCurrentUser'
			])
		},
		methods: {
			...mapActions([
				'actDeleteUser',
				'actGetUserByRole',
				'actGetListOrgByLocationLevel',
				'actGetUserInfoById',
			]),

			// EDIT 
			// ===========================================================================
			handleUserEdit(user_id){
				this.user_id = user_id		// Gán giá trị để hightlight user đang edit
				
				// Xác định cấp quản lý của user: toàn quốc, vùng, tỉnh, huyện
				var user_edit = this.getAllUser.find(obj => obj.user_id = user_id)
				var location_level_code = user_edit.location_level_code

				this.actGetListOrgByLocationLevel(location_level_code).then(()=>{
					this.$emit('emitShowFormUserditFromTable', user_id)
				})
			},


			// DELETE 
			// ===========================================================================
			async handleUserDelete(id){
				// console.log(id)
				await this.actGetUserInfoById(id).then((userInfo) => {
					// Lấy thông tin nhóm cần xóa
					this.user_name = userInfo.data.user_name
					this.user_id = userInfo.data.user_id

					// Hiển thị bảng confirm
					this.$refs.openConfirmDelete.click()
				})
			},

			deleteUser() {
				this.actDeleteUser(this.user_id).then(res => {

					if(res.ok === true){
			
						// Đóng modelConfirm delete
						this.$refs.closeConfirmDelete.click()

						// Mở model thông báo xóa thành công
						toastr.success('', `<div> Xóa tài khoản <span style="font-weight: bold; font-size: 18px;"> ${this.user_name} </span> thành công! </div>`)

						// Cập nhật lại thông tin User
						var user = {
								user_id: this.getCurrentUser.user_id,
								role_id: this.getCurrentUser.role_id,
								location_code: this.getCurrentUser.location_code,
								location_level_code: this.getCurrentUser.location_level_code
							}

						var user_json = JSON.stringify(user)
						this.actGetUserByRole(user_json)
					}else{
						toastr.error(`Không thể xóa tài khoản ${this.user_name}, do đã lập quyết định xử phạt.`,"Xóa tài khoản")
					}
				})
			},

			// CHANGE PASSWORD 
			// ===========================================================================
			handChangePassword(user_id){
				this.$emit('emitShowFormChangePasswordFromTable', user_id)
			},

			handResetPassword(user_id){
				this.$emit('emitShowFormResetPasswordFromTable', user_id)
			},

		
		}
	}
</script>



<style>

	.mclass {
		border-color: white;
		background-color: white;
		border: 0px;
	}

	.mclass-delete {
		color: #dc3545;
	}

	.mclass-edit {
		color: #05668D;
	}

	.mclass-password {
		color: #157347;
	}

	#del-group, #cancel-del {
		margin-top: 40px;
		font-size: 12px;
    	width: 120px;
	}

	.mybtn-cancel {
		width: 150px; 
		padding: 2px; 
		background-color: #FFFFFF; 
		color: black; 
		border: 1px solid #dee2e6 
	}

	.mybtn-cancel:hover {
		width: 150px; 
		padding: 2px; 
		background-color: #E5E5E5; 
		color: black; 
		border: 1px solid #dee2e6 
	}



</style>