<template>
	<div>
		<NotifyResult />
	</div>
</template>

<script>
	import NotifyResult from '@/components/GetNotify/Result/Index.vue'
	export default {
		name: 'page-notify-result',
		components: {
			NotifyResult
		}
	}
</script>

<style scoped>
	

</style>