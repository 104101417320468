import Vue from 'vue';

// Moment
import moment from 'moment'
Vue.prototype.moment = moment

// My plugin
import MyPlugin from './myplugin'
Vue.use(MyPlugin)

// Bootstrap
import './bootstrap'

// CK Editor
import './CKEditor'

// VCalendar
import './Vcalendar'

// Vue-multiselect
import './vue-multiselect'

// Vue scroll to
import './vue-scroll-to'

// Vue GridJs: table
import './GridJs'

// Vue uuid
import './vue-uuid-v4'

// Numeral
import './numeral'

// Download excel
// import './vue-json-excel'          // Đã test: Okie --> xuất ra file .xls; Ưu điểm: có chức năng handle: beforeStart and finishDownload.
import './vue-excel-xlsx'

// Vue fix navbar
import './vue-scroll-fixed-navbar'

// Vue pass data to any component
// import './pass-data-to-any-component'

// Vue highchart
import './highcharts-vue'

// Vue: v-mask
// import './v-mask'

import './primevue'
