<template>
	<!-- Content Wrapper. Contains page content -->
	<div>
		<!-- HEADER -->
            <IndexHeader />
        <!-- / HEADER -->

        <!-- MAIN content -->
        <section class="content">
            <div style="padding-bottom: 30px;">
                <!-- DANH SÁCH TÀI KHOẢN -->
                    <Table /> 
            </div>
        </section>
        <!-- / MAIN content -->
	</div>
</template>

<script>

    // Component
    import IndexHeader from './IndexHeader.vue'
    import Table from './Table.vue'
    import { mapActions } from 'vuex'

	export default {
		name: 'phone-book-index',
        components: {
            IndexHeader,
            Table
        },
        async created(){
            await this.actGetNumberUserByLevelLocation()
            await this.actGetUserPhoneBook()   
        },
        methods: {
            ...mapActions([
                'actGetUserPhoneBook',
                'actGetNumberUserByLevelLocation'       // Sử dụng để thống kê số lượng user theo location_level
            ])
        },
    }
</script>


<style scoped>
    .mya {
        text-decoration: none;
        color: #138496;
    }

    .mya:hover {
        color: #138496;
        text-decoration: none;
    }

    /* badge padding */
    .mbadge-pd {
        padding: 6px 15px;
    }

    /* ICON */
    .icon-mr {
        margin-right: 8px;
    }

    .txt-center {
        text-align: center;
    }

    .icon-cl-del {
        color: #f3722c;
    }

    .icon-cl-edit {
        color: #05668d;
    }

</style>