<template>
    <div>
        <!-- Bộ lọc thời gian -->
        <FormNation v-if="isUserLevelNation" />
        <FormRegion v-if="isUserLevelRegion" />
        <FormProvince v-if="isUserLevelProvince" />
        <FormDistrict v-if="isUserLevelDistrict" />
        
        <div v-if="getDecisionOrganization.total">
            <!-- Thống kê chung -->
            <div class="clearfix"></div>
            <div class="mtitle">I. THỐNG KÊ CHUNG</div>
            <Statistic />

            <!-- Biểu đồ -->
            <div class="mtitle" style="margin-top: 50px;">II. BIỂU ĐỒ</div>

            <!-- Thống kê số tổ chức theo nhóm hành vi -->
            <BarchartNumOrgBehavior />
            <TableNumOrgBehavior />

            <!-- Thống kê số quyết định theo nhóm hành vi -->
            <BarchartNumDecisionBehavior />
            <PieChartNumDecisionBehavior />
            <TableNumDecisionBehavior />

            <!-- Thống kê số tiền vi phạm theo nhóm hành vi -->
            <BarchartNumMoneyBehavior />
            <TableNumMoneyBehavior />

            <!-- Thống kê diện tích thiệt hại theo nhóm hành vi -->
            <BarchartNumAreaBehavior />
            <TableNumAreaBehavior />

        </div>

        <div v-else>
            <NotFound />
        </div>       
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    import FormNation from './ReportOrganizationFormNation.vue';
    import FormRegion from './ReportOrganizationFormRegion.vue';
    import FormProvince from './ReportOrganizationFormProvince.vue';
    import FormDistrict from './ReportOrganizationFormDistrict.vue';

    import Statistic from './ReportOrganizationStatistic.vue';

    import BarchartNumOrgBehavior from './NumOrgBehavior/Barchart.vue';
    import TableNumOrgBehavior from './NumOrgBehavior/Table.vue';

    import BarchartNumDecisionBehavior from './NumDecisionBehavior/Barchart.vue';
    import PieChartNumDecisionBehavior from './NumDecisionBehavior/PieChart.vue';
    import TableNumDecisionBehavior from './NumDecisionBehavior/Table.vue';

    import BarchartNumMoneyBehavior from './NumMoneyBehavior/Barchart.vue';
    import TableNumMoneyBehavior from './NumMoneyBehavior/Table.vue';

    import BarchartNumAreaBehavior from './NumAreaBehavior/Barchart.vue';
    import TableNumAreaBehavior from './NumAreaBehavior/Table.vue';

    import NotFound from './NotFound.vue'

    export default {
        name: 'report-organization',
        components: {
            FormNation,
            FormRegion,
            FormProvince,
            FormDistrict,
            Statistic,

            BarchartNumOrgBehavior,
            TableNumOrgBehavior,

            BarchartNumDecisionBehavior,
            PieChartNumDecisionBehavior,
            TableNumDecisionBehavior,

            BarchartNumMoneyBehavior,
            TableNumMoneyBehavior,

            BarchartNumAreaBehavior,
            TableNumAreaBehavior,

            NotFound
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters([
                'isUserLevelNation',
                'isUserLevelRegion',
                'isUserLevelProvince',
                'isUserLevelDistrict',
                'getDecisionOrganization'
            ])
        },
		methods: {
		}
    }
</script>

<style scoped>
   
</style>