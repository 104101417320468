

export default {
    GET_LIST_LOCATION_LEVEL(state, data) {
        console.log('data = ', data)
        state.list_location_level = data.list_location_level
    },

    GET_LIST_ORGANIZATION_BY_LOCATION_LEVEL(state, data) {
        state.list_organization_by_location_level = data.list_organization_by_location_level
        state.list_province = data.list_province
    },

    GET_LIST_DISTRICT_BY_PROVINCE(state, data) {
        state.list_district_by_province = data.list_district_by_province
    },

    GET_LIST_PROVINCE(state, data) {
        state.list_province = data.list_province
    },

    GET_ENTITY_TYPE(state, data) {
        state.entity_type = data.entity_type
    },

    GET_SEX_TYPE(state, data) {
        state.sex_type = data.sex_type
    },

    GET_LIST_COUNTRY(state, data) {
        state.list_country = data.list_country
    },

    GET_ORGANIZATION_GROUP(state, data) {
        state.list_organization_group = data.list_organization_group
    },

    GET_LIST_ARTICLE(state, data) {
        state.list_article = data.list_article
    },

    GET_LIST_DECISION_STATUS (state, data) {
        state.list_decision_status = data.list_decision_status                 
    },

}