<template>
	<!-- Content Wrapper. Contains page content -->
	<div>
		<!-- HEADER -->
            <IndexHeader />
        <!-- / HEADER -->

        <!-- MAIN content -->
        <section class="content">
            <div style="padding-bottom: 30px;">
                <AddNotify />
            </div>
        </section>
        <!-- / MAIN content -->
	</div>
</template>

<script>
    import { mapActions } from 'vuex'

    // Component
    import IndexHeader from './IndexHeader.vue'
    import AddNotify from './AddNotify.vue'
    
	export default {
		name: 'get-notify-index',
        components: {
            IndexHeader,
            AddNotify
        },
        created(){
            
        },
        methods: {
            ...mapActions([
                
            ])
        },
    }
</script>


<style scoped>
    
</style>