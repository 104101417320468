<template>
    <div>
        <div class="card" style="border-radius: 0px">
            <div class="card-header ui-sortable-handle" style="padding-top: 15px;">
                <h3 class="card-title">
                    <i class="ion ion-clipboard mr-1"></i>
                    Điều 1 / Phần 1: Thông tin đối tượng xử phạt là cá nhân
                </h3>
            </div>

            <!-- I. MẪU VĂN BẢN DÀNH CHO CÁ NHÂN -->
            <!-- ========================================================================= -->
            <div class="card-body">
                <div class="mheader-1"> <i class="fas fa-file-word fa-lg"></i> Mẫu quyết định </div>

                <!-- I.1. HIỂN THỊ THÔNG TIN ĐỐI TƯỢNG XỬ PHẠT -->
                <!-- NẾU CHƯA NHẬP XONG số người vi phạm thì hiển thị form: trường hợp nhập hai người trở lên hoặc nhập 1 người -->
                <div>1. Xử phạt vi phạm hành chính đối với <span class="bold">cá nhân </span> có tên sau đây:</div>

                <!-- NẾU ĐÃ NHẬP XONG thì zend ra form -->
                <div v-if="individual.length > 0">
                    <template v-for="vperson in individual">

                        <!-- Thông tin đối tượng xử phạt -->
                        <div class="mline">
                            Họ và tên: 
                            <span class="bold">{{vperson.full_name}}</span>.  
                            Giới tính: 
                            <span class="bold">{{vperson.sex_name}}</span>.

                        </div>
                        
                        <div class="mline">
                            Ngày, tháng, năm sinh: 
                            <span class="bold">{{vperson.birthday}}</span>.
                            Quốc tịch: 
                            <span class="bold">{{vperson.country_name}}</span>.
                        </div>
                        
                        <div class="mline">
                            Nghề nghiệp: 
                            <span class="bold">{{vperson.occupation}}</span>.
                        </div>

                        <div class="mline">
                            Nơi ở hiện tại: 
                            <span class="bold">{{vperson.current_residence}}</span>. 
                        </div>
                        
                        <div class="mline">
                            Số định danh cá nhân/CMND/Hộ chiếu: 
                            <span class="bold">{{vperson.person_id_number}}</span>
                            ngày cấp: 
                            <span class="bold">{{vperson.date_issued}}</span>
                            nơi cấp: 
                            <span class="bold">{{vperson.issued_by}}</span>
                        </div>
                        <div style="margin-top: 30px"></div>
                    </template>
                </div>

                <div v-if="(num_violation_person >= 2 & individual.length < num_violation_person) || num_violation_person === 1 & individual.length === 0">
          
                    <!-- Thông tin đối tượng xử phạt -->
                    <div class="mline">
                        Họ và tên: 
                        <span class="num1 txt-center " :class="lfull_name">1</span> 
                        <span v-html="html_full_name"></span>.  
                        Giới tính: 
                        <span class="num1 txt-center " :class="lsex_name">2</span> 
                        <span v-html="html_sex_name"></span> </div>
                    
                    <div class="mline">
                        Ngày, tháng, năm sinh: 
                        <span class="num1 txt-center " :class="lbirthday">3</span>
                        <span v-html="html_birthday"></span>.  
                        Quốc tịch: 
                        <span class="num1 txt-center " :class="lcountry_name">4</span>
                        <span v-html="html_country_name"></span></div>
                    
                    <div class="mline">
                        Nghề nghiệp: 
                        <span class="num1 txt-center " :class="loccupation">5</span> 
                        <span v-html="html_occupation"></span></div>
                    
                    <div class="mline">
                        Nơi ở hiện tại: 
                        <span class="num1 txt-center " :class="lcurrent_residence">6</span>
                        <span v-html="html_current_residence"></span> </div>
                    
                    <div class="mline">
                        Số định danh cá nhân/CMND/Hộ chiếu: 
                        <span class="num1 txt-center " :class="lperson_id_number">7</span>
                        <span v-html="html_person_id_number"></span>, 
                        ngày cấp: 
                        <span class="num1 txt-center " :class="ldate_issued">8</span>
                        <span v-html="html_date_issued"></span>, 
                        nơi cấp: 
                        <span class="num1 txt-center " :class="lissued_by">9</span>
                        <span v-html="html_issued_by"></span>
                    </div>
                </div>

                <!-- hr line -->
                <!-- <hr class="mtop-bottom-40"> -->
                <div class="mtop-bottom-40"></div>
            </div>

            <!-- I.2. NHẬP THÔNG TIN ĐỐI TƯỢNG XỬ PHẠT -->
            <!-- ========================================================================== -->
            <div class="card-body mbg-input">
                
                <div class="mheader-2" id="input-individual"> 
                    <i class="fas fa-marker fa-lg"></i> Nhập thông tin 
                    <i class="fas fa-backspace scroll-up fa-lg txt-right micon" @click="resetAll()"></i>

                    <!-- Hướng dẫn -->
                     <span class="txt-right pointer" @click="showGuide()" style="margin-top: -6px; margin-right: 15px">
                        <i class="far fa-question-circle" v-if="!isShowGuide" style="margin-top: 8px; margin-right: 5px;"></i>
                        <i class="far fa-window-close" v-if="isShowGuide" style="margin-top: 8px; margin-right: 5px;"></i>
                    </span>
                </div>

                <!-- Hướng dẫn cập nhật -->
                <div v-if="isShowGuide">
                    Hướng dẫn nhập thông tin người vi phạm:
                    <ul>
                        <li>Trường hợp có 1 người vi phạm: chỉ điền thông tin vào mẫu.</li>
                        <li>Trường hợp có từ 2 người vi phạm trở lên: điền thông tin từng cá nhân, sau đó ấn "Thêm người vi phạm" sau khi nhập xong từng người.</li>
                    </ul>
                </div>

                <!-- DANH SÁCH NGƯỜI VI PHẠM -->
                <div v-if="num_violation_person >= 2 & individual.length >= 1">
                    <ul class="nav nav-pills ml-auto p-2 txt-right">
                        <!-- <li class="nav-item"><a class="nav-link" data-toggle="tab">Nguyễn Văn Anh</a></li> -->
                        <li class="nav-item dropdown">
                            <div class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="true">
                                Danh sách người vi phạm <span class="caret"></span>
                            </div>
                            <div class="dropdown-menu" x-placement="bottom-start" 
                                style="position: absolute; transform: translate3d(-5px, 40px, 0px); top: 0px; left: 0px; will-change: transform;">

                                <!-- Danh sách cá nhân vi phạm -->
                                <div class="dropdown-item" tabindex="-1" 
                                    v-for="(item, index) in individual">
                                        {{item.full_name}}  
                                        <span class="txt-right">
                                            <i class="fas fa-trash micon-delete" @click="confirmDeleteViolationPerson(item.id)"></i></span>
                                        <span class="txt-right">
                                            <i class="fas fa-edit micon-edit" style="margin-right: 10px;" @click="editViolationPersonFillToForm(item.id)"></i></span>
                                </div>                   
                            </div>
                        </li>
                    </ul>
                </div>
                <div style="clear: both;"></div>
  

                <!-- NHẬP THÔNG TIN NGƯỜI VI PHẠM -->
                <!-- ========================================================= -->
                <div>
                    <div class="row">
                        <!-- Số người vi phạm -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-user micon"></i> 
                                Số người vi phạm  </p>
                            <div class="input-group mb-3">
                                <input type="number" min="1" class="form-control" placeholder="Nhập số người vi phạm" autocomplete="off"
                                    v-model="num_violation_person" >
                            </div>
                        </div>

                        <!-- Họ và tên -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-user micon"></i> 
                                Họ và tên <sup><code>(*)</code></sup> <span class="mybadge txt-right">1</span> </p>
                            <div class="input-group mb-3">
                                <input v-model="full_name" type="text" class="form-control" placeholder="Nhập họ và tên người bị xử phạt" autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Giới tính -->
                        <div class="col-md-6" style="margin-top: 3px">     
                            <div><i class="fas fa-ankh micon"></i>
                            <span> Giới tính <sup><code>(*)</code></sup> <span class="mybadge txt-right">2</span> </span></div>
                            <multiselect
                                id="sex_id"
                                track-by="sex_id" label="sex_name" 
                                @input="handleGetSex()"
                                v-model="sex_selected" 
                                open-direction="bottom"
                                placeholder="Chọn giới tính"
                                :options="getSexType" 
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :clear-on-select="true"
                                :show-pointer="true"
                                :hide-selected="true">
                            </multiselect>
                        </div> 

                        <!-- Ngày tháng năm sinh -->
                        <div class="col-md-6">
                            <div style="margin-bottom: 6px;"> <i class="fas fa-calendar-alt micon"></i> 
                            Ngày, tháng, năm sinh  <span class="mybadge txt-right">3</span></div>
                                
                            <v-date-picker 
                                :masks="masks"
                                :attributes='attrs'
                                show-weeknumbers
                                @input="handExtractDateBirthDay()"
                                v-model="birthday_selected">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="input-group">
                                        <input
                                            placeholder="Ngày, tháng, năm sinh: DD-MM-YYYY"
                                            class="form-control"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Quốc tịch -->
                        <div class="col-md-6">
                            <div class="mheader-3"><i class="fas fa-flag micon"></i>
                            <span> Quốc tịch <sup><code>(*)</code></sup> <span class="mybadge txt-right">4</span> </span></div>
                            <multiselect
                                id="country_code"
                                track-by="country_code" label="country_name" 
                                @input="handleGetCountry()"
                                v-model="country_selected" 
                                open-direction="bottom"
                                placeholder="Chọn quốc tịch"
                                :options="getListCountry" 
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :clear-on-select="true"
                                :show-pointer="true"
                                :hide-selected="true">
                            </multiselect>
                        </div>

                        <!-- Nghề nghiệp -->
                        <div class="col-md-6">
                            <p class="mheader-3"> <i class="fas fa-user-tag micon"></i> 
                                Nghề nghiệp  <span class="mybadge txt-right">5</span></p>
                            <div class="input-group mb-3">
                                <input v-model="occupation" type="text" class="form-control" placeholder="Nhập nghề nghiệp" autocomplete="off">
                            </div>
                        </div>                        
                    </div>

                    <!-- Số định danh cá nhân, ngày cấp, nơi cấp -->
                     <div class="row" style="margin-top: 5px">
                        <!-- Nơi ở hiện tại -->
                        <div class="col-md-6">
                            <div> <i class="fas fa-map-marker-alt micon"></i> 
                                Nơi ở hiện tại  <span class="mybadge txt-right">6</span> </div>
                            <div class="input-group mb-3">
                                <input v-model="current_residence" type="text" class="form-control" placeholder="Nhập địa chỉ nơi ở hiện tại" autocomplete="off">
                            </div>
                        </div>

                        <!-- Số định danh cá nhân -->
                        <div class="col-md-6">
                            <div> <i class="fas fa-address-card micon"></i> 
                                Số định danh cá nhân/CMND/Hộ chiếu  <span class="mybadge txt-right">7</span> </div>
                            <div class="input-group mb-3">
                                <input v-model="person_id_number" type="text" class="form-control" placeholder="Nhập số định danh cá nhân/CMND/Hộ chiếu" autocomplete="off">
                            </div>
                        </div>                    
                    </div>

                    <div class="row" style="margin-top: 5px; margin-bottom: 10px;">
                        <!-- Ngày cấp -->
                        <div class="col-md-6">
                            <div> <i class="fas fa-calendar-alt micon"></i> 
                            Ngày cấp CMND/Hộ chiếu  <span class="mybadge txt-right">8</span></div>
                                
                            <v-date-picker 
                                :masks="masks"
                                :attributes='attrs'
                                show-weeknumbers
                                @input="handExtractDateCMND()"
                                v-model="date_issued_selected">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="input-group">
                                        <input
                                            placeholder="Ngày cấp: DD-MM-YYYY"
                                            class="form-control"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>  

                        <!-- Nơi cấp -->
                        <div class="col-md-6">
                            <div> <i class="fas fa-map-marker-alt micon"></i> 
                                Nơi cấp CMND/Hộ chiếu  <span class="mybadge txt-right">9</span></div>
                            <div class="input-group mb-3">
                                <input v-model="issued_by" type="text" class="form-control" placeholder="Nhập nơi cấp CMND/Hộ chiếu" autocomplete="off">
                            </div>
                        </div>
                        <div id="article12" style="margin-bottom: 5px;"></div>
                    </div>

                </div>

            </div>
            <!-- /I. MẪU VĂN BẢN DÀNH CHO CÁ NHÂN -->

           
            <!-- II. BUTTON THÊM MỚI và CHỈNH SỬA ĐỐI TƯỢNG XỬ PHẠT -->
            <!-- Add: confirm info -->
            <div class="card-footer clearfix" v-if="num_violation_person >= 2 & isEditAdd === false">
                <div class="row">
                    <!-- Nút ấn lưu thông tin đối tượng xử phạt -->
                    <div class="col-md-4">
                        <button type="button" class="btn btn-default btn-sm float-left " :class="isDisabled" 
                            style="background-color: #05668D; color: white;"
                            v-if="num_violation_person - individual.length > 0"
                            @click="addViolationPerson()">
                                <span v-if="num_violation_person - individual.length > 1 "> <i class="fas fa-plus"></i> Lưu và thêm người vi phạm</span> 
                                <span v-if="num_violation_person - individual.length === 1"> <i class="fas fa-save"></i> Lưu</span> 
                        </button>
                    </div>

                    <!-- Nut trạng thái -->
                    <div class="col-md-4">
                        <span class="info-input-status">Số người vi phạm đã nhập thông tin: {{ individual.length }}</span> 
                    </div>
                    <div class="col-md-4">
                        <span class="info-input-status">Số người vi phạm chưa nhập thông tin: {{ num_violation_person - individual.length}} </span>
                    </div>
                </div>
            </div>

            <!-- Edit: save edited info -->
            <div class="card-footer clearfix" v-if="isEditAdd">
                <div class="row">
                    <!-- Nut ấn chỉnh sửa -->
                    <div class="col-md-6">
                        <button type="button" class="btn btn-default btn-sm float-left" 
                            style="background-color: #E5E5E5; color: black; border-color: #c4c4c4;"
                            @click="saveEditViolationPerson()">
                        <i class="fas fa-save"></i> Lưu thông tin chỉnh sửa </button>
                    </div>

                    <!-- Nut hủy chỉnh sửa -->
                    <div class="col-md-6">
                        <button type="button" class="btn btn-default btn-sm txt-right" 
                            style="background-color: #E5E5E5; color: black; border-color: #c4c4c4;"
                            @click="cancelEditViolationPerson()">
                            Hủy chỉnh sửa </button>
                    </div>
                </div>
            </div>
            <!-- /II. LƯU VÀ THÊM MỚI ĐỐI TƯỢNG XỬ PHẠT -->


            <!-- III. FORM XÁC NHẬN XÓA THỐNG TIN NGƯỜI VI PHẠM ====================================================================== -->
            <div class="modal fade" id="view_violation_person">
				<div class="modal-dialog modal-dialog-centered modal-xl">
					<div class="modal-content" style="border-color: cadetblue; margin: 10px;">
						
						<!-- Header -->
						<div class="modal-header myheader-info">
							<i class="fas fa-info-circle myicon-info"></i>
							<p class="modal-title" style="font-size: 16px; ">Xác nhận xóa thông tin người vi phạm</p>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="viewFormClose">
								<span aria-hidden="true" style="color:white">&times;</span>
							</button>
						</div>

						<!-- Body: content edit user -->
						<div style="margin: 15px">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="mheader-3"> 
                                        <i class="fas fa-user micon " :class="warningIcon(lfull_name_delete)"></i> 
                                        Họ và tên: {{this.full_name_delete}}   
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mheader-3"> 
                                        <i class="fas fa-ankh micon " :class="warningIcon(lsex_name_delete)"></i>
                                        Giới tính: {{this.sex_name_delete}}  
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="mheader-3"> <i class="fas fa-calendar-alt micon " :class="warningIcon(lbirthday_delete)"></i> 
                                        Ngày, tháng, năm sinh: {{this.birthday_delete}}
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <div class="mheader-3"><i class="fas fa-flag micon " :class="warningIcon(lcountry_name_delete)"></i>
                                        Quốc tịch: {{this.country_name_delete}} 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="mheader-3"> <i class="fas fa-user-tag micon " :class="warningIcon(loccupation_delete)"></i> 
                                        Nghề nghiệp: {{this.occupation_delete}}  
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mheader-3"> <i class="fas fa-map-marker-alt micon " :class="warningIcon(lcurrent_residence_delete)"></i> 
                                        Nơi ở hiện tại: {{this.current_residence_delete}}  
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mheader-3"> <i class="fas fa-address-card micon " :class="warningIcon(lperson_id_number_delete)"></i> 
                                        Số định danh cá nhân/CMND/Hộ chiếu: {{this.person_id_number_delete}} 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <p class="mheader-3"> <i class="fas fa-calendar-alt micon " :class="warningIcon(ldate_issued_delete)"></i> 
                                        Ngày cấp CMND/Hộ chiếu: {{ this.date_issued_delete}}
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="mheader-3"> <i class="fas fa-map-marker-alt micon " :class="warningIcon(lissued_by_delete)"></i> 
                                    Nơi cấp CMND/Hộ chiếu: {{ this.issued_by_delete}} 
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between" style="padding: 15px; background-color: #F7F7F7">
							<button type="button" class="btn btn-danger mybtn-delete" data-dismiss="modal" 
                                    v-on:click.prevent="deleteViolationPerson"
                                    >Xóa</button>
                            <button type="button" class="btn btn-danger mybtn-cancel" data-dismiss="modal" 
                                    v-on:click.prevent="cancelDeleteViolationPerson"
                                    >Hủy xóa</button>
						</div>
                    </div>
                </div>		
			</div>
			<button type="button" class="btn btn-info" data-toggle="modal" 
                data-target="#view_violation_person" 
                style="display:none" 
                ref="openConfirmDelete"></button>
             <!-- /IV. XÓA THÔNG TIN NGƯỜI VI PHẠM ĐÃ LƯU TRONG MẢNG INDIVIDUAL -->
             
        </div> 
    </div>
</template>

<script>
    import { fixMonthAndDay } from '@/helper/index'
    import moment from 'moment';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'vd-article11-individual',
        data() {
            return {
                // Cá nhân
                // 1. Form Add ---------------------------------------------------------------
                full_name: '',                  // * Họ và tên (2)
                birthday: '',                   // * Ngày tháng năm sinh: hiển thị ra ngoài web
                country_code: '',               // * Quốc tịch - code
                country_name: '',               // Quốc tịch
                occupation: '',                 // * Nghề nghiệp
                current_residence: '',          // * Nơi ở hiện nay
                person_id_number: '',           // * CMT / CCCD
                sex_id: '',                     // * Giới tính (3)
                sex_name: '',
                date_issued: '',                // * Ngày cấp: hiển thị ra ngoài web
                issued_by: '',                  // * Nơi cấp

                // Selected: multi select
                birthday_selected: [],          // Sử dụng để lấy thông tin ngày tháng, năm sinh người bị phạt
                country_selected: [],
                sex_selected: [],               
                date_issued_selected: [],       // Sử dụng để lấy thông tin ngày cấp CMT/CCCD 

                // Variable temp
                date: '',                       // variable temp
                month: '',                      // variable temp
                year: '',                       // variable temp
                isShowGuide: false,             // Ẩn / hiện hướng dẫn
                full_name_temp: '',             // Full_Name dùng để truyền sang Article3

                // The array saves violation person 
                num_violation_person: 1,        // * Số người vi phạm
                individual: [],                  // * Sử dụng để lưu thông tin từng người vi phạm [obj1, obj2, ...]

                /// Edit: Temp
                person_id_edit: '',                    // Temp: Biến lưu tạm thông tin id đang edit
                isEditAdd: false,

                // Delete: Temp
                person_id_delete: '',                  // id của obj trong mảng individual
                full_name_delete: '',                 
                birthday_delete: '',                   
                country_name_delete: '',               
                occupation_delete: '',                 
                current_residence_delete: '',          
                person_id_number_delete: '',           
                sex_name_delete: '',
                date_issued_delete: '',                
                issued_by_delete: '',                  

                // VCalendar
				attrs: [
					{
						key: 'today',
						highlight: true,       // dot
						dates: new Date()
					}
				],
				masks: {
					input: 'DD-MM-YYYY',
				},

                // EDIT FROM DATABASE (Chỉ dùng cho trường hợp có 1 người vi phạm)
                violation_person_id: ''
            }
        },
        created(){
            this.actGetSexType()
            this.actGetListCountry()
        },
        watch: {
            full_name (newVal) { // Lắng nghe khi nhập tên người dùng thì emit lên Index để truyền vào Article3
                this.$emit('setPersonNameInArticle3', newVal) 
            },

            full_name_temp (newVal) { // Lắng nghe khi nhập tên người dùng thì emit lên Index để truyền vào Article3
                this.$emit('setPersonNameInArticle3', newVal) 
            }
        },
        computed: {
			...mapGetters([
                'getSexType',
                'getListCountry',
                'isEditFromServer'
                
			]),

            // I. FORM ADD
            // 1. Họ và tên
            lfull_name: function (){
                if(this.full_name === '') return ''
                return 'hide'
            },

            html_full_name: function (){
                if(this.full_name === '') return '.........................................................................................'
                return '<span class="bold">' + this.full_name +'</span>'
            },

            // 2. Giới tính
            lsex_name: function (){
                if(this.sex_name === '') return ''
                return 'hide'
            },

            html_sex_name: function (){
                if(this.sex_name === '') return '......................................................................................................................................................................'
                return '<span class="bold">' + this.sex_name +'</span>'
            },

            // 3. Ngày, tháng, năm sinh
            lbirthday: function (){
                if(!this.birthday) return ''
                return 'hide'
            },

            html_birthday: function (){
                if(this.birthday === '' || !this.birthday_selected ) return '......................................................................................................................................................................'
                return '<span class="bold">' + this.birthday +'</span>'
            },

            // 4. Quốc tịch
            lcountry_name: function (){
                if(this.country_name === '') return ''
                return 'hide'
            },

            html_country_name: function (){
                if(this.country_name === '') return '.............................................................'
                return '<span class="bold">' + this.country_name +'</span>'
            },

            // 5. Nghề nghiệp
            loccupation: function (){
                if(this.occupation === '') return ''
                return 'hide'
            },

            html_occupation: function (){
                if(this.occupation === '') return '.....................................................................................................................................................................................................................................................................................'
                return '<span class="bold">' + this.occupation +'</span>'
            },

            // 6. Nơi ở hiện tại
            lcurrent_residence: function (){
                if(this.current_residence === '') return ''
                return 'hide'
            },

            html_current_residence: function (){
                if(this.current_residence === '') return '....................................................................................................................................................................................................................................................................................'
                return '<span class="bold">' + this.current_residence +'</span>'
            },

            // 7. Chứng minh thư
            lperson_id_number: function (){
                if(this.person_id_number === '') return ''
                return 'hide'
            },

            html_person_id_number: function (){
                if(this.person_id_number === '') return '..............................'
                return '<span class="bold">' + this.person_id_number +'</span>'
            },

            // 8. Ngày cấp
            ldate_issued: function (){
                if(this.date_issued === '') return ''
                return 'hide'
            },

            html_date_issued: function (){
                if(this.date_issued === '' || !this.date_issued_selected) return '........../.........../...........'
                return '<span class="bold">' + this.date_issued +'</span>'
            },

            // 9. Nơi cấp
            lissued_by: function (){
                if(this.issued_by === '') return ''
                return 'hide'
            },

            html_issued_by: function (){
                if(this.issued_by === '') return '......................................................................................................'
                return '<span class="bold">' + this.issued_by +'</span>'
            },

            // 10. Xác nhận thông tin người vi phạm
            text_mr_mrs: function(){
                if(this.sex_id === 1) return 'ông'
                if(this.sex_id === 2) return 'bà'
                return 'ông/bà'
            },


            // II. FORM VIEW: sử dụng để cảnh báo thông tin còn thiếu thông qua icon (mầu đỏ), đã đủ (mầu xanh)
            lfull_name_delete: function (){
                if(this.full_name_delete === '') return ''
                return 'hide'
            },

            lsex_name_delete: function (){
                if(this.sex_name_delete === '') return ''
                return 'hide'
            },

            lbirthday_delete: function (){
                if(this.birthday_delete === '') return ''
                return 'hide'
            },

            lcountry_name_delete: function (){
                if(this.country_name_delete === '') return ''
                return 'hide'
            },

            loccupation_delete: function (){
                if(this.occupation_delete === '') return ''
                return 'hide'
            },

            lcurrent_residence_delete: function (){
                if(this.current_residence_delete === '') return ''
                return 'hide'
            },

            lperson_id_number_delete: function (){
                if(this.person_id_number_delete === '') return ''
                return 'hide'
            },

            ldate_issued_delete: function (){
                if(this.date_issued_delete === '') return ''
                return 'hide'
            },

            lissued_by_delete: function (){
                if(this.issued_by_delete === '') return ''
                return 'hide'
            },

            // Disable button cập nhật nếu số người nhập bằng số người đã khai báo
            isDisabled: function(){
                if(parseInt(this.num_violation_person) === this.individual.length) return 'disabled'
                return ''
            }
		},
		methods: {
			...mapActions([ 
                'actGetSexType',
                'actGetListCountry'
            ]),

            // 0. START
            // ==============================================================================
            warningIcon(var_check){
                if(var_check === '') return 'mwarning'
                return 'msuccess'
            },

            showGuide(){ // Hiển thị hướng dẫn nhập
                this.isShowGuide = !this.isShowGuide
            },

            setInfoPersonViolationToIndividualArr(){

                // Lấy thông tin
                if(this.isEditFromServer){ // Chỉnh sửa tài khoản trên CSDL
                    let id = this.violation_person_id
                    var violation_person = this.getInfoViolationPerson(id)

                }else{ // Thêm mới
                    let id = this.$uuidKey()
                    var violation_person = this.getInfoViolationPerson(id)
                }

                // Gán vào mảng individual
                this.individual = []
                this.individual.push(violation_person)
            },

            // USER SELECT =================================================================
			handExtractDateBirthDay(){ // Ngày, tháng, năm sinh
                this.date   = fixMonthAndDay(moment(this.birthday_selected).format('DD'))
                this.month  = fixMonthAndDay(moment(this.birthday_selected).format('MM'))
                this.year   = moment(this.birthday_selected).format('YYYY')

                if(this.date !== '' & this.date !== 'Invalid date'){ // Nếu chọn ngày tháng
                    this.birthday = this.date + '/' + this.month + '/' + this.year
                }
            },

            handExtractDateCMND(){ // Số định danh cá nhân/CMND/Hộ chiếu
                this.date   = fixMonthAndDay(moment(this.date_issued_selected).format('DD'))
                this.month  = fixMonthAndDay(moment(this.date_issued_selected).format('MM'))
                this.year   = moment(this.date_issued_selected).format('YYYY')

                if(this.date !== '' & this.date !== 'Invalid date'){ // Nếu chọn ngày tháng
                    this.date_issued = this.date + '/' + this.month + '/' + this.year
                }
            },

            handleGetSex(){
                this.sex_id     = this.sex_selected.sex_id
                this.sex_name   = this.sex_selected.sex_name
            },

            handleGetCountry(){
                this.country_code   = this.country_selected.country_code
                if(this.country_code === 704){
                    this.country_name = 'Việt Nam'
                }else{
                    this.country_name   = this.country_selected.country_name
                }   
            },

            // VALIDATE: BEFORE SAVE
            // ======================================================================
            validateIndividual(obj){
                console.log("obj", obj)

                if(obj.full_name === ''){
                    toastr.error("Chưa nhập họ và tên", "Thông tin đối tượng xử phạt", { "closeButton": true });
                    return false
                }

                if(obj.sex_id === ''){
                    toastr.error("Chưa nhập giới tính.", "Thông tin đối tượng xử phạt", { "closeButton": true });
                    return false
                }

                if(obj.country_code === ''){
                    toastr.error("Chưa chọn quốc tịch.", "Thông tin đối tượng xử phạt", { "closeButton": true });
                    return false
                }

                return true
            },

            // ADD ======================================================================
            addViolationPerson(){ // Lưu tạm thông tin người vi phạm vào mảng individual

                let id = this.$uuidKey()
                var violation_person = this.getInfoViolationPerson(id)

                let flag_validate = this.validateIndividual(violation_person)

                // Lưu thông tin người vi phạm vào mảng
                if(flag_validate){
                    // Lưu thông tin người vi phạm vào mảng              
                    this.individual.push(violation_person)

                    // Reset form
                    this.resetFormAfterAdd()

                    // Hiển thị thông báo lưu thành công
                    toastr.success('Thêm thành công!', '', {"closeButton": true})

                    // Cập nhật bổ sung số người vào Điều 3, component Article3.vue
                    this.setFullNameForArticle3(this.individual)
                }
            },

            setFullNameForArticle3(individual){  // Hàm sử dụng để gửi tên người dùng sang cập nhật ở article 3.
                if(this.num_violation_person === 1){  // Trường hợp 1 người vi phạm
                    this.full_name_temp = this.full_name

                }else{ // Trường hợp nhiều người vi phạm
                    let violation_person_name = ''
                    individual.forEach((obj)=>{
                        violation_person_name += obj.full_name + ', '
                    })
                    this.full_name_temp = violation_person_name.slice(0, -2)
                }
            },

            getInfoViolationPerson(id){// Hàm sử dụng để lấy thống tin người vi phạm lưu vào obj để Add và Edit
                // Cần truyền vào ID vì trường hợp Add là tạo ID mới, Edit là lấy ID đã có ở data
                var violation_person = {
                    id: '',
                    num_violation_person: this.num_violation_person,
                    full_name: this.full_name,
                    sex_id: this.sex_id,
                    birthday: this.birthday,
                    country_code: this.country_code,
                    occupation: this.occupation,
                    current_residence: this.current_residence,
                    person_id_number: this.person_id_number,
                    date_issued: this.date_issued,
                    issued_by: this.issued_by,

                    // 2. Form Web view (sử dụng cho hiển thị trên giao diện Web khi người dùng đang nhập)
                    country_name: this.country_name,
                    sex_name: this.sex_name,
                    country_selected: this.country_selected,
                    sex_selected: this.sex_selected,
                    birthday_selected: this.birthday_selected,
                    date_issued_selected: this.date_issued_selected      // Select ngày cấp
                }

                violation_person.id = id
                return violation_person
            },

            resetFormAfterAdd(){
                this.full_name = ''                    
                this.birthday = ''           
                this.country_code = ''       
                this.country_name = ''       
                this.occupation = ''         
                this.current_residence = ''  
                this.person_id_number = ''   
                this.sex_id = ''             
                this.sex_name = ''
                this.date_issued = ''        
                this.issued_by = '' 
                
                // Multi selected   
                this.birthday_selected = []              
                this.date_issued_selected = []
                this.country_selected = []
                this.sex_selected = []
                // this.individual = []

                // Date temp
                this.date = ''
                this.month = ''
                this.year = ''
                this.isShowGuide = false
            },

            // EDIT (khi đang ADD USER) ======================================================================
            editViolationPersonFillToForm(person_id){
                // person_id: là id (=i) của người thứ i trong mảng individual
                // console.log('showViolationPersonInfo person_id', person_id)
                
                // 1. Lấy thông tin người vi phạm trong mảng individual cần view
                var violation_person = this.individual.find(obj => obj.id === person_id)
                // console.log('violation_person Edit ===', violation_person)

                // 2. Điền thông tin violation_person vào data() để hiển thị ra form view
                this.person_id_edit    = person_id
                this.full_name         = violation_person.full_name
                this.birthday          = violation_person.birthday
                this.country_code      = violation_person.country_code
                this.country_name      = violation_person.country_name
                this.occupation        = violation_person.occupation
                this.current_residence = violation_person.current_residence
                this.person_id_number  = violation_person.person_id_number
                this.sex_id            = violation_person.sex_id
                this.sex_name          = violation_person.sex_name
                this.date_issued       = violation_person.date_issued
                this.issued_by         = violation_person.issued_by

                // 3. Vue multi selected
                this.birthday_selected      = violation_person.birthday_selected
                this.country_selected       = violation_person.country_selected
                this.sex_selected           = violation_person.sex_selected
                this.date_issued_selected   = violation_person.date_issued_selected 

                // Enable Edit
                this.enableEditButton()
            },

            saveEditViolationPerson(){ // Lưu tạm thông tin người vi phạm vào mảng individual

                let id = this.person_id_edit
                var violation_person = this.getInfoViolationPerson(id)
                let flag_validate = this.validateIndividual(violation_person)

                // Lưu thông tin người vi phạm vào mảng
                if(flag_validate){
                    // Thay thế obj cũ trong mảng individual bằng thông tin mới
                    // 1. Tìm index mảng cần thay thế dựa vào person_id_edit
                    var index_obj_edit = this.individual.findIndex(obj => obj.id === id);
                    // console.log('index =====', index_obj_edit);

                    // 2. Tiến hành thay thế
                    this.individual[index_obj_edit] = violation_person

                    // Reset form
                    this.resetForm()
                    
                    // Hiển thị thông báo lưu thành công
                    toastr.success('Chỉnh sửa thành công!', '', {"closeButton": true})

                    // Cập nhật bổ sung số người vào Điều 3, component Article3.vue
                    this.setFullNameForArticle3(this.individual)
                }
            },

            cancelEditViolationPerson(){
                this.resetForm()
            },

            enableEditButton(){
                this.isEditAdd = true
            },

            resetVariableTempUseForEdit(){
                this.person_id_edit = ''
                this.isEditAdd = false
            },

            // DELETE (Khi đang ADD user) ======================================================================
            confirmDeleteViolationPerson(id){
                this.person_id_delete = id              // Lưu vào để sử dụng cho deleteViolationPerson
                
                // 1. Lấy thông tin người cần xóa
                let person_delete = this.individual.find(obj => obj.id === id)
                // console.log("person_delete =======", person_delete)

                // 2. Lưu vào variable temp để hiển thị ra cho người dùng xác minh thông tin trước khi xóa
                this.full_name_delete           = person_delete.full_name
                this.birthday_delete            = person_delete.birthday
                this.country_name_delete        = person_delete.country_name
                this.occupation_delete          = person_delete.occupation
                this.current_residence_delete   = person_delete.current_residence
                this.person_id_number_delete    = person_delete.person_id_number
                this.sex_name_delete            = person_delete.sex_name
                this.date_issued_delete         = person_delete.date_issued
                this.issued_by_delete           = person_delete.issued_by
                
                // 3. Show form view
                this.$refs.openConfirmDelete.click()
            },

            deleteViolationPerson(){  // Xóa thông tin người vi phạm đang view (xóa person trong mảng individual)
                
                let id = this.person_id_delete
                // 1. Loại bỏ obj (người cần xóa) trong mảng individual
                this.individual = this.individual.filter(item => item.id !== id)

                // 2. Hiển thị thông báo xóa thành công
                toastr.success('Xóa thành công!', 'Xóa người vi phạm', {"closeButton": true})

                // 3. Reset lại form
                this.resetVariableTempUseForDelete()

                // 4. Cập nhật bổ sung số người vào Điều 3, component Article3.vue
                this.setFullNameForArticle3(this.individual)
            },

            cancelDeleteViolationPerson(){
                this.resetVariableTempUseForDelete()
            },

            resetVariableTempUseForDelete(){
                this.person_id_delete = ''                  // id của obj trong mảng individual
                this.violation_behavior_enter_delete = ''
                this.regulation_at_enter_delete = ''
                this.main_penalty_enter_delete = ''
                this.full_name_delete = ''                  // Họ và tên (2)
                this.birthday_delete = ''                   // Ngày tháng năm sinh_delete = hiển thị ra ngoài web
                this.country_name_delete = ''               // Quốc tịch
                this.occupation_delete = ''                 // Nghề nghiệp
                this.current_residence_delete = ''          // Nơi ở hiện nay
                this.person_id_number_delete = ''           // CMT / CCCD
                this.sex_name_delete = ''
                this.date_issued_delete = ''                // Ngày cấp hiển thị ra ngoài web
                this.issued_by_delete = ''                  // Nơi cấp
            },  

            // II. RESET FORM =============================================================
            resetAll(){
                this.resetForm()
                this.individual = []
                this.num_violation_person = 1
                this.full_name_temp = ''
            },

            resetForm(){
                // Add
                this.resetFormAfterAdd()

                // Edit 
                this.resetVariableTempUseForEdit()

                // Delete
                this.resetVariableTempUseForDelete()

                // Remove person_name on Article3
                this.$emit('setPersonNameInArticle3', '')
                this.violation_person_id = ''               // Edit user trường hợp Edit một người.
            },
            

            // EDIT VIOLATION DECISION (GET DATA FROM DATABASE --> FILL TO FORM)
            // ================================================================================================
            // SET DATA
            // ------------------------------------------------------------------------------------------------
            setEdit(data){
                console.log("data setEdit ===", data)
                // console.log("data.length ===", data.length)

                // Số người vi phạm
                this.num_violation_person = data.length

                // 1. Nếu có một người vi phạm
                // -----------------------------------------------------------------------
                if(this.num_violation_person === 1){
                    var data = data[0]
                    console.log('data[0] :=====', data);
                    // Personal info
                    this.violation_person_id = data.violation_person_id

                    // Ngày tháng năm sinh
                    var check_birthday = parseInt(moment(data.birthday).format('YYYY'))
                    if(check_birthday !== 1900){
                        var birthday = ''

                        // Birthday hiển thị ra web
                        this.date = fixMonthAndDay(moment(data.birthday).format('DD'))
                        this.month = fixMonthAndDay(moment(data.birthday).format('MM'))
                        this.year = moment(data.birthday).format('YYYY')
                        birthday = this.date + '/' + this.month + '/' + this.year
                        this.birthday = birthday                            // Hiển thị ra web

                        // Birthday lưu vào CSDL
                        this.birthday_selected = data.birthday              // Lưu vào CSDL  
                    }

                    // Ngày cấp
                    var check_date_issued = parseInt(moment(data.date_issued).format('YYYY'))
                    var date_issued = ''
                    if(check_date_issued !== 1900){
                        // Hiển thị ra web
                        this.date = fixMonthAndDay(moment(data.date_issued).format('DD'))
                        this.month = fixMonthAndDay(moment(data.date_issued).format('MM'))
                        this.year = moment(data.date_issued).format('YYYY')
                        date_issued = this.date + '/' + this.month + '/' + this.year
                        this.date_issued = date_issued                      // Hiển thị ra web

                        // Lưu vào CSDL
                        this.date_issued_selected = data.date_issued        // Lưu vào CSDL
                    }

                    this.full_name = data.full_name
                    this.sex_id = data.sex_id
                    this.sex_name = data.sex_name
                    this.sex_selected.push({                            // Hiển thị ra web
                        sex_id: data.sex_id,
                        sex_name: data.sex_name
                    })
                    this.country_code = data.country_code
                    this.country_name = data.country_name
                    this.country_selected.push({                        // Hiển thị ra web
                        country_code: data.country_code,
                        country_name: data.country_name
                    })
                    this.occupation = data.occupation
                    this.current_residence = data.current_residence
                    this.person_id_number = data.person_id_number
                    this.issued_by = data.issued_by
                }
                // END: Nếu có một người vi phạm


                // 2. Nếu có nhiều người vi phạm
                // -----------------------------------------------------------------------
                if(this.num_violation_person > 1){

                    // Duyệt qua mảng và đưa từng người vào mảng individual. Lưu ý dữ liệu ngày tháng (hiển thị, lưu CSDL)
                    data.forEach(obj => {

                        // Check và format ngày, tháng, năm sinh
                        var check_date = parseInt(moment(obj.birthday).format('YYYY'))
                        var birthday = ''
                        if(check_date !== 1900){
                            this.date = fixMonthAndDay(moment(obj.birthday).format('DD'))
                            this.month = fixMonthAndDay(moment(obj.birthday).format('MM'))
                            this.year = moment(obj.birthday).format('YYYY')
                            birthday = this.date + '/' + this.month + '/' + this.year
                        }

                        // Ngày cấp
                        var check_date_issued = parseInt(moment(obj.date_issued).format('YYYY'))
                        var date_issued = ''
                        if(check_date_issued !== 1900){
                            this.date = fixMonthAndDay(moment(obj.date_issued).format('DD'))
                            this.month = fixMonthAndDay(moment(obj.date_issued).format('MM'))
                            this.year = moment(obj.date_issued).format('YYYY')
                            date_issued = this.date + '/' + this.month + '/' + this.year
                        }
                        
                        var violation_person = {
                            id: obj.violation_person_id,
                            num_violation_person: this.num_violation_person,
                            full_name: obj.full_name,
                            sex_id: obj.sex_id,
                            birthday: birthday,                                 // Hiển thị ra web
                            country_code: obj.country_code,
                            occupation: obj.occupation,
                            current_residence: obj.current_residence,
                            person_id_number: obj.person_id_number,
                            date_issued: date_issued,                           // Hiển thị ra web
                            issued_by: obj.issued_by,

                            // 2. Form Web view (sử dụng cho hiển thị trên giao diện Web khi người dùng đang nhập)
                            country_name: obj.country_name,
                            sex_name: obj.sex_name,
                            country_selected: {                         // Hiển thị ra web
                                country_code: obj.country_code,
                                country_name: obj.country_name
                            },
                            sex_selected: {                             // Hiển thị ra web
                                sex_id: obj.sex_id,
                                sex_name: obj.sex_name
                            },
                            birthday_selected: obj.birthday,            // Lưu vào CSDL
                            date_issued_selected: obj.date_issued       // Lưu vào CSDL
                        }
                        this.individual.push(violation_person)
                        
                    })
                }
                // END: Nếu có nhiều người vi phạm

                // Cập nhật bổ sung số người vào Điều 3, component Article3.vue
                this.setFullNameForArticle3(this.individual)
            }
            // END SET DATA
		}
    }
</script>

<style scoped>
.mheader-3{
    color: #5C677D;
    margin-bottom: 5px;
    margin-top: 20px;
}

.myicon-info {
    margin-top: 6px;
    padding-right: 10px;
    font-size: 18px;
}

.myheader-info {
    padding: 22px;
    background-color: #17A2B8;
    color: white;
}

.mybtn-cancel {
    width: 150px; 
    padding: 2px !important; 
    font-size: 14px !important; 
    background-color: #FFFFFF !important;   
    color: black !important;  
    border: 1px solid #dee2e6 !important;  
}

.mybtn-delete{
    width: 150px; 
    padding: 2px !important; 
    font-size: 14px !important; 
    border: 1px solid #dee2e6 !important; 
}

.mybtn-cancel:hover {
    width: 150px; 
    padding: 2px !important; 
    font-size: 14px !important; 
    background-color: #E5E5E5 !important;  
    color: black !important; ; 
    border: 1px solid #dee2e6 !important;  
}

.mwarning {
    color: #C82333
}

.msuccess {
    color: #218838;
}

</style>