

export default {
    SET_LOGIN (state, data) {
        state.current_user = data.current_user
        state.ACCESS_TOKEN = data.token                   // Khi mới load về phải lưu vào state --> thì getters mới đọc được, không sẽ bị lỗi do bất đồng bộ, ở state ACCESS_TOKEN: localStorage.getItem('ACCESS_TOKEN') || '' -> đọc sau so với getters --> isLogin = false --> không login được.
        localStorage.setItem('ACCESS_TOKEN', data.token)
    },

    SET_CHECK_LOGIN(state, data) {
        state.current_user = data.current_user
        state.ACCESS_TOKEN = data.token_refresh                     // Refresh token            
        localStorage.setItem('ACCESS_TOKEN', data.token_refresh)
    },

    SET_LOGOUT(state) {
        state.ACCESS_TOKEN = '';
        state.current_user = [];
        localStorage.removeItem('ACCESS_TOKEN');
    },
}