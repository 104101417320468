import axiosInstance from '@/plugin/axios.js';

export default {

    // Lấy danh sách nhóm cấp quản lý:
    async actLocationLevel({ commit }) {
        try {
            var res = await axiosInstance.get('/share/list_location_level')
            var result = res.data;

            if (res.status === 200) {
                commit('GET_LIST_LOCATION_LEVEL', result)
                return {
                    ok: true,
                    location_level: result
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actLocationLevel: ', error)
        }
    },

    async actGetListRegion() {
        try {
            var res = await axiosInstance.get('/share/list_region')

            if (res.status === 200) {
                return {
                    ok: true,
                    list_region: res.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListRegion: ', error)
        }
    },

    
    async actGetListProvinceByRegion({ commit }, region_code) {
        try {
            var res = await axiosInstance.get('/share/list_province_by_region/' + region_code)

            if (res.status === 200) {
                commit('GET_LIST_PROVINCE', res.data)
                
                return {
                    ok: true,
                    list_province: res.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListProvinceByRegion: ', error)
        }
    },


    // 2. Lấy dánh sách đơn vị theo cấp quản lý
    async actGetListOrgByLocationLevel({ commit }, location_level_code) {
        try {
            var res = await axiosInstance.get('/share/list_organization_by_locationlevel/' + location_level_code)
            const result = res.data;
            // console.log('actGetListOrgByLocationLevel = ', result)

            if (res.status === 200) {
                commit('GET_LIST_ORGANIZATION_BY_LOCATION_LEVEL', result)
                return {
                    ok: true,
                    result: result
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListOrgByLocationLevel: ', error)
        }
    },

    // 3. Lấy danh sách huyện theo mã tỉnh
    async actGetListProvince({ commit }) {

        try {
            var res = await axiosInstance.get('/share/list_province')

            if (res.status === 200) {
                commit('GET_LIST_PROVINCE', res.data)
                return {
                    ok: true,
                    result: res.data
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListProvince: ', error)
        }
    },

    // Lấy danh sách tỉnh theo thông tin user đăng nhập
    async actGetListProvinceByUserIdAndLocationLevelCode({ commit }, data) { // Xóa một nhóm

        try {
            
            var res = await axiosInstance.get('/share/list_province/' + data.location_level_code + '/' + data.user_id)
            const result = res.data;
            // console.log('result actGetListProvince = ', result)

            if (res.status === 200) {
                commit('GET_LIST_PROVINCE', result)
                return {
                    ok: true,
                    result: result
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListProvince: ', error)
        }
    },


    // 3. Lấy danh sách huyện theo mã tỉnh
    async actGetDistrictByProvinceCode({ commit }, province_code) { // Xóa một nhóm

        try {
            var res = await axiosInstance.get('/share/list_district_by_province/' + province_code)
            const result = res.data;
            // console.log('actGetDistrictByProvinceCode = ', result)

            if (res.status === 200) {
                commit('GET_LIST_DISTRICT_BY_PROVINCE', result)
                return {
                    ok: true,
                    result: result
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetDistrictByProvinceCode: ', error)
        }
    },


    // II. PENALTY DECISION
    // =========================================================================

    // 4. Lấy bảng định nghĩa cá nhân và tổ chức (entity_type)
    async actGetEntityType({ commit }) { 

        try {
            var res = await axiosInstance.get('/share/entity_type')
            const result = res.data;
            // console.log('actGetEntityType = ', result)

            if (res.status === 200) {
                commit('GET_ENTITY_TYPE', result)
                return {
                    ok: true,
                    result: result
                }
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }
        } catch (error) {
            console.log('Error action actGetEntityType: ', error)
        }
    },

    // Lấy bảng định nghĩa GIỚI TÍNH
    async actGetSexType({ commit }) { 

        try {
            var res = await axiosInstance.get('/share/sex')
            const result = res.data;
            // console.log('actGetSexType = ', result)

            if (res.status === 200) {
                commit('GET_SEX_TYPE', result)
                return {
                    ok: true,
                    result: result
                }
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }
        } catch (error) {
            console.log('Error action actGetSexType: ', error)
        }
    },


    // Lấy bảng định nghĩa QUỐC GIA
    async actGetListCountry({ commit }) { 

        try {
            var res = await axiosInstance.get('/share/country')
            const result = res.data;
            // console.log('actGetListCountry = ', result)

            if (res.status === 200) {
                commit('GET_LIST_COUNTRY', result)
                return {
                    ok: true,
                    result: result
                }
            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }
        } catch (error) {
            console.log('Error action actGetListCountry: ', error)
        }
    },


    // Lấy danh sách các tổ chức có quyền ra quyết định xử phạt
    async actGetOrganizationGroup({ commit }) { // Sử dụng lấy thông tin khi tạo và xóa user
        try {
            var res = await axiosInstance.get('/share/organization_group')
            const result = res.data;
            // console.log('result actGetOrganizationGroup = ', result)

            if (res.status === 200) {
                commit('GET_ORGANIZATION_GROUP', result)
                return {
                    ok: true,
                    data: result.organization_group
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetOrganizationGroup: ', error)
        }
    },

    // Lấy danh sách các Điều (Cũng là hành vi)
    async actGetListArctile({ commit }) { // List article
        try {
            var res = await axiosInstance.get('/share/list_article')
            const result = res.data;
            // console.log('result actGetListArctile = ', result)

            if (res.status === 200) {
                commit('GET_LIST_ARTICLE', result)

                return {
                    ok: true,
                    data: result.list_article
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListArctile: ', error)
        }
    },

    // LOAD TRANG: get list violation decision status
    async actGetListDecisionStatus({ commit }) { // List violation decision
        try {
            var res = await axiosInstance.get('/share/list_decision_status')
            const result = res.data;
            // console.log('result actGetListDecisionStatus = ', result)

            if (res.status === 200) {
                commit('GET_LIST_DECISION_STATUS', result)

                return {
                    ok: true
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actGetListDecisionStatus: ', error)
        }
    },



    
}