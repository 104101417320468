// CÁC HÀM FUNCTION VIẾT DÙNG CHUNG

export default {
      install(Vue) {

            // TEMPLATE
            // Vue.prototype.$myMethod = function (methodOptions) {
            //       // some logic ...
            // }

            //1. Message: 
            /* 
                <div v-html="$msg('header', 'body', 'warning')">
                </div>
            */

            Vue.prototype.$msg = (header, body, type = 'success') => {
                  var xhtml = '';
                  if (type === 'success') {
                        xhtml = `<div class="alert alert-success alert-dismissible" style="background-color: #28A745; color: white">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                        <h5><i class="icon fas fa-check"></i> ${header} </h5>
                        ${body}
                    </div>`
                  }

                  if (type === 'warning') {
                        xhtml = `<div class="alert alert-warning alert-dismissible" style="background-color: #FFC107; color: black">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                        <h5><i class="icon fas fa-exclamation-triangle"></i> ${header} </h5>
                        ${body}
                    </div>`
                  }

                  if (type === 'info') {
                        xhtml = `<div class="alert alert-info alert-dismissible" style="background-color: #17A2B8; color: white">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                        <h5><i class="icon fas fa-info"></i> ${header} </h5>
                        ${body}
                    </div>`
                  }

                  if (type === 'danger') {
                        xhtml = `<div class="alert alert-danger alert-dismissible" style="background-color: #DC3545; color: white">
                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                        <h5><i class="icon fas fa-ban"></i> ${header} </h5>
                        ${body}
                    </div>`
                  }
                  return xhtml;
            }

            Vue.prototype.$msgModel = (header, body, type = 'success') => {
                  /* Hướng dẫn:
                        - header: tiêu đề thông báo
                        - body: Nội dung thông báo
                        - type: kiểu thông báo (success, warning, info, danger)
                        - labelBtnAction: Nhãn nút thực hiện hành động
                        - labelBtnCancel: nhãn nút CanCel
                  */
                  var xhtml = '';
                  if (type === 'success') {
                        xhtml = `<div class="modal fade" id="mymodal-lg">
                                    <div class="modal-dialog modal-lg">
                                          <div class="modal-content">
                                                <div class="modal-header bg-${type}">
                                                      <h5 class="modal-title"><i class="icon fas fa-check"></i> ${header} </h5>
                                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                      </button>
                                                </div>
                                                <div class="modal-body">
                                                      <div style="padding: 20px"> ${body} </div>
                                                </div>
                                                <div class="modal-footer justify-content-between">
                                                      <button type="button" class="btn btn-secondary" data-dismiss="modal"> Đóng </button>
                                                </div>
                                          </div>
                                    </div>
                              </div>`
                  }

                  if (type === 'warning') {
                        xhtml = `<div class="modal fade" id="mymodal-lg">
                                    <div class="modal-dialog modal-lg">
                                          <div class="modal-content">
                                                <div class="modal-header bg-${type}">
                                                      <h5 class="modal-title"><i class="icon fas fa-exclamation-triangle"></i> ${header} </h5>
                                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                      </button>
                                                </div>
                                                <div class="modal-body">
                                                      <div style="padding: 20px"> ${body} </div>
                                                </div>
                                                <div class="modal-footer justify-content-between">
                                                      <button type="button" class="btn btn-secondary" data-dismiss="modal"> Đóng </button>
                                                </div>
                                          </div>
                                    </div>
                              </div>`
                  }

                  if (type === 'info') {
                        xhtml = `<div class="modal fade" id="mymodal-lg">
                                    <div class="modal-dialog modal-lg">
                                          <div class="modal-content">
                                                <div class="modal-header bg-${type}">
                                                      <h5 class="modal-title"><i class="icon fas fa-info"></i> ${header} </h5>
                                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                      </button>
                                                </div>
                                                <div class="modal-body">
                                                      <div style="padding: 20px"> ${body} </div>
                                                </div>
                                                <div class="modal-footer justify-content-between">
                                                      <button type="button" class="btn btn-secondary" data-dismiss="modal"> Đóng </button>
                                                </div>
                                          </div>
                                    </div>
                              </div>`
                  }

                  // type === 'danger' Không sử dụng được thì dùng v-html gen ra file html nên v-on:click.prevent="delGroup" không hoạt động
                  return xhtml;
            }

            Vue.prototype.$numberToString = (number) => {
                  // Hàm chuyển CHỮ thành SỐ
                  //=================================================================
                  // Bước 1: Viết hàm convert kiểu số thành chữ cho mỗi khối
                  const defaultNumbers =' hai ba bốn năm sáu bảy tám chín';

                  const chuHangDonVi = ('1 một' + defaultNumbers).split(' ')
                  const chuHangChuc = ('lẻ mười' + defaultNumbers).split(' ')
                  const chuHangTram = ('không một' + defaultNumbers).split(' ')

                  function convert_block_three(number) {
                        if(number == '000') return '';
                        var _a = number + ''; //Convert biến 'number' thành kiểu string
                        //Kiểm tra độ dài của khối
                        switch (_a.length) {
                        case 0: return '';
                        case 1: return chuHangDonVi[_a];
                        case 2: return convert_block_two(_a);
                        case 3: 
                              var chuc_dv = '';
                              if (_a.slice(1,3) != '00') {
                              chuc_dv = convert_block_two(_a.slice(1,3));
                              }
                              var tram = chuHangTram[_a[0]] + ' trăm';
                              return tram + ' ' + chuc_dv;
                        }
                  }

                  function convert_block_two(number) {
                        var dv = chuHangDonVi[number[1]];
                        var chuc = chuHangChuc[number[0]];
                        var append = '';
                        // Nếu chữ số hàng đơn vị là 5
                        if (number[0] > 0 && number[1] == 5) {
                        dv = 'lăm'
                        }
                        // Nếu số hàng chục lớn hơn 1
                        if (number[0] > 1) {
                        append = ' mươi';
                        
                        if (number[1] == 1) {
                              dv = ' mốt';
                        }
                        }
                        return chuc + '' + append + ' ' + dv; 
                  }

                  // Bước 2: Viết hàm nhận vào một số chuyển sang kiểu chữ
                  const dvBlock = '1 nghìn triệu tỷ'.split(' ');

                  function to_vietnamese(number) {
                        var str = parseInt(number) + '';
                        var i = 0;
                        var arr = [];
                        var index = str.length;
                        var result = [];
                        var rsString = '';

                        if (index == 0 || str == 'NaN') {
                              return '';
                        }

                        // Chia chuỗi số thành một mảng từng khối có 3 chữ số
                        while (index >= 0) {
                              arr.push(str.substring(index, Math.max(index - 3, 0)));
                              index -= 3;
                        }

                        // Lặp từng khối trong mảng trên và convert từng khối đấy ra chữ Việt Nam
                        for (i = arr.length - 1; i >= 0; i--) {
                        if (arr[i] != '' && arr[i] != '000') {
                              result.push(convert_block_three(arr[i]));

                              // Thêm đuôi của mỗi khối
                              if (dvBlock[i]) {
                              result.push(dvBlock[i]);
                              }
                        }
                        }

                        // Join mảng kết quả lại thành chuỗi string
                        rsString = result.join(' ');

                        // Trả về kết quả kèm xóa những ký tự thừa
                        return rsString.replace(/[0-9]/g, '').replace(/ /g,' ').replace(/ $/,'');
                  }
                  return to_vietnamese(number);
            }
      }
}

