<template>
	<div>
		<div class="row">
			<!-- DOWNLOAD -->
			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="txt-right" style="margin-right: -30px;">
						<vue-excel-xlsx
							:data="getUserPhoneBook"
							:columns="columns"
							:file-name="'Danh sach can bo'"
							:file-type="'xlsx'"
							:sheet-name="'Danh sach can bo'"
							>
							<span 
								id="hide-btn-vue-excel-xlsx"
								class="btn btn-sm" 
								style="border: 1px solid #5A6268;"
							>
							Tải về 
							<i class="fas fa-download micon"></i>
							</span>
						</vue-excel-xlsx>
					</div>
				</div>
			</div>
			
			<!-- LIST PHONE BOOKS -->
			<grid
				:cols="header_table" 
				:rows="getUserPhoneBook"
				:language="language"
    			:pagination="pagination"
				:search="search"
				:sort="sort"
			>
			</grid>
		</div>  
	</div>
</template>

<script>

	import { Grid } from 'gridjs-vue'
	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'phone-book-table-index',
		components: {
			Grid
		},
		data() {
			return {
				// Download
				// vue-excel-xlsx
				// ----------------------------
				columns: [
					{
						field: 'user_name',
						label: 'Tài khoản'
					},
					{
						field: 'full_name',
						label: 'Đơn vị'
					},
					{
						field: 'address',
						label: 'Địa chỉ'
					},
					{
						field: 'mobile',
						label: 'Điện thoại'
					},
					{
						field: 'email',
						label: 'Email'
					},
					{
						field: 'location',
						label: 'Phạm vi xử phạt'
					}
				],

				// GridJS: table phone-book
				header_table: [
					{ 
						id: 'user_name',
						name: 'Tài khoản',
						width: '13%'
					},
					{
						id: 'full_name',
						name: 'Đơn vị',
						width: '20%'
					},
					{
						id: 'address',
						name: 'Địa chỉ liên hệ',
						width: '20%'
					},
					{
						id: 'mobile',
						name: 'Điện thoại',
						width: '12%'
					},
					{
						id: 'email',
						name: 'Email'
					},
					{ // 5
						id: 'location',
						name: 'Phạm vi xử phạt',
						hidden: false,
						width: '15%'
					},
					{ // 6
						id: 'get_notify_status',
						name: 'Nhận tin báo',
						width: '12%',
						formatter: (cell) => { 
							if(cell === false) return 'Không'
							if(cell === true) return 'Có'
						}
					},
				],

				pagination: true,
				search: true,
				sort: false,
				language: {
					search: {
						placeholder: '🔍 Tìm kiếm ...',
					},
					sort: {
						sortAsc: 'Sắp xếp tăng dần',
						sortDesc: 'Sắp xếp giảm dần',
					},
					pagination: {
						previous: 'Về trước',
						next: 'Kế tiếp',
						navigate: (page, pages) => `Trang ${page} trên tổng ${pages}`,
						page: (page) => `Trang ${page}`,
						showing: 'Hiển thị',
						of: 'trên tổng',
						to: 'từ',
						results: 'kết quả',
					},
					loading: 'Đang load trang ...',
					noRecordsFound: 'Không có kết quả nào được tìm thấy!',
					error: 'Có lỗi xảy ra trong quá trình lấy dữ liệu!',
				},
			}
		},
		created(){
			this.actGetUserPhoneBook()
		},
		computed: {
			...mapGetters([
				'getUserPhoneBook'
			])
		},
		methods: {
			...mapActions([
				'actGetUserPhoneBook',
			])
		}
	}
</script>



<style scoped>
	.mclass {
		border-color: white;
		background-color: white;
		border: 0px;
	}

	.mclass-delete {
		color: #dc3545;
	}

	.mclass-edit {
		color: #05668D;
	}

	.mclass-password {
		color: #157347;
	}

	button {
		background-color: white;
		border: white;
	}
</style>