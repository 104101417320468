<template>
    <div>
        <div class="alert alert-warning alert-dismissible">
            <!-- <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button> -->
            <h5><i class="icon fas fa-exclamation-triangle"></i> Kết quả tra cứu tổ chức</h5>
            Không tìm thấy tổ chức nào vi phạm thỏa mãn điều kiện tìm!
        </div>
    </div>
</template>

<script>
    export default {
        name: 'search-org-not-found',
    }
</script>

<style scoped>

</style>