<template>
    <div>
        <div class="row" style="margin-bottom: 50px">
            <div style="font-weight: bold; margin-top: 20px">Bảng thống kê số tiền vi phạm theo nhóm hành vi</div>
            
			<!-- Download -->
			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="txt-right" style="margin-right: -28px; margin-bottom: 5px">
						<vue-excel-xlsx
							:data="getMoneyBehaviorIndividual"
							:columns="columns"
							:file-name="'fvdms_so tiền vi pham theo hanh vi'"
							:file-type="'xlsx'"
							:sheet-name="'fvdms'"
							>
							<span 
								id="hide-btn-vue-excel-xlsx"
								class="btn btn-sm" 
								style="border: 1px solid #D9D9D9;"
							>
							Tải về 
							<i class="fas fa-download micon"></i>
							</span>
						</vue-excel-xlsx>
					</div>
				</div>
			</div>
			
			<!-- Table -->
			<grid
				:cols="header_table" 
				:rows="getMoneyBehaviorIndividual"
				:language="language"
    			:pagination="pagination"
				:search="search"
				:sort="sort"
			>
			</grid>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'report-individual-table-behavior-money',
        data() {
            return {
				// Download
				columns: [
					{ 
						field: 'stt',
						label: 'Stt'
					},
					{
						field: 'behavior_name',
						label: 'Hành vi vi phạm'
					},
					{ 
						field: 'money_penalty',
						label: 'Số tiền (đồng)'
					},
					{
						field: 'per_cent',
						label: 'Tỷ lệ (%)'
					},
				],
                num_type_behavior: 0,
                total_num_person: 0,
				header_table: [
					{ 
						id: 'stt',
						name: 'Stt'
					},
					{ 
						id: 'behavior_name',
						name: 'Hành vi vi phạm'
					},
					{
						id: 'money_penalty',
						name: 'Số tiền (đồng)',
						formatter: (cell) => this.$gridjs.html(new Intl.NumberFormat('vi-IT').format(cell))
					},
                    {
						id: 'per_cent',
						name: 'Tỷ lệ (%)',
                        formatter: (cell) => this.$gridjs.html(new Intl.NumberFormat('vi-IT').format(cell))
					}
				],

				pagination: false,
				sort: true,
				search: false,
				language: {
					search: {
						placeholder: '🔍 Tìm kiếm ...',
					},
					sort: {
						sortAsc: 'Sắp xếp tăng dần',
						sortDesc: 'Sắp xếp giảm dần',
					},
					pagination: {
						previous: 'Về trước',
						next: 'Kế tiếp',
						navigate: (page, pages) => `Trang ${page} trên tổng ${pages}`,
						page: (page) => `Trang ${page}`,
						showing: 'Hiển thị',
						of: 'trên tổng',
						to: 'từ',
						results: 'kết quả',
					},
					loading: 'Đang load trang ...',
					noRecordsFound: 'Không có kết quả nào được tìm thấy!',
					error: 'Có lỗi xảy ra trong quá trình lấy dữ liệu!',
				},
            }
        },
        computed: {
			...mapGetters([
                'getMoneyBehaviorIndividual'
			])
		},
		methods: {
			...mapActions([ ]),

			handleAct(){
				
			}
		}
    }
</script>

<style>
	td[data-column-id = 'stt'],
    td[data-column-id = 'money_penalty'],
    td[data-column-id = 'per_cent']
    {
        text-align: right;
    }
</style>