<template>
    <div>
        <!-- Tiêu đề báo cáo -->
        <div class="mtitle" v-html="html_title_report"></div>

        <!-- Bộ lọc thời gian -->
        <div class="row" style="margin-top: 20px">
            <div class="col-md-6">
                <div><i class="far fa-calendar-alt myicon"></i> Thời gian báo cáo</div>                               
                <v-date-picker 
                    :masks="masks"
                    :attributes='attrs'
                    :max-date='new Date()'
                    show-weeknumbers
                    @input="handStartDate()"
                    v-model="date_start">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group">
                            <input
                                placeholder="Thời gian bắt đầu: DD-MM-YYYY"
                                class="form-control"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </div>
                    </template>
                </v-date-picker>
            </div>
            <div class="col-md-6" style="margin-top: 23px;">
                <v-date-picker 
                    :masks="masks"
                    :attributes='attrs'
                    :max-date='new Date()'
                    show-weeknumbers
                    @input="handEndDate()"
                    v-model="date_end">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group">
                            <input
                                placeholder="Thời gian kết thúc: DD-MM-YYYY"
                                class="form-control"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </div>
                    </template>
                </v-date-picker>
            </div>
        </div>
        <button type="button" 
                class="btn btn-sm float-right" 
                style="width: 100px; margin-top: 10px; background-color: #e0b153; color: white"
                @click="handleResetFormFilter()"> Hủy lọc </button>

        <button type="button" 
                class="btn btn-sm float-right" 
                style="width: 100px; margin-top: 10px; margin-right: 10px; background-color: #307484; color: white"
                @click="handleFilter()"> Lọc </button>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { DATE_TYPE, LOCATION_LEVEL } from '@/config/enum'
    import moment from 'moment';

    export default {
        name: 'report-organization-form-district',
        data() {
            return {
                location_level_code: LOCATION_LEVEL.DISTRICT,

                // Thời gian thống kê: mặc định là năm hiện tại
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),

                // VCalendar
                attrs: [
                    {
                        key: 'today',
                        highlight: true,       // dot
                        dates: new Date()
                    }
                ],
                masks: {
                    input: 'DD-MM-YYYY'
                },
            }
        },
        created() {
            this.handleResetFormFilter('by-user-login')
        },
        watch: {
            date_end(newVal) {
                if(moment(newVal) < moment(this.date_start)) {
                    toastr.error("Thời gian kết thúc nhỏ hơn bắt đầu", "Lỗi chọn thời gian kết thúc", { "closeButton": true });
                }
            }
        },  
        computed: {
			...mapGetters([
                'getCurrentUser'
			]),

            // eslint-disable-next-line vue/return-in-computed-property
            html_title_report: function (){
                // Mặc định năm hiện tại
                let isCurrentYear = this.date_start === '' && this.date_end === ''
                if(isCurrentYear) return `<span>THỐNG KÊ VI PHẠM TỪ ${moment().startOf('year').format("DD-MM-YYYY")} ĐẾN ${moment().format('DD-MM-YYYY')}</span>`
                
                // Chỉ chọn ngày bắt đầu
                let isOnlySelectedStartDate = this.date_start !== '' && this.date_end === ''
                if(isOnlySelectedStartDate) {
                    return `<span>THỐNG KÊ VI PHẠM TỪ ${moment(this.date_start).format("DD-MM-YYYY")}</span>`
                }

                // Chỉ chọn ngày kết thúc
                let isOnlySelectedEndDate = this.date_start === '' && this.date_end !== ''
                if(isOnlySelectedEndDate) {
                    return `<span>THỐNG KÊ VI PHẠM ĐẾN NGÀY ${moment(this.date_end).format("DD-MM-YYYY")}</span>`
                }

                // Nếu chọn ngày bắt đầu và ngày kết thúc
                let isSelectedRangeDate = this.date_start !== '' && this.date_end !== ''
                if(isSelectedRangeDate) {
                    let date_start = moment(this.date_start).format("DD-MM-YYYY")
                    let date_end = moment(this.date_end).format("DD-MM-YYYY")
                    return `<span>THỐNG KÊ VI PHẠM TỪ ${date_start} ĐẾN ${date_end}</span>`
                }
            },

		},
		methods: {
            ...mapActions([ 
                'actReportOrganization'
            ]),

            // SELECT INFO ============================================
            handStartDate(){
                this.date_start = moment(this.date_start).format('YYYY-MM-DD')
            },

            handEndDate(){
                this.date_end = moment(this.date_end).format('YYYY-MM-DD')
            },


            // VALIDATE INFO ============================================
            validateDateSelected(){
                if(this.date_start === ''){
                    toastr.error("Chưa chọn thời gian bắt đầu!", "", { "closeButton": true });
                    return false
                }

                if(this.date_end === ''){
                    toastr.error("Chưa chọn thời gian kết thúc!", "", { "closeButton": true });
                    return false
                }

                if(moment(this.date_end) < moment(this.date_start)) {
                    toastr.error("Thời gian kết thúc nhỏ hơn bắt đầu", "Lỗi chọn thời gian kết thúc", { "closeButton": true });
                    return false
                }

                return true
            },          

            // HANDLE GET DATA ============================================
            handleFilter(){
                let isDateSelectedValid     = this.validateDateSelected()
                if(isDateSelectedValid){
                    let data = {
                        user_id             : this.getCurrentUser.user_id,
                        role_id             : this.getCurrentUser.role_id,
                        location_level_code : LOCATION_LEVEL.DISTRICT,
                        location_code       : this.getCurrentUser.location_code,
                        date_type           : DATE_TYPE.DATE_ISSUED,                                                   
                        date_start          : this.date_start,
                        date_end            : this.date_end,
                        type_get_data       : 'filter'
                    }

                    var data_json = JSON.stringify(data)

                    this.actReportOrganization(data_json).then(() => {
                        toastr.success("Lọc thành công!", "", { "closeButton": true });
                    })
                }
            },

            handleResetFormFilter(type='filter'){
                var data = {
                    user_id             : this.getCurrentUser.user_id,
                    role_id             : this.getCurrentUser.role_id,
                    location_level_code : this.getCurrentUser.location_level_code,
                    date_type           : DATE_TYPE.DATE_ISSUED,                                                  
                    date_start          : moment().startOf('year').format("YYYY-MM-DD"),
                    date_end            : moment().format("YYYY-MM-DD"),
                    type_get_data       : 'by_user'
                }

                var data_json = JSON.stringify(data)

                this.actReportOrganization(data_json).then(() => {
                    if(type == 'filter') toastr.success("Hủy lọc thành công!", "", { "closeButton": true });
                })
            },
		}
    }
</script>

<style scoped>
    .mfont-size {
        font-size: 14px;
    }

    .number-circle {
        padding: 2px 10px; 
        border-radius: 50%; 
        background-color: #307484; 
        color: white
    }

    .header {
        color: #307484; 
        margin-bottom: 10px; 
        margin-top: 40px;
        font-size: 17px;
    }

    .mtitle {
        color: #307484; 
        margin-bottom: 10px; 
        margin-top: 30px;
        font-size: 20px;
        font-weight: 500
    }

</style>