<template>
    <div>
        <div class="card-body" style="margin-bottom: 150px;">
            <!-- 1. THÔNG TIN CÁ NHÂN -->
            <!-- ========================================================================== -->
            <div>
                <div class="mheader-2" style="font-size: 14px;"> 
                    THÔNG TIN NGƯỜI GỬI TIN BÁO
                    <div style="color: red; float: right; font-weight: normal;">Tin báo của Ông/Bà gửi đến hệ thống sẽ được bảo mật!</div>
                </div>
               
                <div style="clear: both;"></div>
                <div class="row">
                    <div class="col-md-6">
                        <i class="fas fa-user micon"></i> Họ và tên <sup><code>(*)</code></sup>
                        <div class="input-group mb-3">
                            <input v-model="full_name" type="text" class="form-control" placeholder="Nhập họ và tên" autocomplete="off">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <i class="fas fa-map-marker-alt micon"></i> Địa chỉ <sup><code>(*)</code></sup>
                        <div class="input-group mb-3">
                            <input v-model="address" type="text" class="form-control" placeholder="Nhập địa chỉ nơi ở" autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <i class="fas fa-mobile micon"></i> Điện thoại <sup><code>(*)</code></sup>
                        <div class="input-group mb-3">
                            <input v-model="mobile" type="number" class="form-control" placeholder="Nhập số điện thoại" autocomplete="off">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <i class="fas fa-envelope micon"></i> Email 
                        <div class="input-group mb-3">
                            <input v-model="email" type="text" class="form-control" placeholder="Nhập địa chỉ email" autocomplete="off">
                        </div>
                    </div>
                    
                </div>
                <div>
                    <i class="fas fa-info micon"></i> Thông tin khác 
                    <div class="input-group mb-3">
                        <textarea v-model="other_info" type="textarea" class="form-control" placeholder="Nhập thông tin khác" autocomplete="off"></textarea>
                    </div>
                </div>
            </div>

            <!-- 2. NỘI DUNG TIN BÁO -->
            <!-- ========================================================================== -->
            <div>
                <div class="mheader-2" style="font-size: 14px; margin-top: 40px;"> 
                    NỘI DUNG TIN BÁO
                </div>
                <div>
                    <div class="mheader-3"><i class="fas fa-flag micon"></i>
                        <span> Hành vi vi phạm <sup><code>(*)</code></sup> </span></div>
                    <multiselect
                        id="article_id"
                        track-by="article_id" label="behavior_name" 
                        @input="handleGetBehavior()"
                        v-model="behavior_selected" 
                        open-direction="bottom"
                        placeholder="Chọn hành vi vi phạm"
                        :options="getListBehavior" 
                        :multiple="false"
                        :searchable="true"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :show-pointer="true"
                        :hide-selected="true">
                    </multiselect>
                </div>

                <div style="margin-top: 20px;">
                    <i class="fas fa-map-marker-alt micon"></i> Địa điểm phát hiện vi phạm
                    <div class="input-group mb-3">
                        <textarea v-model="location_violation" type="textarea" class="form-control" 
                            placeholder="Nhập thông tin chi tiết về địa điểm phát hiện vi phạm (ví dụ: tiểu khu, khoảnh, lô, đường phố, thôn, bản, xã, huyện, tỉnh....)" autocomplete="off"></textarea>
                    </div>
                </div>

                <div style="margin-top: 20px;">
                    <i class="fas fa-info micon"></i> Mô tả thông tin chi tiết khác
                    <div class="input-group mb-3">
                        <textarea v-model="detail_violation" type="textarea" class="form-control" style="min-height: 150px;"
                            placeholder="Mô tả chi tiết về hành vi vi phạm hoặc thông tin khác" autocomplete="off"></textarea>
                    </div>
                </div>
                
            </div>

            <!-- 3. ĐƠN VỊ TIẾP NHẬN TIN BÁO -->
            <!-- ========================================================================== -->
            <div>
                <div class="mheader-2" style="font-size: 14px; margin-top: 40px;"> 
                    ĐƠN VỊ TIẾP NHẬN TIN BÁO
                </div>

                <!-- Phạm vi -->
                <div>
                    <div class="row">
                        <!-- Cấp tiếp nhận tin báo -->
                        <div class="col-md-6">
                            <p class="mytitle"> <i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Cấp tiếp nhận tin báo <code>*</code></p>
                            <multiselect
                                id="location_level"
                                track-by="location_level_code" label="location_level_name" 
                                @input="handleGetLocationLevel()"
                                v-model="location_level_selected" 
                                placeholder="Chọn cấp tiếp nhận tin báo"
                                open-direction="bottom"
                                :options="listLocationLevel" 
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :show-pointer="true"
                                :hide-selected="true">
                            </multiselect>
                        </div>

                        <!-- Đơn vị tiếp nhận tin báo -->
                        <div class="col-md-6" v-if="location_level_code !== ''">
                            <p class="mytitle"> <i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Đơn vị tiếp nhận tin báo <code>*</code></p>
                            <multiselect
                                id="organization"
                                track-by="organization_code" label="organization_name" 
                                @input="handleGetOrganization()"
                                v-model="organization_selected" 
                                placeholder="Chọn đơn vị tiếp nhận tin báo"
                                open-direction="bottom"
                                :options="listOrgByLocationLevel" 
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :clear-on-select="false"
                                :show-pointer="true"
                                :hide-selected="true">
                            </multiselect>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 15px">
                        <!-- Cấp vùng -->
						<div v-if="this.organization_code === 3" style="margin-top: 10px">
							<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn vùng <code>*</code></p>
							<multiselect
								id="zone_id"
								track-by="zone_code" label="zone_name" 
								@input="handleGetZone()"
                                v-model="zone_selected" 
								open-direction="bottom"
								:options="zone_options" 
								:multiple="false"
								:searchable="true"
								:close-on-select="true"
								:clear-on-select="true"
								:show-pointer="true"
								:hide-selected="true">
							</multiselect>
						</div>

                        <!-- Cấp tỉnh -->
						<div v-if="this.organization_code === 4 || this.organization_code === 5" style="margin-top: 10px">
							<p class="mytitle"><i class="fas fa-globe-africa" style="width: 20px; color: #143F6B;"></i> Chọn tỉnh <code>*</code></p>
							<multiselect
								id="province_id"
								track-by="province_code" label="province_name" 
								@input="handleGetProvince()"
                                v-model="province_selected" 
								open-direction="bottom"
								:options="province_options" 
								:multiple="false"
								:searchable="true"
								:close-on-select="true"
								:clear-on-select="true"
								:show-pointer="true"
								:hide-selected="true">
							</multiselect>
						</div>
                    </div>

                    <!-- Cấp huyện -->
                    <!-- Chọn tỉnh có huyện có email nhận tin cảnh báo -->
                    
                    <div class="row" style="margin-top: 15px" v-if="this.organization_code === 6">
                        <!-- Cấp tỉnh -->
                        <div class="col-md-6">
                            <p class="mytitle"><i class="fas fa-globe-africa" style="color: #143F6B;"></i> Chọn tỉnh <code>*</code></p>
                            <multiselect
                                id="province_id"
                                track-by="province_code" label="province_name" 
                                @input="handleGetProvinceShowDistrict()"
                                v-model="province_selected" 
                                open-direction="bottom"
                                :options="province_options" 
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :clear-on-select="true"
                                :show-pointer="true"
                                :hide-selected="true">
                            </multiselect>
                        </div>

                        <!-- Cấp huyện thuộc tỉnh -->
                        <div class="col-md-6" v-if="this.district_selected !== ''">
                            <p class="mytitle"><i class="fas fa-globe-africa" style="color: #143F6B;"></i> Chọn huyện <code>*</code></p>
                            <multiselect
                                id="district_id"
                                track-by="district_code" label="district_name" 
                                @input="handleGetDistrict()"
                                v-model="district_selected" 
                                open-direction="bottom"
                                :options="district_options" 
                                :multiple="false"
                                :searchable="true"
                                :close-on-select="true"
                                :clear-on-select="true"
                                :show-pointer="true"
                                :hide-selected="true">
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>

            <!-- GỬI TIN -->
            <div>
                <!-- Lưu quyết định -->
                <button type="button" 
                        style="background-color: #05668D; width: 150px; margin-top: 20px;"
                        class="btn btn-primary btn-sm"
                        @click="sendNotify()"
                        > Gửi tin báo </button>
            </div>
        </div>        
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'form-add-notify',
        data() {
            return {
                // 1. Thông tin cá nhân
                full_name: '',
                address: '',
                email: '',
                mobile: '',
                other_info: '',             // Thông tin khác

                // 2. Nội dung tin báo
                article_id_selected: '',    // Mã hành vi vi phạm người dùng chọn
                behavior_name: '',          // Tên hành vi vi phạm
                behavior_selected: [],      // Hành vi vi phạm người dùng chọn
                location_violation: '',     // Địa điểm phát hiện vi phạm
                detail_violation: '',       // Mô tả chi tiết thông tin khác
                status_process_id: 1,       // Trạng thái xử lý tin, mặc định khi gửi tin là chưa xử lý
                
                // 3. Đơn vị tiếp nhận tin báo
                organization_get_notify: '',    // Tên đơn vị tiếp nhận tin báo
                email_get_notify: '',
                location_level_code: '',        // Cấp nhận tin
                location_level_selected: [], 
                location_code:  '',             // Địa điểm: Quốc gia, vùng, tỉnh, huyện

                organization_code: '',          // Đơn vị tiếp nhận tin
                organization_selected: [],
         
                zone_selected: [],              // Vùng
                zone_options: [],

                province_selected: [],          // Tỉnh: Cục Kiểm lâm, Đội Kiểm lâm cơ động
                province_options: [],
             
                district_selected: [],          // Huyện
                district_options: [],

                // Note: không sử dụng zone_code, province_code, district_code mà sử dụng location_code thay thế
            }
        },
        async created() {
            await this.actGetListBehavior(); // Lấy danh sách các mục ở chương II  
            await this.actGetLocationLevel()   
        },
        computed: {
			...mapGetters([
                 "getListBehavior",
                 'listLocationLevel',
                 'listOrgByLocationLevel'
			]),

		},
		methods: {
			...mapActions([ 
                'actGetListBehavior',
                'actGetLocationLevel',
                'actGetOrgByLocationLevel',
                'actGetEmailFPD',                           // Cấp cục
                'actGetZoneGetNotify',                      // Cấp vùng
                'actGetProvinceGetNotify',                  // Cấp tỉnh
                'actGetProvinceForGetDistrictNotify',       // Cấp huyện: chọn tỉnh
                'actGetDistrictGetNotifyByProvince',        // Cấp huyện: chọn huyện
                'actSendNofity'                             // Gửi tin cảnh báo
            ]),

			handleGetBehavior(){
				this.article_id_selected = this.behavior_selected.article_id
                this.behavior_name       = this.behavior_selected.behavior_name
			},

            handleGetLocationLevel(){                
                if(Object.keys(this.location_level_selected).length >= 1){// Nếu chọn location_level
					this.location_level_code = this.location_level_selected.location_level_code
					this.actGetOrgByLocationLevel(this.location_level_code).then(() => {
                        this.resetUnderLocationLevel()
                    })
				}
            },

            // Chọn đơn vị 
            async handleGetOrganization(){
                this.organization_code = this.organization_selected.organization_code
                await this.resetUnderOrganization()

                // Cấp cục
                if( this.organization_code === 1 || this.organization_code === 2) 
                {
                    this.location_code = 704        // Mã quốc gia VN
                    this.organization_get_notify = this.organization_selected.organization_name

                    this.actGetEmailFPD(this.organization_code).then((res) => {
                        if(res.ok){
                            this.email_get_notify = res.email
                        }
                    })
                }

                // Cấp vùng
                if( this.organization_code === 3) 
                {
                    this.actGetZoneGetNotify(this.organization_code).then((res) => {
                        if(res.ok){
                            this.zone_options = res.zone
                        }
                    })
                }

                // Cấp tỉnh: Chi cục Kiểm lâm / Đội Kiểm lâm cơ động
                if( this.organization_code === 4 || this.organization_code === 5) 
                {
                    this.actGetProvinceGetNotify(this.organization_code).then((res) => {
                        if(res.ok){
                            this.province_options = res.province
                        }
                    })
                }
                
                // Huyện: Hạt kiểm lâm
                if( this.organization_code === 6) 
                {
                    // Lấy danh sách tỉnh có huyện email nhận tin đã được active
                    this.actGetProvinceForGetDistrictNotify().then((res) => {
                        if(res.ok){
                            this.province_options = res.province
                        }
                    })
                }
            },

            // Chọn vùng
            handleGetZone(){
                this.organization_get_notify = "Kiểm lâm vùng " + this.zone_selected.zone_code
                this.location_code           = this.zone_selected.zone_code
                this.email_get_notify        = this.zone_selected.email
            },

            // Chọn tỉnh
            handleGetProvince(){
                if(this.organization_code === 4){
                    this.organization_get_notify = "Chi cục Kiểm lâm " + this.province_selected.province_name
                }

                if(this.organization_code === 5){
                    this.organization_get_notify = "Đội kiểm lâm cơ động và PCCC " + this.province_selected.province_name
                }
                
                this.location_code           = this.province_selected.province_code
                this.email_get_notify        = this.province_selected.email 
            },

            // Chọn huyện
            // Chọn huyện: Liệt kê danh sách tỉnh
            handleGetProvinceShowDistrict(){
                this.resetDistrict()
                this.location_code           = this.province_selected.province_code

                // Lấy danh sách huyện thuộc tỉnh có huyện email nhận tin đã được active
                this.actGetDistrictGetNotifyByProvince(this.location_code).then((res) => {
                    if(res.ok){
                        this.district_options = res.district
                    }
                })
            },

            // Chọn huyện: Liệt kê danh sách huyện thuộc tỉnh
            handleGetDistrict(){
                this.organization_get_notify = "Hạt Kiểm lâm " + this.district_selected.district_name
                this.location_code           = this.district_selected.district_code
                this.email_get_notify        = this.district_selected.email
            },

            // Validate data
            validateData(){
                // Thông tin người gửi tin
                if(this.full_name === ''){
                    toastr.error('Chưa nhập họ và tên người gửi tin.', '')
                    return false
                }

                if(this.address === ''){
                    toastr.error('Chưa nhập địa chỉ người gửi tin.', '')
                    return false
                }

                if(this.mobile === ''){
                    toastr.error('Chưa nhập số điện người gửi tin.', '')
                    return false
                }

                // Nội dung tin báo
                if(this.article_id_selected === ''){
                    toastr.error('Chưa chọn hành vi vi phạm.', '')
                    return false
                }

                // Đơn vị nhận tin
                if(this.location_level_code === ''){
                    toastr.error('Chưa chọn cấp nhận tin báo.', '')
                    return false

                }else{ // Nếu đã chọn cấp tiếp nhận

                    if(this.organization_code === ''){
                        toastr.error('Chưa chọn đơn vị tiếp nhận tin báo', '')
                        return false

                    }else{ // Nếu đã chọn đơn vị tiếp nhận

                        if(this.organization_code === 3){
                            if(this.location_code === ''){
                                toastr.error('Chưa chọn vùng nhận tin báo', '')
                                return false
                            }
                        }

                        if( this.organization_code === 4 || 
                            this.organization_code === 5 ||
                            this.organization_code === 6
                        ){
                            if(this.location_code === ''){
                                toastr.error('Chưa chọn tỉnh nhận tin báo', '')
                                return false
                            }
                        }

                        if(this.organization_code === 6 && this.district_options.length > 0){
                            if(this.location_code === ''){
                                toastr.error('Chưa chọn huyện nhận tin báo', '')
                                return false
                            }
                        }  
                    }                
                }

                return true
            },

            // GỬI TIN
            sendNotify(){
                // 1. Validate data
                var flag_validate    = this.validateData()
          
                // 2. Get content for send
                if(flag_validate){ // Nếu không có lỗi
                    var data = {
                        // Thông tin cá nhân
                        full_name: this.full_name,
                        address: this.address,
                        email: this.email,
                        mobile: this.mobile,
                        other_info: this.other_info,  

                        // Nội dung tin báo
                        behavior_name: this.behavior_name,
                        article_id: this.article_id_selected,
                        location_violation: this.location_violation,
                        detail_violation: this.detail_violation,
                        status_process_id: this.status_process_id,

                        // Đơn vị nhận tin báo: 
                        email_get_notify: this.email_get_notify,
                        organization_get_notify: this.organization_get_notify,
                        location_level_code: this.location_level_code,
                        location_code: this.location_code
                    }

                    var data_json = JSON.stringify(data)
                    // console.log(data)

                    this.actSendNofity(data_json).then((res) => {
                        if(res.ok){
                            // Thông báo thành công
                            toastr.success(res.message, '', {closeButton: true})

                            // Reset form
                            this.resetForm()

                        }else{
                            toastr.error(res.message, '', {closeButton: true})
                        }
                        
                    })
                }
            },

            resetForm(){
                this.full_name = '' 
                this.address = '' 
                this.email = '' 
                this.mobile = '' 
                this.other_info = '' 

                this.article_id_selected = ''     
                this.behavior_name = ''          
                this.behavior_selected = []       
                this.location_violation = ''     
                this.detail_violation = ''  
                
                this.location_level_code = ''
                this.location_level_selected = []

                this.resetUnderLocationLevel()
            },

            resetUnderLocationLevel(){
                this.organization_get_notify = ''
                this.email_get_notify = ''
                this.location_code = ''

                this.resetOrg()
                this.resetZone()
                this.resetProvince()
                this.resetDistrict()
            },

            resetUnderOrganization(){
                this.email_get_notify = ''
                this.location_code = ''
                this.resetZone()
                this.resetProvince()
                this.resetDistrict()
            },

            resetOrg(){
                this.organization_code = ''
                this.organization_selected = []
            },

            resetZone(){
                this.zone_selected = []
                this.zone_options = []
            },

            resetProvince(){
                this.province_selected = []
                this.province_options = []
            },

            resetDistrict(){
                this.district_selected = []
                this.district_options = []
            }
		}
    }
</script>

<style scoped>

</style>