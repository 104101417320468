<template>
    <div>
        <div class="card" style="border-radius: 0px" id="report">
            <!-- Navbar report -->
            <Navbar
                v-on:emitEntityType="setEntityType"
            />            
        
            <!-- Tra cứu kết quả xử phạt -->
            <!-- 1. Cá nhân -->
            <FormSearchIndividual 
                v-if="entity_type_id === 1" 
                v-on:searchIndividualNotFound="setSearchIndividualNotFound"
            />

            <!-- 2. Tổ chức -->
            <FormSearchOrganization 
                v-if="entity_type_id === 2" 
                v-on:searchOrganizationNotFound="setSearchOrganizationNotFound"
            />            
        </div>

        <!-- DANH SÁCH KẾT QUẢ XỬ PHẠT 
        ====================================================================== -->
        <!-- 1. Cá nhân -->
        <TableSearchIndividual
            v-if="entity_type_id === 1 & getListIndividualSearch.length > 0"
        />

        <!-- 2. Tổ chức -->
        <TableSearchOrganization
            v-if="entity_type_id === 2 & getListOrganizationSearch.length > 0"
        />

        <!-- SEARCH NOT FOUND 
        ====================================================================== -->
        <!-- 1. Cá nhân -->
        <SearchIndividualNotFound
            v-if="entity_type_id === 1 & isSearchIndividualNotFound"
        />

        <!-- 2. Tổ chức -->
        <SearchOrganizationNotFound
            v-if="entity_type_id === 2 & isSearchOrgnizationNotFound"
        />
        
        
    </div>
</template>

<script>
    import Navbar from './Navbar.vue'
    import FormSearchIndividual from './FormSearchIndividual.vue'
    import FormSearchOrganization from './FormSearchOrganization.vue'
    import TableSearchIndividual from './TableSearchIndividual.vue'
    import TableSearchOrganization from './TableSearchOrganization.vue'
    import SearchIndividualNotFound from './SearchIndividualNotFound.vue'
    import SearchOrganizationNotFound from './SearchOrganizationNotFound.vue'
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'comp-search-result',
        components: {
            Navbar,
            FormSearchIndividual,
            TableSearchIndividual,
            FormSearchOrganization,
            TableSearchOrganization,
            SearchIndividualNotFound,
            SearchOrganizationNotFound
        },
        data() {
            return {
                entity_type_id: 1,                      // Mặc định hiển thị là cá nhân
                isSearchIndividualNotFound: false,
                isSearchOrgnizationNotFound: false,
            }
        },
        computed: {
			...mapGetters([
                'getListIndividualSearch',
                'getListOrganizationSearch'
			])
		},
		methods: {
			...mapActions([ ]),

			setEntityType(entity_type_id){
				this.entity_type_id = parseInt(entity_type_id)
			},

            setSearchIndividualNotFound(status){
                this.isSearchIndividualNotFound = status
            },

            setSearchOrganizationNotFound(status){
                this.isSearchOrgnizationNotFound = status
            }
		}
    }
</script>

<style scoped>


</style>