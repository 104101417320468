

export default {
    GET_LIST_BEHAVIOR(state, data) {
        state.list_behavior = data
    },

    GET_LIST_LOCATION_LEVEL(state, data) {
        state.list_location_level = data
    },

    GET_LIST_ORG_BY_LOCATION_LEVEL(state, data) {
        state.list_org_by_location_level = data
    },

    GET_LIST_NOTIFY_RESULT(state, data) {
        state.list_notify_result = data
    },

}