<template>
	<div class="content-header" style="margin: 30px -8px 0px;">
        <div>
            <p class="mymain-header">DANH SÁCH TIN BÁO VI PHẠM </p>
        </div>
        
        <!-- Thống kê -->
        <div class="row">

        </div>
 
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
	
    export default {
		name: 'notify-result-header',
        data() {
            return {

            }
        },
        computed: {
			...mapGetters([
	
			])
		},
		methods: {
			...mapActions([

			]),
        }
	}
</script>

<style scoped>


</style>