<template>
	<div class="content-header" style="margin-left:5px; margin-bottom: 30px;">
        <div>
            <p class="mymain-header" v-if="isAdminSystem || isSuperAdmin">QUẢN TRỊ TÀI KHOẢN HỆ THỐNG FVDMS</p>
            <p class="mymain-header" v-if="isAdminProvince">QUẢN TRỊ TÀI KHOẢN CHI CỤC KIỂM LÂM <span style="text-transform: uppercase;">{{getCurrentUser.location}}</span></p>
        </div>

        <div class="callout callout-info position-relative">
			<div class="ribbon-wrapper ribbon-lg">  <!-- ribbon-xl -->
				<div class="ribbon bg-warning">  <!-- text-lg-->
					SỐ LƯỢNG TK
				</div>
			</div>

            <!-- Quản trị hệ thống -->
			<div class="row" v-if="isAdminSystem || isSuperAdmin" style="margin: 20px 0px;">

                <!-- CỤC KIỂM LÂM -->
                <div class="col-md-3" style="cursor: pointer;" @click="handleGetUserByLocationLevel(1)">
                    <i class="fas fa-user-cog" style="color: #5C6B91"></i> Cục kiểm lâm <span class="badge badge-info right"> {{getFpdUser}} </span>
                </div>

                <!-- KIỂM LÂM VÙNG -->
                <div  class="col-md-3" style="cursor: pointer;" @click="handleGetUserByLocationLevel(2)">
                    <i class="fas fa-user" style="color: #5C6B91"></i> Kiểm lâm vùng <span class="badge badge-info right">{{getZoneUser}} </span>
                </div>

                <!-- KIỂM LÂM TỈNH -->
                <div class="col-md-3" style="cursor: pointer;" @click="handleGetUserByLocationLevel(3)">
                    <i class="fas fa-user" style="color: #5C6B91"></i> Chi cục kiểm lâm <span class="badge badge-info right">{{getProvinceUser}} </span>
                </div>

                <!-- HẠT KIỂM LÂM -->
                <div class="col-md-3" style="cursor: pointer;" @click="handleGetUserByLocationLevel(4)">
                    <i class="fas fa-user" style="color: #5C6B91"></i> Hạt kiểm lâm <span class="badge badge-info right">{{getDistrictUser}} </span>
                </div>

				<!-- TỔNG SỐ -->
                <div style="margin: 30px 0px 0px 0px; cursor: pointer;"  @click="handleGetAllUser()">
                    Tổng số <span class="badge badge-info right"> 
                {{getTotalUser}} tài khoản </span></div>				
			</div>

            <!-- Quản trị cấp tỉnh -->
            <div class="row" v-if="isAdminProvince" style="margin: 20px 0px;">
                <!-- KIỂM LÂM TỈNH -->
                <div class="col-md-3">
                    <i class="fas fa-user" style="color: #5C6B91"></i> Chi cục kiểm lâm <span class="badge badge-info right">{{getProvinceUser}} </span>
                </div>

                <!-- HẠT KIỂM LÂM -->
                <div class="col-md-3">
                    <i class="fas fa-user" style="color: #5C6B91"></i> Hạt kiểm lâm <span class="badge badge-info right">{{getDistrictUser}} </span>
                </div>

                <!-- TỔNG SỐ -->
                <div style="margin: 30px 0px 0px 0px;">
                    Tổng số <span class="badge badge-info right"> 
                {{getTotalUser}} tài khoản </span></div>				
            </div>

		</div>

        <!-- Tài liệu hướng dẫn -->
        <div class="pointer mdownload"><i class="fas fa-book micon"></i> <span style="font-size: 95%"> Tài liệu hướng dẫn quản trị hệ thống FVDMS </span></div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
	
    export default {
		name: 'user-manage-header',
        data() {
            return {
                isShow: false
            }
        },
        computed: {
			...mapGetters([
                'isSuperAdmin',
                'isAdminSystem',
                'isAdminProvince',
                'getCurrentUser',
				'getTotalUser',
                'getFpdUser',
                'getZoneUser',
                'getProvinceUser',
                'getDistrictUser',
			])
		},
        created() {
            var user = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_code: this.getCurrentUser.location_code,
                location_level_code: this.getCurrentUser.location_level_code
            }

            var user_json = JSON.stringify(user)
            this.actGetNumberUserByRole(user_json)
        },
		methods: {
			...mapActions([
				'actGetNumberUserByRole',
                'actGetUserByLocationLevelFromManageUser',
                'actGetAllUser'
			]),

            handleGetAllUser(){
				this.actGetAllUser().then(() => {})
			},

            handleGetUserByLocationLevel(location_level_code){   
                this.actGetUserByLocationLevelFromManageUser(location_level_code).then(()=>{})
            },
        }
	}
</script>

<style scoped>
	.mya {
        text-decoration: none;
        color: #138496;
    }

    .mya:hover {
        color: #138496;
        text-decoration: none;
    }

    .mdownload {
        color: #138496;
    }

    .mdownload:hover {
        color: darkgreen;
    }

</style>