<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <div class="header msub-header">
                    <span class="number-circle">I.1</span> Số tổ chức vi phạm
                </div>
                <ul>
                    <li>Tổng số: <span class="badge bg-light mfont-size">{{getDecisionOrganization.total}}</span>tổ chức </li>
                    <li>Số tổ chức chờ thi hành: <span class="badge bg-light mfont-size">{{getDecisionOrganization.num_wait}}</span>tổ chức</li>
                    <li>Số tổ chức đang thi hành: <span class="badge bg-light mfont-size">{{getDecisionOrganization.num_doing}}</span>tổ chức</li>
                    <li>Số tổ chức đã thi hành: <span class="badge bg-light mfont-size">{{getDecisionOrganization.num_completed}}</span>tổ chức</li>
                </ul>
            </div>

            <div class="col-md-6">
                <div class="header msub-header">
                    <span class="number-circle">I.2</span> Số quyết định xử phạt
                </div>
                <ul>
                    <li>Tổng số: <span class="badge bg-light mfont-size">{{getDecisionOrganization.total}}</span>quyết định</li>
                    <li>Số quyết định chờ phê duyệt: <span class="badge bg-light mfont-size">{{getDecisionOrganization.num_wait}}</span>quyết định</li>
                    <li>Số quyết định đang thi hành: <span class="badge bg-light mfont-size">{{getDecisionOrganization.num_doing}}</span>quyết định</li>
                    <li>Số quyết định đã thi hành: <span class="badge bg-light mfont-size">{{getDecisionOrganization.num_completed}}</span>quyết định</li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="header msub-header">
                    <span class="number-circle">I.3</span> Hành vi vi phạm
                </div>
                <ul>
                    <li>Tổng số nhóm hành vi: <span class="badge bg-light mfont-size">{{getBehaviorOrganization.num_type_behavior}}</span> nhóm hành vi </li>
                    <li>Tổng số hành vi vi phạm: <span class="badge bg-light mfont-size">{{getBehaviorOrganization.total_num_behavior}}</span> hành vi</li>
                </ul>
            </div>
            <div class="col-md-6">
                <div class="header msub-header">
                    <span class="number-circle">I.4</span> Số tiền xử phạt
                </div>
                <div><i class="far fa-credit-card micon"></i> <span>{{ new Intl.NumberFormat('it-IT').format(getTotalMoneyBehaviorOrganization) }} (đồng)</span></div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="header msub-header">
                    <span class="number-circle">I.5</span> Diện tích thiệt hại
                </div>
                <div><i class="far fa-square micon"></i> <span>{{ new Intl.NumberFormat('it-IT').format(getTotalAreaBehaviorOrganization) }} (m2)</span></div>
            </div>

            <div class="col-md-6">
                <div class="header msub-header">
                    <span class="number-circle">I.6</span> Tải số liệu
                </div>
                <vue-excel-xlsx
                    :data="getDataDownloadOrganization"
                    :columns="columns"
                    file-name="fvdms_so lieu vi phạm"
                    :file-type="'xlsx'"
                    :sheet-name="'Du lieu xu phat ca nhan'"
                    >
                    <span style="border: none;">
                        <i class="fas fa-download micon"></i> Excel
                    </span>
                </vue-excel-xlsx>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'report-organization-statistic',
        data() {
            return {
                // Download: vue-excel-xlsx
                // ----------------------------
                columns: [
                    { 
                        field: 'id',
                        label: 'STT'
                    },
                    { 
                        field: 'organization_name',
                        label: 'Tên tổ chức'
                    },
                    { 
                        field: 'address',
                        label: 'Địa chỉ'
                    },
                    { 
                        field: 'organization_code_number',
                        label: 'Mã số doanh nghiệp'
                    },
                    { 
                        field: 'business_certificate_number',
                        label: 'Số GCN hoặc giấy phép hoạt động'
                    },
                    { 
                        field: 'odv_date_issued',
                        label: 'Ngày cấp'
                    },
                    { 
                        field: 'issued_by',
                        label: 'Nơi cấp'
                    },
                    { 
                        field: 'representative_legal',
                        label: 'Người đại diện theo pháp luật'
                    },
                    { 
                        field: 'sex_name',
                        label: 'Giới tính'
                    },
                    { 
                        field: 'position',
                        label: 'Chức danh'
                    },
                    { 
                        field: 'decision_status_name',
                        label: 'Trạng thái QĐXP'
                    },
                    { 
                        field: 'province_name',
                        label: 'Tỉnh xảy ra vụ việc'
                    },
                    { 
                        field: 'organization_group_name',
                        label: 'ĐV ra quyết định'
                    },
                    { 
                        field: 'violation_decision_number',
                        label: 'Số QĐ'
                    },
                    { 
                        field: 'decision_organization_head',
                        label: 'Cơ quan chủ quản'
                    },
                    { 
                        field: 'decision_organization_name',
                        label: 'Đơn vị ban hành văn bản'
                    },
                    { 
                        field: 'decision_site',
                        label: 'Địa danh'
                    },
                    { 
                        field: 'decision_date',
                        label: 'Ngày, tháng, năm ban hành'
                    },
                    { 
                        field: 'article_id',
                        label: 'Điều'
                    },
                    { 
                        field: 'article_content',
                        label: 'Tên Điều'
                    },
                    { 
                        field: 'clause_id_origin',
                        label: 'Khoản'
                    },
                    { 
                        field: 'clause_content',
                        label: 'Tên khoản'
                    },
                    { 
                        field: 'point_id_origin',
                        label: 'Điểm'
                    },
                    { 
                        field: 'point_content',
                        label: 'Tên điểm'
                    },
                    { 
                        field: 'under_point_id_origin',
                        label: 'Tiết'
                    },
                    { 
                        field: 'under_point_content',
                        label: 'Tên tiết'
                    },
                    { 
                        field: 'violation_behavior_enter',
                        label: 'Hành vi vi phạm'
                    },
                    { 
                        field: 'statistic_index_name',
                        label: 'Tên chỉ số thống kê'
                    },
                    { 
                        field: 'statistic_value',
                        label: 'Giá trị chỉ số thống kê'
                    },
                    { 
                        field: 'statistic_input_unit',
                        label: 'Đơn vị tính'
                    },
                    { 
                        field: 'regulation_at_enter',
                        label: 'Quy định tại'
                    },
                    { 
                        field: 'main_penalty_enter',
                        label: 'Hình thức phạt chính'
                    },
                    { 
                        field: 'money_penalty_by_behavior',
                        label: 'Số tiền phạt theo hành vi (VNĐ)'
                    },
                    { 
                        field: 'increase_issue',
                        label: 'Tình tiết tăng nặng'
                    },
                    { 
                        field: 'decrease_issue',
                        label: 'Tình tiết giảm nhẹ'
                    },
                    { 
                        field: 'money_penalty_total_int',
                        label: 'Tổng số tiền phạt (VNĐ)'
                    },
                    { 
                        field: 'extend_penalty_exhibit',
                        label: 'Tịch thu tang vật VPHC'
                    },
                    { 
                        field: 'extend_penalty_other',
                        label: 'Hình thức phạt bổ sung'
                    },
                    { 
                        field: 'extend_penalty_duration',
                        label: 'Thời gian phạt'
                    },
                    { 
                        field: 'remedial_measure',
                        label: 'Biện pháp khắc phục hậu quả'
                    },
                    { 
                        field: 'remedial_measure_duration',
                        label: 'Thời gian thực hiện BPKP hậu quả'
                    },
                    { 
                        field: 'remedial_measure_content',
                        label: 'Nội dung BPKP hậu quả'
                    },
                    { 
                        field: 'remedial_measure_money_int',
                        label: 'Số tiền thực hiện BPKP hậu quả'
                    },
                    { 
                        field: 'organization_get_money',
                        label: 'Cơ quan thực hiện BPKP hậu quả'
                    },
                    { 
                        field: 'vld_date_issued',
                        label: 'QĐ có hiệu lực từ'
                    },
                    { 
                        field: 'place_pay_violation_money',
                        label: 'Nơi thu tiền phạt'
                    },
                    { 
                        field: 'violation_account_number',
                        label: 'Số tài khoản nộp tiền vào'
                    },
                    { 
                        field: 'state_treasury_name',
                        label: 'Tên kho bạc nhà nước'
                    },
                    { 
                        field: 'duration_pay_fine',
                        label: 'Thời hạn nộp tiền phạt (ngày)'
                    },
                    { 
                        field: 'kept_exhibit_name',
                        label: 'Tên phương tiện, tang vật bị giữ'
                    },
                    { 
                        field: 'organization_implement_decision',
                        label: 'Tên cá nhân/tổ chức thực hiện thi hành quyết định'
                    },
                    { 
                        field: 'organization_relation_violator',
                        label: 'Tên cơ quan, tổ chức liên quan đến người xử phạt'
                    },
                    { 
                        field: 'signed_person_name',
                        label: 'Người ký QĐ'
                    },
                    { 
                        field: 'date_violator_get_decision',
                        label: 'Ngày người vi phạm nhận quyết định xử phạt'
                    },
                    { 
                        field: 'user_created',
                        label: 'Người lập QĐ'
                    }
                ]
            }
        },
        computed: {
			...mapGetters([
                'getDecisionOrganization',
                'getBehaviorOrganization',
                'getDataDownloadOrganization',
                'getTotalMoneyBehaviorOrganization',
                'getTotalAreaBehaviorOrganization'
			])
		},
		methods: {
			...mapActions([ ])
		}
    }
</script>

<style scoped>
    
</style>