<template>
    <div>
        <div style="text-align: left; font-weight: bold; margin-bottom: 20px">Biểu đồ tỷ lệ số quyết định xử phạt theo nhóm hành vi</div>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <highcharts :options="pieChart"></highcharts>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { Chart } from 'highcharts-vue'

    export default {
        name: 'report-individual-pie-chart',
        components: {
            highcharts: Chart,
        },
        data() {
            return {
                pieChart: {
                    chart: {
                        type: "pie"
                    },
                    plotOptions: {
                        pie: {
                            innerSize: 0,
                            depth: 0,
                            // dataLabels: {
                            //     enabled: true,
                            //     format: '<b></b><br>{point.percentage:.1f} %',
                            //     distance: -50
                            // }
                        }
                    },
                    title: {
                        text: ""
                    },
                    tooltip: {
                        pointFormat: ''
                    },
                    series: [ 
                        {
                            data: [ 
                                // ["Bananas", 8],
                                // ["Kiwi", 3],
                                // ["Mixed nuts", 1]
                            ]
                        }
                    ],
                    credits: {
                        enabled: false           // Hide highchart.com
                    },
                },
            }
        },
        computed: {
			...mapGetters([
                'getBehaviorIndividual'
			]),
		},
        async created() {
            this.pieChart.series = await this.createSeriesPieChart(this.getBehaviorIndividual.behavior)
        },
        watch: {
            '$store.state.report.behavior_individual': async function() {
                this.pieChart.series = await this.createSeriesPieChart(this.$store.state.report.behavior_individual)
            }
        },
		methods: {
            createSeriesPieChart(data_behavior){
                let series = []
                var data = []

                if(data_behavior.length){
                    data_behavior.forEach(o => {
                        let one_element = [ o.behavior_name + ` (${o.per_cent} %)`, parseInt(o.num_behavior)]
                        data.push(one_element)
                    })
                    return series[0] = { data }
                }
                return []
            }
        }
}
</script>

<style scoped>

</style>