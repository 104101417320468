export default {

    getListIndividualSearch: state => {
        state.list_individual_search.forEach((obj, index) => {
            if(moment(obj.birthday).format('DD-MM-YYYY') === '01-01-1900' || obj.birthday === 'Invalid date'){
                state.list_individual_search[index].birthday = ''
            }

            if(moment(obj.decision_date).format('DD-MM-YYYY') === '01-01-1900' || moment(obj.decision_date).format('DD-MM-YYYY') === '31-12-1899' || obj.decision_date === 'Invalid date'){
                state.list_individual_search[index].decision_date = ''
            }

            if(moment(obj.execution_date).format('DD-MM-YYYY') === '01-01-1900' || obj.execution_date === 'Invalid date'){
                state.list_individual_search[index].execution_date = ''
            }
        })
        return state.list_individual_search
    },

    getListOrganizationSearch: state => {
        state.list_organization_search.forEach((obj, index) => {           
            if(moment(obj.decision_date).format('DD-MM-YYYY') === '01-01-1900' || moment(obj.decision_date).format('DD-MM-YYYY') === '31-12-1899' || obj.decision_date === 'Invalid date'){
                state.list_organization_search[index].decision_date = ''
            }

            if(moment(obj.execution_date).format('DD-MM-YYYY') === '01-01-1900' || obj.execution_date === 'Invalid date'){
                state.list_organization_search[index].execution_date = ''
            }
        });
        return state.list_organization_search
    },
}