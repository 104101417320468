<template>
    <div>
        <router-view></router-view>
    </div>  
</template>

<script>
    export default {
        name: 'penalty-decision',
    }

</script>

<style scoped>
    .content-wrapper {
        background-color: white;
    }
</style>