<template>
    <div>
        <!-- Tiêu đề báo cáo -->
        <div class="mtitle" v-html="html_title_report"></div>

        <div class="row" style="margin-top: 30px">
            <div class="col-md-4">     
                <div class="mheader-3"><i class="fas fa-globe-africa micon"></i> 
                <span> Chọn cấp hành chính </span> </div>
                <multiselect
                    id="location_level_code"
                    track-by="location_level_code" 
                    label="location_level_name" 
                    @input="handleSelectLocaltionLevel()"
                    v-model="location_level_selected" 
                    open-direction="bottom"
                    placeholder="Chọn vùng"
                    :options="list_location_level" 
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :show-pointer="true"
                    :hide-selected="true">
                </multiselect>
            </div>

            <div class="col-md-4" v-if="location_level_code == 2">     
                <div class="mheader-3"><i class="fas fa-globe-africa micon"></i> 
                Chọn vùng </div>
                <multiselect
                    id="region"
                    track-by="location_code" label="location_name" 
                    @input="handleSelectRegion()"
                    v-model="region_selected" 
                    open-direction="bottom"
                    placeholder="Chọn vùng"
                    :options="list_region" 
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :show-pointer="true"
                    :hide-selected="true">
                </multiselect>
            </div>

            <div class="col-md-4" v-if="location_level_code == 3">     
                <div class="mheader-3"><i class="fas fa-globe-africa micon"></i> 
                Chọn tỉnh </div>
                <multiselect
                    id="province"
                    track-by="province_code" label="province_name" 
                    @input="handleSelectProvince()"
                    v-model="province_selected" 
                    open-direction="bottom"
                    placeholder="Chọn tỉnh"
                    :options="list_province" 
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :show-pointer="true"
                    :hide-selected="true">
                </multiselect>
            </div>
        </div>


        <!-- Bộ lọc thời gian -->
        <div class="row" style="margin-top: 20px">
            <div class="col-md-6">
                <div><i class="far fa-calendar-alt myicon"></i> Thời gian báo cáo</div>                               
                <v-date-picker 
                    :masks="masks"
                    :attributes='attrs'
                    :max-date='new Date()'
                    show-weeknumbers
                    @input="handStartDate()"
                    v-model="date_start">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group">
                            <input
                                placeholder="Thời gian bắt đầu: DD-MM-YYYY"
                                class="form-control"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </div>
                    </template>
                </v-date-picker>
            </div>
            <div class="col-md-6" style="margin-top: 23px;">
                <v-date-picker 
                    :masks="masks"
                    :attributes='attrs'
                    :max-date='new Date()'
                    show-weeknumbers
                    @input="handEndDate()"
                    v-model="date_end">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group">
                            <input
                                placeholder="Thời gian kết thúc: DD-MM-YYYY"
                                class="form-control"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                        </div>
                    </template>
                </v-date-picker>
            </div>
        </div>
        <button type="button" 
                class="btn btn-sm float-right" 
                style="width: 100px; margin-top: 10px; background-color: #e0b153; color: white"
                @click="handleResetFormFilter()"> Hủy lọc </button>

        <button type="button" 
                class="btn btn-sm float-right" 
                style="width: 100px; margin-top: 10px; margin-right: 10px; background-color: #307484; color: white"
                @click="handleFilter()"> Lọc </button>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { DATE_TYPE, LOCATION_LEVEL, NATION_CODE } from '@/config/enum'
    import moment from 'moment';

    export default {
        name: 'report-organization-form-nation',
        data() {
            return {
                // Cấp hành chính
                list_location_level: [],
                location_level_selected: [],
                location_level_code: null,

                // Vùng
                list_region: [],
                region_selected: [],
                region_code: null,

                // Tỉnh
                list_province: [],
                province_selected: [],
                province_code: null,

                // Thời gian thống kê: mặc định là năm hiện tại
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),

                // VCalendar
                attrs: [
                    {
                        key: 'today',
                        highlight: true,       // dot
                        dates: new Date()
                    }
                ],
                masks: {
                    input: 'DD-MM-YYYY'
                },
            }
        },
        async created() {
            // Danh sách vùng
            this.actLocationLevel().then((data) => {
                this.handleFilterLocationLevel(data)
            })

            // Báo cáo
            this.handleResetFormFilter('by-user')

        },
        watch: {
            date_end(newVal) {
                if(moment(newVal) < moment(this.date_start)) {
                    toastr.error("Thời gian kết thúc nhỏ hơn bắt đầu", "Lỗi chọn thời gian kết thúc", { "closeButton": true });
                }
            }
        },  
        computed: {
			...mapGetters([
                'getCurrentUser',
                'getListLocationLevel'
			]),

            // eslint-disable-next-line vue/return-in-computed-property
            html_title_report: function (){
                // Mặc định năm hiện tại
                let isCurrentYear = this.date_start === '' && this.date_end === ''
                if(isCurrentYear) return `<span>THỐNG KÊ VI PHẠM CỦA TỪ ${moment().startOf('year').format("DD-MM-YYYY")} ĐẾN ${moment().format('DD-MM-YYYY')}</span>`
                
                // Chỉ chọn ngày bắt đầu
                let isOnlySelectedStartDate = this.date_start !== '' && this.date_end === ''
                if(isOnlySelectedStartDate) {
                    return `<span>THỐNG KÊ VI PHẠM TỪ ${moment(this.date_start).format("DD-MM-YYYY")}</span>`
                }

                // Chỉ chọn ngày kết thúc
                let isOnlySelectedEndDate = this.date_start === '' && this.date_end !== ''
                if(isOnlySelectedEndDate) {
                    return `<span>THỐNG KÊ VI PHẠM ĐẾN NGÀY ${moment(this.date_end).format("DD-MM-YYYY")}</span>`
                }

                // Nếu chọn ngày bắt đầu và ngày kết thúc
                let isSelectedRangeDate = this.date_start !== '' && this.date_end !== ''
                if(isSelectedRangeDate) {
                    let date_start = moment(this.date_start).format("DD-MM-YYYY")
                    let date_end = moment(this.date_end).format("DD-MM-YYYY")
                    return `<span>THỐNG KÊ VI PHẠM TỪ ${date_start} ĐẾN ${date_end}</span>`
                }
            },

		},
		methods: {
            ...mapActions([ 
                'actReportOrganization',
                'actLocationLevel',
                'actGetListRegion',
                'actGetListProvinceByRegion',
                'actGetListProvince'
            ]),

            // SELECT INFO ============================================
            handleFilterLocationLevel(data){
                this.list_location_level = data.location_level.list_location_level
                this.list_location_level = this.list_location_level.filter(obj => {
                    return obj.location_level_code != LOCATION_LEVEL.DISTRICT
                })
            },

            handleSelectLocaltionLevel(){
                this.location_level_code = this.location_level_selected.location_level_code

                if(this.location_level_code == LOCATION_LEVEL.NATION){
                    this.resetLevelRegionSelect()
                }

                if(this.location_level_code == LOCATION_LEVEL.REGION){
                    this.resetLevelProvinceSelect()
                    this.actGetListRegion().then(data => { this.list_region = data.list_region.list_region })
                }

                if(this.location_level_code == LOCATION_LEVEL.PROVINCE){
                    this.actGetListProvince().then(data => { 
                        this.list_province = data.result.list_province 
                    })
                }
            },

            handleSelectRegion(){
                this.region_code = this.region_selected.location_code
                this.actGetListProvinceByRegion(this.region_code).then(data => {
                    this.list_province = data.list_province.list_province
                })
            },

            handleSelectProvince(){ 
  			    this.province_code = this.province_selected.province_code
			},

            handStartDate(){
                this.date_start = moment(this.date_start).format('YYYY-MM-DD')
            },

            handEndDate(){
                this.date_end = moment(this.date_end).format('YYYY-MM-DD')
            },


            // VALIDATE INFO ============================================
            validateDateSelected(){
                if(this.date_start === ''){
                    toastr.error("Chưa chọn thời gian bắt đầu!", "", { "closeButton": true });
                    return false
                }

                if(this.date_end === ''){
                    toastr.error("Chưa chọn thời gian kết thúc!", "", { "closeButton": true });
                    return false
                }

                if(moment(this.date_end) < moment(this.date_start)) {
                    toastr.error("Thời gian kết thúc nhỏ hơn bắt đầu", "Lỗi chọn thời gian kết thúc", { "closeButton": true });
                    return false
                }

                return true
            },
            
            validateSelectRegion(){
                let isNotSelectedRegion = this.location_level_code == LOCATION_LEVEL.REGION && this.region_code == null
                if(isNotSelectedRegion) {
                    toastr.error("Chưa chọn vùng!", "", { "closeButton": true });
                    return false
                }
                return true
            },

            validateSelectProvince(){
                let isNotSelectProvinced = this.location_level_code == LOCATION_LEVEL.PROVINCE && this.province_code == null
                if(isNotSelectProvinced) {
                    toastr.error("Chưa chọn tỉnh!", "", { "closeButton": true });
                    return false
                }
                return true
            },
            

            // HANDLE GET DATA ============================================
            handleFilter(){
                let isDateSelectedValid     = this.validateDateSelected()
                if(isDateSelectedValid){
                    if(this.location_level_code == LOCATION_LEVEL.NATION) this.handleGetDataLevelNation()
                    if(this.location_level_code == LOCATION_LEVEL.REGION) this.handleGetDataLevelRegion()
                    if(this.location_level_code == LOCATION_LEVEL.PROVINCE) this.handleGetDataLevelProvince()
                }
            },

            handleGetDataLevelNation(){
                let data = {
                    user_id             : this.getCurrentUser.user_id,
                    role_id             : this.getCurrentUser.role_id,
                    location_level_code : LOCATION_LEVEL.NATION,
                    location_code       : NATION_CODE,
                    date_type           : DATE_TYPE.DATE_ISSUED,                                                   
                    date_start          : this.date_start,
                    date_end            : this.date_end,
                    type_get_data       : 'filter'
                }

                var data_json = JSON.stringify(data)

                this.actReportOrganization(data_json).then(() => {
                    toastr.success("Lọc thành công!", "", { "closeButton": true });
                })
            },

            handleGetDataLevelRegion(){
                let isRegionSelectedValid   = this.validateSelectRegion()

                if( isRegionSelectedValid ) {
                    let data = {
                        user_id             : this.getCurrentUser.user_id,
                        role_id             : this.getCurrentUser.role_id,
                        location_level_code : LOCATION_LEVEL.REGION,
                        location_code       : this.province_code > 0 ? this.province_code : this.region_code,
                        date_type           : DATE_TYPE.DATE_ISSUED,                                                   
                        date_start          : this.date_start,
                        date_end            : this.date_end,
                        type_get_data       : 'filter'
                    }

                    var data_json = JSON.stringify(data)

                    this.actReportOrganization(data_json).then(() => {
                        toastr.success("Lọc thành công!", "", { "closeButton": true });
                    })
                }
            },

            handleGetDataLevelProvince(){
                let isProvinceSelectedValid = this.validateSelectProvince()
                if( isProvinceSelectedValid ) {
                    let data = {
                        user_id             : this.getCurrentUser.user_id,
                        role_id             : this.getCurrentUser.role_id,
                        location_level_code : LOCATION_LEVEL.PROVINCE,
                        location_code       : this.province_code,
                        date_type           : DATE_TYPE.DATE_ISSUED,                                                   
                        date_start          : this.date_start,
                        date_end            : this.date_end,
                        type_get_data       : 'filter'
                    }

                    var data_json = JSON.stringify(data)

                    this.actReportOrganization(data_json).then(() => {
                        toastr.success("Lọc thành công!", "", { "closeButton": true });
                    })
                }
            },

            handleResetFormFilter(type='filter'){
                this.resetLevelNationSelect()

                var data = {
                    user_id             : this.getCurrentUser.user_id,
                    role_id             : this.getCurrentUser.role_id,
                    location_level_code : this.getCurrentUser.location_level_code,
                    date_type           : DATE_TYPE.DATE_ISSUED,                                                  
                    date_start          : moment().startOf('year').format("YYYY-MM-DD"),
                    date_end            : moment().format("YYYY-MM-DD"),
                    type_get_data       : 'by_user'
                }

                var data_json = JSON.stringify(data)

                this.actReportOrganization(data_json).then(() => {
                    if(type == 'filter') toastr.success("Hủy lọc thành công!", "", { "closeButton": true });
                })
            },


            // RESET =================================================================
            resetLevelNationSelect(){
                this.location_level_code = null
                this.location_level_selected = []
                this.resetLevelRegionSelect()
            },

            resetLevelRegionSelect(){
                this.list_region = []
                this.region_selected = []
                this.region_code = null
                this.resetLevelProvinceSelect()
            },

            resetLevelProvinceSelect(){
                this.list_province = []
                this.province_selected = []
                this.province_code = null
            }
		}
    }
</script>

<style scoped>
    .mfont-size {
        font-size: 14px;
    }

    .number-circle {
        padding: 2px 10px; 
        border-radius: 50%; 
        background-color: #307484; 
        color: white
    }

    .header {
        color: #307484; 
        margin-bottom: 10px; 
        margin-top: 40px;
        font-size: 17px;
    }

    .mtitle {
        color: #307484; 
        margin-bottom: 10px; 
        margin-top: 30px;
        font-size: 20px;
        font-weight: 500
    }

</style>