<template>
    <div>
        <div class="card" style="border-radius: 0px" id="report">
            <!-- Navbar report -->
            <Navbar
                v-on:emitEntityType="setEntityType"
            />            
        </div>
        <!-- Tra cứu kết quả xử phạt -->
        <!-- 1. Cá nhân -->
        <ReportIndividual 
            v-if="entity_type_id === 1" 
        />

        <!-- 2. Tổ chức -->
        <ReportOrganization 
            v-if="entity_type_id === 2" 
        />
    </div>
</template>

<script>

    import Navbar from './Navbar.vue'
    import ReportIndividual from './Individual/ReportIndividual.vue'
    import ReportOrganization from './Organization/ReportOrganization.vue'

	export default {
		name: 'report-index',
        components: {
            Navbar,
            ReportIndividual,
            ReportOrganization
        },
        data() {
            return {
                entity_type_id: 1,           // Mặc định hiển thị là cá nhân
            }
        },
        methods: {
            setEntityType(entity_type_id){
				this.entity_type_id = parseInt(entity_type_id)
			}
        },
    }
</script>


<style scoped>
    

</style>