<template>
	<div>
		<div class="mycenter_div mgt-50">
			<div class="txt-center"><b-img :src="this.$ASSETS_PATH + '/img/icon/reset-password.jpg'" width="80px"></b-img></div>
			<div class="txt-center sub-header">KHÔI PHỤC MẬT KHẨU</div>

			<!-- Form nhập địa chỉ email khôi phục mật khẩu -->
			<div v-if="!isShowMessage">
				<p class="email">Email <code class="txt-right" v-if="isEmailError">{{ msgEmail }}</code></p>
				<div class="input-group mb-3">
					<div class="input-group-prepend">
					<span class="input-group-text"><i class="fas fa-envelope"></i></span>
					</div>
					<input v-model="email" type="email" class="form-control" placeholder="Địa chỉ Email khôi phục mật khẩu">
				</div>
			</div>

			<!-- Nếu nhập sai địa chỉ Email thì cảnh báo -->
			<div style="margin: 30px;" v-else>
				<i class="fas fa-envelope-open-text fa-2x" style="color: #3974B1; margin-bottom: 8px;"></i>
				<br/>
				{{message}}
			</div>
			<!-- /End cảnh báo nhập sai địa chỉ Email -->

			<div class="txt-right forget-password"><router-link :to="{name: 'login'}"> Trở về trang ĐĂNG NHẬP</router-link></div>
			<b-button 
				v-if="!isShowMessage"
				size="sm" class="login"
				@click="handleResetPassword()"> Thực hiện khôi phục</b-button>
		</div>
		
	</div>
</template>

<script>
	import { mapActions } from 'vuex';

	export default {
		name: 'forget-password',
		data() {
			return {
				email: '',
				isEmailError: false,
				msgEmail: '',

				// Show kết quả
				isShowMessage: false,
				message: ''
			}
		},
		watch: { // Sử dụng lắng nghe khi người dùng thay đổi giá trị trong Form
			email: function (val) {
				if(this.isEmailError === true & val.length > 0) this.isEmailError = false
			},
		},
		methods: {
			...mapActions([
				"actResetPasswordViaEmailSendEmail"
			]),

			validEmail: function (email) {
				var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email);
			},
			
			handleResetPassword(){

				if (this.validEmail(this.email) === false) { 
					this.isEmailError = true
					this.msgEmail = 'Email không hợp lệ!'
				}

				if(this.isEmailError === false){
					this.actResetPasswordViaEmailSendEmail(this.email).then((res) => {
						if(res.ok){

							// Hiển thị thông báo: phungdinhtrung@gfd.com.vn
							// toastr.success(res.message, 'Khôi phục mật khẩu', {closeButton: true})
							this.message = res.message

							// Reset form
							this.resetForm()

						}else{
							toastr.error(res.error, '')
						}
					})
				}
			},

			resetForm(){
				this.email = '',
				this.isEmailError = false,
				this.msgEmail = '',
				this.isShowMessage = true
			}
		}
		
	}
</script>

<style scoped>
	.mycenter_div{ /* Điều chỉnh hộp login vào giữa */
		margin: 0 auto;
		width: 600px /* value of your choice which suits your alignment */;
		border: 1px solid #ced4da;
		padding: 30px;
		border-radius: 5px;
		background-color: #f8f9fa; 
	}

	.main-header {
		font-weight: 500;
		color: #264653;
	}

	.sub-header {
		color: #264653;
		font-weight: 500;
	}

	.email {
		margin-top:20px;
		font-size: 14px;
		color: #264653;
	}

	.password {
		margin-top:20px;
		font-size: 14px;
		color: #264653;
	}

	.forget-password {
		font-size: 13px;
	}

	.txt-right.forget-password > a{
		text-decoration: none;
		color: #264653;
	}

	.txt-right.forget-password > a:hover{
		text-decoration: none;
		color: #6a040f;
	}

	::placeholder {
		font-size: 13px;
	}

	.mgt-15{
		margin-top: 15px;
	}

	.mgt-50 {
		margin-top: 80px;
	}

	.login {
		margin-top: 20px;
		background-color: #17A2B8;
		border: #17A2B8;
	}

	.size-icon {
		width: 43px;
	}

</style>