import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

// MODULES
// 0. SHARE
import share from '@/store/share/index.js'

// 1. LOGIN
import login from '@/store/login/index.js'

// 2. USER MANAGEMENT
import admin from '@/store/ManageUser/index.js'

// 3. PANALTY DECISION
import penalty_decision from '@/store/PenaltyDecision/index.js'

// 4. DECREE
import decree from '@/store/decree/index.js'

// 5. SEARCH
import search from '@/store/SearchResult/index.js'

// 6. GET NOTIFY
import get_notify from '@/store/GetNotify/index.js'

// 7. REPORT
import report from '@/store/Report/index.js'

const store = new Vuex.Store({
	state () {},
	mutations: {},
	actions: {},
	getters: {},
	modules: {
		// 0. SHARE -------------------------------------------------------- 
		share,											// module dùng chung

		// 1. LOGIN --------------------------------------------------------
		login,   										// Login

		// 2. USER MANAGEMENT ----------------------------------------------
		admin,											// Quản lý các tài khoản user

		// 3. PANALTY DECISION ---------------------------------------------
		penalty_decision,

		// 4. DECREE -------------------------------------------------------
		decree,											// Nghị định	

		// 5. SEARCH -------------------------------------------------------
		search,

		// 6. TIẾP NHẬN TIN BÁO --------------------------------------------
		get_notify,

		// 7. BÁO CÁO --------------------------------------------
		report
	},
	plugins: [createPersistedState()]
  })

export default store

// NOTE: Store này như một cái server, trong server này có thể tạo nhiều database (module) để chứa dữ liệu
// store: 
//        - db1 - module 1
//        - db2 - module 2
//        - db3 - module 3
//        - ...