<template>
    <div>
        <CompPenaltyResult />
    </div>
</template>

<script>
    import { nonAccentVietnamese } from '@/helper/index'
    import CompPenaltyResult from '@/components/PenaltyResult/Index.vue';

    export default {
        name: 'penalty-result',
        components: {
            CompPenaltyResult
        },
        async created(){
            // this.actGetAllUser().then(()=> {
                
                $.fn.dataTableExt.ofnSearch['string'] = (data) => {
                    return nonAccentVietnamese(data)
                }

                $("#tbl-penalty-result").DataTable({
                        "search": {
                            // "return": true,              // Ấn enter mới search
                        },
                        "language": {
                            "sProcessing":    "Đang thực hiện...",
                            "sLengthMenu":    "Xem _MENU_ tài khoản",
                            "sZeroRecords":   "Không tìm thấy kết quả thỏa mãn yêu cầu!",
                            "sEmptyTable":    "Không có kết quả!",
                            "sInfo":          `Hiển thị từ _START_ đến _END_ trên tổng _TOTAL_ tài khoản`,  //
                            "sInfoEmpty":     "Hiển thị từ 0 đến 0 trên tổng 0 tài khoản",
                            "sInfoFiltered":  "",                                            // Thông tin hiển thị cuối đuôi bảng khi kết quả tìm kiếm là NULL (nên để "")
                            "sInfoPostFix":   "",
                            "sSearch":        "Tìm kiếm",
                            "oPaginate": {
                                "sFirst":     "Trang đầu",
                                "sLast":      "Cuối cùng",
                                "sNext":      "Kế tiếp",
                                "sPrevious":  "Về trước"
                            },
                            "oAria": {
                                "sSortAscending":  ": Từ nhỏ đến lớn",
                                "sSortDescending": ": Từ lớn đến nhỏ"
                            }
                    },
                        "responsive": true, 
                        "paging": true,                 // Pagination
                        "lengthChange": false,           // Show selected number rows
                        "autoWidth": false,             // Nên để false
                        "searching": true,
                        "ordering": true,
                        "buttons": ["copy", "csv", "excel", "pdf", "print"],        // Buttons
                        "bDestroy": true                // Fix lỗi cannot reinitialise data table
                    })
            // })         
        },
    }
</script>

<style scoped>

</style>