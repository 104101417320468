// NOT FOUND
import NotFound from '@/pages/NotFound/Index.vue'

// Gắn Page import vào router để điều hướng đến trang Import
export default [
    // PAGE NOT FOUND -------------------------------------------------------
    {
        path: "/:pathMatch(.*)*",
        name: 'not-found',
        component: NotFound,
        meta:{ keepAlive: false } 
    }
]