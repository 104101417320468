<template>
    <div>
        <div class="card" style="border-radius: 0px">    
            <!-- Header -->       
            <div class="card-header ui-sortable-handle">     
                <h3 class="card-title">Kết quả tra cứu quyết định xử phạt cá nhân </h3>

                <!-- DOWNLOAD -->
                <div class="row" v-if="getListIndividualSearch.length > 0">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                        <div class="txt-right" style="margin-right: -20px;">
                            <vue-excel-xlsx
                                :data="getListIndividualSearch"
                                :columns="columns"
                                :file-name="'Danh sach QD xu phat ca nhan'"
                                :file-type="'xlsx'"
                                :sheet-name="'Danh sach QD xu phat ca nhan'"
                                >
                                <span 
                                    id="hide-btn-vue-excel-xlsx"
                                    class="btn btn-sm" 
                                    style="border: 1px solid #D9D9D9;"
                                >
                                Tải về 
                                <i class="fas fa-download micon"></i>
                                </span>
                            </vue-excel-xlsx>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Header -->

            <!-- LIST INDIVIDUAL -->
            <ProgressBar mode="indeterminate" style="height: .2em; border-radius: 0px;" v-if="is_loading_download" />

            <div>
                <grid 
                    :cols="header_table_admin" 
                    :rows="getListIndividualSearch" 
                    :language="language" 
                    :pagination="pagination"
                    :sort="true">
                </grid>
            </div>
            <!-- /LIST INDIVIDUAL -->

        </div>
        <!-- /DIV content -->
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { Grid } from 'gridjs-vue'
import ProgressBar from 'primevue/progressbar';

export default {
    name: 'table-search-result',
    components: {
        Grid,
        ProgressBar
    },
    data() {
        return {
            // Download: vue-excel-xlsx
            // ----------------------------
            columns: [
                { 
                    field: 'violation_decision_number',
                    label: 'Số QĐ'
                },
                {
                    field: 'decision_date',
                    label: 'Ngày ban hành'
                },
                { 
                    field: 'organization_group_name',
                    label: 'Đơn vị ra quyết định'
                },
                { 
                    field: 'decision_organization_name',
                    label: 'Cơ quan ban hành'
                },
                {
                    field: 'full_name',
                    label: 'Họ và tên'
                },
                {
                    field: 'sex_name',
                    label: 'Giới tính'
                },
                {
                    field: 'birthday',
                    label: 'Ngày sinh'
                },
                {
                    field: 'country_name',
                    label: 'Quốc tịch'
                },
                {
                    field: 'person_id_number',
                    label: 'CMND/Hộ chiếu'
                },
                { 
                    field: 'current_residence',
                    label: 'Địa chỉ'
                },
                {
                    field: 'occupation',
                    label: 'Nghề nghiệp'
                },
                {
                    field: 'decision_status_name',
                    label: 'Trạng thái'
                },
                { 
                    field: 'execution_date',
                    label: 'Ngày chấp hành xong QĐXP'
                },
                { 
                    field: 'province_name',
                    label: 'Tỉnh'
                }
            ],

            // GridJS: table phone-book: formatter: (cell) => `${moment(cell).format('DD-MM-YYYY')}`
            header_table_admin: [
                { // 0
                    id: 'file_upload',
                    name: 'file_upload',
                    hidden: true
                },
                { // 1 | // Cột này sử dụng cho hàm updateDecisionStatusViolationDecision trên server, trường hợp: quyết định có nhiều người sử phạt 
                    id: 'violation_person_id',
                    name: 'violation_person_id',
                    hidden: true
                },    
                { // 2 | // Cột này sử dụng cho hàm updateDecisionStatusViolationDecision trên server, trường hợp: quyết định có nhiều người sử phạt 
                    id: 'user_id_created',
                    name: 'user_id_created',
                    hidden: true
                },          
                { // 3
                    id: 'violation_decision_number',
                    name: 'Số QĐ',
                    formatter: (cell, row) => {
                        if(row.cells[2].data === this.getCurrentUser.user_id){ // type = 1: Quyết định do mình tạo ra
                            return this.$gridjs.html(this.violationDecisionCode(cell, 1))

                        }else{ // type = 2: Quyết định do đơn vị khác tạo ra
                            return this.$gridjs.html(this.violationDecisionCode(cell, 2))
                        }
                    }
                    // width: '130px'
                },
                { // 4
                    id: 'decision_date',
                    name: 'Ngày ban hành',
                    sort: {
                        compare: (a, b) => {  
                            if(a != null && b != null){
                                a = a.split('-').reverse().join('');
                                b = b.split('-').reverse().join('');
                                if (a > b) {
                                    return 1;
                                } else if (b > a) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }else{
                                return 0
                            }         
                        }
                    }
                },
                { // 5 (new)
                    id: 'organization_group_name',
                    name: 'Đơn vị ra quyết định',
                    sort: false,
                },
                { // 6 (new)
                    id: 'decision_organization_name',
                    name: 'Cơ quan ban hành',
                    sort: false,
                },
                { // 6 -> 7
                    id: 'full_name',
                    name: 'Họ và tên'
                },
                { // 7
                    id: 'birthday',
                    name: 'Ngày sinh',
                    sort: {
                        compare: (a, b) => {            
                            a = a.split('-').reverse().join('');
                            b = b.split('-').reverse().join('');
                            
                            if (a > b) {
                                return 1;
                            } else if (b > a) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                },
                { // 8
                    id: 'person_id_number',
                    name: 'CMND/CCCD',
                    sort: false
                },
                { // 9
                    id: 'current_residence',
                    name: 'Địa chỉ',
                    width: '240px',
                    sort: false
                },
                { // 10
                    id: 'decision_status_id',
                    name: 'Trạng thái',
                    sort: false,
                    formatter: (cell) => this.$gridjs.html(this.formartStatus(cell))
                },
                { // 11
                    id: 'violation_decision_id',
                    name: 'Quyết định',
                    sort: false,
                    width: '240px',
                    formatter: (cell, row) => {  // row: array value of row; cell: value of this cell (user_id) / 
                        // 1. Trạng thái chờ phê duyệt: chỉ người tạo ra quyết định thì mới có quyền xem bản nháp
                        if(row.cells[11].data === 1 && row.cells[2].data === this.getCurrentUser.user_id){ 
                            return [ 
                      
                                //  Download violation decision
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleDownloadDecisionDraft(cell)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="far fa-file-word micon2"></i></div>`))                                
                            ]
                        }

                        // 2. Đang và đã thi hành thì được tải
                        if(row.cells[11].data === 2 || row.cells[11].data === 3 ){ 
                            return [ 
                                //  Download
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleDownloadDecisionAccepted(cell, row)       
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm"> <i class="fas fa-file-pdf micon2"></i> </div>`))
                            ]
                        }                       
                    }
                },
                { // 13 (new)
                    id: 'execution_date',
                    name: 'Ngày chấp hành xong QĐXP',
                    compare: (a, b) => {  
                        if(a != null && b != null){
                            a = a.split('-').reverse().join('');
                            b = b.split('-').reverse().join('');
                            if (a > b) {
                                return 1;
                            } else if (b > a) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }else{
                            return 0
                        }         
                    }
                },
                { // 12
                    id: 'province_name',
                    name: 'Tỉnh'
                },
                       
            ],

            pagination: true,
            // resizable: true,
            sort: false,
            language: {
                search: {
                    placeholder: '🔍 Tìm kiếm ...',
                },
                sort: {
                    sortAsc: 'Sắp xếp tăng dần',
                    sortDesc: 'Sắp xếp giảm dần',
                },
                pagination: {
                    previous: 'Về trước',
                    next: 'Kế tiếp',
                    navigate: (page, pages) => `Trang ${page} trên tổng ${pages}`,
                    page: (page) => `Trang ${page}`,
                    showing: 'Hiển thị',
                    of: 'trên tổng',
                    to: 'từ',
                    results: 'kết quả',
                },
                loading: 'Đang load trang ...',
                noRecordsFound: 'Không có kết quả nào được tìm thấy!',
                error: 'Có lỗi xảy ra trong quá trình lấy dữ liệu!',
            },

            // validation_decision
            full_name: '',                      // Tên người thi hành quyết định, người xóa
            violation_decision_id: '',          // current violation_decision_id selected, sử dụng khi upload file, để biết file này upload cho quyết định nào
            violation_person_id: 0,             // Mã số violation_person_id ở bảng vd_individual, sử dụng cho hàm updateDecisionStatusViolationDecision trên server, trường hợp: quyết định có nhiều người sử phạt 

            // Download
            is_loading_download: false,
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentUser',
            'getListIndividualSearch'
        ])
    },
    methods: {
        ...mapActions([
            'actGetIndividualViolationByUser',
            'actDownloadDecisionIndividual',
            'actDownloadDecision'
        ]),

        // TABLE
        // =========================================================
        // ViolationDecisionCode
        violationDecisionCode(cell, type){
            if(cell === ''){ // Nếu chưa điền số quyết định
                if(type === 1){
                    return `.../...`
                }

                if(type === 2){
                    return `<span style="color: red"> .../... </span>`
                }

            }else{ // Nếu đã có số quyết định
                if(type === 1){
                    return cell + `/QĐ-XPHC`
                }

                if(type === 2){
                    return `<span style="color: red">` + cell + "/QĐ-XPHC" + `</span>`
                }
            }
        },   

        // 1. Format cell
        formartStatus(status_decision_id){
            if(status_decision_id === 1) return '<span> <i class="fas fa-ban status-wait"></i> Chờ phê duyệt </span>'
            if(status_decision_id === 2) return '<span class="status-process"> <i class="fas fa-circle-notch"></i> Đang thi hành </span>'
            if(status_decision_id === 3) return `<span class="status-success"> <i class="fas fa-check-circle"></i> Đã thi hành </span>`
        },

        resetForm(){
            this.files = []
            this.violation_decision_id = ''
            this.num_files = 0
            this.decision_status_note = ''
            this.full_name = ''
            this.violation_person_id = 0
        },

        // DOWNLOAD WORD
        // =========================================================
        handleDownloadDecisionDraft(violation_decision_id) {
            this.is_loading_download = true

            this.actDownloadDecisionIndividual(violation_decision_id).then((data) => {
                this.is_loading_download = false

                if(data.ok){
                    toastr.success('Tải quyết định xử phạt thành công!', '', {closeButton: true})
                }
            })
        },

        // DOWNLOAD DECISION
        // =========================================================
        // Doing: đang thi hành quyết định
        handleDownloadDecisionAccepted(violation_decision_id, row){

            // Lấy thông tin file theo quyết định (Gridjs): row là chứa toàn bộ thông tin của dòng click, cột file_upload là cột thứ 9 (ẩn đi)
            var files = row.cells[11].data

            var data = {
                violation_decision_id: violation_decision_id,
                files: files
            }

            this.is_loading_download = true

            this.actDownloadDecision(data).then((res) => {
                this.is_loading_download = false

                if(!res) {
                    toastr.error(`File quyết định không tồn tại, tải thất bại!`,'', {closeButton: true})
                }

                if(res.ok === true){
                    toastr.success(`Tải quyết định xử phạt thành công!`,'', {closeButton: true})
                }
            })
        },

    }
}
</script>

<style scoped>
    button {
		background-color: #F7F7F7;
		border: white;
	}

    .mheader-dialog {
        padding: 20px;
        background-color: #17A2B8;
        color: white;
    }

    .mybtn-cancel {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #FFFFFF !important;   
        color: black !important;  
        border: 1px solid #dee2e6 !important;  
    }

    .mybtn-upload{
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #17A2B8 !important;
        border: 1px solid #dee2e6 !important; 
    }

    .mybtn-cancel:hover {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #E5E5E5 !important;  
        color: black !important; ; 
        border: 1px solid #dee2e6 !important;  
    }

    .mclose-btn{
        color: white;
        background-color: #17A2B8;
        font-size: 20px;
    }

    .mclass-btn{
        border: 1px solid #b0b1b2; 
        background-color: white;
        padding: 5px;
        border-radius: 5px;
        margin-left: -10px;
    }

</style>