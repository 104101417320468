export default {
    user_all: [],                               // Danh sách user
    user_phone_book: [],                        // Danh bạ điện thoại
    user_id_inserted: [],                       // User vừa add vào
    user_edit: [],                              // User chỉnh sửa
    user_id_edited: [],                         // ID user chỉnh sửa
    user_delete: [],                            // Thông tin tài khoản xóa
    total_user: 0,                              // Tổng số lượng tài khoản
    fpd_user: 0,                                // Tổng số lượng tài khoản cấp cục
    zone_user: 0,                               // Tổng số lượng tài khoản cấp vùng
    province_user: 0,                           // Tổng số lượng tài khoản cấp tỉnh
    district_user: 0                            // Tổng số lượng tài khoản cấp huyện
}