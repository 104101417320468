export default {
    list_location_level: [],                    // Danh sách cấp lập biên bản: Toàn quốc / Vùng / Tỉnh / Huyện
    list_organization_by_location_level: [],    // Danh sách các đơn vị 
    list_district_by_province: [],              // Danh sách huyện theo mã tỉnh
    list_province: [],                          // Danh sách tỉnh
    entity_type: [],                            // Bảng định nghĩa tổ chức, cá nhân
    sex_type: [],                               // Bảng định nghĩa giới tính
    list_country: [],                           // Bang định nghĩa quốc gia
    list_organization_group: [],                // Danh sách các tổ chức ra quyết định xử phạt
    list_article: [],                           // Danh sách các Điều (cũng là danh sách các Hành vi vi phạm)
    list_decision_status: [],                   // Danh sách tình trạng quyết định
}