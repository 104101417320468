<template>
	<div class="mgt-90">
		<div class="center_div">
			<!--
			Lưu ý:
			1). Phải đưa vào Form để khi ấn Enter thực hiện lệnh 
			2). Đặt submit.prevent để lưu lại các giá trị form input khi nhập sai -->

			<form @submit.prevent="handleLogin">
				<div class="card card-info">
					<div class="card-header" :class="{ myheader_warning: isShowWarning, myheader: !isShowWarning }">
						<div class="txt-center" v-if="!isShowWarning"><b-img :src="this.$ASSETS_PATH + '/img/icon/admin.png'" width="60px"></b-img></div>
						<div class="txt-center" v-if="isShowWarning"><b-img :src="this.$ASSETS_PATH + '/img/icon/admin-warning.png'" width="60px"></b-img></div>
						<h3 class="txt-center login-header" v-if="!isShowWarning">ĐĂNG NHẬP HỆ THỐNG</h3>
						<h3 class="txt-center login-header" :class="{'login-header-warning': isShowWarning }" v-if="isShowWarning">TÀI KHOẢN HOẶC MẬT KHẨU KHÔNG CHÍNH XÁC!</h3>
					</div>

					<div class="card-body my-card-body">
						<p class="user_name">Tài khoản <code class="txt-right" v-if="isUserNameError">{{ msgUserName }}</code></p>
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text"><i class="fas fa-envelope"></i></span>
							</div>
							<input v-model="user_name" type="text" class="form-control" placeholder="Tài khoản" autocomplete="off">
						</div>

						<p class="password">Mật khẩu <code class="txt-right" v-if="isPasswordError">{{ msgPassword }}</code></p>
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text size-icon"><i class="fas fa-lock"></i></span>
							</div>

							<input v-if="showPassword" v-model="password" type="text" class="form-control" placeholder="Nhập mật khẩu" autocomplete="off">
							<input v-else v-model="password" type="password" class="form-control" placeholder="Nhập mật khẩu" autocomplete="off">
							<div class="input-group-prepend" @click="toggleShowPassword">
								<span class="input-group-text" style="background-color: white;">
									<i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
								</span>
							</div>
						</div>
						<div class="txt-right forget-password"><router-link :to="{name: 'forget-password'}"> Quên mật khẩu ? </router-link></div>

						<b-button size="sm" type="submit" class="btn-login" :class="{'btn-login-warning': isShowWarning }">Đăng nhập</b-button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	export default {
		name: 'login-page-index',
		data() {
			return {
				// Check user name and password
				isUserNameError: false,
				isPasswordError: false,

				// Message warning Error
				msgUserName: '',
				msgPassword: '',

				// Variables store user data
				user_name: '',
				password: '',
				isShowWarning: false,

				// Show / Hide password
				showPassword: ''
			}
		},
		watch: { // Sử dụng lắng nghe khi người dùng thay đổi giá trị trong Form
			// User Name: Nếu đang có lỗi (this.isUserNameError === true)
			user_name: function (val) {
				if(this.isUserNameError === true & val.length > 0) this.isUserNameError = false
				if(val.length > 0 && this.password.length > 0) this.isShowWarning = false
			},

			password: function (val) {
				if(this.isPasswordError === true & val.length > 0 ) this.isPasswordError = false
				if(val.length > 0 && this.password.length > 0) this.isShowWarning = false
			}
		},
		methods: {
			...mapActions([
				'actLogin',
				'actGetListProvinceByUserIdAndLocationLevelCode'
			]),

			toggleShowPassword() {
				this.showPassword = !this.showPassword;
			},

			resetForm(){
				this.user_name = '',
				this.password = '',
				this.isUserNameError = false,
				this.isPasswordError = false
			},

			handleLogin(){// Lấy dữ liệu từ Form Login
				// Validate Form khi submit 
				if(this.user_name === ''){
					this.isUserNameError = true
					this.msgUserName = 'Chưa nhập thông tin tài khoản!'
				}

				if(this.password === ''){
					this.isPasswordError = true
					this.msgPassword = 'Chưa nhập mật khẩu!'
				}

				let data = {
					user_name: this.user_name,
					password: this.password
				}
				// console.log('data from handleLogin: ', data)

				if(
					this.isUserNameError === false &&
					this.isPasswordError === false){

					// Thực hiện action, trả về kết quả check true (tài khoản hợp lệ), false (không hợp lệ)
					this.actLogin(data).then(res => {

						if(res.ok === true) {// Đăng nhập thành công
							console.log("res login =", res)

							// Lấy danh sách tỉnh theo user đăng nhập --> cho form nhập quyết định xử phạt
							var data = {
								location_level_code: res.data.location_level_code,
								user_id: res.data.user_id
							}   
							this.actGetListProvinceByUserIdAndLocationLevelCode(data)   // To set place_violation
							
							if(res.data.role_id === 1 || res.data.role_id === 2){
								this.$router.push({ name: 'user-manage'})
							}
							
							if(res.data.role_id === 3 || res.data.role_id === 4){
								this.$router.push({ name: 'form-violation-decision'})  // Chuyển về trang lập quyết định xử phạt (Tra cứu xử phạt: search-penalty-result)
							}

						}else{// Đăng nhập thất bại
							this.isShowWarning = true
							// setTimeout(() => this.isShowWarning = false, 6000);
						}
					})
				} // End if
			}
		}
	}
</script>

<style scoped>
	.txt-right {
		float: right;
	}
	.txt-center {
    	text-align: center;
	}

	.center_div{ /* Điều chỉnh hộp login vào giữa */
		margin: 0 auto;
		width: 500px /* value of your choice which suits your alignment */;
	}

	.mgt-90 {
		margin-top: 90px;
	}

	.card-header.myheader {
		background-color: #15AABF;
	}

	.card-header.myheader_warning {
		background-color: #BC6C25 !important;
		color: white !important;
	}

	.card-body.my-card-body {
		padding: 30px;
	}

	.login-header {
		color: #94E4EF;
		font-size: 13px;
		margin-top: 10px;
	}

	.login-header-warning {
		color: white;
		font-size: 13px;
		margin-top: 10px;
	}

	.smg-warning {
		margin-top:20px;
		font-size: 14px;
		color: red;
	}

	.user_name {
		margin-top:10px;
		font-size: 14px;
		color: #264653;
	}

	.password {
		margin-top:10px;
		font-size: 14px;
		color: #264653;
	}

	.forget-password {
		font-size: 13px;
		color: #264653;
	}

	.txt-right.forget-password > a{
		text-decoration: none;
		color: #264653;
	}

	.txt-right.forget-password > a:hover{
		text-decoration: none;
		color: #6a040f;
	}

	::placeholder {
		font-size: 13px;
	}

	.login-page {
		margin-top: 80px;
		margin-bottom: 120px;
	}

	.btn-login.btn-secondary.btn-sm {
		margin-top: 20px;
		background-color: #409CD0;
		border: 1px solid #409CD0;
	}

	.btn-login.btn-secondary.btn-sm:hover {
		background-color: #17A2B8;
		border: 1px solid #17A2B8;
	}

	.btn-login-warning {
		margin-top: 20px;
		background-color: #BC6C25 !important;
		border: 1px solid #BC6C25 !important;
	}

	.size-icon {
		font-size: 18px;
	}


</style>