<template>
	<div>
		<!-- FORM EDIT -->
			<div class="modal fade" id="reset-password">
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content" style="border-color: cadetblue;">
						<div class="modal-header myheader-info">
							<i class="fas fa-edit myicon-info"></i>
							<p class="modal-title" style="font-size: 16px; ">Khôi phục mật khẩu</p>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true" style="color:white">&times;</span>
							</button>
						</div>

						<div style="padding: 20px; margin-top:10px; margin-bottom: 10px;">
							<p class="mytitle">Mật khẩu mới <code>*</code><code class="txt-right" v-if="isNewPasswordError">{{ msgValidateNewPassword }}</code></p>
							<div class="input-group mb-3">
								<div class="input-group-prepend">
									<span class="input-group-text"><i class="fas fa-lock"></i></span>
								</div>

								<input v-if="showNewPassword" v-model="new_password" type="text" class="form-control" placeholder="Nhập mật khẩu mới" autocomplete="off">
								<input v-else v-model="new_password" type="password" class="form-control" placeholder="Nhập mật khẩu mới" autocomplete="off">
								<div class="input-group-prepend" @click="toggleShowNewPassword">
									<span class="input-group-text" style="background-color: white;">
										<i class="fas" :class="{ 'fa-eye-slash': showNewPassword, 'fa-eye': !showNewPassword }"></i>
									</span>
								</div>

							</div>
						</div>
						
						<div class="modal-footer justify-content-between" style="padding: 15px; background-color: #F7F7F7">
							<button type="button" class="btn btn-info" style=" width: 150px; padding: 2px; font-size: 15px; color: white;" v-on:click.prevent="handleResetPassword">Khôi phục mật khẩu</button>
							<button type="button" class="btn btn-secondary mybtn-cancel" data-dismiss="modal" ref="closeForm" v-on:click.prevent="cancelResetPassword">Hủy</button>
						</div>
					</div>
					<!-- /.modal-content -->
				</div>
				<!-- /.modal-dialog -->
			</div>
			<button type="button" class="btn btn-info" data-toggle="modal" data-target="#reset-password" style="display:none" ref="formResetPassword">
                Change password
            </button>
			<!-- /.modal -->
		
	</div>

</template>

<script>

	import { mapActions } from 'vuex'

	export default {
		name: 'reset-password-user',
		data() {
			return {
				// Is show validate warning
				isNewPasswordError: false,

				// Messade validate
				msgValidateNewPassword: '',

				// Value			
				new_password: '', 
				user_id: '',

				// Show / Hide password
				showNewPassword: '',
			}
		},
		watch: { // Sử dụng lắng nghe khi người dùng thay đổi giá trị trong Form
			new_password: function (val) {
				if(this.isNewPasswordError === true & val.length > 0) this.isNewPasswordError = false
			}

		},
		methods: {
			...mapActions([
				'actResetPassword'
			]),

			openFormResetPassword(user_id){
				// console.log('user id === ', user_id)
				this.user_id = user_id
				this.$refs.formResetPassword.click()
			},

			toggleShowNewPassword() {
				this.showNewPassword = !this.showNewPassword;
			},

			cancelResetPassword(){
				this.new_password = ''
			},

			// Tạo nhóm
			handleResetPassword(){
				if (this.new_password === '') { //this.new_password === '' || 
					this.isNewPasswordError = true
					this.msgValidateNewPassword = 'Chưa nhập mật khẩu mới!'
				}

				if (this.new_password !== '' && this.new_password.length < 6 ) { //this.new_password === '' || 
					this.isNewPasswordError = true
					this.msgValidateNewPassword = 'Mật khẩu tối thiểu phải có 6 ký tự!'
				}

				if(this.new_password.length >= 6){
					var data = {
						user_id: this.user_id,
						new_password: this.new_password
					}
					// console.log('req data = ', data)

					this.actResetPassword(data).then((res)=>{

						if(res.ok === true){
							// Bỏ giá trị đang lưu ở form
							this.new_password = ''

							// Ẩn Form
							this.$refs.closeForm.click()

							// Hiển thị câu thông báo thành công
							toastr.success('Khôi phục mật khẩu thành công!', 'Khôi phục mật khẩu')

						}else{ // Nhập sai mật khẩu cũ hoặc mật khẩu mới và cũ trùng nhau

							toastr.error(`${res.error}`,"Khôi phục mật khẩu")
						}
					})
				}
			}
		}
	}
</script>

<style scoped>

	/* LABEL INPUT */
    .mytitle {
        margin-top: 15px;
		margin-bottom: 5px;
    }

	.mya {
        text-decoration: none;
        color: #138496;
    }

    .mya:hover {
        color: #138496;
        text-decoration: none;
    }

	/* CARD */
    .mcard-header {
        padding: 0;
    }

    .mcard-subheader {
        padding: 5px 0px 5px 20px;
        margin-bottom: 0px;
    }

	/* BUTTON */
    .mbtn {
        width: 200px !important;
        margin-bottom: 20px !important;
        margin-left: 15px !important;
    }

	/* LABEL INPUT */
    .mlb-input {
        margin-top: 15px;
		margin-bottom: 5px;
    } 


	/* FORM EDIT */
	.mybtn-cancel {
		width: 150px; 
		padding: 2px !important; 
		font-size: 15px !important; 
		background-color: #FFFFFF !important;   
		color: black !important;  
		border: 1px solid #dee2e6 !important;  
	}

	.mybtn-cancel:hover {
		width: 150px; 
		padding: 2px !important; 
		font-size: 15px !important; 
		background-color: #E5E5E5 !important;  
		color: black !important; ; 
		border: 1px solid #dee2e6 !important;  
	}

	.myicon-info {
		margin-top: 6px;
		padding-right: 10px;
		font-size: 18px;
	}

	.myheader-info {
		padding: 22px;
		background-color: #17A2B8;
		color: white;
	}


	/* SUCCESS */
	.myicon-success {
		margin-top: 6px;
		padding-right: 10px;
		font-size: 18px;
	}

	.myheader-success {
		padding: 12px;
		background-color: #28A745;
		color: white;
	}

	.mybtn-action-success {
		width: 150px; 
		padding: 2px; 
		font-size: 14px; 
		background-color: #A0A6AB; 
		border-color: #A0A6AB
	}

</style>