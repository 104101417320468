import { render, staticRenderFns } from "./Barchart.vue?vue&type=template&id=52c44d5c&scoped=true&"
import script from "./Barchart.vue?vue&type=script&lang=js&"
export * from "./Barchart.vue?vue&type=script&lang=js&"
import style0 from "./Barchart.vue?vue&type=style&index=0&id=52c44d5c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c44d5c",
  null
  
)

export default component.exports