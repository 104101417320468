import axiosInstance from '@/plugin/axios.js';

export default {

    // BÁO CÁO CÁ NHÂN
    async actReportIndividual({ commit }, data_json) {
        try {
            var res = await axiosInstance.get('/report/individual/' + data_json)

            if (res.status === 200) {

                commit('GET_REPORT_INDIVIDUAL', res.data)

                return {
                    ok: true,
                    data: res.data.individual
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actReport: ', error.response.data)
            return error.response.data 
        }
    },    
    
    // BÁO CÁO TỔ CHỨC
    async actReportOrganization({ commit }, data_json) {
        try {
            var res = await axiosInstance.get('/report/organization/' + data_json)

            if (res.status === 200) {
                commit('GET_REPORT_ORGANIZATION', res.data)

                return {
                    ok: true,
                    data: res.data.organization
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            return error.response.data 
        }
    },   
}