<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Danh sách quyết định xử phạt</h3>

                <!-- DOWNLOAD -->
                <div class="row">
                    <div class="col-md-10"></div>
                    <div class="col-md-2">
                        <div class="txt-right" style="margin-right: -20px;">
                            <vue-excel-xlsx
                                :data="getListIndividual"
                                :columns="columns"
                                :file-name="'Danh sach QD xu phat ca nhan'"
                                :file-type="'xlsx'"
                                :sheet-name="'Danh sach QD xu phat ca nhan'"
                                >
                                <span 
                                    id="hide-btn-vue-excel-xlsx"
                                    class="btn btn-sm" 
                                    style="border: 1px solid #D9D9D9;"
                                >
                                Tải về 
                                <i class="fas fa-download micon"></i>
                                </span>
                            </vue-excel-xlsx>
                        </div>
                    </div>
                </div>
            </div>

            <!-- LIST INDIVIDUAL: :sort="sort" -->
            <ProgressBar mode="indeterminate" style="height: .2em; border-radius: 0px;" v-if="is_loading_download" />

            <div class="card-body">
                <grid 
                    :cols="header_table_admin" 
                    :rows="getListIndividual" 
                    :language="language" 
                    :pagination="pagination"
                    :sort="true"
                    :search="search">
                </grid>
            </div>
            <!-- /LIST INDIVIDUAL -->

            <!-- UPLOAD FILES -->
            <div>
                <div class="modal fade" id="upload_form" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header mheader-dialog">
                                <h4 class="modal-title" style="font-size: 18px;">
                                    <i class="fas fa-info-circle"></i> 
                                Lưu quyết định xử phạt
                                </h4>
                                <button type="button" class="mclose-btn" data-dismiss="modal" aria-label="Close" ref="closeFormUpload">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <ProgressBar mode="indeterminate" style="height: .3em; border-radius: 0px;" v-if="is_loading_upload" />
                            <div class="modal-body">
                                <div class="row">
                                    <h6>Xác nhận thông tin</h6>
                                    <p>Quyết định xử phạt tải lên là của <span class="bolder">{{full_name}}</span> và là bản chính thức, đã đóng dấu, kiểu file pdf.</p>
                                    <p class="mwarning">Lưu ý: Không thể thay đổi file quyết định sau khi lưu.</p>
                                </div>
                                <div class="row">
                                    <h6>Ghi chú</h6>
                                    <textarea class="form-control" rows="3" placeholder="Nhập ghi chú" v-model="decision_status_note"></textarea>
                                </div>
                                <div class="row" style="margin-top: 15px;">
                                    <h6>Thông tin file quyết định</h6>
                                    <!-- Thống kê số lượng files -->
                                    <div style="margin-top: 5px;" v-if="num_files">
                                        <span><i class="far fa-check-circle" style="color: #82C91E"></i></span>
                                        {{ num_files }} file </div>
                                    
                                    <!-- Danh sách tập tin -->
                                    <span style="margin-top: 5px;" v-if="num_files">
                                        <span class="btn-tool" 
                                            v-for="(file, index) in files"
                                            v-bind:key="index"> {{ file.name }} <i class="fas fa-times my-un-attach-file" v-on:click.prevent="removeFile(index)"></i>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-between" style="padding: 15px;">
                                <button type="button" class="btn btn-danger mybtn-upload"
                                        v-on:click.prevent="submitFile()"
                                        >Lưu</button>
                                <button type="button" class="btn btn-danger mybtn-cancel" data-dismiss="modal" 
                                        v-on:click.prevent="cancelSubmitFile()"
                                        >Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-info" data-toggle="modal" 
                    data-target="#upload_form" 
                    style="display: none" 
                    ref="openConfirmUpload"></button>

                <!-- Input file -->
                <input style="display: none" type="file" ref="files" name="myFiles" multiple 
                    v-on:change="selectFile()">
            </div>
            <!-- /UPLOAD FILES -->


            <!--  XÁC NHẬN CHUYỂN TỪ TRẠNG THÁI ĐANG THI HÀNH -- ĐÃ THI HÀNH ÁN XONG -->
            <div>
                <div class="modal fade" id="confirm_complete_decision" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header mheader-dialog">
                                <h4 class="modal-title" style="font-size: 18px;">
                                    <i class="fas fa-info-circle"></i> 
                                    Xác nhận thi hành xong quyết định xử phạt
                                </h4>
                                <button type="button" class="mclose-btn" data-dismiss="modal" aria-label="Close" ref="closeFormConfirmComplete">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <h6>Xác nhận</h6>
                                    <div>Bạn xác nhận ông (bà) <span class="bolder">{{full_name}}</span> đã thi hành xong quyết định xử phạt?</div>
                                </div>

                                <!-- Ngày chấp hành xong quyết định xử phạt -->
                                <div class="row" style="margin-top: 20px;">
                                    <div class="mheader-3"> <i class="fas fa-calendar-alt micon"></i> 
                                    Ngày chấp hành xong quyết định xử phạt <span style="color: red">(*)</span></div>
                                        
                                    <!-- :min-date="new Date()" -->
                                    <v-date-picker 
                                        :masks="masks"
                                        :attributes='attrs'
                                        show-weeknumbers
                                        v-model="execution_date">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="input-group">
                                                <input
                                                    placeholder="Ngày chấp hành xong quyết định xử phạt: DD-MM-YYYY"
                                                    class="form-control"
                                                    :value="inputValue"
                                                    v-on="inputEvents"
                                                />
                                            </div>
                                        </template>
                                    </v-date-picker>
                                </div>

                                <div class="row" style="margin: 20px 0px;">
                                    <h6>Ghi chú</h6>
                                    <textarea class="form-control" rows="3" placeholder="Nhập ghi chú" v-model="decision_status_note"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-between" style="padding: 15px;">
                                <button type="button" class="btn btn-danger mybtn-upload"
                                        v-on:click.prevent="confirmCompleteDecision()"
                                        >Xác nhận hoàn thành</button>
                                <button type="button" class="btn btn-danger mybtn-cancel" data-dismiss="modal" 
                                        v-on:click.prevent="cancelConfirmCompleteDecision()"
                                        >Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-info" data-toggle="modal" 
                    data-target="#confirm_complete_decision" 
                    style="display:none" 
                    ref="openConfirmComplete"></button>
            </div>
            <!--  /XÁC NHẬN CHUYỂN TỪ TRẠNG THÁI ĐANG THI HÀNH -- ĐÃ THI HÀNH ÁN XONG -->


            <!-- DELETE VIOLATION DECISION -->
			<div class="modal fade" id="delete-decision">
				<div class="modal-dialog modal-default modal-lg">
					<div class="modal-content">
						<div class="modal-header bg-warning" style="color: white; background-color: #138496;">
							<h6 class="modal-title"><i class="icon fas fa-ban"></i> Xóa quyết định xử phạt </h6>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeConfirmDelete" style="display: none">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div style="padding: 20px"> 
								Xác nhận <span class="mwarning">xóa quyết định (mã QĐ: {{violationDecisionCode(violation_decision_number, 1)}}) </span>xử phạt ông (bà) <span class="mwarning"> {{full_name}} </span>?
							</div>
						</div>
						<div class="modal-footer justify-content-between">
							<button type="button" class="btn btn-sm" v-on:click.prevent="deleteDecision()" style="width: 150px; color: white; background-color: #DC5C51;">Xác nhận xóa</button>
							<button type="button" class="btn btn-sm mybtn-cancel" data-dismiss="modal" v-on:click.prevent="resetDeleceDecision()" style="width: 100px; color: #5C677D; background-color: #E5E5E5;"> Hủy xóa </button>
						</div>
					</div>
				</div>
			</div>

			<button type="button" class="btn btn-default" data-toggle="modal" data-target="#delete-decision" style="display: none" ref="openConfirmDelete">
                Confirm Delete
            </button>
            <!-- /DELETE VIOLATION DECISION -->


            <!-- GHI CHÚ CHO TRẠNG THÁI QUYẾT ĐỊNH ĐANG THI HÀNH -->
            <div>
                <div class="modal fade" id="note-decision" style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header mheader-dialog">
                                <h4 class="modal-title" style="font-size: 17px;">
                                    <i class="fas fa-info-circle"></i> 
                                    Ghi chú quyết định đang thi hành
                                </h4>
                                <button type="button" class="mclose-btn" data-dismiss="modal" aria-label="Close" ref="closeFormConfirmNote">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row" style="margin-top: 10px;">
                                    <textarea class="form-control" rows="6" placeholder="Nhập ghi chú" v-model="decision_status_is2_note"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-between" style="padding: 15px;">
                                <button type="button" class="btn btn-danger mybtn-upload" data-dismiss="modal"
                                        style=""
                                        v-on:click.prevent="handleSaveNote()"
                                        >Lưu ghi chú</button>
                                <button type="button" class="btn btn-danger mybtn-cancel" data-dismiss="modal" 
                                        v-on:click.prevent="cancelConfirmNote()"
                                        >Hủy</button>
                            </div>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-info" data-toggle="modal" 
                    data-target="#note-decision" 
                    style="display:none" 
                    ref="openConfirmNote"></button>
            </div>
            <!-- /GHI CHÚ CHO TRẠNG THÁI QUYẾT ĐỊNH ĐANG THI HÀNH -->


        </div>
        <!-- /DIV content -->
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { Grid } from 'gridjs-vue'
import moment from 'moment';
import ProgressBar from 'primevue/progressbar';

export default {
    name: 'table-penalty-result',
    components: {
        Grid,
        ProgressBar
    },
    data() {
        return {
            // Download: vue-excel-xlsx
            // ----------------------------
            columns: [
                { 
                    field: 'violation_decision_number',
                    label: 'Số QĐ'
                },
                {
                    field: 'decision_date',
                    label: 'Ngày ban hành'
                },
                { 
                    field: 'organization_group_name',
                    label: 'Đơn vị ra quyết định'
                },
                { 
                    field: 'decision_organization_name',
                    label: 'Cơ quan ban hành'
                },
                {
                    field: 'full_name',
                    label: 'Họ và tên'
                },
                {
                    field: 'sex_name',
                    label: 'Giới tính'
                },
                {
                    field: 'birthday',
                    label: 'Ngày sinh'
                },
                {
                    field: 'country_name',
                    label: 'Quốc tịch'
                },
                {
                    field: 'person_id_number',
                    label: 'CMND/Hộ chiếu'
                },
                { 
                    field: 'current_residence',
                    label: 'Địa chỉ'
                },
                {
                    field: 'occupation',
                    label: 'Nghề nghiệp'
                },
                {
                    field: 'decision_status_name',
                    label: 'Trạng thái'
                },
                { 
                    field: 'execution_date',
                    label: 'Ngày chấp hành xong QĐXP'
                },
                { 
                    field: 'province_name',
                    label: 'Tỉnh'
                }
            ],

            // GridJS: table phone-book: formatter: (cell) => `${moment(cell).format('DD-MM-YYYY')}`
            header_table_admin: [
                { // 0
                    id: 'file_upload',
                    name: 'file_upload',
                    hidden: true
                },
                { // 1 | // Cột này sử dụng cho hàm updateDecisionStatusViolationDecision trên server, trường hợp: quyết định có nhiều người sử phạt 
                    id: 'violation_person_id',
                    name: 'violation_person_id',
                    hidden: true
                },    
                { // 2 | // Cột này sử dụng cho hàm updateDecisionStatusViolationDecision trên server, trường hợp: quyết định có nhiều người sử phạt 
                    id: 'user_id_created',
                    name: 'user_id_created',
                    hidden: true
                },          
                { // 3
                    id: 'violation_decision_number',
                    name: 'Số QĐ',
                    formatter: (cell, row) => {
                        if(row.cells[2].data === this.getCurrentUser.user_id){ // type = 1: Quyết định do mình tạo ra
                            return this.$gridjs.html(this.violationDecisionCode(cell, 1))

                        }else{ // type = 2: Quyết định do đơn vị khác tạo ra
                            return this.$gridjs.html(this.violationDecisionCode(cell, 2))
                        }
                    },
                    width: '110px'
                },
                { // 4
                    id: 'decision_date',
                    name: 'Ngày ban hành',
                    sort: {
                        compare: (a, b) => {  
                            if(a != null && b != null){
                                a = a.split('-').reverse().join('');
                                b = b.split('-').reverse().join('');
                                if (a > b) {
                                    return 1;
                                } else if (b > a) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }else{
                                return 0
                            }         
                        }
                    }
                },
                { // 5
                    id: 'organization_group_name',
                    name: 'Đơn vị ra quyết định',
                    sort: false,
                },
                { // 6
                    id: 'decision_organization_name',
                    name: 'Cơ quan ban hành',
                    sort: false,
                },
                { // 7
                    id: 'full_name',
                    name: 'Họ và tên'
                },
                { // 8
                    id: 'birthday',
                    name: 'Ngày sinh',
                    sort: {
                        compare: (a, b) => {  
                            if(a != null && b != null){
                                a = a.split('-').reverse().join('');
                                b = b.split('-').reverse().join('');
                                if (a > b) {
                                    return 1;
                                } else if (b > a) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }else{
                                return 0
                            }         
                        }
                    }
                },
                { // 9
                    id: 'person_id_number',
                    name: 'CMND/CCCD',
                    sort: false,
                    // width: '110px'
                },
                { // 10
                    id: 'current_residence',
                    name: 'Địa chỉ',
                    sort: false,
                    // width: '240px'
                },
                { // 11
                    id: 'decision_status_id',
                    name: 'Trạng thái',
                    sort: false,
                    width: '160px',
                    formatter: (cell) => this.$gridjs.html(this.formartStatus(cell))
                },
                { // 12
                    id: 'violation_decision_id',
                    name: 'Công cụ',
                    sort: false,
                    width: '170px',
                    formatter: (cell, row) => {  // row: array value of row; cell: value of this cell (user_id) / 
                        // 1. Chờ phê duyệt
                        if(row.cells[11].data === 1 && row.cells[2].data === this.getCurrentUser.user_id || this.isSuperAdmin === true){ // Người tạo ra quyết định thì mới có quyền chỉnh sửa, xem bản nháp
                            return [ 
                                //  Edit
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleEditDecision(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="fas fa-pencil-alt micon2"></i></div>`)),                                
                                //  Download violation decision
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handDownloadDecision(cell)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="far fa-file-word micon2"></i></div>`)),
                                
                                //  handleUpload
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handUploadDecision(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="fas fa-upload micon2"></i></div>`)),

                                //  Delete
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.confirmDeleteDecision(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="fas fas fa-trash mclass-delete"></i></div>`))
                            ]
                        }

                        // 2. Đang thi hành:
                        if(row.cells[11].data === 2 && row.cells[2].data === this.getCurrentUser.user_id){ // Nếu là người tạo ra quyết định, được quyền tải, chỉnh sửa trạng thái <i class="fal fa-download"></i>
                            return [ 
                                //  Download
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleDownloadDecision(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm"><i class="fas fa-file-pdf micon2"></i></div>`)              
                                ), 
                                                        
                                //  Change status to complete
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleChangeStatusToComplete(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="fas fa-check-circle status-success"></i></div>`)),

                                //  Note
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleChangeNote(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm">
                                                    <i class="far fa-sticky-note micon2"></i></div>`))
                            ]
                        }

                        if(row.cells[11].data === 2 && row.cells[2].data !== this.getCurrentUser.user_id){ // Nếu không phải người tạo ra quyết định, chỉ xem
                            return [ 
                                //  Download
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleDownloadDecision(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm"> <i class="fas fa-file-pdf micon2"></i> </div>`))
                            ]
                        }

                        // 3. Đã thi hành: tất cả văn bản rơi vào khu vực mình quản lý thì có quyền xem
                        if(row.cells[11].data === 3){
                            return [ 
                                this.$gridjs.h('button', 
                                    {
                                        className: 'mclass',
                                        onClick: () => this.handleDownloadDecision(cell, row)
                                    }, 
                                this.$gridjs.html(`<div class="btn btn-sm"> <i class="fas fa-file-pdf micon2"></i> </div>`))
                            ]
                        }
                    }
                },
                { // 13 
                    id: 'execution_date',
                    name: 'Ngày chấp hành xong QĐXP',
                    compare: (a, b) => {  
                        if(a != null && b != null){
                            a = a.split('-').reverse().join('');
                            b = b.split('-').reverse().join('');
                            if (a > b) {
                                return 1;
                            } else if (b > a) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }else{
                            return 0
                        }         
                    }
                },
                { // 14 (new)
                    id: 'province_name',
                    name: 'Tỉnh'
                },
                { // 14->15
                    id: 'note',
                    name: 'Ghi chú',
                    sort: false,
                    formatter: (cell, row) => {
                        if(row.cells[11].data === 2 && row.cells[2].data === this.getCurrentUser.user_id){ // Đang thi hành, hiển thị note 2
                            return this.$gridjs.html(this.showNote(2, row))
                        }
                        if(row.cells[11].data === 3 && row.cells[2].data === this.getCurrentUser.user_id){ // Đang thi hành, hiển thị note 3
                            return this.$gridjs.html(this.showNote(3, row))
                        }
                    }
                },
                { // 15->16
                    id: 'decision_status_is2_note',
                    name: 'decision_status_is2_note',
                    hidden: true
                },
                { // 16->17
                    id: 'decision_status_is3_note',
                    name: 'decision_status_is3_note',
                    hidden: true
                },    
            ],

            pagination: true,
            // Resizable: true,
            sort: true,
            search: true,
            language: {
                search: {
                    placeholder: '🔍 Tìm kiếm ...',
                },
                sort: {
                    sortAsc: 'Sắp xếp tăng dần',
                    sortDesc: 'Sắp xếp giảm dần',
                },
                pagination: {
                    previous: 'Về trước',
                    next: 'Kế tiếp',
                    navigate: (page, pages) => `Trang ${page} trên tổng ${pages}`,
                    page: (page) => `Trang ${page}`,
                    showing: 'Hiển thị',
                    of: 'trên tổng',
                    to: 'từ',
                    results: 'kết quả',
                },
                loading: 'Đang load trang ...',
                noRecordsFound: 'Không có kết quả nào được tìm thấy!',
                error: 'Có lỗi xảy ra trong quá trình lấy dữ liệu!',
            },

            // Validation_decision
            full_name: '',                      // Tên người thi hành quyết định, người xóa
            violation_decision_id: '',          // current violation_decision_id selected, sử dụng khi upload file, để biết file này upload cho quyết định nào
            violation_person_id: 0,             // Mã số violation_person_id ở bảng vd_individual, sử dụng cho hàm updateDecisionStatusViolationDecision trên server, trường hợp: quyết định có nhiều người sử phạt 
            violation_decision_number: '',

            // File upload
            files: [],							// Upload file
            num_files: 0,						// Số lượng file Upload
            decision_status_note: '',           // Ghi chú
            is_loading_upload: false,                  // Show / Hide loading

            // Download
            is_loading_download: false,

            // Ghi chú đang thi hành
            decision_status_is2_note: '',       // Ghi chú

            // Date type
            date_type: 1,

            // VCalendar
            execution_date: null,
            attrs: [
                {
                    key: 'today',
                    highlight: true,       // dot
                    dates: new Date()
                }
            ],
            masks: {
                input: 'DD-MM-YYYY',
            },

            // Check decision_date_to_check
            decision_date_to_check: null
        }
    },
    async created(){
        // Current user info
        var data = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_level_code: this.getCurrentUser.location_level_code,
                date_type: this.date_type,
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD"),
                type_get_data: 'by_user'
            }

        // List individual violation person
        this.actGetIndividualViolationByUser(data)
    },
    computed: {
        ...mapGetters([
            'isSuperAdmin',
            'getCurrentUser',
            'getListIndividual'
        ]),
    },
    methods: {
        ...mapActions([
            'checkLogin',
            'actGetIndividualViolationByUser',
            'actDownloadDecisionIndividual',
            'uploadViolationDecision',
            'actDownloadDecision',
            'actConfirmCompleteDecision',
            'actGetViolationDecisionById',
            'actDeleteDecision',
            'actSaveNote'
        ]),

        // TABLE
        // =========================================================
        // ViolationDecisionCode
        violationDecisionCode(cell, type){
            if(cell === ''){ // Nếu chưa điền số quyết định
                if(type === 1){ // Current user login lập
                    return `.../...`
                }

                if(type === 2){ // Người khác lập
                    return `<span style="color: red"> .../... </span>`
                }

            }else{ // Nếu đã có số quyết định
                if(type === 1){ // Current user login lập
                    return cell + `/QĐ-XPHC`
                }

                if(type === 2){ // Người khác lập
                    return `<span style="color: red">` + cell + "/QĐ-XPHC" + `</span>`
                }
            }
        },  

        // 1. Format cell
        formartStatus(status_decision_id){
            if(status_decision_id === 1) return '<span> <i class="fas fa-ban status-wait"></i> Chờ phê duyệt </span>'
            if(status_decision_id === 2) return '<span class="status-process"> <i class="fas fa-circle-notch"></i> Đang thi hành </span>'
            if(status_decision_id === 3) return `<span class="status-success"> <i class="fas fa-check-circle"></i> Đã thi hành </span>`
        },

        // EDIT
        // =========================================================
        handleEditDecision(violation_decision_id) {
            // console.log("handleEditDecision individual: ", violation_decision_id)

            var data = {
                violation_decision_id: violation_decision_id, 
                entity_type_id: 1
            }
            this.$router.push({name: 'form-violation-decision', params: {data}})
        },

        // DELETE
        // =========================================================
        confirmDeleteDecision(violation_decision_id, row) {
            this.violation_decision_number = row.cells[3].data

            this.violation_decision_id = violation_decision_id

            var data = {
                violation_decision_id: violation_decision_id,
                entity_type_id: 1                                   // Cá nhân (sử dụng để phân biệt cá nhân / tổ chức: do dùng chung action upload; cần trường này để biết lấy kết quả trả về)
            }

            var data_json = JSON.stringify(data)
            this.actGetViolationDecisionById(data_json).then((res) => {
                if(res.ok){
                    // Res data
                    var individual = res.data.individual
                    var violation_decision = res.violation_decision     // Chưa dùng đến, cần sẽ bổ sung thông tin người vi pham để confirm trước khi xóa

                    // Gộp danh sách tên: để hiển thị ở thông báo confirm Delete
                    var full_name  = ''
                    individual.forEach(obj => {
                        full_name += obj.full_name + ', '
                    });

                    this.full_name = full_name.slice(0, -2)
                    
                    // Hiển thị box confirm delete
                    this.$refs.openConfirmDelete.click()
                }
            })
        },

        deleteDecision(){
            var data = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_level_code: this.getCurrentUser.location_level_code,
                violation_decision_id: this.violation_decision_id,
                entity_type_id: 1,                                   // Cá nhân (sử dụng để phân biệt cá nhân / tổ chức: do dùng chung action upload; cần trường này để biết lấy kết quả trả về)
                date_type: this.date_type,
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD")
            }

            this.actDeleteDecision(data).then((res)=>{
                // console.log("actDeleteDecision", res)

                if(res.ok === true){
                    // Ẩn Form
                    this.$refs.closeConfirmDelete.click()					

                    // Hiển thị câu thông báo thành công
                    toastr.success(`Xóa quyết định thành công!`,'', {closeButton: true})

                    this.resetDeleceDecision()

                }else{
                    toastr.error(`Xóa quyết định thất bại!`,'', {closeButton: true})
                }
            })
        },

        resetDeleceDecision(){
            this.full_name = ''
            this.violation_decision_id = ''
        },

        // DOWNLOAD WORD
        // =========================================================
        handDownloadDecision(violation_decision_id) {
            this.is_loading_download = true

            this.actDownloadDecisionIndividual(violation_decision_id).then((data) => {
                this.is_loading_download = false

                if(data.ok){
                    toastr.success('Tải quyết định xử phạt thành công!', '', {closeButton: true})
                }else{
                    toastr.error(`File quyết định không tồn tại, tải thất bại!`,'', {closeButton: true})
                }
            })
        },

        // UPLOAD DECISION
        // =========================================================
        // Kích nut input upload file
        handUploadDecision(violation_decision_id, row){
            this.violation_decision_id  = violation_decision_id
            this.full_name              = row.cells[7].data
            this.violation_person_id    = row.cells[1].data             // Riêng đối với cá nhân, phải sử dụng cột violation_person_id để set decision_status_id cho bảng vd_violation_decision trường hợp có nhiều cá nhân vi phạm                  
            this.decision_date_to_check = row.cells[4].data 
            this.$refs.files.click()
        },

        validateFileUpload(file){
            // console.log(file)

            let size_file = 0
            let MAX_SIZE_BIT = 52428800   				// 50MB  | 52428800 | 25M = 26,214,400 
            let MAX_SIZE_MB = Math.round(MAX_SIZE_BIT / (1024 * 1024), 0)

            file.forEach(obj => {
                size_file = size_file + obj.size
            })

            // console.log(size_file)

            // const allowedType = [
            //     "image/jpeg", "image/jpg", "image/png",                                              // jpeg, jpg, png
            //     "application/pdf", 																	// pdf
            //     "application/vnd.rar", 																// .rar
            //     "application/vnd.ms-excel",															// xls
            //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",				    // xlsx
            //     "application/msword",																// doc
            //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",			// docx
            //     "application/vnd.ms-powerpoint",													    // ppt
            //     "application/vnd.openxmlformats-officedocument.presentationml.presentation"			// pptx
            // ]

            // if(!allowedType.includes(file.type)) {
            //     return `Không được upload kiểu file này!`
            // }

            if(size_file > MAX_SIZE_BIT) {
                toastr.error(`Dung lượng tải lên vượt quá quy định ${MAX_SIZE_MB}Mb`,'', {closeButton: true})
                return false
            }
            return true
        },

        // Chọn file
        selectFile(){

            // Add file
            this.files = [...this.files, ...this.$refs.files.files]
            this.num_files = this.files.length		

            // Check upload file
            var check_mb_file_upload = this.validateFileUpload(this.files)

            if(check_mb_file_upload){
                // Kích hoạt nút xác nhân
                this.$refs.openConfirmUpload.click()

            }else{
                this.files = []
            }
        },

        removeFile(id){
            var arr = []

            // Loại bỏ phần tử có ID = ID remove
            for (let index = 0; index < this.files.length; index++) {
                if(index !== id) arr.push(this.files[index])
            }

            // Cập nhật lại mảng files và số lượng files
            this.files = arr
            this.num_files = arr.length
        },

        cancelSubmitFile(){
            this.files = []
            this.resetForm()
            this.$refs.closeFormUpload.click()
        },

        validateDecisionDateEntered(){
            if(!this.decision_date_to_check){
                toastr.error(`Cần thực hiện chỉnh sửa, bổ sung ngày ban hành trước khi lưu file quyết định xử phạt.`,'Chưa nhập thông tin ngày ban hành', {closeButton: true})
                return false
            }
            return true
        },  

        submitFile(){

            // Validate ngày ban hành quyết định
            let is_entered_decision_date = this.validateDecisionDateEntered()

            if(is_entered_decision_date){
                var data = {
                    violation_person_id: this.violation_person_id,                                // Riêng đối với cá nhân, phải sử dụng cột violation_person_id để set decision_status_id cho bảng vd_violation_decision trường hợp có nhiều cá nhân vi phạm
                    user_id: this.getCurrentUser.user_id,
                    role_id: this.getCurrentUser.role_id,
                    location_level_code: this.getCurrentUser.location_level_code,
                    violation_decision_id: this.violation_decision_id,
                    files: this.files,
                    decision_status_id: 2,                              // Chuyển sang trạng thái đang thi hành quyết định
                    decision_status_note: this.decision_status_note,
                    entity_type_id: 1,                                  // Cá nhân (sử dụng để phân biệt cá nhân / tổ chức: do dùng chung action upload; cần trường này để biết lấy kết quả trả về)
                    date_type: this.date_type,
                    date_start: moment().startOf('year').format("YYYY-MM-DD"),
                    date_end: moment().format("YYYY-MM-DD")
                }
                this.is_loading_upload = true

                this.uploadViolationDecision(data).then((res)=>{
                    if(res.ok === true){
                        // Ẩn Form
                        this.$refs.closeFormUpload.click()					

                        // Hiển thị câu thông báo thành công
                        toastr.success(`Lưu quyết định thành công!`,'', {closeButton: true})

                        this.is_loading_upload = false
                        this.resetForm()
                        
                    }else{
                        toastr.error(`Lưu quyết định thất bại!`,'', {closeButton: true})
                    }
                })
            }
        },

        resetForm(){
            this.files = []
            this.violation_decision_id = ''
            this.num_files = 0
            this.decision_status_note = ''
            this.full_name = ''
            this.violation_person_id = 0
            this.date_type = 1
            this.execution_date = null
            this.decision_date_to_check = null
        },

        // DOWNLOAD FILE ------------------------------------------------------------------


        // DOWNLOAD DECISION
        // =========================================================
        // Doing: đang thi hành quyết định
        handleDownloadDecision(violation_decision_id, row){

            // Lấy thông tin file theo quyết định (Gridjs): row là chứa toàn bộ thông tin của dòng click, cột file_upload là cột thứ 9 (ẩn đi)
            var files = row.cells[0].data

            var data = {
                violation_decision_id: violation_decision_id,
                files: files
            }

            this.is_loading_download = true

            this.actDownloadDecision(data).then((res) => {
                this.is_loading_download = false

                if(!res) {
                    toastr.error(`File quyết định không tồn tại, tải thất bại!`,'', {closeButton: true})
                }

                if(res.ok === true){
                    toastr.success(`Tải quyết định xử phạt thành công!`,'', {closeButton: true})
                }

            })
        },

        // CONFIRM CHANGE STATUS FROM DOING TO COMPLETE
        // =========================================================
        handleChangeStatusToComplete(violation_decision_id, row){
            this.violation_decision_id  = violation_decision_id
            this.full_name              = row.cells[7].data
            this.violation_person_id    = row.cells[1].data             // Riêng đối với cá nhân, phải sử dụng cột violation_person_id để set decision_status_id cho bảng vd_violation_decision trường hợp có nhiều cá nhân vi phạm                  
            this.$refs.openConfirmComplete.click()
        },

        validateExecutionDate(execution_date){
            if(execution_date === null || execution_date === ''){
                toastr.error("Chưa nhập ngày chấp hành xong quyết định xử phạt", { "closeButton": true });
                return false
            }
            return true
        },

        confirmCompleteDecision(){

            let check_execution_date = this.validateExecutionDate(this.execution_date)
            if(check_execution_date){
                var data = {
                    violation_person_id: this.violation_person_id,          // Riêng đối với cá nhân, phải sử dụng cột violation_person_id để set decision_status_id cho bảng vd_violation_decision trường hợp có nhiều cá nhân vi phạm
                    user_id: this.getCurrentUser.user_id,
                    role_id: this.getCurrentUser.role_id,
                    location_level_code: this.getCurrentUser.location_level_code,
                    violation_decision_id: this.violation_decision_id,
                    decision_status_id: 3,                              // Chuyển sang trạng thái đang thi hành quyết định
                    decision_status_note: this.decision_status_note,
                    entity_type_id: 1,                                   // Cá nhân (sử dụng để phân biệt cá nhân / tổ chức: do dùng chung action upload; cần trường này để biết lấy kết quả trả về)
                    date_type: this.date_type,
                    date_start: moment().startOf('year').format("YYYY-MM-DD"),
                    date_end: moment().format("YYYY-MM-DD"),
                    execution_date: moment(this.execution_date).format('YYYY-MM-DD')
                }

                this.actConfirmCompleteDecision(data).then((res) => {
                    if(res.ok === true){
                        toastr.success(`Xác nhận thi hành xong quyết định xử phạt thành công!`,'', {closeButton: true})
         
                        this.cancelConfirmCompleteDecision()

                    }else{
                        toastr.error(`Lỗi: Xác nhận hoàn thành quyết định xử phạt thất bại!`,'', {closeButton: true})
                    }
                })
            }
        },

        cancelConfirmCompleteDecision(){
            this.$refs.closeFormConfirmComplete.click()
            this.resetForm()
        },

        // NOTE
        // =========================================================
        showNote(status_decision_id, row){
            var note = ''

            if(status_decision_id === 2 && row.cells[16].data) note = `Giai đoạn đang thi hành: ` + row.cells[16].data
            if(status_decision_id === 3) {
                if(row.cells[17].data && row.cells[16].data) note = `Giai đoạn đã thi hành: ` + row.cells[17].data + ` <br> Giai đoạn đang thi hành: ` + row.cells[16].data
                if(row.cells[17].data && !row.cells[16].data) note = `Giai đoạn đã thi hành: ` + row.cells[17].data
            }
            return note
        },

        handleChangeNote(violation_decision_id, row){
            // Lưu id quyết định xử phạt
            this.violation_decision_id = violation_decision_id

            // Ghi chú hiện tại
            this.decision_status_is2_note = row.cells[16].data

            // Hiển thị box confirm
            this.$refs.openConfirmNote.click()
        },

        handleSaveNote(){
            var data = {
                user_id: this.getCurrentUser.user_id,
                role_id: this.getCurrentUser.role_id,
                location_level_code: this.getCurrentUser.location_level_code,
                violation_decision_id: this.violation_decision_id,
                decision_status_is2_note: this.decision_status_is2_note,
                entity_type_id: 1,                                   // Cá nhân (sử dụng để phân biệt cá nhân / tổ chức: do dùng chung action upload; cần trường này để biết lấy kết quả trả về)
                date_type: this.date_type,
                date_start: moment().startOf('year').format("YYYY-MM-DD"),
                date_end: moment().format("YYYY-MM-DD")
            }

            this.actSaveNote(data).then((res) => {
                console.log(res)

                if(res.ok){
                    toastr.success('Lưu thành công!', '', {closeButton: true})
                }
            })
        },
        
        cancelConfirmNote(){
            this.decision_status_is2_note = ''
            this.$refs.closeFormConfirmNote.click()
        }, 
        
        // SET DATE TYPE current user selected from component FormPenaltyIndividual / FormPenaltyOrganization
        setDateType(date_type){
            this.date_type = date_type
        }
    }
}
</script>

<style>
    /* .gridjs-tr {
        font-size: 14px;
        margin: 0px !important;
        padding: 0px !important;
    } */

    td.gridjs-td, .btn-sm {
        font-size: 14.5px;
        padding: 5px !important;
        white-space: nowrap !important
    }

    th.gridjs-th {
        font-size: 14px;
        padding: 5px !important;
        text-align: center;
        white-space: nowrap !important
    }

    table.gridjs-table {
        table-layout: auto !important
    }

    td[data-column-id = 'violation_decision_number'],
    td[data-column-id = 'decision_date'],
    td[data-column-id = 'person_id_number'],
    td[data-column-id = 'birthday']
    {
        text-align: right;
    }

    td[data-column-id = 'note']
    {
		white-space: wrap !important
    }

    button {
		background-color: #F7F7F7;
		border: white;
	}

    .mheader-dialog {
        padding: 20px;
        background-color: #17A2B8;
        color: white;
    }

    .mybtn-cancel {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #FFFFFF !important;   
        color: black !important;  
        border: 1px solid #dee2e6 !important;  
    }

    .mybtn-upload{
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #17A2B8 !important;
        border: 1px solid #dee2e6 !important; 
    }

    .mybtn-cancel:hover {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #E5E5E5 !important;  
        color: black !important; ; 
        border: 1px solid #dee2e6 !important;  
    }

    .mclose-btn{
        color: white;
        background-color: #17A2B8;
        font-size: 20px;
    }

    .mclass-btn{
        border: 1px solid #b0b1b2; 
        background-color: white;
        padding: 5px;
        border-radius: 5px;
        margin-left: -10px;
    }


</style>