<template>
    <div class="mgt-50">
        <div class="center_div_404">
            <div class="txt-center"><b-img :src="this.$ASSETS_PATH + '/img/icon/warning.png'" width="60px"></b-img></div>
            <div class="txt-center warning-header mgb-15"> KHÔNG THỂ TRUY CẬP THEO ĐƯỜNG DẪN TRÊN</div>
            <div class="text-center"><b-button size="sm" variant="success" class="mg"> <router-link :to="{name: 'login'}"> <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> QUAY LẠI TRANG CHỦ </router-link></b-button></div>
        </div>
        <b-img :src="this.$ASSETS_PATH + `/img/404.png`"></b-img>
    </div>
</template>

<script>

    export default {
        name: 'not-found',
    }
    
</script>

<style scoped>

    .center_div_404{ /* Điều chỉnh hộp login vào giữa */
		margin: 0 auto;
		width: 30% /* value of your choice which suits your alignment */
	}

    .warning-header {
        color: #834D03;
        font-size: 11x;
        font-weight: 500;
    }

    .mgt-50 {
        margin-top: 50px;
    }

    .mg {
        margin-top: 25px;
        color: #EEF6FA;
    }

    .btn.mg.btn-success.btn-sm > a, .btn.mg.btn-success.btn-sm > a.router-link-active {
        color: white;
        font-size: 11.5px;
        text-decoration: none;
    }

    .btn.mg.btn-success.btn-sm > a.router-link-active:hover {
        color: white;
        font-size: 11.5px;
        text-decoration: none;
    }

</style>