<template>
    <div>
        <!-- Navbar -->
        <VueScrollFixedNavbar>
            <Navbar 
                v-on:savePenaltyDecision="checkDataBeforeSave"
                v-on:confirmCancelSavePenaltyDecision="confirmCancelSavePenaltyDecision"
            />
            <ProgressBar mode="indeterminate" style="height: .3em; border-radius: 0px;" v-if="is_loading" />
        </VueScrollFixedNavbar>

            <!-- Thông tin chung -->
            <GeneralInfo 
                v-on:emitEntityType="setEntityType"
                ref="general_info"
            />
            
            <!-- Căn cứ -->
            <Article0
                ref="article0"
            />

            <!-- Điều 1 -->
            <!-- Cá nhân -->
            <Article11_Individual 
                v-if="entity_type_id === 1" 
                v-on:setPersonNameInArticle3="setPersonNameInArticle3"
                ref="article11_individual"
            />

            <!-- Tổ chức -->
            <Article11_Orgnization 
                v-if="entity_type_id === 2"
                v-on:emitRepresentativeLegal="setRepresentativeLegal"
                v-on:emitPenaltyOrganizationName="setPenaltyOrganizationName"
                ref="article11_organization"
            />

            <!-- Hành vi vi phạm -->
            <Article12 
                v-bind:entity_type_id="entity_type_id"
                ref="article12"
            />

            <!-- Điều 2 -->
            <Article2 
                ref="article2"
            />

            <!-- Điều 3: Lưu ý trước khi savePenaltyDecision sẽ check thông tin form -->
            <Article3
                v-bind:info_penaltied_person="{entity_type_id, penaltied_person_name, organization_name}"
                v-on:savePenaltyDecision="checkDataBeforeSave"
                v-on:confirmCancelSavePenaltyDecision="confirmCancelSavePenaltyDecision"
                ref="article3"
            />
   

        <!-- VALIDATE FORM BEFORE SAVE -->
        <div class="modal fade" id="validate_form">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" style="border-color: cadetblue; margin: 10px;">
                    
                    <!-- Header -->
                    <div class="modal-header myheader-info">
                        <i class="fas fa-exclamation-triangle myicon-info"></i>
                        <p class="modal-title" style="font-size: 18px; ">Các trường thiếu thông tin</p>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeFormValidate">
                            <span aria-hidden="true" style="color:black">&times;</span>
                        </button>
                    </div>

                    <!-- Body -->
                    <div style="margin: 15px">
                        <div class="row">
                            <!-- 1. Thông tin chung -->
                            <div v-if="error_general_info.length > 0">
                                <h6>
                                    Thông tin chung 
                                    <i class="fas fa-arrow-circle-right scroll-up txt-right micon" v-scroll-to="'#general-info'" @click="handCloseFormValidate()"></i>
                                </h6>
                                <p v-for="item in error_general_info" class="error-font">
                                <i class="nav-icon far fa-circle text-danger" style="font-size: 12px;"></i> {{item.erorr}} </p>
                            </div>

                            <!-- 2. Đối tượng xử phạt -->
                            <!-- 2.1. Cá nhân -->
                            <div v-if="error_individual.length > 0">
                                <h6>
                                    Đối tượng xử phạt là cá nhân
                                    <i class="fas fa-arrow-circle-right scroll-up txt-right micon" v-scroll-to="'#input-individual'" @click="handCloseFormValidate()"></i>
                                </h6>
                                <p v-for="item in error_individual" class="error-font">
                                <i class="nav-icon far fa-circle text-danger" style="font-size: 12px;"></i> {{item.erorr}} </p>
                            </div>
                            <!-- 2.2. Tổ chức -->
                            <div v-if="error_organization.length > 0">
                                <h6>
                                    Đối tượng xử phạt là tổ chức
                                    <i class="fas fa-arrow-circle-right scroll-up txt-right micon" v-scroll-to="'#input-organization'" @click="handCloseFormValidate()"></i>
                                </h6>
                                <p v-for="item in error_organization" class="error-font">
                                <i class="nav-icon far fa-circle text-danger" style="font-size: 12px;"></i> {{item.erorr}} </p>
                            </div>

                            <!-- 3. Article 12 -->
                            <div v-if="error_article12.length > 0">
                                <h6>
                                    Hành vi, hình thức xử phạt và biện pháp khắc phục hậu quả 
                                    <i class="fas fa-arrow-circle-right scroll-up txt-right micon" v-scroll-to="'#input_behavior'" @click="handCloseFormValidate()"></i>
                                </h6>
                                <p v-for="item in error_article12" class="error-font">
                                <i class="nav-icon far fa-circle text-danger" style="font-size: 12px;"></i> {{item.erorr}} </p>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>		
        </div>
        <button type="button" class="btn btn-info" data-toggle="modal" 
            data-target="#validate_form" 
            style="display:none" 
            ref="openConfirmValidate"></button>
        <!-- /VALIDATE FORM BEFORE SAVE -->


        <!-- HỦY LƯU QUYẾT ĐỊNH XỬ PHẠT -->
        <div class="modal fade" id="form-cancel">
            <div class="modal-dialog modal-default">
                <div class="modal-content">
                    <div class="modal-header bg-warning" style="color: white; background-color: #138496;">
                        <h6 class="modal-title"><i class="icon fas fa-ban"></i> Hủy lưu quyết định xử phạt </h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" ref="closeConfirmCancel" style="display: none">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="padding: 20px"> 
                            <span style="color: red;">Hệ thống sẽ xóa các trường thông tin đang nhập.</span> Bạn có chắc chắn xóa các thông tin này ?.
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-sm" v-on:click.prevent="cancelSavePenaltyDecision()" style="width: 150px; color: white; background-color: #DC5C51;">Xác nhận hủy lưu</button>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-default" data-toggle="modal" 
            data-target="#form-cancel" 
            style="display: none" 
            ref="openConfirmCancel">
            Confirm Delete
        </button>
        <!-- /HỦY LƯU QUYẾT ĐỊNH XỬ PHẠT -->

    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import {VueScrollFixedNavbar} from "vue-scroll-fixed-navbar";
    import ProgressBar from 'primevue/progressbar';
    
    import Navbar from './Navbar.vue'
    import GeneralInfo from './GeneralInfo.vue'
    import Article0 from './Article0.vue'
    import Article11_Individual from './Article1/Article11_Individual.vue'
    import Article11_Orgnization from './Article1/Article11_Orgnization.vue'
    import Article12 from './Article1/Article12.vue'
    import Article2 from './Article2.vue'
    import Article3 from './Article3.vue'
    

    export default {
        name: 'com-penalty-decision-index',
        components: {
            Navbar,
            GeneralInfo,
            Article0,
            Article11_Individual,           // Form add individual
            Article11_Orgnization,          // Form add orgnization
            Article12,                      // Hành vi vi phạm
            Article2,
            Article3,
            VueScrollFixedNavbar,
            ProgressBar
        },
        data() {
            return {
                is_loading: false,            // Loading khi save quyet dinh xu phat

                entity_type_id: 1,           // Mã xác định đối tượng xử phạt là cá nhân, tổ chức
                penaltied_person_name: '',   // Tên người bị phạt cá nhân hoặc của tổ chức,
                organization_name: '',       // Tên tổ chức (nếu là 2)

                // VALIDATE FORM BEFORE SAVE
                // =========================
                // General Info
                error_general_info: [],

                // Đối tượng bị xử phạt
                error_individual: [],
                error_organization: [],

                // Article12
                error_article12: [],         // Lỗi hành vi vi phạm và biện pháp khắc phục hậu quả

                // EDIT
                violation_decision_id: ''   // ID quyết định xử phạt đang chỉnh sửa.
            }
        },
        created(){
            this.actIsEdit(false)           // Sử dụng để khắc phục lỗi: nếu đang Edit quay sang làm ở tab khác, sau đó trở lại edit tiếp thì reset lại về trạng thái ban đầu.
        },
        // mounted(){ // Đã render DOM --> có thể tác động đến HTML. Sử dụng cho Edit khi không sử dụng KeepAlive
        //     if(this.violation_decision_id !== ''){ // Nếu chỉnh sửa thì lấy thông tin chỉnh sửa.
        //         this.handleGetInfoToEdit()
        //     }            
        // },
        // activated() { // Sử dụng cho Edit khi sử dụng KeepAlive
        //     if(this.violation_decision_id !== ''){ // Nếu chỉnh sửa thì lấy thông tin chỉnh sửa.
                
        //     }  
        // },
        // deactivated() { // Sử dụng cho KeepAlive khi out khỏi trang đang KeepAlive thì làm gì
        //     console.log('About has been deactivated')
        // },
        watch: { // watch params and data. 
            "$route.params.data": { // EDIT: lắng nghe lấy params violation_decision_id gửi đến từ trang "DANH SÁCH KẾT QUẢ XỬ PHẠT"
                handler: function(data) {
                    if(data){
                        this.violation_decision_id = data.violation_decision_id
                        this.entity_type_id = data.entity_type_id
                        this.handleGetInfoToEdit()
                    }
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
			...mapGetters([
				'getCurrentUser',
                'isEditFromServer'
			])
		},
        methods: {
            ...mapActions([ 
                'actSavePenaltyDecision',
                'actGetInfoToEdit',
                'actIsEdit'
            ]),

            // ==========================================================================================
            //                                           EDIT                                          //
            // ==========================================================================================
            // 1. Get info to Edit
            handleGetInfoToEdit(){

                var data = {
                    violation_decision_id: this.violation_decision_id, 
                    entity_type_id: this.entity_type_id
                }

                var data_json = JSON.stringify(data)

                this.actGetInfoToEdit(data_json).then(res => {
                    // console.log("data edit from Index ===", res)
                    // console.log("this.entity_type_id ===", this.entity_type_id)

                    if(res.ok){
                        // Xác nhận chỉnh sửa
                        this.actIsEdit(true)

                        // Thông tin chung
                        var general_info = res.data.general_info
                        this.$refs.general_info.setEdit(general_info)

                        // Căn cứ
                        var base = res.data.base
                        this.$refs.article0.setEdit(base)

                        // Điều 1 / Phần 1: Thông tin đối tượng xử phạt
                        if(this.entity_type_id === 1){ // Cá nhân
                            var individual = res.data.individual
                            this.$refs.article11_individual.setEdit(individual)
                        }

                        if(this.entity_type_id === 2){ // Tổ chức
                            var organization = res.data.organization
                            this.$refs.article11_organization.setEdit(organization)
                        }

                        // Điều 1 / Phần 2: Hành vi, hình thức xử phạt và biện pháp khắc phục hậu quả
                        var behavior  = res.data.behavior
                        var violation = res.data.violation
                        this.$refs.article12.setEdit({behavior, violation})

                        // Điều 2: Hiệu lực thi hành
                        this.$refs.article2.setEdit(violation)
                        
                        // Điều 3
                        this.$refs.article3.setEdit(violation)
                    }
                    
                })
            },

            // END EDIT /////////////////////////////////////////////////////////////////////////////////


            // ==========================================================================================
            //                                           ADD                                           //
            // ==========================================================================================
            // I. CHUYỂN TỔ CHỨC SANG CÁ NHÂN VÀ NGƯỢC LẠI
            // ==========================================================================================
            setEntityType(entity_type_id){
                this.entity_type_id = entity_type_id

                if(entity_type_id === 1){ // Cá nhân
                    this.resetFormChangeToIndividual()
                }

                if(entity_type_id === 2){ // Tổ chức
                    this.resetFormChangeToOrganization()
                }
            },

            // II. TRUYỀN TÊN TỔ CHỨC CÁ NHÂN SANG ĐIỀU 3
            // ==========================================================================================
            setPersonNameInArticle3(full_name_temp){
                this.penaltied_person_name = full_name_temp
            },

            setRepresentativeLegal(representative_legal){
                this.penaltied_person_name = representative_legal
            },

            setPenaltyOrganizationName(organization_name){
                this.organization_name = organization_name
            },

            // III. LẤY THÔNG TIN FORM
            // ==========================================================================================
            handSavePenaltyDecision(){     
                var entity_type_id = this.$refs.general_info.$data.entity_type_id

                var data = {
                    edit_status: this.isEditFromServer,                               // Nếu là false --> Add; Nếu là true: --> Edit
                    violation_decision_id: this.violation_decision_id,      // Nếu Add thì trường này rỗng ''. Nếu là Edit thì có thông tin.

                    // 3.1. THÔNG TIN CHUNG (GeneralInfo.vue)
                    general_info: this.getGeneralInfo(),
                
                    // 3.2. CĂN CỨ (Article0.vue)
                    base: this.getBaseInfo(),                    

                    // 3.3. ĐIỀU 1, PHẦN 1: THÔNG TIN ĐỐI TƯỢNG XỬ PHẠT
                    // 1. Cá nhân (Article11_Individual.vue): dữ liệu dạng mảng [obj1, obj2, ...]
                    individual: this.getDataIndividual(entity_type_id), 

                    // 2. Tổ chức (Article11_Orgnization): dữ liệu là obj(key:value)
                    organization: this.getDataOrganization(entity_type_id),

                    // 3.4. ĐIỀU 1, PHẦN 2: HÌNH THỨC XỬ PHẠT VÀ BIỆN PHÁP KHẮC PHỤC HẬU QUẢ
                    article12: this.getDataArticle12(),

                    // 3.5. ĐIỀU 2: HIỆU LỰC THI HÀNH
                    article2: this.getDataArticle2(),

                    // 3.6. ĐIỀU 3: GIAO CHO, NGƯỜI KÝ, NƠI NHẬN
                    article3: this.getDataArticle3(),

                    // 3.7. Người tạo văn bản
                    user_id_created: this.getCurrentUser.user_id
                }
                this.is_loading = true
                // console.log("data save = ", data)

                // Luu thông tin lên Server
                this.actSavePenaltyDecision(data).then((data) => {
                    // console.log("result actSavePenaltyDecision = ", data)

                    if(data.ok){
                        // Thông báo lưu thành công
                        toastr.success('Lưu quyết định xử phạt thành công!', '', {closeButton:true} )

                        // Reset lại form
                        this.is_loading = false

                        this.resetForm()

                        this.actIsEdit(false)
                        this.resetKeepAliveAfterSavePenaltyDecision()
                        

                    }else{
                        toastr.error('Lỗi: Lưu quyết định xử phạt không thành công!', '', {closeButton:true} )
                    }
                })
            },

            resetKeepAliveAfterSavePenaltyDecision(){
                var d = [];
                for(var vm of this.$router.app.$children[0].$children) {
                    if(vm._inactive === true)
                        d.push(vm);
                }
                for(var v of d) {
                    v.$destroy();
                }
            },


            // HÀM LẤY THÔNG TIN TỪ FORM
            // =============================================================================================
            // 0. Check thông tin các trường nhập trước khi lưu
            checkDataBeforeSave(){   

                // 1. Thông tin chung
                // ============================================================
                var entity_type_id = this.$refs.general_info.$data.entity_type_id
                var general_info = this.validateFormGeneralInfo()
                var flag_general_info = general_info.flag
                this.error_general_info = general_info.error
                
                // 2. Căn cứ
                // ============================================================

                // 3. Đối tượng xử phạt
                // ============================================================
                // 3.1. Cá nhân -----------------------------------------------
                if(entity_type_id === 1){
                    var individual = this.validateFormIndividual()
                    var flag_individual = individual.flag
                    this.error_individual = individual.error
                }
                
                // 3.2. Tổ chức -----------------------------------------------
                if(entity_type_id === 2){
                    var organization = this.validateFormOrganization()
                    var flag_organization = organization.flag
                    this.error_organization = organization.error
                }

                // 4. Hành vi, hình thức xử phạt và biện pháp khắc phục hậu quả
                // ============================================================
                var article12 = this.validateFormArticle12()
                var flag_article12 = article12.flag
                this.error_article12 = article12.error

                // Final: Nếu không còn lỗi, thì lưu quyết định xử phạt
                // ============================================================
                if( 
                    flag_general_info   === true & 
                    (flag_individual    === true ||
                    flag_organization   === true) &
                    flag_article12      === true
                ) {
                    this.handSavePenaltyDecision()

                }else{ // Nếu còn lỗi, thì hiển thị thông báo lỗi
                    this.$refs.openConfirmValidate.click()
                }
            },

            // 1. Thông tin chung: file GeneralInfo.vue
            getGeneralInfo(){
                return {
                    entity_type_id: this.$refs.general_info.$data.entity_type_id,                   // Đối tượng bị xử phạt: tổ chức, cá nhân        
                    province_code: this.$refs.general_info.$data.province_code,                     // Tỉnh (nơi xảy ra vi phạm)
                    organization_group_id: this.$refs.general_info.$data.organization_group_id,     // Mã tổ chức ra quyết định xử phạt
                }
            },

            validateFormGeneralInfo(){
                var flag = false
                var error = []
                var data = this.getGeneralInfo()
                if(!data.entity_type_id) error.push({erorr: 'Chưa chọn: Đối tượng bị xử phạt'})
                if(!data.province_code) error.push({erorr: 'Chưa chọn: Tỉnh (nơi xảy ra vi phạm)'})
                if(!data.organization_group_id) error.push({erorr: 'Chưa chọn: Đơn vị ra quyết định xử phạt'})
                if(error.length === 0) flag = true
                return {flag, error}
            },

            // 2. Căn cứ: file Article0.vue
            getBaseInfo(){
                return {
                    decision_organization_head: this.$refs.article0.$data.decision_organization_head,           // * Cơ quan chủ quản
                    decision_organization_name: this.$refs.article0.$data.decision_organization_name,           // * Tên cơ quan tổ chức ra quyết định (1)
                    violation_decision_number: this.$refs.article0.$data.violation_decision_number,             // * Số quyết định (2)
                    decision_site: this.$refs.article0.$data.decision_site,                                     // * Địa danh (3)
                    decision_date: moment(this.$refs.article0.$data.decision_date).format('YYYY-MM-DD'),        // * Ngày tháng năm ra quyết định xử phạt (v-calendar) (4)
                    decision_title_agency_name: this.$refs.article0.$data.decision_title_agency_name,           // Cơ quan ban hành
                    decision_law_base: this.$refs.article0.$data.decision_law_base                              // Căn cứ ban hành
                }
            },

            // 3. Điều 1 / Phần 1: Cá nhân (Article11_Individual.vue): dữ liệu dạng mảng [obj1, obj2, ...]
            getDataIndividual(entity_type_id){
                var individual = []

                if(entity_type_id === 1){ // Nếu là cá nhân
                    // Check số người vi phạm
                    let num_violation_person = this.$refs.article11_individual.$data.num_violation_person

                    if(num_violation_person === 1){ // Quyết định có 1 người vi phạm
                        // Thì đẩy data vào mảng individual
                        this.$refs.article11_individual.setInfoPersonViolationToIndividualArr()
                    }

                    // Nếu quyết định có nhiều người, thì mặc định dữ liệu đã ở trong mảng individual
                    // Trả dữ liệu cá nhân individual
                    individual = {
                        num_violation_person: num_violation_person, 
                        individual: this.$refs.article11_individual.$data.individual
                    }
                }
                return individual
            },

            validateFormIndividual(){
                var flag = false
                var error = []
                var data = this.getDataIndividual(1)

                // console.log("individual ===", individual)
                if(data.num_violation_person < 1) error.push({erorr: 'Chưa nhập: Số người vi phạm'})

                if(data.num_violation_person === 1){ // Nếu một người vi phạm
                    var individual = data.individual[0]

                    if(!individual.full_name) error.push({erorr: 'Chưa nhập: Họ và tên người vi phạm'})
                    if(!individual.sex_id) error.push({erorr: 'Chưa nhập: Giới tính'})
                    if(!individual.country_code) error.push({erorr: 'Chưa nhập: Quốc tịch'})
                }

                if(data.num_violation_person > 1){ // Nếu nhiều người vi phạm, check số lượng người đã nhập = số người khai báo
                    var individual = data.individual
                    if(individual.length < data.num_violation_person ){
                        error.push({erorr: `Chưa nhập đủ số người vi phạm, thiếu ${data.num_violation_person - individual.length} người.`})
                    }
                } 
                
                if(error.length === 0) flag = true
                return {flag, error}
            },

            // 4. Điều 1 / Phần 1: Tổ chức (Article11_Orgnization): dữ liệu là obj(key:value) | moment(this.start_date).format('YYYY-MM-DD'),
            getDataOrganization(entity_type_id){
                var organization = []
                if(entity_type_id === 2){
                    organization = {
                        organization_name: this.$refs.article11_organization.$data.organization_name,       // * Tên tổ chức
                        address: this.$refs.article11_organization.$data.address,                           // * Địa chỉ trụ sở chính
                        organization_code_number: this.$refs.article11_organization.$data.organization_code_number,             // * Mã số doanh nghiệp
                        business_certificate_number: this.$refs.article11_organization.$data.business_certificate_number,       // * Số GCN đăng ký đầu tư/doanh nghiệp
                        date_issued: moment(this.$refs.article11_organization.$data.date_issued).format('YYYY-MM-DD'),                                       // * Ngày cấp số GCN đăng ký đầu tư/doanh nghiệp (lưu vào CSDL)
                        issued_by: this.$refs.article11_organization.$data.issued_by,                           // * Nơi cấp số GCN đăng ký đầu tư/doanh nghiệp
                        representative_legal: this.$refs.article11_organization.$data.representative_legal,     // * Người đại diện theo pháp luật
                        position: this.$refs.article11_organization.$data.position,                             // * Chức danh
                        sex_id: this.$refs.article11_organization.$data.sex_id                                  // * Giới tính (3)
                    }
                }
                return organization
            },

            validateFormOrganization(){
                var flag = false
                var error = []
                var data = this.getDataOrganization(2)
                if(!data.organization_name) error.push({erorr: 'Chưa nhập: Tên của tổ chức'})
                if(!data.organization_code_number) error.push({erorr: 'Chưa nhập: Mã số doanh nghiệp'})
                if(error.length === 0) flag = true
                return {flag, error}
            },

            // 5. Điều 1 / Phần 2: Hình thức xử phạt và biện pháp khắc phục hậu quả (Article12.vue)
            getDataArticle12(){
                return {
                    num_violation_behavior: this.$refs.article12.$data.num_violation_behavior,                  // Số hành vi vi phạm
                    behavior_regulation_penalty: this.$refs.article12.$data.behavior_regulation_penalty,        // Dữ liệu dạng mảng [obj1, obj2,...]
                    increase_issue: this.$refs.article12.$data.increase_issue,                                  // Số (*). Các tình tiết tăng nặng (nếu có)
                    decrease_issue: this.$refs.article12.$data.decrease_issue,                                  // Số (**). Các tình tiết giảm nhẹ (nếu có)
                    money_penalty_total_int: this.$refs.article12.$data.money_penalty_total_int,                // Số . Tổng số tiền phạt bằng số
                    money_penalty_total_text: this.$refs.article12.$data.money_penalty_total_text,              // Số . Tổng số tiền phạt bằng chữ
                    extend_penalty_exhibit: this.$refs.article12.$data.extend_penalty_exhibit,                  // Số (6). Hình thức xử phạt bổ sung / Tịch thu tang vật
                    extend_penalty_vihicle: this.$refs.article12.$data.extend_penalty_vihicle,                  // Số (7). Hình thức xử phạt bổ sung / Tịch thu phương tiện
                    extend_penalty_vihicle_num: this.$refs.article12.$data.extend_penalty_vihicle_num,
                    extend_penalty_vihicle_unit_num: this.$refs.article12.$data.extend_penalty_vihicle_unit_num,
                    extend_penalty_other: this.$refs.article12.$data.extend_penalty_other,                      // Số (10). Hình thức xử phạt bổ sung / Nội dung khác có liên quan
                    extend_penalty_duration: this.$refs.article12.$data.extend_penalty_duration,                // Số (11). Thời gian thực hiện hình thức xử phạt bổ sung
                    remedial_measure: this.$refs.article12.$data.remedial_measure,                              // Số (12). Biện pháp khắc phục hậu quả (nếu có):
                    remedial_measure_duration: this.$refs.article12.$data.remedial_measure_duration,            // Số (13). Thời hạn thực hiện biện pháp khắc phục hậu quả
                    remedial_measure_content: this.$refs.article12.$data.remedial_measure_content,              // Số (14). Nội dung trực tiếp liên quan đến việc thực hiện biện pháp khắc phục hậu quả (nếu có)
                    remedial_measure_money_int: this.$refs.article12.$data.remedial_measure_money_int,          // Số (15, 16). Ông (bà)/Tổ chức(**) có tên tại Điều này phải hoàn trả số kinh phí là
                    remedial_measure_money_text: this.$refs.article12.$data.remedial_measure_money_text,
                    organization_get_money: this.$refs.article12.$data.organization_get_money,                  // Số (17). Cho: 16 .................................... là cơ quan đã thực hiện biện pháp khắc phục hậu quả theo quy định tại khoản 5 Điều 85 Luật Xử lý vi phạm hành chính.
                }
            },

            validateFormArticle12(){
                var flag = false
                var error = []
                var data = this.getDataArticle12()
                var num_violation_behavior = data.num_violation_behavior
                var behavior_regulation_penalty = data.behavior_regulation_penalty

                if(num_violation_behavior < 1) error.push({erorr: 'Chưa nhập: Số hành vi vi phạm'})
                if(behavior_regulation_penalty.length === 0) error.push({erorr: 'Chưa nhập: Hành vi vi phạm hành chính'})

                if(num_violation_behavior === 1 & behavior_regulation_penalty.length > 0){ // Nếu một người vi phạm
                    var one_behavior = data.behavior_regulation_penalty[0]
                    if(!one_behavior.violation_behavior_enter) error.push({erorr: 'Chưa nhập: Hành vi vi phạm hành chính'})
                    if(!one_behavior.regulation_at_enter) error.push({erorr: 'Chưa nhập: Quy định tại'})
                    if(!one_behavior.main_penalty_enter) error.push({erorr: 'Chưa nhập: Hình thức xử phạt chính'})
                    if(error.length === 0) flag = true
                }

                if(num_violation_behavior > 1){ // Nếu nhiều người vi phạm, check số lượng người đã nhập = số người khai báo
                    if(behavior_regulation_penalty.length < num_violation_behavior ){ // Nếu thiếu hành vi
                        error.push({erorr: `Chưa nhập đủ số hành vi vi phạm, thiếu ${num_violation_behavior - behavior_regulation_penalty.length} hành vi.`})
                    }
                }       

                if(error.length === 0) flag = true
                return {flag, error}
            },

            // 6. Điều 2: Hiệu lực thi hành: file Article2.vue
            getDataArticle2(){
                return this.$refs.article2.$data.date_issued        // Hiệu lực thi hành
            },

            // 7. Điều 3
            getDataArticle3(){
                return {
                    place_pay_violation_money: this.$refs.article3.$data.place_pay_violation_money,                 // * 1_Nơi nộp tiền phạt tại (19)
                    violation_account_number: this.$refs.article3.$data.violation_account_number,                   // * 2_Tài khoản số (20)
                    state_treasury_name: this.$refs.article3.$data.state_treasury_name,                             // * 3_Tên ngân hàng thu tiền nộp phạt (21)
                    duration_pay_fine: this.$refs.article3.$data.duration_pay_fine,                                 // * 4_Thời hạn nộp phạt
                    kept_exhibit_name: this.$refs.article3.$data.kept_exhibit_name,                                 // * 5_Tên tang vật, phương tiện bị giữ (22)
                    organization_implement_decision: this.$refs.article3.$data.organization_implement_decision,     // * 6_Tên cá nhân/tổ chức thực hiện thi hành quyết định (23).....để tổ chức thực hiện
                    organization_relation_violator: this.$refs.article3.$data.organization_relation_violator,       // * 7_Tên cơ quan ..... để biết và phối hợp thực hiện
                    position_signer: this.$refs.article3.$data.position_signer,                                                                 // * 8_Chức vụ của người ký
                    signed_person_name: this.$refs.article3.$data.signed_person_name,                                                           // * 9_Họ và tên người ký
                    hour_violator_get_decision: this.$refs.article3.$data.hour_violator_get_decision,                                           // * 10_Giờ giao quyết định xử phạt
                    minute_violator_get_decision: this.$refs.article3.$data.minute_violator_get_decision,                                       // * 11_Phút giao quyết định xử phạt
                    date_violator_get_decision: moment(this.$refs.article3.$data.date_violator_get_decision).format('YYYY-MM-DD')               // * 12_Ngày người vi phạm nhận quyết định xử phạt
                }
            },

            // IV. RESET FORM
            // ==========================================================================================
            confirmCancelSavePenaltyDecision(){
                this.$refs.openConfirmCancel.click()
            },

            cancelSavePenaltyDecision(){
                this.is_loading = false
                this.resetForm()
                this.$refs.closeConfirmCancel.click()
            },

            
           resetForm(){ // Sử dụng khi lưu xong quyết định xử phạt
                this.$refs.general_info.resetForm()
                this.$refs.article0.resetForm()

                if(this.entity_type_id === 1){
                    this.$refs.article11_individual.resetAll()
                }
                if(this.entity_type_id === 2){
                    this.$refs.article11_organization.resetForm()
                }               
                this.$refs.article12.resetForm()
                this.$refs.article2.resetForm()
                this.$refs.article3.resetForm()

                // Reset về mặc định form cá nhân
                this.entity_type_id = 1

                // Reset Edit = false
                this.resetEdit()
            },

            async resetEdit(){
                this.violation_decision_id = ''
                await this.actIsEdit(false)
            },

            resetFormChangeToIndividual(){ // Sử dụng khi chuyển đối tượng từ cá nhân sang tổ chức thì reset lại form cá nhân và form khác
                this.$refs.general_info.resetFormExceoptEntityType()
                this.$refs.article0.resetForm()
                this.$refs.article12.resetForm()
                this.$refs.article2.resetForm()
                this.$refs.article3.resetForm()
            },

            resetFormChangeToOrganization(){ // Sử dụng khi chuyển đối tượng từ cá nhân sang tổ chức thì reset lại form tổ chức và form khác
                this.$refs.general_info.resetFormExceoptEntityType()
                this.$refs.article0.resetForm()
                this.$refs.article12.resetForm()
                this.$refs.article2.resetForm()
                this.$refs.article3.resetForm()
            },

            // V. CLOSE FORM VALIDATE
            // ==========================================================================================
            handCloseFormValidate(){
                this.$refs.closeFormValidate.click()
            }

            // END ADD /////////////////////////////////////////////////////////////////////////////////

        }
    }
</script>

<style scoped>
    .error-font{
        color: #5C677D;
        font-size: 13px;
    }

    .myicon-info {
        margin-top: 6px;
        padding-right: 10px;
        font-size: 18px;
    }

    .myheader-info {
        padding: 22px;
        background-color: #ffc107;
        color: black;
    }

    .mybtn-cancel {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #FFFFFF !important;   
        color: black !important;  
        border: 1px solid #dee2e6 !important;  
    }

    .mybtn-cancel:hover {
        width: 150px; 
        padding: 2px !important; 
        font-size: 14px !important; 
        background-color: #E5E5E5 !important;  
        color: black !important; ; 
        border: 1px solid #dee2e6 !important;  
    }

</style>