// Sử dụng trong Data Table cho việc search không dấu
function nonAccentVietnamese(str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
}

// Sử dụng để Parse Json Web token để keeplogin
function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function getElementOfObjByKey(obj, myArrKey){
   // obj: obj { firstName: 'John', lastName: 'Doe', userName: 'johndoe12' }
   // mykey: key of obj cần lấy (VD: firstName)
   // Kết quả trả về: {firstName: 'John'}
    return Object.fromEntries(Object.entries(obj).filter(([key]) => key.includes(myArrKey)));
}

const getValueOfArrObjByKeyObj = (arr, key) => {
    // var district = [
    //     {
    //         "district_code": 269,
    //         "district_name": "Thị xã Sơn Tây"
    //     },
    //     {
    //         "district_code": 271,
    //         "district_name": "Huyện Ba Vì"
    //     },
    // ]

    // Kết quả trả về lấy theo 'district_code': 
    // result = [269, 271]

    var result = []
    arr.forEach(obj => {
        result.push(obj[key])
    });
    return result;
}

const getKeyAndValueOfArrObjByKey = (arr, key) => {  // Lấy cặp key = value theo key của obj trong arrObj
    // var district = [
    //     {
    //         "district_code": 269,
    //         "district_name": "Thị xã Sơn Tây"
    //     },
    //     {
    //         "district_code": 271,
    //         "district_name": "Huyện Ba Vì"
    //     },
    // ]

    // Kết quả trả về lấy theo 'district_code': 
    // result = [ { district_code: 269 }, { district_code: 271 } ]

    var result = []
    arr.forEach(obj => {
        let myobj = {}
        myobj[key] = obj[key]
        result.push(myobj)
    });
    return result;
}

function getKeyOfArrayObjByKey(arrObj, arrMyKey) {
    // arrObj: const users = {
    //     John: { username: 'johncam112', age: 19 },
    //     Daniel: { key: 'Dandandel1', age: 21 },
    //     Ruth: { key: 'rutie01', age: 24 },
    //     Joe: { key: 'Joemathuel', age: 28 }
    // };

    // const arrKey =['Ruth', 'Daniel'];

    const filteredArray = Object.keys(arrObj)
        .filter(key => arrMyKey.includes(key))
        .reduce((obj, key) => {
            obj[key] = arrObj[key];
            return obj
        }, {});

    return filteredArray;
}


const getValueObjInArrayByKey = (arr_selected, key = '') => { // Lấy giá trị theo key
    var str = ''
    arr_selected.forEach(element => {
        str += element[key] + ', '
    });
    
    return str.substring(0, str.length - 2);
}


// 0. Set time out: Thời gian hiển thị Message
const setTimeShow = (isValue = true, time) => {
    setTimeout(() => isValue = false, time);
}

// 1. Đếm số lượng 
const countSelected = (data) => {
    if (data.length) return data.length
}

// 2. Kiểm tra giá trị tồn tại trong Arr: Kết quả trả về là giá trị true | false
const checkValueExit = (arrValue, valueCheck) => {
    return arrValue.includes(valueCheck)
}


// 4. Conver từ Arr Integer tới Arr character
const convertArrIntToArrStr = (arrInt) => {
    return arrInt.map(String)       // ArrStr: Arr đã convert từ Arr Integer tới Arr String
}


// Hàm sử dụng để loại bỏ các element in Obj là null, '' hoặc undefined
function removeEmptyElementInObj(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName].length === 0) {
            delete obj[propName];
        }
    }
    return obj
}


// Hàm sử dụng để đếm số lượng phần tử
const countElementInObj = (obj) => {
    return Object.keys(obj).length;
}

// Loại bỏ phần tử của mảng theo mảng các phần tử (Để sau ứng dụng)
// let arrFirst = [2, 3, 5, 8, 4];
// let arrTwo = [2, 4];
// arrFirst = arrFirst.filter(item => !arrTwo.includes(item));      // [3,5,8]

// Lấy item có ở ArrayFirst nhưng không có ở ArrayTwo
const getElementOnlyInArrayFirst = (ArrFirst, arrTwo) => {
    return ArrFirst.filter(item => !arrTwo.includes(item));
}

// Lấy Item đều có ở ArrFirst và ArrTwo
const getElementInBothArray = (ArrFirst, arrTwo) => {
    return ArrFirst.filter(item => arrTwo.includes(item));
}

// 3. getCodeSelectedOption sử dụng để: 1) lấy toàn bộ key_code ở mảng arr_selected = [{key1: 'key_code', key2 = 'value of key_code' }] dựa vào key1; 2) Xóa bỏ các element đã selected nếu bỏ chọn.
// Tom lai: ham su dung lay value of key_code va luu gia tri do vao key = key_code
const getCodeSelectedOption = (arr_selected, arr_code_selected, key = '') => {
    // console.log('Get Code option selected', arr_selected)

    arr_code_selected = []
    // Tham số: arr_selected là mảng đã chọn, arr_code_selected sử dụng để lưu giá trị key_code của mảng arr_selected; key chính là giá trị key1
    if (arr_selected.length) {       // Nếu chọn select option
        // Get list code
        arr_selected.forEach(item => {
            arr_code_selected.push(item[key])
        })

    } else {// Nếu bỏ chọn hoặc không chọn thì không hiển thị
        arr_selected = []
        arr_code_selected = []
    }
    return arr_code_selected;
}


function uniqBy(a, key) {
    var seen = {};
    return a.filter(function (item) {
        var k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
}

const validateInputNumber = (number) => {
    // var reg = new RegExp('/^\d*\.?\d*$/')
    // var check = reg.test(this.area)

    if (number === null | number === '' | number === undefined) {
        return null
    } else if (number > 0) {
        return true
    } else {
        return false
    }
}

const validateDensity = (density) => {
    var check = /^([1-9]|[1-9]\d+)$/.test(density)

    if (density === null | density === '') {
        return null
    } else if (check === true) {
        return true
    } else {
        return false
    }
}

// Validate data
function validateElementInObj(obj) {
    var obj_clean = {}
    var countEleTrue = 0  // Số code_selected hợp lệ
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName].length === 0) {
            obj_clean[propName] = 'false';
        } else {
            countEleTrue = countEleTrue + 1
            obj_clean[propName] = obj[propName];
        }
    }
    return { obj_clean, countEleTrue }
}


// GET COOKIE
function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


// Fix số 0 trước ngày và tháng
function fixMonthAndDay(num){
    if(num === '01') return 1
    if(num === '02') return 2
    if(num === '03') return 3
    if(num === '04') return 4
    if(num === '05') return 5
    if(num === '06') return 6
    if(num === '07') return 7
    if(num === '08') return 8
    if(num === '09') return 9
    return num
}

// HTML to text
function htmlToText(html){
    return html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")
}

// Viết chữ hoa ký tự đầu tiên trong chuỗi
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Viết chữ số hàng nghìn
function formatNumberVn(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


// Hàm sử dụng để check ẩn, hiện mục Article, Clause, Point, Under point khi người dùng selected Vue multiselect option
function checkToShowSubOption(selectOptions){ //selectOptions: là array obj [obj1, obj2, ...]
    if(Object.keys(selectOptions).length > 0) return true
    return false
}

function sortDate(data) {
    data.sort(function(a,b) {
        a = a.split('-').reverse().join('');
        b = b.split('-').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0;
    });
}


export {
    sortDate,
    getKeyOfArrayObjByKey,
    getKeyAndValueOfArrObjByKey,
    getValueOfArrObjByKeyObj,
    getElementOfObjByKey,
    getElementOnlyInArrayFirst,
    getElementInBothArray,
    getCodeSelectedOption,
    validateInputNumber,
    validateDensity,
    validateElementInObj,
    getCookie,
    nonAccentVietnamese,
    parseJwt,                 // Để lấy thông tin user  
    getValueObjInArrayByKey,    // Get key code
    setTimeShow,              // Set thời gian hiển thị Message
    countSelected,            // Đếm số lượng selected option
    checkValueExit,           // Check exits value in array
    convertArrIntToArrStr,    // Convert 
    uniqBy,
    removeEmptyElementInObj,
    countElementInObj,
    fixMonthAndDay,
    htmlToText,
    capitalizeFirstLetter,
    formatNumberVn,
    checkToShowSubOption
}