import LoginPage from '@/pages/Login/Index.vue'
import ForgetPassword from '@/pages/Login/ForgetPassword.vue'
import ResetPassword from '@/pages/Login/ResetPassword.vue'

export default [
    {
        path: '/',
        name: 'login',
        component: LoginPage,
        meta:{ keepAlive: false } 
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        component: ForgetPassword,
        meta:{ keepAlive: false } 
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta:{ keepAlive: false } 
    }
]