import { parseJwt } from '../../helper/index'

export default {

    isLogin: state => { 
        if (state.ACCESS_TOKEN && Object.keys(state.current_user).length > 0) { // Sau khi login
            let userObj = parseJwt(state.ACCESS_TOKEN)
            
            if (userObj) {
                return true;

            } else {
                return false;
            }

        } else { // Mới load trang, chưa login --> isLogin = false
            return false
        }
    },

    getCurrentUser: state => {
        return state.current_user
    },

    isSuperAdmin: state => {
        if (state.current_user.role_id === 1) return true
        return false
    },

    isAdminSystem: state => {
        if (state.current_user.role_id === 2 && state.current_user.location_level_code === 1) return true
        return false
    },

    isAdminProvince: state => {
        if (state.current_user.role_id === 2 && state.current_user.location_level_code === 3) return true
        return false
    },

    isDecisionMaker: state => {
        if (state.current_user.role_id === 3 || state.current_user.role_id === 4) return true
        return false
    },

    isUserLevelNation: state => {
        if (state.current_user.location_level_code === 1) return true
        return false
    },

    isUserLevelRegion: state => { 
        if (state.current_user.location_level_code === 2) return true
        return false
    },

    isUserLevelProvince: state => { 
        if (state.current_user.location_level_code === 3) return true
        return false
    },

    isUserLevelDistrict: state => {
        if (state.current_user.location_level_code === 4) return true
        return false
    }
}