<template>
    <div>
        <div class="card" style="border-radius: 0px; margin-top: 30px;">
            <div class="card-header ui-sortable-handle" style="padding-top: 15px;">
                <h3 class="card-title">
                    <i class="ion ion-clipboard mr-1"></i>
                    Điều 2: Hiệu lực thi hành
                </h3>
                <!-- <i class="fas fa-arrow-circle-up scroll-up txt-right micon" v-scroll-to="'#top-nav-header'"></i> -->
            </div>
            <div class="card-body">

                <!-- I. MẪU QUYẾT ĐỊNH -->
                <div class="mheader-1"> <i class="fas fa-file-word fa-lg"></i> Mẫu quyết định </div>

                <div class="mline">
                    Quyết định này có hiệu lực thi hành kể từ
                    <span class="num1 txt-center " :class="ldate_issued">1</span> 
                    <span v-html="html_date_issued"></span>
                </div>

                <!-- hr line -->
                <!-- <hr class="mtop-bottom-40"> -->
            </div>

            <!-- I.2. NHẬP THÔNG TIN -->
            <!-- ========================================================================== -->
            <div class="card-body mbg-input">

                <div class="mheader-2"> 
                    <i class="fas fa-marker fa-lg"></i> Nhập thông tin 
                    <i class="fas fa-backspace scroll-up fa-lg txt-right micon" @click="resetForm()"></i>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <p class="mheader-3"> <i class="fas fa-gavel micon"></i> 
                            Hiệu lực thi hành  <span class="mybadge txt-right">1</span>
                            <span class="txt-right pointer" @click="showGuide()">
                                <i class="far fa-question-circle" v-if="!isShowGuide" style="margin-top: 8px; margin-right: 5px;"></i>
                                <i class="far fa-window-close" v-if="isShowGuide" style="margin-top: 8px; margin-right: 5px;"></i>
                            </span>
                        </p>
                        <div v-if="isShowGuide">
                            Ghi cụ thể theo từng trường hợp:
                            <ul>
                                <li>Trường hợp thông thường thì ghi: <span class="bold">«ngày ký»</span>.</li>
                                <li>Trường hợp khác thì ghi cụ thể: <span class="bold">«ngày, tháng, năm»</span>.</li>
                                <li>Trường hợp quyết định có áp dụng hình thức xử phạt tước quyền sử dụng giấy phép, chứng chỉ hành nghề có thời hạn thì ghi: <span class="bold">«ngày, tháng, năm»</span>, mà cá nhân/tổ chức bị xử phạt bản giao giấy phép, chứng chỉ hành nghề cho người ra quyết định xử phạt vi phạm hành chính hoặc cho người có thẩm quyền xử phạt vi phạm hành chính theo ngành, lĩnh vực thuộc cơ quan chuyên môn của Ủy ban nhân dân cấp tỉnh nơi cá nhân/tổ chức bị xử phạt cư trú.</li>
                            </ul>
                        </div>
                        <div class="input-group mb-3">
                            <input v-model="date_issued" type="text" class="form-control" placeholder="Nhập thông tin hiệu lực thi hành" autocomplete="off">
                        </div>
                    </div>

                </div>
                <div id="article3" style="margin-bottom: 10px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vd-article2',
        data() {
            return {
                isShowGuide: false,
                date_issued: ''
            }
        },
        computed: {
            ldate_issued: function (){
                if(this.date_issued === '') return ''
                return 'hide'
            },

            html_date_issued: function (){
                if(this.date_issued === '') return '.....................................................................................................................................................................................................'
                return '<span class="bold">' + this.date_issued +'</span>'
            },
		},
		methods: {
			resetForm(){
                this.date_issued = ''
            },

            showGuide(){
                this.isShowGuide = !this.isShowGuide
            },

            setEdit(data){
                // console.log("data setEdit ===", data)
                this.date_issued = data.date_issued
            }
		}
    }
</script>

<style scoped>
    #toast-container.toast-info{
        background-color: #138496;
    }
</style>