<template>
    <div>
        <PhoneBook />
    </div>
</template>

<script>
    import PhoneBook from '@/components/PhoneBook/Index.vue';

    export default {
        name: 'page-phone-book',
        components: {
            PhoneBook
        }
    }
</script>

<style scoped>

</style>