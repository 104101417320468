<template>
    <div style="margin: 5px 0px 10px 0px">
        <div class="bold">Điều 9. Tình tiết giảm nhẹ </div>
        <div>Những tình tiết sau đây là tình tiết giảm nhẹ:</div>
        <div>1. Người vi phạm hành chính đã có hành vi ngăn chặn, làm giảm bớt hậu quả của vi phạm hoặc tự nguyện khắc phục hậu quả, bồi thường thiệt hại;</div>
        <div>2. Người vi phạm hành chính đã tự nguyện khai báo, thành thật hối lỗi; tích cực giúp đỡ cơ quan chức năng phát hiện vi phạm hành chính, xử lý vi phạm hành chính;</div>
        <div>3. Vi phạm hành chính trong tình trạng bị kích động về tinh thần do hành vi trái pháp luật của người khác gây ra; vượt quá giới hạn phòng vệ chính đáng; vượt quá yêu cầu của tình thế cấp thiết;</div>
        <div>4. Vi phạm hành chính do bị ép buộc hoặc bị lệ thuộc về vật chất hoặc tinh thần;</div>
        <div>5. Người vi phạm hành chính là phụ nữ mang thai, người già yếu, người có bệnh hoặc khuyết tật làm hạn chế khả năng nhận thức hoặc khả năng điều khiển hành vi của mình;</div>
        <div>6. Vi phạm hành chính vì hoàn cảnh đặc biệt khó khăn mà không do mình gây ra;</div>
        <div>7. Vi phạm hành chính do trình độ lạc hậu;</div>
        <div>8. Những tình tiết giảm nhẹ khác do Chính phủ quy định.</div>
    </div>
</template>

<script>
    export default {
        name: 'decrease-issue',
        data() {
            return {
            }
        },
    }
</script>

<style scoped>

</style>