import axios from 'axios';

const VUE_APP_PROTOCAL  = process.env.VUE_APP_PROTOCAL || 'http'
const HOST              = process.env.VUE_APP_HOST || 'localhost'
const PORT              = process.env.VUE_APP_PORT_SERVER || 1111
const ACCESS_TOKEN      = localStorage.getItem('ACCESS_TOKEN') || ''
const API               = (process.env.VUE_APP_ENV === 'dev') ? '' : '/api'

const axiosInstance = axios.create({
    baseURL: `${VUE_APP_PROTOCAL}://${HOST}:${PORT}` + API,    
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ACCESS_TOKEN}`
    }
});

export default axiosInstance;
