import axiosInstance from '@/plugin/axios.js';

export default {

    // I. CÁ NHÂN
    async actSearchIndividualViolation({ commit }, data_json) { // Tìm kiếm đối tượng vi phạm ở trang "Danh sách kết quả xử phạt"
        try {
            var res = await axiosInstance.get('/search_violation/individual/' + data_json)
            const result = res.data;
            // console.log('result actSearchIndividualViolation = ', result)

            if (res.status === 200) {
                commit('GET_LIST_INDIVIDUAL_SEARCH', result)

                return {
                    ok: true,
                    data: result.list_individual_search
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {

            console.log('Error action actSearchIndividualViolation: ', error.response.data)
            return error.response.data 
        }
    },

    async actSetListIndividualEmpty({ commit }) { // Tìm kiếm đối tượng vi phạm ở trang "Danh sách kết quả xử phạt"
        try {

            var result = {
                list_individual_search: []
            }

            commit('GET_LIST_INDIVIDUAL_SEARCH', result)

        } catch (error) {
            console.log('Error action actSearchIndividualViolation: ', error)
        }
    },


    // II. TỔ CHỨC
    async actSearchOrganizationViolation({ commit }, data_json) { // Tìm kiếm đối tượng vi phạm ở trang "Danh sách kết quả xử phạt"
        try {
            var res = await axiosInstance.get('/search_violation/organization/' + data_json)
            const result = res.data;
            // console.log('result actSearchOrganizationViolation = ', result)

            if (res.status === 200) {
                commit('GET_LIST_ORGANIZATION_SEARCH', result)

                return {
                    ok: true,
                    data: result.list_organization_search
                }

            } else {
                return {
                    ok: false,
                    error: res.data.error
                }
            }

        } catch (error) {
            console.log('Error action actSearchOrganizationViolation: ', error)
        }
    },


    async actSetListOrganizationSearchEmpty({ commit }) { // Tìm kiếm đối tượng vi phạm ở trang "Danh sách kết quả xử phạt"
        try {

            var result = {
                list_organization_search: []
            }

            commit('GET_LIST_ORGANIZATION_SEARCH', result)
            
        } catch (error) {
            console.log('Error action actSetListOrganizationSearchEmpty: ', error)
        }
    },
}