import { getValueOfArrObjByKeyObj } from '../../helper/index'

export default {

    getListLocationLevel: state => { // Lấy danh location_level
        return state.list_location_level
    },

    getListLocationLevelAdminProvince: state => { // Lấy danh location_level
        var list_location_level = []
        
        state.list_location_level.forEach(obj => {
            if(obj.location_level_code === 3 || obj.location_level_code === 4){
                list_location_level = [...list_location_level, obj]
            }
        })
        return list_location_level
    },

    getListOrgByLocationLevel: state => { 
        return state.list_organization_by_location_level
    },

    getListDistrictByProvinceCode: state => { 
        return state.list_district_by_province
    },

    getListProvince: state => { // Lấy danh sách các tỉnh
        return state.list_province
    },

    getEntityType: state => { // Lấy bảng định nghĩa tổ chức, cá nhân
        return state.entity_type
    },

    getSexType: state => { // Lấy bảng định nghĩa giới tính
        return state.sex_type
    },

    getListCountry: state => { // Lấy bảng định nghĩa quốc gia
        return state.list_country
    },

    getListOrganizationGroup: state => { // Lấy bảng định nghĩa quốc gia
        return state.list_organization_group
    },

    getListArticle: state => { // Lấy bảng định nghĩa quốc gia
        return state.list_article
    },

    getListDecisionStatus: state => {
        return state.list_decision_status
    },
}