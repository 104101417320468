// MANAGE USER
// Home page ======================================
// PAGE: Trang này xây dựng router view cho trang quản trị
import PageManageUser from '@/pages/ManageUser/Index.vue'


// 1. COMPONENT: CONTENT USER MANAGEMENT (VIEW IN VIEW ROUTER)======================================
// View router: User management
import UserManagement from '@/components/ManageUser/ViewRouter/User/Index.vue'        // Quản lý tài khoản cá nhân

// View router: User guide
import UserGuide from '@/components/ManageUser/ViewRouter/Guide/Index.vue'               // Hướng dẫn quản trị

// 3. AUTHENTICATED =======================================
// import { ifAuthenticated } from '../authenticated.js'

// 4. ROUTER TO REF TO PAGE
// Gắn Page import vào router để điều hướng đến trang Import
export default [ 
    { // Pages: header , sidebar, content (view router)
        path: '/manage-user',
        name: 'manage-user',
        component: PageManageUser,                      
        // beforeEnter: ifAuthenticated,
        children: [ // View Router
            {
                path: 'user-manage',
                name: 'user-manage',
                component: UserManagement,           // Tài khoản cá nhân / Quản trị tài khoản
                meta:{ keepAlive: false } 
            },
            {
                path: 'user-guide',
                name: 'user-guide',
                component: UserGuide,            // Hướng dẫn quản trị
                meta:{ keepAlive: false } 
            }
        ]
    }
]